import { Button } from "@wac-ui-dashboard/wac_component_library";
import useHideProfileForm from "./usePdfModalForm";
import style from "./hideProfileForm.scss";

const PdfModalForm = ({ handleCancel, handleDownloadPdf, isFetchLoading ,pdfLoading }) => {
  const {
    formik,
    // loggedStaffData,
    hasAddressPermission,
    hasPhonePermission,
    hasRoutePermission,
    hasHoroscopePermission
  } = useHideProfileForm({
    handleCancel,
    handleDownloadPdf,
  });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Manage Download</h4>
      </div>

      <div className={`pro-w-100 row row-cols-auto pro-mt-5`}>

        <>
          {hasPhonePermission &&
            (<div className={`pro-check-box pro-w-auto`}>
              <input
                type="checkbox"
                className="pro-check "
                id="phone"
                name="phone"
                checked={formik.values?.phone ?? false}
                onChange={(e) => formik.setFieldValue("phone", !formik.values?.phone ?? false)}
              />
              <label htmlFor="phone" className="pro-check-label">
                Phone
              </label>
            </div>
            )}
          {hasAddressPermission &&
            (<div className={`pro-check-box pro-w-auto`}>
              <input
                type="checkbox"
                className="pro-check "
                id="address"
                name="address"
                checked={formik.values?.address ?? false}
                onChange={(e) => formik.setFieldValue("address", !formik.values?.address ?? false)}
              />
              <label htmlFor="address" className="pro-check-label">
                Address
              </label>
            </div>
            )}
          {hasRoutePermission &&
            (<div className={`pro-check-box pro-w-auto`}>
              <input
                type="checkbox"
                className="pro-check "
                id="route"
                name="route"
                checked={formik.values?.route ?? false}
                onChange={(e) => formik.setFieldValue("route", !formik.values?.route ?? false)}
              />
              <label htmlFor="route" className="pro-check-label">
                Route
              </label>
            </div>
            )}
          {hasHoroscopePermission &&
            (<div className={`pro-check-box pro-w-auto`}>
              <input
                type="checkbox"
                className="pro-check "
                id="horoscope"
                name="horoscope"
                checked={formik.values?.horoscope ?? false}
                onChange={(e) =>
                  formik.setFieldValue("horoscope", !formik.values?.horoscope ?? false)
                }
              />
              <label htmlFor="horoscope" className="pro-check-label">
                Horoscope
              </label>
            </div>
            )}
        </>



        <div className="col-12 pro-mt-6 pro-d-flex pro-justify-end">
          <Button className={"pro-btn-link lg pro-px-5"} onClick={() => handleCancel()}>
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-mx-3 ${pdfLoading ? "loading" : ""}`}
            disabled={isFetchLoading}
            onClick={formik.handleSubmit}
            type="submit"
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PdfModalForm;
