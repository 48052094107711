import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showTableFieldsModal: false,
};

const orderColumnSlice = createSlice({
  name: "orderColumn",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = orderColumnSlice.actions;

export default orderColumnSlice.reducer;
