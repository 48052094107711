import {
  HeadingGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useAddedServiceList from "./useAddedServiceList";

const AddedServiceList = ({ refetchRef, formik }) => {
  const {
    list,
    isFetching,
    currentPage,
    paginationOptions,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
  } = useAddedServiceList({ refetchRef, formik });
  return (
    <div>
      <HeadingGroup title={"Added Service"} extraClassName={`pro-mb-2`} />
      <div className={`col-auto pro-pb-6`}>
        <Table
          data={list?.data?.data || []}
          uniqueID={"web_id"}
          fields={list?.data?.fields}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={10}
        />

        {list?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              list?.data?.total_count / list?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
    </div>
  );
};

export default AddedServiceList;
