import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const time = {
  startTime: new Date(),
  endTime: new Date(),
};

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  searchKey: "",
  dateRange: [
    moment(Date.now()).add(-30, "days").format("YYYY-MM-DD"),
    moment(Date.now()).format("YYYY-MM-DD"),
  ],
  showFilterModal: false,
  timeFilter: ["00:00", "23:59"],
  timeFilterValue: [
    new Date(time.startTime.setHours(0, 0, 0, 0)),
    new Date(time.endTime.setHours(23, 59, 59, 999)),
  ],
};

const incomingCallSlice = createSlice({
  name: "incomingCall",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = incomingCallSlice.actions;

export default incomingCallSlice.reducer;
