import {
  Button,
  HeadingGroup,
  OffCanvasLayout,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import ErrorMessage from "../../Global/ErrorMessage";
import UserDetailTableShimmer from "../../Global/Shimmers/UserDetailTableShimmer";
import UserDetailTableListing from "../../Global/UserDetailTableListing";
import style from "./activitySearch.module.scss";
import ActivitySearchForm from "./ActivitySearchForm/ActivitySearchForm";
import useActivitySearch from "./useActivitySearch";
import AddService from "../../../pages/Profile/AddService";
import AddedServiceExistList from "../../Profile/AddService/AddedServiceExistList";
import Select from "react-select";

const ActivitySearch = () => {
  const {
    formik,
    state,
    showScroll,
    horoscope,
    optionsData,
    isSuccess,
    profileState,
    addedServiceExistState,
    exportOptions,
    exportValue,
    exportLoading,
    hasExportPermission,
    setExportValue,
    handleExportActivityResult,
    handleChangeToggle,
    closeModal,
    handleScrollToTop,
    lastElement,
    handleSearch,
    handleReset,
    handleSearchAddService,
    handleCloseOffCanvas,
  } = useActivitySearch();
  return (
    <div className={`pro-mb-4`}>
      {showScroll && (
        <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
          <span class="material-symbols-outlined">expand_less</span>
        </button>
      )}
      <HeadingGroup
        title={"User Activity Search"}
        extraClassName={`${style.fixed_position}`}
      >
        <div className="pro-check-box pro-items-center">
          <Button
            className={"pro-btn-link pro-px-5 pro-mx-2"}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            type="submit"
            className={`pro-btn pro-btn-primary ${
              state.activitySearchStatus === "pending" ? "loading" : ""
            }`}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </HeadingGroup>
      <ActivitySearchForm
        formik={formik}
        horoscope={horoscope}
        handleChangeToggle={handleChangeToggle}
      />
      <div className="pro-d-flex pro-justify-end pro-py-2 pro-pe-2 pro-gap-2 pro-items-center">
        {state.activitySearchData?.data &&
          state.activitySearchData?.data?.[0]?.user_data && (
            <div
              className={
                "pro-d-flex pro-justify-end pro-py-2 pro-pe-2 pro-gap-2 pro-items-center"
              }
            >
              <label
                htmlFor="flexSwitchCheckDefault"
                className="pro-font-sm pro-mb-0 pro-fw-medium"
              >
                Horoscope Matched
              </label>
              <div className="form-check form-switch pro-mb-0">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={horoscope}
                  // onChange={(e) => updateTutorStatus(data?._id)}
                  onChange={handleChangeToggle}
                />
              </div>
            </div>
          )}

        {state?.activitySearchData?.data &&
          state.activitySearchData?.data?.length > 0 &&
          hasExportPermission && (
            <div className="pro-d-flex ">
              <div className="col-auto pro-ms-3 pro-me-3">
                <Select
                  id="export_options"
                  isClearable={false}
                  placeholder={"Export Options"}
                  options={exportOptions}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.value}
                  className={`pro-input lg `}
                  classNamePrefix="pro-input"
                  value={exportOptions?.filter(
                    (p) =>
                      // registrationReportState?.currentPlatformFilter ===
                      exportValue === p.value
                  )}
                  onChange={(value) => setExportValue(value?.value)}
                ></Select>
              </div>
              <button
                className={`pro-btn pro-btn-primary pro-px-5 lg  ${
                  exportLoading ? "loading" : ""
                }`}
                onClick={handleExportActivityResult}
              >
                Export
              </button>
            </div>
          )}
      </div>
      <UserDetailTableListing
        data={state.activitySearchData?.data || []}
        lastElement={lastElement}
        search
        handleSearchAddService={handleSearchAddService}
        activity
        horoscope={horoscope}
        handleChangeToggle={handleChangeToggle}
      />

      <OffCanvasLayout
        show={profileState.showCreateModal}
        handleClose={handleCloseOffCanvas}
        title={"Add Service"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        body={
          <AddService
            search
            activity
            optionsData={optionsData}
            isSuccess={isSuccess}
          />
        }
      />

      <ModalLayout
        show={addedServiceExistState.showAddedServiceModal}
        handleClose={closeModal}
        propStyle={{ root: style.root_modal }}
      >
        <AddedServiceExistList />
      </ModalLayout>

      {state?.activitySearchStatus === "pending" ? (
        <div className={`col-12 pro-mt-4`}>
          <div className={`row g-4`}>
            {[...Array(2)].map((_, i) => (
              <div className={`col-6`} key={i}>
                <UserDetailTableShimmer />
              </div>
            ))}
          </div>
        </div>
      ) : (
        state?.activitySearchStatus === "fulfilled" &&
        state?.activitySearchData?.data?.length === 0 && <ErrorMessage />
      )}
      {state?.activitySearchStatus === "rejected" && <ErrorMessage />}
    </div>
  );
};

export default ActivitySearch;
