import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateProfileConfig } from "../../../store/slices/Profile";
import { updateConfig } from "../../../store/slices/Profile/AddedServiceExist";

const useUserDetailTable = ({ search, data }) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];
    
  // eslint-disable-next-line
  const navigate = useNavigate();

  const handleClick = () => {
    sessionStorage.setItem("active", data?.profile_data?.id);
    const newIds = ids.filter((id) => id !== data?.profile_data?.id).join(",");
    // if (search) {
    //   window.open(
    //     `${
    //       window.location.origin
    //     }/${`profile?active=${data?.profile_data?.id}&id=${newIds}`}`
    //   );
    // } else {
    //   navigate({
    //     pathname: "/profile",
    //     search: `active=${data?.profile_data?.id}&id=${newIds}`,
    //   });
    // }
    window.open(
      `${
        window.location.origin
      }/${`profile?active=${data?.profile_data?.id}&id=${newIds}`}`
    );
  };

  const handleAddService = (e, partner_web_id) => {
    e?.stopPropagation();
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = true;
        state.partner_client_web_id = partner_web_id;
      })
    );
    const dateInput1 = document.getElementById("service_date");
    const dateInput2 = document.getElementById("follow_up_date");

    if (dateInput1) {
      dateInput1.value = ""; 
    }
    if (dateInput2) {
      dateInput2.value = ""; 
    }
    
    dispatch(
      updateConfig((state) => {
        state.storedFormikValues = {};
      })
    );
  };

  return {
    handleClick,
    handleAddService,
  };
};

export default useUserDetailTable;
