import React from "react";
import {
  HeadingGroup,
  SearchFilters,
  Table,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import useOutgoingCall from "./useOutgoingCall";
import { FaSort } from "react-icons/fa";
import { DateRangePicker } from "rsuite";

const OutgoingCall = () => {
  const {
    outgoingCallData,
    outgoingCallState,
    isFetching,
    paginationOptions,
    isLoading,
    initialDateRange,
    initialTimeRange,
    handleClearValues,
    handleGetValues,
    handleDateChange,
    handleSearch,
    handleClearClick,
    handlePageSize,
    handlePagination,
    getRow,
    handleSort,
  } = useOutgoingCall();
  return (
    <>
      <div className="pro-mt-2">
        <HeadingGroup title={"Outgoing Calls"} extraClassName={`pro-mb-2`} />
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                outgoingCallState?.searchKey === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: outgoingCallState?.searchKey }}
              showActions={false}
              //date picker props
              showDateRange={true}
              initialDateRange={initialDateRange}
              onDateChange={handleDateChange}
            />
          </div>
          <div className="col-auto">
            <DateRangePicker
              menuClassName="time-picker"
              placement="bottomEnd"
              format="HH:mm"
              ranges={[]}
              placeholder={"Select Time Range"}
              defaultValue={initialTimeRange}
              onChange={(value) => handleGetValues(value)}
              onClean={() => handleClearValues()}
              showHeader={false}
              value={outgoingCallState?.timeFilterValue}
              character={" - "}
              // showWeekNumbers={false}
              // hoverRange={(date) => [subDays(date, 1), addDays(date, 1)]}
            />
            {/* <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn} `}
              onClick={handleFilterAction}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Time Filter</span>
            </button> */}
          </div>
        </div>
        <div className={`col-auto pro-pb-6`}>
          <Table
            data={outgoingCallData?.data?.data || []}
            uniqueID={"id"}
            fields={outgoingCallData?.data?.field}
            showCheckBox={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={outgoingCallState?.currentPageSize}
            // handleEdit={handleTableEditClick}
            assignable={false}
            deletable={false}
            multiSelect={false}
          />
          {outgoingCallData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={outgoingCallState.currentPage}
              totalPageCount={Math.ceil(
                outgoingCallData?.data?.total_count /
                  outgoingCallState?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OutgoingCall;
