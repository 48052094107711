import React from "react";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const RowOne = ({
  formik,
  optionsData,
  casteRef,
  handleSelectReligion,
}) => {
  return (
    <>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Religion
          </label>
          <Select
            id="prefer_religion"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.religionList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.religion_name}
            value={optionsData?.data?.religionList?.filter?.(
              (c) =>
                formik.values?.prefer_religion?.toString() === c.id?.toString()
            )}
            onChange={(value) => handleSelectReligion(value)}
            onBlur={formik.handleBlur("prefer_religion")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_religion &&
              formik.errors.prefer_religion &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_religion && formik.errors.prefer_religion && (
            <span className="error-text">{formik.errors.prefer_religion}</span>
          )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Caste
          </label>
          <Select
            isMulti
            id="prefer_caste"
            placeholder={"Select"}
            ref={casteRef}
            options={optionsData?.data?.casteList?.filter(
              (p) =>
                p.religion_id?.toString() ===
                formik.values.prefer_religion?.toString()
            )}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.caste_name}
            value={optionsData?.data?.casteList?.filter?.((c) =>
              formik.values.prefer_caste?.includes?.(c.id?.toString())
            )}
            onChange={(value) => {
              formik.setFieldValue(
                "prefer_caste",
                value.map((a) => a.id?.toString())
              );
            }}
            onBlur={formik.handleBlur("prefer_caste")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_caste &&
              formik.errors.prefer_caste &&
              " error"
            } ${!formik.values.prefer_religion && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_caste && formik.errors.prefer_caste && (
            <span className="error-text">{formik.errors.prefer_caste}</span>
          )}
        </div>
      </div>

      <div className={`col-4`}>
        <Input
          label={"Sub Caste"}
          type="text"
          id="prefer_sub_caste"
          name="prefer_sub_caste"
          className={`pro-input lg ${
            formik.touched.prefer_sub_caste &&
            formik.errors.prefer_sub_caste &&
            " error"
          }`}
          {...formik.getFieldProps("prefer_sub_caste")}
          error={
            formik.touched.prefer_sub_caste && formik.errors.prefer_sub_caste
          }
          errorMessage={formik.errors.prefer_sub_caste}
        />
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Marital Status
          </label>
          <Select
            id="prefer_marital_status"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.maritalStatusList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.marital_status}
            value={optionsData?.data?.maritalStatusList?.filter?.((c) =>
              formik.values.prefer_marital_status?.includes?.(c.id?.toString())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "prefer_marital_status",
                value.map((m) => m.id?.toString())
              )
            }
            onBlur={formik.handleBlur("prefer_marital_status")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_marital_status &&
              formik.errors.prefer_marital_status &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_marital_status &&
            formik.errors.prefer_marital_status && (
              <span className="error-text">
                {formik.errors.prefer_marital_status}
              </span>
            )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Age From
          </label>
          <Select
            id="prefer_age_from"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.ageList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.age}
            value={optionsData?.data?.ageList?.filter(
              (m) =>
                formik.values.prefer_age_from?.toString() === m.id?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("prefer_age_from", value?.id || null)
            }
            onBlur={formik.handleBlur("prefer_age_from")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_age_from &&
              formik.errors.prefer_age_from &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik?.touched?.prefer_age_from && formik?.errors?.prefer_age_from && (
            <span className="error-text">{formik?.errors?.prefer_age_from}</span>
          )}
        </div>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Age To
          </label>
          <Select
            id="prefer_age_to"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.ageList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.age}
            value={optionsData?.data?.ageList?.filter(
              (m) =>
                formik.values.prefer_age_to?.toString() === m.id?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("prefer_age_to", value?.id || null)
            }
            onBlur={formik.handleBlur("prefer_age_to")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_age_to &&
              formik.errors.prefer_age_to &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_age_to && formik.errors.prefer_age_to && (
            <span className="error-text">{formik.errors.prefer_age_to}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default RowOne;
