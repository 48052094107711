import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as updateOrderConfig } from "../../store/slices/OrderColumns/orderColumnSlice";

import {
  useUpdateServiceStatusAlertListFieldsMutation,
  useGetServiceStatusAlertListDataQuery,
} from "../../store/queries/Service";
import { PartnerImage, UserImage } from "./Row";
import { updateConfig } from "../../store/slices/Service/ServiceStatusAlert";

const useServiceStatusAlert = () => {
  const dispatch = useDispatch();

  const serviceStatusAlertState = useSelector(
    (state) => state.serviceStatusAlert
  );

  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const [updateServiceStatusAlertFields] =
    useUpdateServiceStatusAlertListFieldsMutation();

  const {
    data: serviceStatusAlert = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetServiceStatusAlertListDataQuery({
    page_size: serviceStatusAlertState.currentPageSize,
    page: serviceStatusAlertState.currentPage,
    sort_by: serviceStatusAlertState.sortBy,
    sort_order: serviceStatusAlertState.sortOrder,
  });

  const obj = serviceStatusAlert?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const hasCreatePermission = useMemo(() => {
    let permission = serviceStatusAlert?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_create")
    )[0].can_create;

    return permission;
    // eslint-disable-next-line
  }, [serviceStatusAlert]);

  const hasEditPermission = useMemo(() => {
    let permission = serviceStatusAlert?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_edit")
    )[0].can_edit;

    return permission;
    // eslint-disable-next-line
  }, [serviceStatusAlert]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (serviceStatusAlertState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            serviceStatusAlertState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      bride_groom_image: (_, data) => (
        <UserImage image={data?.bride_groom_image?.user_image_thumbnail} />
      ),
      partner_bride_Groom_image: (_, data) => (
        <PartnerImage
          image={data?.partner_bride_Groom_image?.user_image_thumbnail}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    serviceStatusAlert,
    serviceStatusAlertState,
    currentPage: serviceStatusAlertState.currentPage,
    hasCreatePermission,
    hasEditPermission,
    paginationOptions,
    isFetching,
    isLoading,
    showTableFieldsModal,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    updateServiceStatusAlertFields,
  };
};

export default useServiceStatusAlert;
