import { useGetServiceSearchFiltersQuery } from "../../../../store/queries/Search";
import { useGetLoggedInStaffProfileDataQuery } from "../../../../store/queries/Staff";

const useServiceSearchForm = () => {
  const { data: optionsData, isSuccess } = useGetServiceSearchFiltersQuery();

  const { data: loggedData } = useGetLoggedInStaffProfileDataQuery();

  return { optionsData, isSuccess, loggedData };
};

export default useServiceSearchForm;
