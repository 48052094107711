import {
  ModalLayout,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { Outlet } from "react-router-dom";
import ProfileLayout from "../Layout/ProfileLayout";
import AddService from "./AddService";
import useProfile from "./useProfile";
import Style from "./profile.module.scss";
import AddedServiceExistList from "../../components/Profile/AddService/AddedServiceExistList";

const Profile = () => {
  const {
    navigations,
    profile,
    addedServiceExistState,
    closeModal,
    closeAddServiceModal,
    optionsData,
    isSuccessServiceType
  } = useProfile();

  return (
    <>
      <ProfileLayout navigations={navigations}>
        <Outlet />
      </ProfileLayout>

      <OffCanvasLayout
        show={profile.showCreateModal}
        handleClose={closeAddServiceModal}
        title={"Add Service"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        body={<AddService optionsData={optionsData} isSuccess={isSuccessServiceType} />}
      />

      <ModalLayout
        show={addedServiceExistState.showAddedServiceModal}
        handleClose={closeModal}
        propStyle={{ root: Style.root_modal }}
      >
        <AddedServiceExistList />
      </ModalLayout>
    </>
  );
};

export default Profile;
