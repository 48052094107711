import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useCreateProfilePartnerInfoMutation } from "../../../../store/queries/Registration";
import {
  getUserDetails,
  updateFormData,
} from "../../../../store/slices/Registration/createProfileSlice";
import { useNavigate } from "react-router-dom";
import { profile } from "../../../../store/queries/Profile";

const usePartner = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const casteRef = useRef("");
  const stateRef = useRef("");
  const districtRef = useRef("");
  const locationRef = useRef("");
  const stateJobRef = useRef("");
  const districtJobRef = useRef("");
  const locationJobRef = useRef("");

  const { currentFormDataPartner } = useSelector(
    (state) => state.createProfile
  );
  const [registerProfile, { isLoading: isUpdating }] =
    useCreateProfilePartnerInfoMutation();

  const dispatch = useDispatch();
  const [saveAndNext,setSaveAndNext] = useState(false);
  let createProfilePartnerVal = Yup.object({});

  const formik = useFormik({
    initialValues: {
      user_id: activeUser,
      prefer_religion: "",
      prefer_caste: [],
      prefer_sub_caste: "",
      prefer_marital_status: [],
      prefer_complexion: [],
      prefer_body_type: [],
      prefer_special_case: [],
      prefer_country: [],
      prefer_state: [],
      prefer_district: [],
      prefer_location: [],
      prefer_educational_main_category: [],
      prefer_educational_category: [],
      prefer_education_in_detail: "",
      prefer_job_main_category: [],
      prefer_job_category: [],
      prefer_job_in_detail: "",
      prefer_financial_expectation: "",
      prefer_religious_expectation: "",
      prefer_jathakam_type: [],
      prefer_age_from: "",
      prefer_age_to: "",
      prefer_height_from: "",
      prefer_height_to: "",
      prefer_job_country: ["1"],
      prefer_job_state: [],
      prefer_job_district: [],
      prefer_job_location: [],
      prefer_job_location_details: "",
      house_square_feet: [],
      matching_stars: [],
    },

    validationSchema: edit !== "true" ? createProfilePartnerVal : null,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values,
        };

        dispatch(
          updateFormData((state) => {
            state.currentFormDataPartner = {
              ...state.currentFormDataPartner,
              ...obj,
            };
            state.completedTabs = {
              ...state.completedTabs,
              Partner: true,
              Gallery: true,
            };
          })
        );

        if (formik.values.user_id) {
          registerProfile(obj).then((response) => {
            formik.setErrors("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              submitForm(resetForm);
              dispatch(profile.util.invalidateTags(["Preference"]));
              dispatch(profile.util.invalidateTags(["PartnerFilters"]));
            } else if (response?.error?.data?.status_code === 422) {
              formik.setErrors(response?.error?.data?.errors);
            }
          });
        } else {
          setErrors("Fill required fields in all  Forms");
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = () => {
    dispatch(profile.util.invalidateTags(["Profile", "Overview"]));
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Gallery";
      })
    );

    toast.success("Successfully updated");
    if (toTab && !saveAndNext) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (Object.keys(currentFormDataPartner).length === 0) {
      dispatch(
        getUserDetails({
          endpoint: "user-partner-preference",
          id: activeUser,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormDataPartner || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormDataPartner?.[key]);
      });
      // formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormDataPartner]);

  useEffect(() => {
    let totalFields = Object.values(formik.values).filter(
      (f) => f !== "" && f !== null
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Partner: Math.ceil(
            (totalFields / Object.keys(formik.values).length) * 100
          ),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const handleSelectReligion = (value) => {
    casteRef.current.setValue([], "clear");
    formik.setFieldValue("prefer_religion", value?.id || null);
  };

  const handleSelectCountry = (value) => {
    stateRef.current.setValue([], "clear");
    districtRef.current.setValue([], "clear");
    locationRef.current.setValue([], "clear");
    formik.setFieldValue(
      "prefer_country",
      value.map((a) => a.id?.toString())
    );
  };

  const handleSelectState = (value) => {
    districtRef.current.setValue([], "clear");
    locationRef.current.setValue([], "clear");
    formik.setFieldValue(
      "prefer_state",
      value.map((a) => a.id?.toString())
    );
  };
  const handleSelectDistrict = (value) => {
    locationRef.current.setValue([], "clear");
    formik.setFieldValue(
      "prefer_district",
      value.map((a) => a.id?.toString())
    );
  };

  const handleSelectJobCountry = (value) => {
    stateJobRef.current.setValue([], "clear");
    districtJobRef.current.setValue([], "clear");
    locationJobRef.current.setValue([], "clear");
    formik.setFieldValue(
      "prefer_job_country",
      value.map((a) => a.id?.toString())
    );
  };

  const handleSelectJobState = (value) => {
    districtJobRef.current.setValue([], "clear");
    locationJobRef.current.setValue([], "clear");
    formik.setFieldValue(
      "prefer_job_state",
      value.map((a) => a.id?.toString())
    );
  };
  const handleSelectJobDistrict = (value) => {
    locationJobRef.current.setValue([], "clear");
    formik.setFieldValue(
      "prefer_job_district",
      value.map((a) => a.id?.toString())
    );
  };

  return {
    errors,
    formik,
    isUpdating,
    casteRef,
    handleSelectReligion,
    handleSelectCountry,
    handleSelectState,
    handleSelectDistrict,
    setSaveAndNext,
    stateRef,
    districtRef,
    locationRef,
    handleSelectJobCountry,
    handleSelectJobDistrict,
    handleSelectJobState,
    stateJobRef,
    districtJobRef,
    locationJobRef,
  };
};

export default usePartner;
