import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  activitySearchStatus: "idle",
  activitySearchData: {},
  activitySearchValues: [],
};

export const getActivitySearch = createAsyncThunk(
  "activitySearch/getActivitySearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`search/user-activity-search`, {
        ...params,
        per_page: 20,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const ActivitySearchSlice = createSlice({
  name: "ActivitySearch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivitySearch.pending, (state) => {
      state.activitySearchStatus = "pending";
    });
    builder.addCase(getActivitySearch.fulfilled, (state, action) => {
      state.activitySearchStatus = "fulfilled";
      if (state.activitySearchData?.data) {
        state.activitySearchData = {
          ...action.payload.data.data,
          data: [
            ...state.activitySearchData?.data,
            ...action.payload.data.data.data,
          ],
        };
      } else {
        state.activitySearchData = action.payload.data.data;
      }
    });
    builder.addCase(getActivitySearch.rejected, (state) => {
      state.activitySearchStatus = "rejected";
    });
  },
});

export const { updateConfig } = ActivitySearchSlice.actions;

export default ActivitySearchSlice.reducer;
