import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  advancedSearchStatus: "idle",
  advancedSearchData: {},
  currentPage:1,
  currentPageSize:15,
  searchValues:[]
};

export const getAdvancedSearch = createAsyncThunk(
  "advancedSearch/getAdvancedSearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`search/advanced-search`, {
        ...params,
        per_page: 20,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const advancedSearchSlice = createSlice({
  name: "advancedSearch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdvancedSearch.pending, (state) => {
      state.advancedSearchStatus = "pending";
    });
    builder.addCase(getAdvancedSearch.fulfilled, (state, action) => {
      state.advancedSearchStatus = "fulfilled";
      if (state.advancedSearchData?.data) {
        state.advancedSearchData = {
          ...action.payload.data.data,
          data: [
            ...state.advancedSearchData?.data,
            ...action.payload.data.data.data,
          ],
        };
      } else {
        state.advancedSearchData = action.payload.data.data;
      }
    });
    builder.addCase(getAdvancedSearch.rejected, (state) => {
      state.advancedSearchStatus = "rejected";
    });
  },
});

export const { updateConfig } = advancedSearchSlice.actions;

export default advancedSearchSlice.reducer;
