import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import ScheduleCreationForm from "../ScheduleCreationForm";
import useSchedule from "./useSchedule";

const Schedule = () => {
  const {
    schedules,
    scheduleState,
    filters,
    currentPage,
    activeFilter,
    showCreateModal,
    showTableFieldsModal,
    hasEditPermission,
    hasCreatePermission,
    paginationOptions,
    sortedTableFields,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleSort,
    handleCreateClick,
    handleEditClick,
    closeModal,
    closeEditModal,
    getRow,
    updateScheduleFields,
    handleTableEditClick,
  } = useSchedule();

  

  return (
    <>
      <HeadingGroup
        title={"Scheduled List"}
        buttonTitle={hasCreatePermission ? "Create" : ""}
        extraClassName={`pro-mb-3`}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={filters}
          activeFilter={activeFilter}
          handleButtonGroupChange={handleFilter}
          loading={isLoading}
          showActions={true}
          handleActionClick={handleEditClick}
          searchable={false}
        />

        <Table
          data={schedules?.data?.data || []}
          uniqueID={"id"}
          fields={sortedTableFields}
          showCheckBox={hasEditPermission}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={scheduleState.currentPageSize}
          assignable={false}
          deletable={false}
          multiSelect={false}
          handleEdit={handleTableEditClick}
        />

        {schedules?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              schedules?.data?.total_count / scheduleState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={"Create Schedule"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <ScheduleCreationForm refetch={refetch} />
        </OffCanvasLayout>

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={schedules?.data?.table_id}
              updateData={updateScheduleFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Schedule;
