import { Outlet } from "react-router-dom";
import CommonLayout from "../Layout/CommonLayout";
import useDashboard from "./useDashboard";

const DashboardPage = () => {
  const { drawerMenu } = useDashboard();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default DashboardPage;
