import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import HoroscopeBox from "./HoroscopeBox";
import useHoroscope from "./useHoroscope";

const Horoscope = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const { formik, errors, isUpdating, setSaveAndNext } = useHoroscope({
    optionsData,
    isOptionDataSuccess,
    activeUser,
    edit,
    toTab,
  });

  return (
    <div className={`pro-p-0 pro-w-100`} id="horoscope-info">
      <form action="" onSubmit={formik?.handleSubmit}>
        <HoroscopeBox
          title={"Grahanila"}
          options={optionsData?.data?.grahsLists || []}
          formik={formik}
        />
        <HoroscopeBox
          title={"Navamshakam"}
          options={optionsData?.data?.grahsLists || []}
          formik={formik}
        />
        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
          {toTab && (
            <Button
              disabled={isUpdating}
              className={`pro-btn-primary lg pro-mx-3 ${isUpdating ? "loading" : ""}`}
              onClick={() => setSaveAndNext(true)}
              type="submit"
            >
              {isUpdating ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {toTab && "Save & Next"}
            </Button>
          )}

          <Button
            disabled={isUpdating}
            className={`pro-btn-primary lg pro-mx-3 ${isUpdating ? "loading" : ""}`}
            type="submit"
          >
            {isUpdating ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            {toTab ? "Save" : "Save & Next"}
          </Button>
        </div>
        {errors && <span className="error-message">{errors}</span>}
      </form>
    </div>
  );
};

export default Horoscope;
