import { useSelector } from "react-redux";
import { useGetAddedServicePdfDataQuery } from "../../../../../store/queries/Profile";

const useViewTableDetailsSS = () => {
  const addedServiceExistState = useSelector(
    (state) => state.addedServiceExist
  );
  const profileState = useSelector((state) => state.profile);

  const { data: existingServiceList } = useGetAddedServicePdfDataQuery({
    page: addedServiceExistState.currentPage,
    per_page: addedServiceExistState.currentPageSize,
    client_web_id:
      addedServiceExistState.client_web_id ?? profileState?.client_web_id,
    partner_client_web_id:
      addedServiceExistState.partner_client_web_id ??
      profileState?.partner_client_web_id,
  });

  return { existingServiceList };
};

export default useViewTableDetailsSS;
