import React from "react";
import Select from "react-select";

const LocationPreference = ({
  formik,
  optionsData,
  handleSelectCountry,
  handleSelectState,
  handleSelectDistrict,
  stateRef,
  districtRef,
  locationRef,
}) => {
  return (
    <div className="row" id="location-preference">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">
          Location Preference
        </h6>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Country
          </label>
          <Select
            id="prefer_country"
            placeholder={"Select"}
            isMulti
            options={optionsData?.data?.countryList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.country_name}
            value={optionsData?.data?.countryList?.filter?.((c) =>
              formik.values.prefer_country?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              handleSelectCountry(value);
            }}
            onBlur={formik.handleBlur("prefer_country")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_country &&
              formik.errors.prefer_country &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_country && formik.errors.prefer_country && (
            <span className="error-text">{formik.errors.prefer_country}</span>
          )}
        </div>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="prefer_state"
            placeholder={"Select"}
            ref={stateRef}
            isMulti
            options={optionsData?.data?.stateList?.filter((p) =>
              formik.values.prefer_country?.includes?.(p.country_id?.toString())
            )}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.state_name}
            value={optionsData?.data?.stateList?.filter?.((c) =>
              formik.values.prefer_state?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              handleSelectState(value);
            }}
            onBlur={formik.handleBlur("prefer_state")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_state &&
              formik.errors.prefer_state &&
              " error"
            }  ${!formik.values.prefer_country && "disabled"} `}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_state && formik.errors.prefer_state && (
            <span className="error-text">{formik.errors.prefer_state}</span>
          )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            District
          </label>
          <Select
            id="prefer_district"
            placeholder={"Select"}
            ref={districtRef}
            isMulti
            options={optionsData?.data?.districtsList?.filter((p) =>
              formik.values.prefer_state?.includes?.(p.state_id?.toString())
            )}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.district_name}
            value={optionsData?.data?.districtsList?.filter?.((c) =>
              formik.values.prefer_district?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              handleSelectDistrict(value);
            }}
            onBlur={formik.handleBlur("prefer_district")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_district &&
              formik.errors.prefer_district &&
              " error"
            }  ${!formik.values.prefer_state && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_district && formik.errors.prefer_district && (
            <span className="error-text">{formik.errors.prefer_district}</span>
          )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Panchayat / Municipality
          </label>
          <Select
            id="prefer_location"
            placeholder={"Select"}
            isMulti
            ref={locationRef}
            options={
              !formik.values.prefer_location?.includes?.("all")
                ? optionsData?.data?.locationsList?.filter(
                    (p) =>
                      formik.values.prefer_district?.includes?.(
                        p.district_id?.toString()
                      ) || p.district_id === "all"
                  )
                : []
            }
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.location_name}
            value={optionsData?.data?.locationsList?.filter?.((c) =>
              formik.values.prefer_location?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              formik.setFieldValue(
                "prefer_location",
                value.map((a) => a.id?.toString())
              );
            }}
            onBlur={formik.handleBlur("prefer_location")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_location &&
              formik.errors.prefer_location &&
              " error"
            }  ${!formik.values.prefer_district && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_location && formik.errors.prefer_location && (
            <span className="error-text">{formik.errors.prefer_location}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationPreference;
