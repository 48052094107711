import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const ScheduleForRow = ({ data, onClick }) => {
  
  if (!data?.schedule_for) {
    return "";
  }
  return <IconText title={`${data?.schedule_for}`} />;
};
