import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import Basic from "./Basic";
import Interval from "./Interval";
import useScheduleCreationForm from "./useScheduleCreationForm";
import style from "./scheduleCreationForm.module.scss";
const ScheduleCreationForm = ({refetch}) => {
  const { tabs, activeTab, optionsData, handleTabClick, isOptionDataSuccess } =
    useScheduleCreationForm();

  return (
    <div className="test">
      <FormTabs
        propStyle={{ root: style.root, root_items: style.root_items }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      {activeTab && activeTab === "Basic" ? (
        <Basic
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
        />
      ) : activeTab === "Interval" ? (
        <Interval
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
          refetch={refetch}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ScheduleCreationForm;
