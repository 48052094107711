import { useDispatch, useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { PartnerWebIdRow, UserWebIdRow } from "./Rows";
import { useGetServiceChatListDataQuery } from "../../../store/queries/Service";
import { updateConfig } from "../../../store/slices/Service/ServiceChat";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

const useServiceChat = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const serviceChatState = useSelector((state) => state.serviceChat);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: serviceChat = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetServiceChatListDataQuery({
    page_size: serviceChatState.currentPageSize,
    page: serviceChatState.currentPage,
    sort_by: serviceChatState.sortBy,
    sort_order: serviceChatState.sortOrder,
  });

  const obj = serviceChat?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (serviceChatState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            serviceChatState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      partner_web_id: (_, data) => (
        <PartnerWebIdRow
          data={data}
          activeProfile={activeProfile}
          params={params}
        />
      ),
      user_web_id: (_, data) => (
        <UserWebIdRow
          data={data}
          activeProfile={activeProfile}
          params={params}
        />
      ),
      service_chat: (_, data) => {
        const renderedValues = data?.service_chat.map((value, index) => (
          <React.Fragment key={index}>
            {value}
            <br />
          </React.Fragment>
        ));

        return <IconText title={renderedValues} />;
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    serviceChat,
    serviceChatState,
    currentPage: serviceChatState.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
  };
};

export default useServiceChat;
