import { getAxiosInstance } from "../../../../api";

export const exportServiceSearch = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`search/service-search-excel`, body, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
