import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("sort_by")}${getParams("user_id")}&sort=${
          params?.sort_order || "desc"
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const deleteHistory = createApi({
  reducerPath: "deleteHistoryApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["DeleteHistory"],
  endpoints: (builder) => ({
    getDeleteHistoryData: builder.query({
      query: (body) => ({
        method: "get",
        params: body,
        endpoint: `profile/delete-history`,
      }),
      providesTags: ["DeleteHistory"],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetDeleteHistoryDataQuery } = deleteHistory;
