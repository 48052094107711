import React from "react";
import Select from "react-select";

const RowTwo = ({ formik, optionsData }) => {
  return (
    <>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Height From
          </label>
          <Select
            id="prefer_height_from"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.heightList}
            getOptionValue={(option) => option?.height_value}
            getOptionLabel={(option) => option?.height}
            value={optionsData?.data?.heightList?.filter(
              (m) =>
                formik.values.prefer_height_from?.toString() ===
                m.id?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("prefer_height_from", value?.id || null)
            }
            onBlur={formik.handleBlur("prefer_height_from")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_height_from &&
              formik.errors.prefer_height_from &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_height_from &&
            formik.errors.prefer_height_from && (
              <span className="error-text">
                {formik.errors.prefer_height_from}
              </span>
            )}
        </div>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Height To
          </label>
          <Select
            id="prefer_height_to"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.heightList}
            getOptionLabel={(option) => option?.height}
            getOptionValue={(option) => option?.height_value}
            value={optionsData?.data?.heightList?.filter(
              (m) =>
                formik.values.prefer_height_to?.toString() === m.id?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("prefer_height_to", value?.id || null)
            }
            onBlur={formik.handleBlur("prefer_height_to")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_height_to &&
              formik.errors.prefer_height_to &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_height_to &&
            formik.errors.prefer_height_to && (
              <span className="error-text">
                {formik.errors.prefer_height_to}
              </span>
            )}
        </div>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Complexion
          </label>
          <Select
            id="prefer_complexion"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.complexionList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.complexion_title}
            value={optionsData?.data?.complexionList?.filter?.((c) =>
              formik.values.prefer_complexion?.includes?.(c.id?.toString())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "prefer_complexion",
                value.map((c) => c.id?.toString())
              )
            }
            onBlur={formik.handleBlur("prefer_complexion")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_complexion &&
              formik.errors.prefer_complexion &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_complexion &&
            formik.errors.prefer_complexion && (
              <span className="error-text">
                {formik.errors.prefer_complexion}
              </span>
            )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Body Type
          </label>
          <Select
            id="prefer_body_type"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.bodyTypes}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.body_type}
            value={optionsData?.data?.bodyTypes?.filter?.((c) =>
              formik.values.prefer_body_type?.includes?.(c.id?.toString())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "prefer_body_type",
                value.map((c) => c.id?.toString())
              )
            }
            onBlur={formik.handleBlur("prefer_body_type")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_body_type &&
              formik.errors.prefer_body_type &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_body_type &&
            formik.errors.prefer_body_type && (
              <span className="error-text">
                {formik.errors.prefer_body_type}
              </span>
            )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Special Case
          </label>
          <Select
            id="prefer_special_case"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.specialCaseList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.special_case}
            value={optionsData?.data?.specialCaseList?.filter?.((c) =>
              formik.values.prefer_special_case?.includes?.(c.id?.toString())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "prefer_special_case",
                value.map((m) => m.id?.toString())
              )
            }
            onBlur={formik.handleBlur("prefer_special_case")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_special_case &&
              formik.errors.prefer_special_case &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_special_case &&
            formik.errors.prefer_special_case && (
              <span className="error-text">
                {formik.errors.prefer_special_case}
              </span>
            )}
        </div>
      </div>
    </>
  );
};

export default RowTwo;
