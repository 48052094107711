import React from "react";
import Style from "./UserDetailTableShimmer.module.scss";
const UserDetailTableShimmer = () => {
  return (
    <div className={`pro-px-5 pro-py-4 ${Style.automatch_shimmer}`}>
      <div className="row">
        <div className={`${Style.shimmer_image_wrapper}`}>
          <div className="shimmer img pro-mb-4"></div>
          <span className="shimmer letter"></span>
          <span className="shimmer letter para"></span>
          <div className="pro-d-flex pro-gap-3 pro-py-3">
            <span className="pro-px-5 pro-py-3 shimmer"></span>
            <span className="pro-px-5 pro-py-3 shimmer"></span>
          </div>
          <span className="shimmer pro-px-6 pro-py-4"></span>
        </div>
        <div className={Style.shimmer_table_wrapper}>
          <div>
            <table className="pro-table no-border">
              <tbody>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                  <td>
                    <span className="shimmer letter para"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailTableShimmer;
