import { useGetUserActivitySearchFiltersQuery } from "../../../../store/queries/Search";
import { useGetLoggedInStaffProfileDataQuery } from "../../../../store/queries/Staff";

const useActivitySearchForm = () => {
  const { data: optionsData, isSuccess } = useGetUserActivitySearchFiltersQuery(
    {}
  );

  const { data: loggedStaffData } = useGetLoggedInStaffProfileDataQuery();

  return {
    optionsData,
    isSuccess,
    loggedStaffData,
  };
};

export default useActivitySearchForm;
