import { getAxiosInstance } from "../../api";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("auth/login", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/auth/forgot-password", params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/auth/reset-password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const validateOTP = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("auth/verify-otp", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};
