import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAxiosInstance } from "../../../../api";
import {
  getAllPermissions,
  getPermission,
  updateConfig,
} from "../../../../store/slices/Staff/roleSlice";
import { staff } from "../../../../store/queries/Staff";
import {
  getMenuPermissions,
  getProfilePermissions,
} from "../../../../store/slices/Global";

const useManageRoles = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const role = params.get("role");
  const update = params.get("update");

  const dispatch = useDispatch();
  const state = useSelector((state) => state.roles);

  const [name, setName] = useState("");
  const [statusFulfilled, setStatusFulfilled] = useState(false);
  const [permissionModules, setPermissionModules] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (state.allPermissionsStatus === "idle") {
      dispatch(getAllPermissions());
    }
    if (role) {
      dispatch(getPermission({ id: role }))
        .unwrap()
        .then((r) => {
          if (update === "true") {
            setName(r.data.data.user_type);
          }
          let menus = r.data.data.permissions.map((m) => `parentmenu_${m.id}`);
          let submenus = r.data.data.permissions.flatMap((m) =>
            m.sub_menu_list.map((sub) => `submenu_${sub.id}`)
          );
          setPermissionModules([...menus, ...submenus]);
          setStatusFulfilled(true);
        });
    }

    // eslint-disable-next-line
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (error) {
      setError("");
    }
  };

  const handlePermissionChange = (item, menuId) => {
    if (item.e.target.checked) {
      setPermissionModules((prev) => {
        return [...prev, item.id];
      });
    } else {
      setPermissionModules((prev) => {
        return prev.filter((id) => id !== item.id);
      });
    }
  };

  const handleSave = async () => {
    if (name.length === 0) {
      setError("*Name cannot be empty");
    } else {
      const api = await getAxiosInstance();
      const menu_permissions = permissionModules
        .filter((id) => id.includes("parentmenu"))
        .map((id) => id.split("_")[1]);
      const sub_menu_permissions = permissionModules
        .filter((id) => id.includes("submenu"))
        .map((id) => id.split("_")[1]);

      if (!update) {
        api
          .post("permissions/create-user-type", {
            name,
            menu_permissions,
            sub_menu_permissions,
          })
          .then((r) => {
            if (r.status === 200) {
              toast.success("Role created successfully");

              dispatch(staff.util.invalidateTags(["Roles"]));

              navigate("/dashboard/staff-roles");
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.error("Name is already taken");
            } else if (err?.response?.status === 403) {
              toast.error(err?.response?.data?.message);
            }
          });
      } else {
        api
          .post(`permissions/update-user-type/${role}`, {
            name,
            menu_permissions,
            sub_menu_permissions,
          })
          .then((r) => {
            if (r.status === 200) {
              toast.success("Role updated successfully");
              dispatch(
                updateConfig((state) => {
                  state.allPermissionsStatus = "idle";
                  state.allPermissions = [];
                  state.rolePermission = {};
                  state.rolePermissionStatus = "idle";
                })
              );
              dispatch(
                getMenuPermissions({
                  token: localStorage.getItem("USER_ACCESS_TOKEN"),
                })
              );
              dispatch(getProfilePermissions());
              dispatch(staff.util.invalidateTags(["Roles"]));

              navigate("/dashboard/staff-roles");
            }
          })
          .catch((err) => {
            if (err?.response?.status === 403) {
              toast.error(err?.response?.data?.message);
            }
          });
      }
    }
  };

  const handleGoBack = () => {
    navigate({ pathname: "/dashboard/staff-roles", search: "refetch=true" });
  };

  return {
    state,
    role,
    update,
    name,
    error,
    permissionModules,
    statusFulfilled,
    handleNameChange,
    handlePermissionChange,
    handleSave,
    handleGoBack,
  };
};

export default useManageRoles;
