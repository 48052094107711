import React from "react";
import useViewProfileDetails from "./useViewProfileDetails";
import ProfileData from "./ProfileData";
// import Style from "./viewProfileDetails.module.scss";

const ViewProfileDetails = React.forwardRef((props, ref) => {
  const { profileData, pdfVisibility,itemData } = useViewProfileDetails(props);

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      {profileData?.data?.map((item, index) => {
        const religion = item?.primary_details?.religion ?? "";
        const caste = item?.primary_details?.caste ?? "";

        const religionFields = [religion, caste];

        const religionStr = religionFields.filter(Boolean).join(" / ");

        const religionValue = {
          religion: religionStr || "Not specified",
        };

        return (
          <ProfileData
            index={index}
            item={item}
            religionValue={religionValue}
            pdfVisibility={pdfVisibility}
            itemData={itemData}
          />
        );
      })}
    </div>
  );
});

export default ViewProfileDetails;
