import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  activityStatus: "idle",
  activityData: {},
  viewHistoryData: {},
  contact_profile_id: "",
};

export const getActivityDetails = createAsyncThunk(
  "activity/getActivityDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `activity/user-activity/${params.id}?page=${params.page}&per_page=20&filter=${params?.filter}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getActivityDetails.pending]: (state) => {
      state.activityStatus = "pending";
    },
    [getActivityDetails.fulfilled]: (state, action) => {
      if (state.activityData?.[action.meta?.arg?.id]) {
        state.activityData[action.meta?.arg?.id] = {
          ...action.payload.data?.data,
          data: [
            ...state.activityData[action.meta?.arg?.id].data,
            ...action.payload.data?.data?.data,
          ],
        };
      } else {
        state.activityData[action.meta?.arg?.id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.activityStatus = "fulfilled";
    },
    [getActivityDetails.rejected]: (state) => {
      state.activityStatus = "failed";
    },
  },
});

export const { updateConfig } = activitySlice.actions;

export default activitySlice.reducer;
