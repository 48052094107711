import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import Schedule from "../components/Schedule/ScheduleListing";
import Login from "../components/Auth/Login";
import DashboardPage from "../pages/Dashboard";
import Overview from "../components/Profile/Overview";
import Dashboard from "../components/Dashboard";
import AutoMatch from "../components/Profile/AutoMatch";
import Staff from "../components/Staff/StaffListing";
import StaffCreationForm from "../components/Staff/StaffCreationForm";
import AutoStarMatch from "../components/Profile/AutoStarMatch";
import PartnerMatch from "../components/Profile/PartnerMatch";
import Registration from "../components/Registration/RegistartionListing";
import ProfileCreationForm from "../components/Registration/CreateProfileForm";
import AdvancedSearch from "../components/Search/AdvancedSearch";
import AddService from "../components/Profile/AddService";
import Report from "../components/Report/ReportListing";
import ReportStatus from "../components/Report/ReportStatusListing";
import Profile from "../pages/Profile";
import JobAssign from "../components/JobAssign";
import UserActivitySearch from "../components/Search/UserActivitySearch";
import ServiceSearch from "../components/Search/ServiceSearch";
import Activity from "../components/Profile/Activity";
import ScheduledHistory from "../components/Profile/ScheduledHistory";
import ViewService from "../components/Profile/ViewService";
import Roles from "../components/Staff/Role/RolesListing";
import ManageRoles from "../components/Staff/Role/ManageRoles";
import GlobalSearch from "../components/Search/GlobalSearch";
import ServiceNotification from "../components/ServiceNotification";
import ServiceStatusAlert from "../components/ServiceStatusAlert";
import OutboundCalls from "../components/Calls/OutboundCalls";
import InboundCalls from "../components/Calls/InboundCalls";
import ServiceChat from "../components/Service/ServiceChat";
import ViewProfileDetails from "../components/Profile/ViewProfileDetails";
import RegistrationReport from "../components/RegistrationReport/RegistrationReportListing";
import CallHistoryMain from "../components/CallHistoryMain";
import CallHistory from "../components/CallHistoryMain/CallHistory";
import MissedCall from "../components/CallHistoryMain/CallHistory/MissedCall";
import IncomingCall from "../components/CallHistoryMain/CallHistory/IncomingCall";
import OutgoingCall from "../components/CallHistoryMain/CallHistory/OutgoingCall";
import SocialMediaReport from "../components/SocialMediaReport";
import UtmReport from "../components/UTM-report";
import BlockUsers from "../components/blockUsers";
import UserAgreement from "../components/UserAgreement";

// import RegistrationTypeReport from "../components/RegistrationTypeReport/RegistrationTypeReportListing";
const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />

        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />} />
        </Route>

        <Route path="/abc" element={<ViewProfileDetails />}></Route>

        <Route path="/outgoing-call" element={<OutboundCalls />}></Route>
        <Route path="/incoming-call" element={<InboundCalls />}></Route>

        <Route path={"/dashboard"} element={<DashboardPage />}>
          <Route path="" element={<Dashboard />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="staff" element={<Staff />} />
          <Route path="staff-roles" element={<Roles />} />
          <Route path="roles/manage-roles" element={<ManageRoles />} />
          <Route path="report" element={<Report />} />
          <Route path="report/:id" element={<ReportStatus />} />
          <Route path="staff-create" element={<StaffCreationForm />} />
          <Route path="registration" element={<Registration />} />
          <Route path="reg-report" element={<RegistrationReport />} />
          <Route path="reg-report" element={<RegistrationReport />} />
          <Route path="social-media-report" element={<SocialMediaReport />} />
          <Route path="social-media-utm-report" element={<UtmReport />} />
          <Route
            path="profile-registration"
            element={<ProfileCreationForm />}
          />
          <Route path="advanced-search" element={<AdvancedSearch />} />
          <Route path="global-search" element={<GlobalSearch />} />

          <Route path="activity-search" element={<UserActivitySearch />} />
          <Route path="service-search" element={<ServiceSearch />} />
          <Route path="job-assign" element={<JobAssign />} />

          <Route
            path="service-notification"
            element={<ServiceNotification />}
          />
          <Route path="service-status" element={<ServiceStatusAlert />} />
          <Route path="service-chat" element={<ServiceChat />} />
          <Route path="block-users" element={<BlockUsers />} />
          <Route path="user-agreement" element={<UserAgreement />} />
        </Route>
        <Route path={"/profile"} element={<Profile />}>
          <Route path="" element={<Overview isBackground={true} />} />
          <Route path="auto-match" element={<AutoMatch />} />
          <Route path="auto-star-match" element={<AutoStarMatch />} />
          <Route path="partner-match" element={<PartnerMatch />} />
          <Route path="partner-star-match" element={<PartnerMatch star />} />
          <Route path="add-service" element={<AddService />} />
          <Route path="view-service" element={<ViewService />} />
          <Route path="activity" element={<Activity />} />
          <Route path="scheduled-history" element={<ScheduledHistory />} />
        </Route>

        <Route path="/call-history" element={<CallHistoryMain />}>
          <Route path="" element={<CallHistory />}>
            <Route path="" element={<MissedCall />} />
            <Route path="missed-call" element={<MissedCall />} />
            <Route path="incoming-call" element={<IncomingCall />} />
            <Route path="outgoing-call" element={<OutgoingCall />} />
          </Route>
        </Route>

        <Route path={"/*"} element={<Navigate to={"/dashboard"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
