import style from './manageRoleShimmer.module.scss'

const ManageRoleShimmer = () => {
  return (
    <div className={`${style.shimmer}`}>
        <div className={`row pro-pb-5`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
          <div className="col-12 pro-pb-5">
                <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
              </div>
        <div className={`row pro-pb-5 pro-pt-3`}>
            <div className={`col-2`}>
                <span className={`letter shine ${style.role_text}`}></span>
            </div>
            <div className={`col-10 pro-pb-3`}>
                <div className={`row g-4`}>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                    <div className={`col-3 pro-d-flex pro-items-center`}>
                        <span className={`letter shine ${style.icon}`}></span><span className={`letter shine ${style.role_text}`}></span>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default ManageRoleShimmer