import {
  
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useJobAssignList from "./useJobAssignList";
import EditModal from "../../Report/ReportStatusListing/EditModal";

const JobAssignList = ({activeProfile, params}) => {
  const {
    queryData,
    dashboardState,
    filters,
    currentPage,
    activeFilter,
    paginationOptions,
    isFetching,
    isLoading,
    showTableFieldsModal,
    sortedTableFields,
    selectedUpdateRows,
    showUpdateModal,
    refetch,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleSort,
    handleEditClick,
    closeEditModal,
    getRow,
    updateTableFields,
    handleTableEditClick,
    closeTableEditModal,
    
  } = useJobAssignList({activeProfile, params});

  return (
    <>
      <HeadingGroup title={"Pending Calls"} extraClassName={`pro-mb-3`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={filters}
          activeFilter={activeFilter}
          handleButtonGroupChange={handleFilter}
          loading={isLoading}
          showActions={true}
          handleActionClick={handleEditClick}
          searchable={false}
        />

        <Table
          data={queryData?.data?.data || []}
          uniqueID={"id"}
          fields={sortedTableFields}
          showCheckBox
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={dashboardState.currentPageSize}
          assignable={false}
          deletable={false}
          handleEdit={handleTableEditClick}
        />
        {queryData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              queryData?.data?.total_count / dashboardState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          centered
          show={showUpdateModal}
          handleClose={closeTableEditModal}
        >
           <div className="pro-m-5">
            <EditModal
              title={"Add Comments"}
              selectedUpdateRows={selectedUpdateRows}
              closeUpdateModal={closeTableEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={queryData?.data?.table_id}
              updateData={updateTableFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default JobAssignList;
