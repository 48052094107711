import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../store/slices/Schedules/createScheduleSlice";
import { updateConfig } from "../../../../store/slices/Schedules/scheduleSlice";
import { getFormatedDate } from "../../../../utils/functions/table";

const useBasic = ({ optionsData, isOptionDataSuccess }) => {
  const [errors, setErrors] = useState("");

  const { currentFormData, backendErrors } = useSelector(
    (state) => state.createSchedule
  );
  const { scheduleUpdateId } = useSelector((state) => state.schedules);

  const dispatch = useDispatch();

  let createScheduleBasicVal = Yup.object({
    schedule_name: Yup.string().required("*Required"),
    schedule_duration: Yup.number()

      .required("*Required")
      .typeError("Period must be a number"),
    count: Yup.number()

      .required("*Required")
      .typeError("No. of Selections must be a number"),
    start_date: Yup.string().required("*Required"),
    schedule_type: Yup.string().required("*Required"),
    exclude_sunday: Yup.string().required("*Required"),
    scheduled_for: Yup.array().min(1).required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      schedule_name: null,
      schedule_duration: null,
      count: null,
      start_date: null,
      schedule_type: null,
      exclude_sunday: null,
      scheduled_for: null,
    },

    validationSchema: createScheduleBasicVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          schedule_name: values.schedule_name,
          schedule_duration: parseInt(values.schedule_duration),
          scheduled_for: values.scheduled_for,
          count: values.count,
          start_date: getFormatedDate(values.start_date),
          schedule_type: values.schedule_type,
          exclude_sunday: values.exclude_sunday,
        };

        dispatch(
          updateFormData((state) => {
            state.currentFormData = { ...state.currentFormData, ...obj };
            state.activeTab = "Interval";
            state.completedTabs = { ...state.completedTabs, Basic: true };
          })
        );
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
      // formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData, scheduleUpdateId]);

  useEffect(() => {
    let totalFields = Object.values(formik.values).filter(
      (f) => f !== null
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: Math.ceil(
            (totalFields / Object.keys(formik.values).length) * 100
          ),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    if (backendErrors) {
      Object.keys(backendErrors).map((keys) =>
        formik.setFieldTouched(keys, true)
      );
      formik.setErrors(backendErrors);
    }
    // eslint-disable-next-line
  }, [formik.values]);

  const handleCloseModal = (e) => {
    e.preventDefault();
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Interval: 0,
        };
        state.activeTab = "Basic";
      })
    );
  };

  return {
    errors,
    formik,
    handleCloseModal,
  };
};

export default useBasic;
