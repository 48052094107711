import React from "react";

export const NameIDrow = ({ data, activeProfile }) => {
  const ids = [activeProfile] ?? [];

  const newIds = ids.filter((id) => id !== data?.id).join(",");
  if (!data?.name) {
    return "";
  }

  return (
    <p
      className="pro-mb-0 pro-pnt"
      onClick={() =>
        window.open(
          `${
            window.location.origin
          }/${`profile?active=${data?.user_id}&id=${newIds}`}`
        )
      }
    >
      {data?.name}
    </p>
  );
};
