import { getAxiosInstance } from "../../api";

export const exportSocialMediaReport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `social-report/social-media-report-export`,
      body,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
