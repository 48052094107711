import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  partnerMatchStatus: "idle",
  partnerMatchSaveStatus:"idle",
  partnerMatchData: {},
  partnerMatchFilters: {},
};

export const getPartnerMatchDetails = createAsyncThunk(
  "partnerMatch/getPartnerMatchDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `matches/partner-${params?.star ? "star-" : ""}match`,
        {
          ...params,
          per_page: 20,
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const savePartnerMatchDetails = createAsyncThunk(
  "partnerMatch/savePartnerMatchDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("matches/partner-data-update", {
        ...params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const partnerMatchSlice = createSlice({
  name: "partnerMatch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getPartnerMatchDetails.pending]: (state) => {
      state.partnerMatchStatus = "pending";
    },
    [getPartnerMatchDetails.fulfilled]: (state, action) => {
      if (state.partnerMatchData?.[action.meta?.arg?.user_id]) {
        state.partnerMatchData[action.meta?.arg?.user_id] = {
          ...action.payload.data?.data,
          data: [
            ...state.partnerMatchData[action.meta?.arg?.user_id].data,
            ...action.payload.data?.data?.data,
          ],
        };
      } else {
        state.partnerMatchData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.partnerMatchStatus = "fulfilled";
      state.partnerMatchPreferenceStatus = "idle";
    },
    [getPartnerMatchDetails.rejected]: (state) => {
      state.partnerMatchStatus = "failed";
    },
    [savePartnerMatchDetails.pending]: (state) => {
      state.partnerMatchSaveStatus = "pending";
    },
    [savePartnerMatchDetails.fulfilled]: (state, action) => {
      state.partnerMatchSaveStatus = "fulfilled";
    },
    [savePartnerMatchDetails.rejected]: (state) => {
      state.partnerMatchSaveStatus = "failed";
    },
  },
});

export const { updateConfig, updatePreference } = partnerMatchSlice.actions;

export default partnerMatchSlice.reducer;
