import "../assets/scss/app.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Pusher from "pusher-js";
import Routes from "../routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { updateConfig } from "../store/slices/Global";

function App() {
  const dispatch = useDispatch();

  const [pusherConnection, setPusherConnection] = useState(null);

  // const { loggedStaffId } = useSelector((state) => state.global);
  const LoggedStaffData = localStorage.getItem("LoggedStaffData");
  const IsAgent = localStorage.getItem("Is_Agent");

  const PUSHER_URL =
    process.env.REACT_APP_PRODUCTION === "TRUE"
      ? process.env.REACT_APP_PUSHERURL_PRODUCTION
      : process.env.REACT_APP_PUSHERURL_WEBC;

  const PUSHER_KEY =
    process.env.REACT_APP_PRODUCTION === "TRUE"
      ? process.env.REACT_APP_PUSHER_KEY_PRODUCTION
      : process.env.REACT_APP_PUSHER_KEY_WEBC;

  const PUSHER_CHANNEL =
    process.env.REACT_APP_PRODUCTION === "TRUE"
      ? process.env.REACT_APP_PUSHER_CHANNEL_PRODUCTION
      : process.env.REACT_APP_PUSHER_CHANNEL_WEBC;

  useEffect(() => {
    const handleStaffData = async () => {
      const token = await localStorage.getItem("USER_ACCESS_TOKEN");

      if (IsAgent === "true") {
        const pusher = new Pusher(PUSHER_KEY, {
          cluster: "ap2",
          channelAuthorization: {
            endpoint: PUSHER_URL + "broadcasting/auth",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        });

        setPusherConnection(pusher);
        const channel = pusher.subscribe(PUSHER_CHANNEL + LoggedStaffData);

        channel.bind(`vi-nexus`, function (data) {
          if (data) {
            dispatch(
              updateConfig((state) => {
                state.call_data = data;
                state.call_active = true;
              })
            );
          }
        });

        // Cleanup function to disconnect from Pusher on unmount
        return () => {
          pusher.disconnect();
        };
      }
    };

    handleStaffData();

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const myListener = document.addEventListener(
      "visibilitychange",
      function () {
        if (document.hidden) {
          pusherConnection?.disconnect();
        } else {
          pusherConnection?.connect();
        }
      }
    );

    return () => {
      document.removeEventListener("visibilitychange", myListener);
    };
  }, [pusherConnection]);

  return (
    <main>
      <Routes />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </main>
  );
}

export default App;
