import React from "react";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const RowTwo = ({ formik, optionsData }) => {
  return (
    <>
      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Land Info
          </label>
          <textarea
            id="land_info"
            name="land_info"
            className={`pro-input lg ${
              formik.touched.land_info && formik.errors.land_info && " error"
            }`}
            {...formik.getFieldProps("land_info")}
          ></textarea>

          {formik.touched.land_info && formik.errors.land_info && (
            <span className="error-text">{formik.errors.land_info}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Land Details
          </label>
          <Select
            id="land_details"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.landCategoryList}
            getOptionLabel={(option) => `${option.land_area_type}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.land_details &&
              formik.errors.land_details &&
              " error"
            }`}
            value={optionsData?.data?.landCategoryList?.filter(
              (p) => p.id?.toString() === formik.values.land_details?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("land_details")}
            onChange={(value) =>
              formik.setFieldValue("land_details", value?.id || null)
            }
          ></Select>
          {formik.touched.land_details && formik.errors.land_details && (
            <span className="error-text">{formik.errors.land_details}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <Input
          label={"House SqFt"}
          type="text"
          id="house_sqft"
          name="house_sqft"
          className={`pro-input lg ${
            formik.touched.house_sqft && formik.errors.house_sqft && " error"
          }`}
          {...formik.getFieldProps("house_sqft")}
          error={formik.touched.house_sqft && formik.errors.house_sqft}
          errorMessage={formik.errors.house_sqft}
        />
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            House Details
          </label>
          <Select
            id="house_details"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.houseCategoryList}
            getOptionLabel={(option) => `${option.house_type}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.house_details &&
              formik.errors.house_details &&
              " error"
            }`}
            value={optionsData?.data?.houseCategoryList?.filter(
              (p) =>
                p.id?.toString() === formik.values.house_details?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("house_details")}
            onChange={(value) =>
              formik.setFieldValue("house_details", value?.id || null)
            }
          ></Select>
          {formik.touched.house_details && formik.errors.house_details && (
            <span className="error-text">{formik.errors.house_details}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Dowry Details
          </label>
          <textarea
            id="dowry_info"
            name="dowry_info"
            className={`pro-input lg ${
              formik.touched.dowry_info && formik.errors.dowry_info && " error"
            }`}
            {...formik.getFieldProps("dowry_info")}
          ></textarea>

          {formik.touched.dowry_info && formik.errors.dowry_info && (
            <span className="error-text">{formik.errors.dowry_info}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <Input
          label={"Vehicle Info"}
          type="text"
          id="vehicle_info"
          name="vehicle_info"
          className={`pro-input lg ${
            formik.touched.vehicle_info &&
            formik.errors.vehicle_info &&
            " error"
          }`}
          {...formik.getFieldProps("vehicle_info")}
          error={formik.touched.vehicle_info && formik.errors.vehicle_info}
          errorMessage={formik.errors.vehicle_info}
        />
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Agreement At
          </label>
          <textarea
            id="agreement_at"
            name="agreement_at"
            className={`pro-input lg ${
              formik.touched.agreement_at &&
              formik.errors.agreement_at &&
              " error"
            }`}
            {...formik.getFieldProps("agreement_at")}
          ></textarea>

          {formik.touched.agreement_at && formik.errors.agreement_at && (
            <span className="error-text">{formik.errors.agreement_at}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Share Details
          </label>
          <textarea
            id="share_info"
            name="share_info"
            className={`pro-input lg ${
              formik.touched.share_info && formik.errors.share_info && " error"
            }`}
            {...formik.getFieldProps("share_info")}
          ></textarea>

          {formik.touched.share_info && formik.errors.share_info && (
            <span className="error-text">{formik.errors.share_info}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Family Income
          </label>
          <Select
            id="family_income"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.familyIncomeList}
            getOptionLabel={(option) => `${option.income_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.family_income &&
              formik.errors.family_income &&
              " error"
            }`}
            value={optionsData?.data?.familyIncomeList?.filter(
              (p) =>
                p.id?.toString() === formik.values.family_income?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("family_income")}
            onChange={(value) =>
              formik.setFieldValue("family_income", value?.id || null)
            }
          ></Select>
          {formik.touched.family_income && formik.errors.family_income && (
            <span className="error-text">{formik.errors.family_income}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Siblings Info
          </label>
          <textarea
            id="sibilings_info"
            name="sibilings_info"
            className={`pro-input lg ${
              formik.touched.sibilings_info &&
              formik.errors.sibilings_info &&
              " error"
            }`}
            {...formik.getFieldProps("sibilings_info")}
          ></textarea>

          {formik.touched.sibilings_info && formik.errors.sibilings_info && (
            <span className="error-text">{formik.errors.sibilings_info}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default RowTwo;
