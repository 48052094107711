import { useState } from "react";
import { addDays } from "date-fns";
import { useFormik } from "formik";
import moment from "moment";
import {
  useGetSocialMediaReportQuery,
  useSaveSocialMediaReportMutation,
  useUpdateSocialMediaFieldsMutation,
} from "../../store/queries/SocialMediaReport";
import { getFormatedDate } from "../../utils/functions/table";
import { toast } from "react-toastify";
import { updateConfig } from "../../store/slices/socialMediaReport/socialMediaReportSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as updateOrderConfig } from "../../store/slices/OrderColumns/orderColumnSlice";
import { exportSocialMediaReport } from "./api";

const useSocialMediaReport = () => {
  const { showEditModal } = useSelector((state) => state.socialMediaReport);
  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);
  const socialMediaState = useSelector((state) => state.socialMediaReport);
  const {
    data: socialMediaData,
    isFetching,
    refetch,
  } = useGetSocialMediaReportQuery({
    page: socialMediaState?.currentPage,
    per_page: socialMediaState?.currentPageSize,
  });

  const dispatch = useDispatch();
  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -15),
    endDate: new Date(),
    key: "selection",
  });

  const [exportLoading, setExportLoading] = useState(false);

  const [saveSocialMediaReport] = useSaveSocialMediaReportMutation();
  const [updateSocialMediaFields] = useUpdateSocialMediaFieldsMutation();

  const formik = useFormik({
    initialValues: {
      date: [
        moment(Date.now()).add(-15, "days").format("YYYY-MM-DD"),
        moment(Date.now()).format("YYYY-MM-DD"),
      ],
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveSocialMediaReport(values).then((res) => {
        setSubmitting(false);
        if (res?.data?.status_code === 200) {
          toast.success("Filters Saved Successfully");
        } else {
          toast.error("Failed to save report filters");
        }
      });
    },
  });

  const handleDateChange = (range) => {
    setInitialDateRange(range);
    formik?.setFieldValue("date", [
      getFormatedDate(range.startDate),
      getFormatedDate(range.endDate),
    ]);
  };

  const handleSyncReport = () => {
    refetch();
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSort = (label) => {
    if (socialMediaState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            socialMediaState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (id) => {
    const selectedId = id?.[0];
    const tempResponse = socialMediaData?.data?.data?.filter(
      (item) => item?.date_id === selectedId
    )?.[0];

    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.selectedData = tempResponse;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
        state.selectedData = [];
      })
    );
  };

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const handleExportClick = () => {
    setExportLoading(true);
    exportSocialMediaReport({}).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setExportLoading(false);
    });
  };

  return {
    initialDateRange,
    formik,
    socialMediaState,
    showEditModal,
    socialMediaData,
    isFetching,
    paginationOptions,
    showTableFieldsModal,
    exportLoading,
    handleExportClick,
    updateSocialMediaFields,
    handleEditFieldsClick,
    handlePagination,
    handlePageSize,
    refetch,
    closeEditModal,
    handleEditAction,
    handleSort,
    getRow,
    handleSyncReport,
    handleDateChange,
  };
};

export default useSocialMediaReport;
