import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useHideProfileMutation,
  useGetProfileDataQuery,
} from "../../../../store/queries/Profile";

const useHideProfileForm = ({ userId, handleCancel, ProfileName }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetProfileDataQuery(userId);

  const [sendRequest] = useHideProfileMutation();

  let hideProfileFormSchema = Yup.object({});

  const formik = useFormik({
    initialValues: {
      user_id: userId,
      reason: null,
    },
    validationSchema: hideProfileFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    sendRequest(values)
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success("Profile Hide Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Hide Profile!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Hide Profile!");
      });
  };
  return { formik, optionsData, isOptionDataSuccess };
};

export default useHideProfileForm;
