import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";

const OtherPreference = ({ formik, optionsData }) => {
  return (
    <div className="row" id="other-preference">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">
          Other Preferences
        </h6>
      </div>
      <div className={`col-4`}>
        <Input
          label={"Social / Religious Expectations"}
          type="text"
          id="prefer_religious_expectation"
          name="prefer_religious_expectation"
          className={`pro-input lg ${
            formik.touched.prefer_religious_expectation && formik.errors.prefer_religious_expectation &&
            " error"
          }`}
          {...formik.getFieldProps("prefer_religious_expectation")}
          error={
            formik.touched.prefer_religious_expectation && formik.errors.prefer_religious_expectation 
          }
          errorMessage={ formik.errors.prefer_religious_expectation }
        />
      </div>
      <div className={`col-4`}>
        <Input
          label={"Financial Expectation"}
          type="text"
          id="prefer_financial_expectation"
          name="prefer_financial_expectation"
          className={`pro-input lg ${
            formik.touched.prefer_financial_expectation && formik.errors.prefer_financial_expectation  &&
            " error"
          }`}
          {...formik.getFieldProps("prefer_financial_expectation")}
          error={
            formik.touched.prefer_financial_expectation && formik.errors.prefer_financial_expectation 
          }
          errorMessage={  formik.errors.prefer_financial_expectation }
        />
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Jathakam Types
          </label>
          <Select
            id="prefer_jathakam_type"
            placeholder={"Select"}
            isMulti
            options={optionsData?.data?.jathakamLists}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.jathakam_type}
            value={optionsData?.data?.jathakamLists?.filter?.((c) =>
              formik.values.prefer_jathakam_type?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              formik.setFieldValue(
                "prefer_jathakam_type",
                value.map((a) => a.id?.toString())
              );
            }}
            onBlur={formik.handleBlur("prefer_jathakam_type")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_jathakam_type && formik.errors.prefer_jathakam_type  &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          { formik.touched.prefer_jathakam_type && formik.errors.prefer_jathakam_type  && (
              <span className="error-text">
                { formik.errors.prefer_jathakam_type }
              </span>
            )}
        </div>
      </div>

      <div className={`col-4`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            House Square Feet Between
          </label>
          <Select
            id="house_square_feet"
            placeholder={"Select"}
            isMulti
            options={optionsData?.data?.houseCategoryList}
            getOptionLabel={(option) => `${option.house_type}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.house_square_feet && formik.errors.house_square_feet &&
              " error"
            }`}
            value={optionsData?.data?.houseCategoryList?.filter?.((c) =>
              formik.values.house_square_feet?.includes(c.id?.toString())
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("house_square_feet")}
            onChange={(value) => {
              formik.setFieldValue(
                "house_square_feet",
                value.map((a) => a.id?.toString())
              );
            }}
          ></Select>
          {formik.touched.house_square_feet && formik.errors.house_square_feet&& (
              <span className="error-text">
                {formik.touched.house_square_feet && formik.errors.house_square_feet}
              </span>
            )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Matching Stars
          </label>
          <Select
            id="matching_stars"
            placeholder={"Select"}
            isMulti
            options={optionsData?.data?.starsList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.star_name}
            value={optionsData?.data?.starsList?.filter?.((c) =>
              formik.values.matching_stars?.includes(c.id?.toString())
            )}
            onChange={(value) => {
              formik.setFieldValue(
                "matching_stars",
                value.map((a) => a.id?.toString())
              );
            }}
            onBlur={formik.handleBlur("matching_stars")}
            className={`pro-input lg multi-select ${
              formik.touched.matching_stars && formik.errors.matching_stars &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          { formik.touched.matching_stars && formik.errors.matching_stars && (
            <span className="error-text">
              { formik.errors.matching_stars}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherPreference;
