import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteReportStatusDataMutation,
  useGetReportStatusListDataQuery,
  useUpdateReportStatusListFieldsMutation,
} from "../../../store/queries/ReportStatus";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/Report/reportStatusSlice";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";
import { StatusRow } from "./Rows";

const useReportStatus = () => {
  const reportStatusState = useSelector((state) => state.reportStatus);
  const location = useLocation();
  const navigate = useNavigate();
  const { showUpdateModal } = useSelector((state) => state.reportStatus);
  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  const [errors, setErrors] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUpdateRows, setSelectedUpdatedRows] = useState([]);

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: reportStatus = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetReportStatusListDataQuery({
    job_id: location.pathname.slice(18),
    per_page: reportStatusState.currentPageSize,
    page: reportStatusState.currentPage,
    sort_status: reportStatusState.currentFilter,
    sort_by: reportStatusState.sortBy,
    sort: reportStatusState.sortOrder,
  });

  const obj = reportStatus?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const [updateReportStatusFields] = useUpdateReportStatusListFieldsMutation();
  const [deleteReportStatusFields] = useDeleteReportStatusDataMutation();

  const dispatch = useDispatch();

  const filters = useMemo(() => {
    if (reportStatus?.data?.filter) {
      const buttonGroups = reportStatus?.data?.filter?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const hasUpdatePermission = useMemo(() => {
    let permission = reportStatus?.data?.permissions?.filter((Obj) =>
    Object.keys(Obj).includes("can_update")
  )[0].can_update;

  return permission;
    // eslint-disable-next-line
  }, [reportStatus]);

  const hasDeletePermission = useMemo(() => {
    let permission = reportStatus?.data?.permissions?.filter((Obj) =>
    Object.keys(Obj).includes("can_delete")
  )[0].can_delete;

  return permission;
    // eslint-disable-next-line
  }, [reportStatus]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (reportStatusState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            reportStatusState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditFieldsModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (_, data) => <StatusRow data={data} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleLinkClick = (item) => {
   
    if (item === "Allotted list") {
      navigate(`/dashboard/report`);
    }
    else{

      const newIds = ids
      .filter((id) => id !== reportStatus?.data?.user_id)
      .join(",");

    window.open(
      `${
        window.location.origin
      }/${`profile?active=${reportStatus?.data?.user_id}&id=${newIds}`}`
    );

    }
  };

  const handleTableEditClick = (item) => {
    dispatch(
      updateConfig((state) => {
        state.showUpdateModal = true;
      })
    );

    setSelectedUpdatedRows(item);
  };

  const handleTableDeleteClick = (item) => {
    setShowDeleteModal(true);
    setSelectedUpdatedRows(item);
  };

  const handleRedirectProfile = () => {
    const newIds = ids
      .filter((id) => id !== reportStatus?.data?.user_id)
      .join(",");

    window.open(
      `${
        window.location.origin
      }/${`profile?active=${reportStatus?.data?.user_id}&id=${newIds}`}`
    );
  };

  const handleDeleteData = (value) => {
    deleteReportStatusFields({ call_id: selectedUpdateRows }).then(
      (response) => {
        if (response?.data?.status_code === 200) {
          refetch();
          setShowDeleteModal(false);

          toast.success("Successfully deleted");
        } else if (response?.error?.status === 422) {
          setErrors(response?.error?.data?.errors);
        }
      }
    );
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const closeUpdateModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showUpdateModal = false;
      })
    );
  };

  return {
    reportStatus,
    reportStatusState,
    filters,
    currentPage: reportStatusState.currentPage,
    activeFilter: reportStatusState.currentFilter,
    showTableFieldsModal,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    showUpdateModal,
    selectedUpdateRows,
    errors,
    sortedTableFields,
    hasUpdatePermission,
    hasDeletePermission,
    handleRedirectProfile,
    refetch,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleSort,
    handleEditFieldsClick,
    closeEditFieldsModal,
    getRow,
    updateReportStatusFields,
    handleLinkClick,
    handleTableEditClick,
    handleTableDeleteClick,
    closeDeleteModal,
    closeUpdateModal,
    handleDeleteData,
  };
};

export default useReportStatus;
