import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateProfileGalleryInfoMutation,
  useGetGalleryImagesDataQuery,
  useDeleteGalleryImagesDataMutation,
  useDeleteGalleryDocumentsDataMutation,
  useUploadMultipleImagesMutation,
  useUploadAgreementDocumentMutation,
  useGetAgreementDocumentsQuery,
  useGetAgreementImagesQuery,
  useRemoveAgreementDocumentMutation,
} from "../../../../store/queries/Registration";
import {
  getUserDetails,
  updateFormData,
} from "../../../../store/slices/Registration/createProfileSlice";
import { getAxiosInstance } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { profile } from "../../../../store/queries/Profile";
import { getImage } from "../../../../utils/functions/getCroppedImage";

const useGallery = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const navigate = useNavigate();

  const imgRef = useRef();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageId, setImageId] = useState("");
  const [pdfId, setPdfId] = useState("");
  const [pdfCategory, setPdfCategory] = useState("");
  const [fileType, setFiletype] = useState("");
  const [imageType, setImageType] = useState("");
  const [image, setImage] = useState(null);
  const [nonImage, setNonImage] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [fileName, setfileName] = useState({});
  const [multipleFiles, setMultipleFiles] = useState({
    files: [],
    fileUrls: [],
  });
  const [agreementImageModal, setAgreementImageModal] = useState(false);
  const [showDeleteAgreementModal, setShowDeleteAgreementModal] =
    useState(false);
  const [deleteAgreementId, setDeleteAgreementId] = useState("");
  const [agreementImageUploading, setAgreementImageUploading] = useState(false);

  const [crop, setCrop] = useState({
    unit: "px",
    width: 300,
    height: 300,
    x: 100,
    y: 90,
    aspect: 1,
  });
  const { currentFormDataImage } = useSelector((state) => state.createProfile);

  const {
    data: imagesData = {},
    isSuccess: isImageDataSuccess,
    refetch,
  } = useGetGalleryImagesDataQuery({ id: activeUser });
  const {
    data: AgreementDocumentData = {},
    isSuccess: isAgreementDocumentSuccess,
    refetch: AgreementDocumentRefetch,
  } = useGetAgreementDocumentsQuery({ user_id: activeUser, type: "pdf" });

  const {
    data: AgreementImageData = {},
    isSuccess: isAgreementImageSuccess,
    refetch: AgreementImageRefetch,
  } = useGetAgreementImagesQuery({ user_id: activeUser, type: "image" });

  const [registerProfile, { isLoading: isUpdating }] =
    useCreateProfileGalleryInfoMutation();
  const [deleteImage] = useDeleteGalleryImagesDataMutation();
  const [deleteDocument] = useDeleteGalleryDocumentsDataMutation();
  const [uploadMultipleImages] = useUploadMultipleImagesMutation();
  const [uploadAgreementDocument] = useUploadAgreementDocumentMutation();
  const [removeAgreementDocument] = useRemoveAgreementDocumentMutation();

  const dispatch = useDispatch();

  let createProfileGalleryVal = Yup.object({
    image: Yup.string(),
  });

  let updateProfileGalleryVal = Yup.object({
    image: Yup.string(),
    non_profile_image: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      user_id: activeUser,
      verify_profile: "",
      featured_profile: "",
      photo_password: "",
      image: "",
      non_profile_image: "",
      non_profile_agreement_images: [],
      non_profile_document: "",
      image_category: null,
      document_category: null,
    },

    validationSchema:
      edit !== "true" ? updateProfileGalleryVal : createProfileGalleryVal,

    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          user_id: values.user_id,
          verify_profile: +values.verify_profile,
          featured_profile: +values.featured_profile,
          photo_password: +values.photo_password,
        };

        dispatch(
          updateFormData((state) => {
            state.currentFormDataImage = {
              ...state.currentFormDataImage,
              ...obj,
            };
            state.completedTabs = { ...state.completedTabs, Gallery: true };
          })
        );

        if (formik.values.user_id) {
          registerProfile(obj).then((response) => {
            formik.setErrors("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              submitForm(resetForm);
              refetch();
            } else if (response?.error?.data?.status_code === 422) {
              dispatch(
                updateFormData((state) => {
                  formik.setErrors(response?.error?.data?.errors);
                })
              );
            }
          });
        } else {
          setErrors("Fill required fields in all  Forms");
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = () => {
    dispatch(profile.util.invalidateTags(["Profile", "Overview"]));
    sessionStorage.setItem(
      "all_profiles",
      JSON?.stringify(
        JSON?.parse(sessionStorage.getItem("all_profiles"))?.filter?.(
          (profile) => profile.id !== activeUser
        )
      ) ?? []
    );
    dispatch(
      updateFormData((state) => {
        state.currentFormDataBasic = {};
        state.currentFormDataFamily = {};
        state.currentFormDataImage = {};
        state.currentFormDataHoroscope = {};
        state.currentFormDataPartner = {};
        state.currentFormDataReligious = {};
        state.activeTab = "Basic";
        state.CompletedPercentage = {
          Basic: 0,
          Family: 0,
          Gallery: 0,
          Horoscope: 0,
          Partner: 0,
          Religious: 0,
        };
        state.completedTabs = {
          Basic: false,
          Family: false,
          Gallery: false,
          Horoscope: false,
          Partner: false,
          Religious: false,
        };
      })
    );
    sessionStorage.removeItem("all_profiles");

    if (edit === true) {
      toast.success("Updation Successfull");
    } else {
      toast.success("Registration succesfull");
    }

    if (toTab) {
      navigate(-1);
    } else {
      navigate("/dashboard/profile-registration");
    }
  };

  useEffect(() => {
    if (Object.keys(currentFormDataImage).length === 0) {
      dispatch(
        getUserDetails({
          endpoint: "user-image-info",
          id: activeUser,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormDataImage || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormDataImage?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormDataImage]);

  useEffect(() => {
    if (isImageDataSuccess) {
      Object.keys(imagesData?.data || {}).forEach((key) => {
        formik.setFieldValue(key, imagesData?.data?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, imagesData?.data]);

  useEffect(() => {
    let totalFields = Object.values(formik.values).filter(
      (f) => f !== "" && f !== null
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Gallery: Math.ceil(
            (totalFields / Object.keys(formik.values).length) * 100
          ),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const handleVerifyProfileCheckbox = (isChecked) => {
    formik.setFieldValue("verify_profile", !isChecked);
  };
  const handlePhotoPasswordCheckbox = (isChecked) => {
    formik.setFieldValue("photo_password", !isChecked);
  };
  const handleFeaturedProfileCheckbox = (isChecked) => {
    formik.setFieldValue("featured_profile", !isChecked);
  };

  const handleRemoveProfileImage = (e) => {
    e.preventDefault();
    document.getElementById("image").value = "";
    formik.setFieldValue("image", "");
  };

  const handleRemoveNonProfileImage = (e) => {
    e.preventDefault();
    document.getElementById("non_profile_image").value = "";
    formik.setFieldValue("non_profile_image", "");
  };

  const handleRemoveNonProfileDocument = (e) => {
    e.preventDefault();
    document.getElementById("non_profile_document").value = "";
    formik.setFieldValue("non_profile_document", "");
  };

  const handleDeleteClick = (id, type) => {
    setImageId(id);
    setImageType(type);
    setShowDeleteModal(true);
    setFiletype("1");
  };

  const handleDeletePdf = (id, category) => {
    setPdfId(id);
    setPdfCategory(category);
    setShowDeleteModal(true);
    setFiletype("2");
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const closeCropModal = () => {
    setShowCrop(false);
  };

  const closePdfModal = () => {
    setShowPdfModal(false);
  };

  const handleDeleteData = (type) => {
    //dynamically checking the type of the data to be removed
    //type 1 is image and type 2 is pdf
    // supppose both type is not chosen then toast will appear "the item is invaid"
    if (type === "1") {
      deleteImage({
        user_id: activeUser,
        image_id: imageId ?? "",
        image_type: imageType,
      }).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(profile.util.invalidateTags(["Profile"]));
          setShowDeleteModal(false);
          refetch();
          toast.success("Successfully deleted");
        } else if (response?.error?.status === 422) {
          setErrors(response?.error?.data?.errors);
        }
      });
    } else if (type === "2") {
      deleteDocument({
        user_id: activeUser,
        document_id: pdfId ?? "",
        document_type: pdfCategory,
      }).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(profile.util.invalidateTags(["Profile"]));
          setShowDeleteModal(false);
          refetch();
          toast.success("Successfully deleted");
        } else if (response?.error?.status === 422) {
          setErrors(response?.error?.data?.errors);
        }
      });
    } else {
      toast.error("The item is invalid ");
    }
  };

  const handleProfileImageUpload = async () => {
    setLoading(true);
    let formData = new FormData();

    try {
      const croppedImage = await getImage(imgRef.current, crop, fileName);

      let obj = {
        user_id: formik.values.user_id,
        image: croppedImage,
      };
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      const api = await getAxiosInstance();
      api
        .post("/profile/upload-profile-image", formData)
        .then((response) => {
          setLoading(false);
          // if (response?.data?.status_code === 200) {
          dispatch(profile.util.invalidateTags(["Profile"]));
          setShowCrop(false);
          refetch();
          formik.setFieldValue("image", "");
          document.getElementById("image").value = "";
          toast.success("Successfully uploaded");
        })
        .catch((response) => {
          setLoading(false);
          setErrors(response?.data?.errors);
          setShowCrop(false);
          formik.setFieldValue("image", "");
          document.getElementById("image").value = "";
          toast.error(
            response?.response?.data?.message ?? "Failed to upload image"
          );
        });
      // else{
      //   setLoading(false);
      //   setErrors(response?.data?.errors);
      //   setShowCrop(false);
      //   toast.error(response?.response?.data?.message ?? "Failed to upload image")
      // }
      // });
    } catch (response) {
      setLoading(false);
      toast.error("Failed to Crop Image!");
    }
  };

  const handleNonProfileImageUpload = async () => {
    setLoading(true);

    let formData = new FormData();
    try {
      const croppedImage = await getImage(imgRef.current, crop, fileName);

      let obj = {
        user_id: formik.values.user_id,
        non_profile_image: croppedImage,
        image_category: formik.values.image_category,
      };

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      const api = await getAxiosInstance();
      api
        .post("/profile/non-profile-image-upload", formData)
        .then((response) => {
          // if (response?.data?.status_code === 200) {
          setShowCrop(false);
          refetch();
          setLoading(false);
          document.getElementById("non_profile_image").value = "";
          formik.setFieldValue("non_profile_image", "");
          toast.success("Successfully uploaded");
          dispatch(profile.util.invalidateTags(["Profile"]));
          // } else if (response?.data?.status_code === 422) {
        })
        .catch((response) => {
          setLoading(false);
          setErrors(response?.data?.errors);
          setShowCrop(false);
          document.getElementById("non_profile_image").value = "";
          formik?.setFieldValue("non_profile_image", "");
          toast.error(
            response?.response?.data?.message ?? "Failed to upload image"
          );
        });
      // });
    } catch (e) {
      toast.error("Failed to Crop Image!");
    }
  };

  const handleNonProfileDocumentUpload = async () => {
    setLoading(true);

    let formData = new FormData();
    if (formik?.values?.document_category !== 4) {
      try {
        let obj = {
          user_id: formik.values.user_id,
          non_profile_pdf: formik?.values?.non_profile_document,
          pdf_category: formik.values.document_category,
        };

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        const api = await getAxiosInstance();
        api
          .post("/profile/non-profile-document", formData)
          .then((response) => {
            // if (response?.data?.status_code === 200) {
            refetch();
            closePdfModal();
            setLoading(false);
            document.getElementById("non_profile_document").value = "";
            formik.setFieldValue("non_profile_document", "");
            toast.success(response?.data?.message ?? "Successfully uploaded");
            dispatch(profile.util.invalidateTags(["Profile"]));
            // } else if (response?.data?.status_code === 422) {
          })
          .catch((response) => {
            setLoading(false);
            setErrors(response?.data?.errors);
            closePdfModal();
            document.getElementById("non_profile_document").value = "";
            formik?.setFieldValue("non_profile_document", "");
            toast.error(
              response?.response?.data?.message ?? "Failed to upload Document"
            );
          });
        // });
      } catch (e) {
        toast.error("Failed to Crop Image!");
      }
    } else {
      let obj = {
        user_id: activeUser,
        non_profile_image: formik?.values?.non_profile_document,
      };

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      uploadAgreementDocument(formData).then((res) => {
        if (res?.data?.status_code === 200) {
          refetch();
          closePdfModal();
          setLoading(false);
          document.getElementById("non_profile_document").value = "";
          formik.setFieldValue("non_profile_document", "");
          toast.success(res?.data?.message ?? "Successfully uploaded");
          dispatch(profile.util.invalidateTags(["Profile"]));
          AgreementDocumentRefetch();
        } else {
          setLoading(false);
          setErrors(res?.data?.errors);
          closePdfModal();
          document.getElementById("non_profile_document").value = "";
          formik?.setFieldValue("non_profile_document", "");
          toast.error(res?.data?.message ?? "Failed to upload Document");
        }
      });
    }
  };

  const handleProfileImageChange = (e) => {
    setfileName({ name: e.target.files[0].name, type: e.target.files[0].type });
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageType(1);
    setShowCrop(true);
    formik.setFieldValue("image", e?.target?.files[0]);
  };
  const handleNonProfileImageChange = (e) => {
    setfileName({ name: e.target.files[0].name, type: e.target.files[0].type });
    setImage(URL.createObjectURL(e.target.files[0]));
    setShowCrop(true);
    setImageType(2);
    formik.setFieldValue("non_profile_image", e?.target?.files[0]);
  };

  const handleNonProfileDocumentChange = (e) => {
    setfileName({
      name: e.target.files[0].name,
      type: e.target.files[0].type,
    });
    setNonImage(URL.createObjectURL(e.target.files[0]));
    setShowPdfModal(true);
    formik.setFieldValue("non_profile_document", e?.target?.files[0]);
  };

  const handleAgreementImageChange = (e) => {
    const files = e?.target?.files;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    let validFiles = [];
    const validBlobFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Invalid type file tried to upload");
        return;
      }
      const urlFiles = URL.createObjectURL(files[i]);
      validFiles.push(file);
      validBlobFiles.push(urlFiles);
    }

    setMultipleFiles((state) => ({
      ...state,
      files: [...validFiles],
      fileUrls: [...validBlobFiles],
    }));

    setAgreementImageModal(true);
  };

  const handleConfirmUploadAgreementImage = () => {
    setAgreementImageUploading(true);
    let formData = new FormData();
    formData.append("user_id", activeUser);
    multipleFiles?.["files"]?.map((item, i) =>
      formData.append(`non_profile_image[${i}]`, item)
    );

    uploadMultipleImages(formData).then((res) => {
      setAgreementImageUploading(false);
      if (res?.data?.status_code === 200) {
        setMultipleFiles((state) => ({
          ...state,
          files: [],
          fileUrls: [],
        }));
        refetch();
        AgreementImageRefetch();
        setAgreementImageModal(false);
        toast.success(
          res?.data?.message ?? "User Agreement Uploaded Successfully"
        );
      } else {
        setMultipleFiles((state) => ({
          ...state,
          files: [],
          fileUrls: [],
        }));
        refetch();
        AgreementImageRefetch();
        setAgreementImageModal(false);
        toast.error(res?.data?.message);
      }
    });
  };

  const handleNonProfileImageClick = () => {
    if (!formik.values.image_category) {
      toast.error("Select a Image Category");
    }
  };

  const handleNonProfileDocumentClick = () => {
    if (!formik.values.document_category) {
      toast.error("Select a Document Category");
    }
  };

  const closeAgreementImageModal = () => {
    setAgreementImageModal(false);
    setMultipleFiles((state) => ({
      ...state,
      files: [],
      fileUrls: [],
    }));
  };

  const handleDeleteAgreement = (id) => {
    setShowDeleteAgreementModal(true);
    setDeleteAgreementId(id);
  };

  const closeDeleteAgreementModal = () => {
    setShowDeleteAgreementModal(false);
    setDeleteAgreementId("");
  };

  const handleDeleteAgreementItem = () => {
    removeAgreementDocument({ image_id: deleteAgreementId }).then((res) => {
      if (res?.data?.status_code === 200) {
        setShowDeleteAgreementModal(false);
        setDeleteAgreementId("");
        toast.success(
          res?.data?.message ?? "User Agreement Deleted Sucessfully"
        );
      } else {
        toast.error("failed to delete User Agreement");
        setShowDeleteAgreementModal(false);
        setDeleteAgreementId("");
      }
    });
  };

  return {
    errors,
    formik,
    isUpdating,
    imagesData,
    isImageDataSuccess,
    showDeleteModal,
    showCrop,
    crop,
    image,
    nonImage,
    document,
    imgRef,
    imageType,
    loading,
    showPdfModal,
    fileType,
    multipleFiles,
    agreementImageModal,
    AgreementDocumentData,
    AgreementImageData,
    isAgreementDocumentSuccess,
    isAgreementImageSuccess,
    showDeleteAgreementModal,
    agreementImageUploading,
    handleDeleteAgreementItem,
    closeDeleteAgreementModal,
    handleDeleteAgreement,
    setMultipleFiles,
    handleConfirmUploadAgreementImage,
    closeAgreementImageModal,
    handleAgreementImageChange,
    handleDeletePdf,
    closePdfModal,
    setNonImage,
    setImage,
    setCrop,
    closeCropModal,
    handleVerifyProfileCheckbox,
    handlePhotoPasswordCheckbox,
    handleFeaturedProfileCheckbox,
    handleRemoveProfileImage,
    handleRemoveNonProfileImage,
    handleRemoveNonProfileDocument,
    handleDeleteClick,
    closeDeleteModal,
    handleDeleteData,
    handleProfileImageUpload,
    handleNonProfileImageUpload,
    handleNonProfileDocumentUpload,
    handleNonProfileImageChange,
    handleNonProfileDocumentChange,
    handleProfileImageChange,
    handleNonProfileImageClick,
    handleNonProfileDocumentClick,
  };
};

export default useGallery;
