import React from "react";
import { format } from "date-fns";
import Select from "react-select";

const RowOne = ({
  optionsData,
  formik,
  horoscope,
  loggedStaffData,
  handleChangeToggle,
}) => {
  return (
    <>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="looking_for"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Activity*
          </label>
          <Select
            id="user_activity"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.activityList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            value={optionsData?.data?.activityList?.filter(
              (m) =>
                formik.values.user_activity?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) =>
              formik.setFieldValue("user_activity", value?.id || null)
            }
            className={`pro-input  lg multi-select ${
              formik.touched.user_activity &&
              formik.errors.user_activity &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />

          {formik.touched.user_activity && formik.errors.user_activity && (
            <span className="error-text">{formik.errors.user_activity}</span>
          )}
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="looking_for"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Search Branch
          </label>
          <Select
            id="branch"
            isDisabled={loggedStaffData?.data?.nest_admin ? false : true}
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.branchList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.branch_name}
            value={optionsData?.data?.branchList?.filter(
              (m) => formik.values.branch?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) =>
              formik.setFieldValue("branch", value?.id || null)
            }
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="religion_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Religion
          </label>
          <Select
            id="religion_id"
            placeholder={"Select"}
            isClearable={true}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData?.data?.religionList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.religion_name}
            value={optionsData?.data?.religionList?.filter(
              (m) =>
                formik.values.religion_id?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("religion_id", value?.id || null);
              formik.setFieldValue("caste_id", []);
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="caste" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Caste
          </label>
          <Select
            id="caste_id"
            placeholder={"Select"}
            isMulti
            isDisabled={formik.values.religion_id ? false : true}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData?.data?.casteList?.filter?.(
              (c) =>
                c.religion_id?.toString?.() ===
                formik.values.religion_id?.toString?.()
            )}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.caste_name}
            value={optionsData?.data?.casteList?.filter((m) =>
              formik.values.caste_id?.includes(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "caste_id",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Date From
          </label>
          <input
            value={formik.values.date_from ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "date_from",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Date To
          </label>
          <input
            value={formik.values.date_to ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "date_to",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="looking_for"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Status
          </label>
          <Select
            id="status"
            placeholder={"Select"}
            options={optionsData?.data?.statusList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            value={optionsData?.data?.statusList?.filter(
              (m) => formik.values.status?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => formik.setFieldValue("status", value.id)}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
    </>
  );
};

export default RowOne;
