import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  showCreateModal:false,
  scheduleUpdateId:null
 
};

const scheduleSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = scheduleSlice.actions;

export default scheduleSlice.reducer;
