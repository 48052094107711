import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetRegisteredProfileListDataQuery,
  useUpdateRegisteredProfileListFieldsMutation,
} from "../../../store/queries/Registration";
import { updateConfig } from "../../../store/slices/Registration/registrationSlice";
import { updateFormData } from "../../../store/slices/Registration/createProfileSlice";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WebIdRow } from "./Rows";

const useRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const activeProfile = params.get("active") ?? sessionStorage.getItem("active");
  const registrationState = useSelector((state) => state.registration);

  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const [updateRegistrationFields] =
    useUpdateRegisteredProfileListFieldsMutation();

  const {
    data: registration = {},
    isFetching,
    isLoading,
    refetch
  } = useGetRegisteredProfileListDataQuery({
    page_size: registrationState.currentPageSize,
    page: registrationState.currentPage,
    sort_by: registrationState.sortBy,
    sort_order: registrationState.sortOrder,
  });

  const obj = registration?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const hasCreatePermission = useMemo(() => {
    
    let permission = registration?.data?.permissions?.filter((Obj) =>
    Object.keys(Obj).includes("can_create")
  )[0].can_create;

  return permission;
    // eslint-disable-next-line
  }, [registration]);


  const hasEditPermission = useMemo(() => {
    let permission = registration?.data?.permissions?.filter((Obj) =>
    Object.keys(Obj).includes("can_edit")
  )[0].can_edit;

  return permission;
    // eslint-disable-next-line
  }, [registration]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (registrationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            registrationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const handleTableEditClick = (item) => {
    navigate(`/dashboard/profile-registration?edit=true&user=${item?.[0]}`);
  };

  const handleRegisterClick = () => {
    dispatch(
      updateFormData((state) => {
        state.currentFormDataBasic = {};
        state.currentFormDataFamily = {};
        state.currentFormDataImage = {};
        state.currentFormDataHoroscope = {};
        state.currentFormDataPartner = {};
        state.currentFormDataReligious = {};
        state.activeTab = "Basic";
        state.CompletedPercentage = {
            Basic: 0,
            Family: 0,
            Gallery: 0,
            Horoscope: 0,
            Partner: 0,
            Religious: 0,
          }
      }
      )
    )

    navigate("/dashboard/profile-registration");
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      register_id: (_, data) => <WebIdRow data={data} activeProfile={activeProfile} params={params} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    registration,
    registrationState,
    currentPage: registrationState.currentPage,
    hasCreatePermission,
    hasEditPermission,
    paginationOptions,
    isFetching,
    isLoading,
    showTableFieldsModal,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    updateRegistrationFields,
    handleTableEditClick,
    handleRegisterClick,
  };
};

export default useRegistration;
