import { Button } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import Select from "react-select";
import useShCompleteForm from "./useShCompleteForm";
import style from "./shCompleteForm.scss";

const ShCompleteForm = ({ handleCancel, userId, profileId, isSuccess }) => {
  const { formik, optionsData, isOptionDataSuccess } = useShCompleteForm({
    handleCancel,
    userId,
  });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Scheduled Job Completed</h4>
        <h6 className="pro-ttl  pro-mb-5">{`Profile Id : ${profileId}`}</h6>
      </div>
      {isSuccess && isOptionDataSuccess ? (
        <>
          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="schedule_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Service
            </label>
            <Select
              id="schedule_id"
              placeholder={"Select"}
              isClearable={true}
              className={`pro-input lg ${
                formik.errors.schedule_id && formik.touched.schedule_id
                  ? "error"
                  : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData?.data?.scheduleList}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.schedule_name}
              value={optionsData.data.scheduleList?.filter(
                (m) =>
                  formik.values.schedule_id?.toString?.() === m.id?.toString?.()
              )}
              onBlur={() => formik.handleBlur("schedule_id")}
              onChange={(value) => {
                formik.setFieldValue("schedule_id", value?.id?.toString()|| null);
              }}
            />
            {formik.errors.schedule_id && formik.touched.schedule_id && (
              <span className="error-text">{formik.errors.schedule_id}</span>
            )}
          </div>

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(1)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default ShCompleteForm;
