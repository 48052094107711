import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api[method](`${endpoint}`, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const utmReport = createApi({
  reducerPath: "UtmReportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["utm-report"],
  endpoints: (builder) => ({
    getUtmReport: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `campaign/campaign-list`,
      }),
      providesTags: ["utm-report"],
    }),
    updateUtmReport: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `campaign/campaign-update`,
      }),
      invalidatesTags: ["utm-report"],
    }),
    updateUtmFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `permissions/table-permission-create`,
      }),
      invalidatesTags: ["utm-report"],
    }),
  }),
});

export const {
  useGetUtmReportQuery,
  useUpdateUtmReportMutation,
  useUpdateUtmFieldsMutation,
} = utmReport;
