import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { updateConfig } from "../../../store/slices/Global";
import { login } from "../api";
import useValidations from "../../../utils/hooks/useValidations";

const useAuth = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { validateEmail } = useValidations();

  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const [showPassword, setShowPassword] = useState(true);

  useEffect(() => {
    if (loginStatus === "success") {
      navigate("/users/members");
      window.location.reload();
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      pin: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email Required";
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      return errors;
    },
    onSubmit: (values) => {
      setLoginStatus("pending");
      login(values).then((response) => {
        if (response?.status === 200) {
          setLoginInfo(response?.data?.data?.message);
          localStorage.setItem(
            "USER_ACCESS_TOKEN",
            response?.data?.data?.access_token
          );
          localStorage.setItem(
            "LoggedStaffData",
            response?.data?.data?.staff_id
          );

          localStorage.setItem("Is_Agent", response?.data?.data?.is_agent);
          // dispatch(
          //   updateConfig(
          //     (state) => (state.loggedStaffId = response?.data?.data?.staff_id)
          //   )
          // );
          setLoginStatus("success");
        } else if (response?.status_code === 422) {
          setLoginStatus("failed");
          setLoginInfo({ password: response?.message });
        } else if (response?.status_code === 400) {
          setLoginInfo({ password: response?.message });
          setLoginStatus("failed");
        } else if (response?.status_code === 403) {
          setLoginInfo({ pin: response?.message });
          setLoginStatus("failed");
        } else setLoginStatus("An error occurred");
      });
    },
  });

  const handleForgotPassword = () => {
    navigate("/login/forgot-password");
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    loginStatus,
    showPassword,
    handleShowPassword,
    handleForgotPassword,
  };
};

export default useAuth;
