import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  useGetServiceTypeQuery,
  useGetViewServiceListDataQuery,
} from "../../../store/queries/Profile";
import { useSearchParams } from "react-router-dom";
import { updateConfig } from "../../../store/slices/Profile/ViewService";
import { updateProfileConfig } from "../../../store/slices/Profile";
import { getFormatedDate } from "../../../utils/functions/table";
import {
  CreatedAt,
  FollowUpDate,
  ServiceDetail,
  UserImage,
  UserNameAndId,
} from "./ViewServiceList/Rows";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import { updateConfig as updateAddedServiceConfig } from "../../../store/slices/Profile/AddedServiceExist";

const useViewService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const [activeFilter, setActiveFilter] = useState(1);
  const viewService = useSelector((state) => state.viewService);
  const addedServiceExistState = useSelector(
    (state) => state.addedServiceExist
  );

  const {
    data: serviceList = {},
    isFetching,
    // isLoading,
  } = useGetViewServiceListDataQuery({
    user_id: activeProfile,
    page: viewService.currentPage,
    per_page: viewService.currentPageSize,
    sort_by: viewService.sortBy ? viewService.sortBy : "created_at",
    sort: viewService.sortOrder ? viewService.sortOrder : "desc",
    filter_type: viewService.currentServiceFilter,
    date_on: viewService.dateOnRange,
    follow_up: viewService.followUpdateRange,
    service_search: viewService.serviceSearch,
    service_type: viewService.serviceValue,
  });

  const { data: optionsData = {} } = useGetServiceTypeQuery();

  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "My Service View", value: 1 },
        { name: "View All Service ", value: 0 },
      ],
    },
  ];

  const handleChangeTab = (item) => {
    if (item.value !== activeFilter) {
      setActiveFilter(item.value);
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.currentServiceFilter = item.value;
        })
      );
    }
  };

  const handleDateChangeDateOn = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.dateOnRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const handleDateChangeFollowUp = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.followUpdateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      user_images: (_, data) => (
        <UserImage image={data?.user_images?.user_image_thumbnail} />
      ),
      name: (_, data) => (
        <UserNameAndId
          data={data}
          activeProfile={activeProfile}
          params={params}
          navigate={navigate}
        />
      ),
      service: (_, data) => <ServiceDetail data={data} />,
      follow_up_date: (_, data) => <FollowUpDate data={data} />,
      created_at: (_, data) => <CreatedAt data={data} />,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (viewService.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = viewService.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "desc";
        })
      );
    }
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const handleAddService = (e, web_id) => {
    e?.stopPropagation();
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = true;
        state.partner_client_web_id = web_id;
      })
    );
    const dateInput1 = document.getElementById("service_date");
    const dateInput2 = document.getElementById("follow_up_date");

    if (dateInput1) {
      dateInput1.value = "";
    }
    if (dateInput2) {
      dateInput2.value = "";
    }

    dispatch(
      updateAddedServiceConfig((state) => {
        state.storedFormikValues = {};
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateAddedServiceConfig((state) => {
        state.showAddedServiceModal = false;
      })
    );
  };

  const handleServiceSearchChange = (value) => {
    dispatch(
      updateConfig((state) => {
        state.serviceSearch = value;
      })
    );
  };

  const handleServiceChange = (value) => {
    dispatch(
      updateConfig((state) => {
        state.serviceValue = value;
      })
    );
  };

  return {
    serviceList,
    isFetching,
    activeFilter,
    filters,
    paginationOptions,
    currentPage: viewService?.currentPage,
    searchValue: viewService?.serviceSearch,
    viewService,
    addedServiceExistState,
    optionsData,
    handleServiceChange,
    handleServiceSearchChange,
    closeModal,
    getRow,
    handleChangeTab,
    handleDateChangeDateOn,
    handleDateChangeFollowUp,
    handleSort,
    handlePagination,
    handlePageSize,
    handleAddService,
  };
};
export default useViewService;
