import { useSearchParams } from "react-router-dom";

import { useGetOverviewDataQuery } from "../../../store/queries/Profile";
import { useEffect, useMemo, useState } from "react";
import { useGetProfileDataQuery } from "../../../store/queries/Profile";
import moment from "moment";
import { intializeCall } from "./api";
import { toast } from "react-toastify";

const useOverview = () => {
  const [params] = useSearchParams();
  const [imageBlob, setImageBlob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showHoroscopeImage, setShowHoroscopeImage] = useState(false);
  const [outgoingCallStatus, setOutGoingCallStatus] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const { data: profileData = {} } = useGetProfileDataQuery(activeProfile);

  const openHoroscopeModal = () => {
    setShowHoroscopeImage(true);
  };

  const closeHoroscopeModal = () => {
    setShowHoroscopeImage(false);
  };

  const activeParam = params.get("active") ?? sessionStorage.getItem("active");
  const {
    data: overviewData,
    isFetching,
    refetch,
  } = useGetOverviewDataQuery(activeParam);

  const horoscopeImageData =
    overviewData?.data?.overview?.horoscope_details?.data.find(
      (item) => item.label === "Horoscope Image"
    );

  const horoscopeData = useMemo(() => {
    return overviewData?.data?.overview?.horoscope_details?.data?.filter(
      (item) => item.label !== "Horoscope Image"
    );
  }, [overviewData?.data?.overview?.horoscope_details]);

  useEffect(() => {
    horoscopeImageData?.value &&
      fetch(horoscopeImageData?.value)
        .then((response) => response.blob())
        .then((blob) => {
          setImageBlob(blob);
        });
  }, [horoscopeImageData]);

  const downloadImage = () => {
    if (imageBlob) {
      const url = window.URL.createObjectURL(imageBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${profileData?.data?.profile_data?.id}_${moment(
        Date.now()
      ).format("DD_MM_YYYY")}.png`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };
  const hasEditPermission = useMemo(() => {
    let permission = profileData?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_edit")
    )[0].can_edit;

    return permission;

    // eslint-disable-next-line
  }, [profileData]);

  const handleInitiateCall = (value) => {
    setOutGoingCallStatus(true);
    setLoadingLabel(value?.label);
    if (value) {
      intializeCall({
        mobile: value?.mobile,
      }).then((res) => {
        setOutGoingCallStatus(false);
        if (res.data.status_code === 200) {
          toast.success(res?.data?.message ?? "Call Initiated Successfully");
        } else {
          toast.error(res?.data?.message ?? "Failed to initiate the call");
        }
      });
    }
  };

  return {
    showModal,
    isFetching,
    horoscopeData,
    horoscopeImageData,
    showHoroscopeImage,
    hasEditPermission,
    profileData: profileData?.data?.profile_data,
    overview: overviewData?.data?.overview,
    outgoingCallStatus,
    loadingLabel,
    handleInitiateCall,
    refetch,
    handleModal,
    downloadImage,
    openHoroscopeModal,
    closeHoroscopeModal,
  };
};

export default useOverview;
