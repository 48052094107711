import React from "react";

export const CallHistoryIdRow = ({ data, activeProfile }) => {
  const ids = [activeProfile] ?? [];

  const newIds = ids.filter((id) => id !== data?.id).join(",");
  if (!data?.user_register_id) {
    return "";
  }

  return (
    <p
      className="pro-mb-0 pro-pnt"
      onClick={() =>
        window.open(
          `${
            window.location.origin
          }/${`profile?active=${data?.user_id}&id=${newIds}`}`
        )
      }
    >
      {data?.user_register_id}
    </p>
  );
};

// import { useNavigate } from "react-router-dom";

// export const RegisterIdRow = ({ data, onClick }) => {
//   // const navigate = useNavigate();

//   if (!data?.register_id) {
//     return "";
//   }
//   return (
//     // <p
//     //   onClick={() => navigate(`/dashboard/reg-report/${data?.id}`)}
//     // >{`${data?.register_id}`}</p>

//     <p>{`${data?.register_id}`}</p>
//   );
// };
