import useRouteUtils from "../../../utils/hooks/useRouteUtils";

const useCallHistory = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Missed Call",
      link: `/call-history`,
      isButton: false,
      active: checkIfActiveRoute("/call-history", true),
    },
    {
      label: "Incoming Call",
      link: `/call-history/incoming-call`,
      isButton: false,
      active: checkIfActiveRoute("/call-history/incoming-call"),
    },
    {
      label: "Outgoing Call",
      link: `/call-history/outgoing-call`,
      isButton: false,
      active: checkIfActiveRoute("/call-history/outgoing-call"),
    },
  ];
  return { navigations };
};

export default useCallHistory;
