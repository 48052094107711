import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const RowOne = ({
  formik,
  optionsData,
  onChangeMob2Response,
  onChangeMobResponse,
  onChangeRegisterIdResponse,
  onChangeEmailResponse,
  handleRegisterNumberBlur,
  handleProfileRedirect,
  handleMobileBlur,
  handleMobile2Blur,
  handleEmailBlur,
  countryData,
}) => {
  return (
    <>
      <div className={`col-3`}>
        <Input
          label={"Registration Number*"}
          type="text"
          id="register_number"
          name="register_number"
          className={`pro-input lg ${
            formik.touched.register_number &&
            formik.errors.register_number &&
            " error"
          }`}
          {...formik.getFieldProps("register_number")}
          onBlur={(e) => handleRegisterNumberBlur(e)}
          error={
            formik.touched.register_number && formik.errors.register_number
          }
          errorMessage={formik.errors.register_number}
        />
        <span>
          {Object.keys(onChangeRegisterIdResponse)?.length > 0 ? (
            <span className={`erro-web-id`}>
              <span className="err">
                {onChangeRegisterIdResponse?.register_number}
              </span>
            </span>
          ) : (
            <></>
          )}
        </span>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Package*
          </label>
          <Select
            id="register_package_id"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.packageList}
            getOptionLabel={(option) => `${option.package_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.register_package_id &&
              formik.errors.register_package_id &&
              " error"
            }`}
            value={optionsData?.data?.packageList?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.register_package_id?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("register_package_id")}
            onChange={(value) =>
              formik.setFieldValue("register_package_id", value?.id || null)
            }
          ></Select>
          {formik.touched.register_package_id &&
            formik.errors.register_package_id && (
              <span className="error-text">
                {formik.errors.register_package_id}
              </span>
            )}
        </div>
      </div>

      <div className={`col-3`}>
        <Input
          label={"Profile Visibility Score"}
          type="text"
          id="profile_visibility_score"
          name="profile_visibility_score"
          className={`pro-input lg ${
            formik.touched.profile_visibility_score &&
            formik.errors.profile_visibility_score &&
            " error"
          }`}
          {...formik.getFieldProps("profile_visibility_score")}
          error={
            formik.touched.profile_visibility_score &&
            formik.errors.profile_visibility_score
          }
          errorMessage={formik.errors.profile_visibility_score}
        />
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Branch*
          </label>
          <Select
            id="branch_id"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.branchList}
            getOptionLabel={(option) => `${option.branch_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input  lg multi-select ${
              formik.touched.branch_id && formik.errors.branch_id && " error"
            }`}
            value={optionsData?.data?.branchList?.filter(
              (p) => p.id?.toString() === formik.values.branch_id?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("branch_id")}
            onChange={(value) =>
              formik.setFieldValue("branch_id", value?.id || null)
            }
          ></Select>
          {formik.touched.branch_id && formik.errors.branch_id && (
            <span className="error-text">{formik.errors.branch_id}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <Input
          label={"Full Name*"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${
            formik.touched.name && formik.errors.name && " error"
          }`}
          {...formik.getFieldProps("name")}
          error={formik.touched.name && formik.errors.name}
          errorMessage={formik.errors.name}
        />
      </div>

      <div className={`col-3`}>
        <Input
          label={"Email"}
          type="text"
          id="email"
          name="email"
          className={`pro-input lg ${
            formik.touched.email && formik.errors.email && " error"
          }`}
          {...formik.getFieldProps("email")}
          onBlur={(e) => handleEmailBlur(e)}
          error={formik.touched.email && formik.errors.email}
          errorMessage={formik.errors.email}
        />
        <span>
          {Object.keys(onChangeEmailResponse)?.length > 0 ? (
            <span className={`erro-web-id`}>
              <span className="err">{onChangeEmailResponse?.email}</span>
            </span>
          ) : (
            <></>
          )}
        </span>
      </div>

      <div className="col-3">
        <div className={`input-wrap pro-mb-4 `}>
          <div className="input-country-wrap ">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Mobile Number
            </label>

            <div className="input-main-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="cc_mobile1"
                  options={countryData}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={countryData?.filter(
                    (item) => item?.value === formik?.values?.cc_mobile1
                  )}
                  classNamePrefix="pro-input"
                  className="country-select"
                  onBlur={formik.handleBlur("cc_mobile1")}
                  menuPlacement="auto"
                  onChange={(value) =>
                    formik.setFieldValue("cc_mobile1", value?.value)
                  }
                ></Select>
              </div>
              <input
                type="text"
                id="mobile"
                name="mobile"
                className={`pro-input lg ${
                  formik.touched.mobile && formik.errors.mobile && " error"
                }`}
                {...formik.getFieldProps("mobile")}
                onBlur={(e) => handleMobileBlur(e)}
                error={formik.touched.mobile && formik.errors.mobile}
                errorMessage={formik.errors.mobile}
              />
              <span>
                {Object.keys(onChangeMobResponse)?.length > 0 ? (
                  <span
                    className={`erro-web-id`}
                    onClick={() =>
                      handleProfileRedirect(onChangeMobResponse?.user_id)
                    }
                  >
                    <span className="err">{onChangeMobResponse?.mobile}</span>
                    <span className={`web-id`}>
                      <span>Register ID :</span> {onChangeMobResponse?.web_id}
                    </span>
                  </span>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-3`}>
        <div className={`input-wrap pro-mb-4 `}>
          <div className="input-country-wrap ">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Mobile 2
            </label>
            <div className="input-main-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="cc_mobile2"
                  options={countryData}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={countryData?.filter(
                    (item) => item?.value === formik?.values?.cc_mobile2
                  )}
                  classNamePrefix="pro-input"
                  className="country-select"
                  onBlur={formik.handleBlur("cc_mobile2")}
                  menuPlacement="auto"
                  onChange={(value) =>
                    formik.setFieldValue("cc_mobile2", value?.value)
                  }
                ></Select>
              </div>
              <input
                // label={"Mobile 2"}
                type="text"
                id="phone_no"
                name="phone_no"
                className={`pro-input lg ${
                  formik.touched.phone_no && formik.errors.phone_no && " error"
                }`}
                {...formik.getFieldProps("phone_no")}
                onChange={(event) =>
                  formik.setFieldValue("phone_no", event.target.value)
                }
                onBlur={(e) => handleMobile2Blur(e)}
                error={formik.touched.phone_no && formik.errors.phone_no}
                errorMessage={formik.errors.phone_no}
              />

              <span>
                {Object.keys(onChangeMob2Response)?.length > 0 ? (
                  <span
                    className={`erro-web-id`}
                    onClick={() =>
                      handleProfileRedirect(onChangeMob2Response?.user_id)
                    }
                  >
                    <span className="err">
                      {onChangeMob2Response?.phone_no}
                    </span>
                    <span className={`web-id`}>
                      <span>Register ID :</span> {onChangeMob2Response?.web_id}
                    </span>
                  </span>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RowOne;
