import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEditReportStatusDataMutation } from "../../../../store/queries/ReportStatus";
import { updateConfig } from "../../../../store/slices/Report/reportStatusSlice";
import { useDispatch } from "react-redux";
import { dashboard } from "../../../../store/queries/Dashboard";

const useEditModal = ({ selectedUpdateRows,refetch }) => {
  const [errors, setErrors] = useState("");
  const [updateReportStatusData] = useEditReportStatusDataMutation();

  const dispatch = useDispatch();

  const optionsData = [
    { label: "Completed", id: 1 },
    { label: "Pending", id: 0 },
  ];

  let updateListVal = Yup.object({
    status: Yup.number()
      .typeError("status must be a number")
      .required("*Required"),
    description: Yup.string(),
    
  });

  const formik = useFormik({
    initialValues: {
      status: 0,
      description: "",
     
    },

    validationSchema: updateListVal,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          call_id: selectedUpdateRows,
          ...values,
        };

        updateReportStatusData(obj).then((response) => {
          formik.setErrors("");
          setErrors("");

          if (response?.data?.status_code === 200) {
            refetch?.()
            dispatch(dashboard.util.invalidateTags(["Dashboard"]));
            submitForm(resetForm);
          } else if (response?.error?.status === 422) {
            formik.setErrors(response?.error?.data?.errors);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = (resetForm) => {
    resetForm();

    dispatch(
      updateConfig((state) => {
        state.showUpdateModal = false;
      })
    );

    toast.success("Successfully updated");
  };

  return {
    errors,
    formik,
    optionsData,
  };
};

export default useEditModal;
