import { getAxiosInstance } from "../../../api";

export const intializeCall = async (data) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("vi/initate-call", data);

    return response;
  } catch (error) {
    return error.response;
  }
};
