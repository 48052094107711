import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
  profile_permissions: {},
  profile_permissions_status: "idle",
  call_data: {},
  call_active: false,
  loggedStaffId: "",
};

export const getMenuPermissions = createAsyncThunk(
  "global/getMenuPermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`staff-menus-list`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfilePermissions = createAsyncThunk(
  "global/getProfilePermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`staff-function-buttons`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.dashboard_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      state.dashboard_permissions = action.payload.data.data;
      state.dashboard_permissions_status = "fulfilled";
      localStorage.setItem(
        "dashboard_permissions",
        JSON.stringify(action.payload.data.data)
      );
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.dashboard_permissions_status = "rejected";
    });
    builder.addCase(getProfilePermissions.pending, (state) => {
      state.profile_permissions_status = "pending";
    });
    builder.addCase(getProfilePermissions.fulfilled, (state, action) => {
      state.profile_permissions = action.payload.data.data;
      state.profile_permissions_status = "fulfilled";
      localStorage.setItem(
        "profile_permissions",
        JSON.stringify(action.payload.data.data)
      );
    });
    builder.addCase(getProfilePermissions.rejected, (state) => {
      state.profile_permissions_status = "rejected";
    });
  },
});

export const { updateConfig } = globalSlice.actions;

export default globalSlice.reducer;
