import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  showAddedServiceModal:false,
  client_web_id:"",
  partner_client_web_id:"",
  storedFormikValues:{}
};

const addedServiceExistSlice = createSlice({
  name: "addServiceExist",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = addedServiceExistSlice.actions;

export default addedServiceExistSlice.reducer;
