import { Image } from "@wac-ui-dashboard/wac_component_library";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Style from "./AgreementMultipleImage.module.scss";
import useAgreementMultipleImage from "./useAgreementMultipleImage";

export const AgreementMultipleImage = ({
  data,
  userId,
  refetch,
  agreement,
  handleClose,
  setMultipleFiles,
  agreementImageUploading,
  handleConfirmUploadAgreementImage,
}) => {
  const { activeImageIndex, image, handleClickImage, handleDeleteClick } =
    useAgreementMultipleImage({
      data,
      setMultipleFiles,
      userId,
      refetch,
      handleClose,
    });

  return (
    <div className={`${Style.root} pro-p-2`}>
      <TransformWrapper>
        {({ zoomIn, zoomOut }) => (
          <>
            <div className={Style.action_icon}>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={() => zoomIn()}
              >
                <span className="material-symbols-outlined">zoom_in</span>
              </button>

              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={() => zoomOut()}
              >
                <span className="material-symbols-outlined">zoom_out</span>
              </button>

              {!agreement && (
                <button
                  className={`${Style.icon} pro-btn pro-p-0`}
                  onClick={() => handleDeleteClick(activeImageIndex)}
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              )}
            </div>
            <TransformComponent>
              <div className={`${Style.root_image} pro-mb-1`}>
                <div className={Style.root_figure}>
                  <figure className={`${Style.image}`}>
                    <img
                      src={
                        agreement
                          ? data?.[activeImageIndex]?.user_image_path
                          : data?.["fileUrls"][activeImageIndex] ?? image
                      }
                      alt={"profile"}
                      heigh={`100%`}
                      width={`100%`}
                    />
                  </figure>
                </div>
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {agreement
            ? data?.map((profile, index) => (
                <div className={`col-2`} key={index}>
                  <div
                    className={`${Style.root_image_inner}`}
                    onClick={() => handleClickImage(index)}
                  >
                    <Image
                      width={100}
                      height={100}
                      src={profile?.user_image_path}
                      alt={`image - 01`}
                    />
                  </div>
                </div>
              ))
            : data["fileUrls"].map((profile, index) => (
                <div className={`col-2`} key={index}>
                  <div
                    className={`${Style.root_image_inner}`}
                    onClick={() => handleClickImage(index)}
                  >
                    <Image
                      width={100}
                      height={100}
                      src={profile}
                      alt={`image - 01`}
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>
      {!agreement && (
        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end  `}
        >
          <button
            onClick={handleConfirmUploadAgreementImage}
            className={`pro-btn pro-btn-primary ${
              agreementImageUploading ? "loading" : ""
            }`}
            disabled={agreementImageUploading}
          >
            {"Upload"}
          </button>
        </div>
      )}
    </div>
  );
};
