import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getPartnerMatchDetails,
  updateConfig,
} from "../../../store/slices/Profile/PartnerMatch";

import useObserver from "../../../utils/hooks/useObserver";
import { updateProfileConfig } from "../../../store/slices/Profile";

const usePartnerMatch = (star) => {
  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const state = useSelector((state) => state.partnerMatch);
  const { selectedProfiles } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const partnerMatchState = state?.partnerMatchData?.[activeProfile];

  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState(false);
  const [entireData, setEntireData] = useState(true);
  const [adminVerified, setAdminVerfied] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [activeFilter, setActiveFilter] = useState(0);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    setPage(state.partnerMatchData?.[activeProfile]?.page || 1);

    return () => {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
          state.selectedProfileNames = [];
        })
      );
      dispatch(
        updateConfig((state) => {
          if (state.partnerMatchData?.[activeProfile]?.data) {
            state.partnerMatchData[activeProfile].data = [];
            state.partnerMatchData[activeProfile].page = 1;
          }
        })
      );
    };
    //eslint-disable-next-line
  }, [activeProfile]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);

    dispatch(
      getPartnerMatchDetails({
        page: page + 1,
        user_id: activeProfile,
        agreement: +checked,
        entire_data: +entireData,
        admin_verified: +adminVerified,
        registered: +registered,
        star: star ?? false,
        ...state.partnerMatchFilters,
      })
    );
  };

  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Contact Not Viewed ", value: 0 },
        { name: "Contact Viewed ", value: 1 },
      ],
    },
  ];

  const handleChange = (item) => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = [];
      })
    );
    if (item.value !== activeFilter) {
      dispatch(
        updateConfig((state) => {
          state.partnerMatchData[activeProfile].data = [];
          state.partnerMatchData[activeProfile].page = 1;
        })
      );
      dispatch(
        getPartnerMatchDetails({
          // page: page + 1,
          user_id: activeProfile,
          viewed_status: item.value,
          agreement: +checked,
          entire_data: +entireData,
          admin_verified: +adminVerified,
          registered: +registered,
          star: star ?? false,
          ...state.partnerMatchFilters,
        })
      );
      setActiveFilter(item.value);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleCheckBox = (checked) => {
    setChecked((state) => !checked);
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = [];
      })
    );
  };

  const handleEntireDataCheckBox = (entireData) => {
    setEntireData((state) => !entireData);
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = [];
      })
    );
  };

  const handleAdminVerifiedCheckbox = (adminVerified) => {
    setAdminVerfied((state) => !adminVerified);
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = [];
      })
    );
  };

  const handleRegisteredCheckbox = (registered) => {
    setRegistered((state) => !registered);
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = [];
      })
    );
  };

  const handleSelect = (id, checked, name) => {
    if (checked) {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [...state.selectedProfiles, id];
          state.selectedProfileNames = [...state.selectedProfileNames, name];
        })
      );
    } else {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = state.selectedProfiles.filter(
            (prevID) => prevID !== id
          );
          state.selectedProfileNames = state.selectedProfileNames.filter(
            (prevID) => prevID !== name
          );
        })
      );
    }
  };

  const handleDownloadClick = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = state.selectedProfiles.filter(
          (a) => a !== activeProfile
        );
        state.showPdf = true;
        state.skip = false;
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.partnerMatchStatus === "pending",
    hasMore:
      partnerMatchState?.per_page * partnerMatchState?.current_page <
      partnerMatchState?.total_count,
    callback: handleLoadMore,
  });

  return {
    partnerMatchState,
    state,
    filters,
    activeFilter,
    showScroll,
    checked,
    selectedProfiles,
    activeProfile,
    entireData,
    adminVerified,
    registered,
    handleRegisteredCheckbox,
    handleAdminVerifiedCheckbox,
    handleEntireDataCheckBox,
    handleDownloadClick,
    handleSelect,
    handleCheckBox,
    lastElement,
    handleChange,
    handleScrollToTop,
  };
};

export default usePartnerMatch;
