import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetDashboardReportsListDataQuery,
  useUpdateDashboardTableFieldsMutation,
  useUpdateStatusMutation,
} from "../../../store/queries/Dashboard";
import { updateConfig } from "../../../store/slices/Dashboard/dashboardSlice";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";
import { WebIdRow } from "./Rows";

const useReportsList = ({activeProfile, params}) => {
  const [selectedUpdateRows, setSelectedUpdatedRows] = useState([]);
  const [showTableEditModal, setShowTableEditModal] = useState(false);
  const [errors, setErrors] = useState(null);


  const dashboardState = useSelector((state) => state.dashboard);

  const { showTableFieldsModal } = useSelector(
    (state) => state.orderColumn
  );


  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];


   // FIXME INCOMPLETE API

  const {
    data: queryData = {},
    isFetching,
    isLoading,
    refetch
  } = useGetDashboardReportsListDataQuery({
   // task_type: 3,
    page_size: dashboardState.currentPageSize,
    page: dashboardState.currentPage,
    user_type_sort: dashboardState.currentFilter,
    sort_by: dashboardState.sortBy,
    sort_order: dashboardState.sortOrder,
  });

  const obj = queryData?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const [updateTableFields] = useUpdateDashboardTableFieldsMutation();

  const [updateStatus] = useUpdateStatusMutation();

  const dispatch = useDispatch();

  const hasCreatePermission = useMemo(() => {
    let permission = queryData?.data?.permissions?.filter((Obj) =>
    Object.keys(Obj).includes("can_create")
  )[0].can_create;

  return permission;
  }, [queryData]);

  const filters = useMemo(() => {
    if (queryData?.data?.filter) {
      const buttonGroups = queryData?.data?.filter?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (dashboardState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = dashboardState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };




  const handleEditClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      register_id: (_, data) => <WebIdRow data={data} activeProfile={activeProfile} params={params} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };


  const handleTableEditClick = (item) => {
    setSelectedUpdatedRows(item);
    setShowTableEditModal(true);
  };
  const closeTableEditModal = () => {
    setShowTableEditModal(false);
  };

  const handleUpdateStatus = (value) => {
    updateStatus({ task_id: selectedUpdateRows }).then((response) => {
      if (response?.data?.status_code === 200) {
        setShowTableEditModal(false);
        refetch()
        toast.success("Successfully Updated");
      } else if (response?.error?.status === 422) {
        setErrors(response?.error?.data?.errors);
      }
    });
  };

  return {
    queryData,
    dashboardState,
    filters,
    currentPage: dashboardState.currentPage,
    activeFilter: dashboardState.currentFilter,
    hasCreatePermission,
    paginationOptions,
    isFetching,
    isLoading,
    showTableEditModal,
    showTableFieldsModal,
    errors,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleSort,
    handleEditClick,
    closeEditModal,
    getRow,
    updateTableFields,
    handleTableEditClick,
    closeTableEditModal,
    handleUpdateStatus,
  };
};

export default useReportsList;
