import React from "react";
import {
  Button,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import Assets from "../../../../../assets/Assets";
import { sliceUrl } from "../../../../../utils/functions/sliceUrl";

const NonProfileDocuments = ({
  optionsData,
  formik,
  errors,
  fileRef,
  isNonProfileImageDataSuccess,
  nonProfileImagesData,
  handleRemoveNonProfileDocument,
  handleDeletePdf,
  handleNonProfileDocumentChange,
  handleNonProfileDocumentClick,
  style,
  AgreementDocumentData,
  isAgreementDocumentSuccess,
  isAgreementUploaded,
  handleDeleteAgreement,
}) => {
  const documentOptions = isAgreementUploaded
    ? optionsData?.data?.imageCategoryList?.filter(
        (item) => item?.name !== "Agreement"
      )
    : optionsData?.data?.imageCategoryList;

  return (
    <div className="row pro-mt-5">
      <div className="input-wrap pro-mb-0 ">
        <h6 className="pro-font-md pro-mb-0 pro-fw-medium">
          Non-Profile Documents Upload (PDF)
        </h6>
      </div>

      <div className={`col-5`}>
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Choose Document Type*
          </label>
          <Select
            id="document_category"
            placeholder={"Select"}
            isClearable={true}
            options={documentOptions}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.document_category &&
              formik.errors.document_category &&
              " error"
            }`}
            value={documentOptions?.filter(
              (p) =>
                p.id?.toString() === formik.values.document_category?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("document_category")}
            onChange={(value) =>
              formik.setFieldValue("document_category", value?.id || null)
            }
          ></Select>
          {formik.touched.document_category &&
            formik.errors.document_category && (
              <span className="error-text">
                {formik.errors.document_category}
              </span>
            )}
        </div>
      </div>
      <div className="col-5 pro-d-flex pro-items-end">
        <div className="input-wrap sm pro-mb-0">
          <div
            className="input-drag"
            onClick={() => handleNonProfileDocumentClick()}
          >
            <input
              type="file"
              ref={fileRef}
              disabled={formik.values.document_category ? false : true}
              placeholder="placeholder"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
              className={`pro-input ${
                formik.touched.non_profile_document &&
                formik.errors.non_profile_document &&
                " error"
              }`}
              id="non_profile_document"
              onBlur={formik.handleBlur("non_profile_document")}
              onChange={(e) => handleNonProfileDocumentChange(e)}
            />
            <span className="input-drag-box">
              <IconText title={`Drop files to attach or browse`} />
            </span>
            {formik?.values?.non_profile_document?.name && (
              <Button className={`pro-btn-link pro-mt-3`}>
                {formik?.values?.non_profile_document?.name}
                <span
                  className="material-symbols-outlined"
                  onClick={(e) => handleRemoveNonProfileDocument(e)}
                >
                  close
                </span>
              </Button>
            )}
            {formik.touched.non_profile_document &&
              formik.errors.non_profile_document && (
                <span className="error-text">
                  {formik.errors.non_profile_document}
                </span>
              )}
          </div>
        </div>
      </div>

      {[...Array(3)].map((_, idx) => (
        <>
          <div className="col-12 pro-mt-5" key={idx + 1}>
            <div className="input-wrap pro-mb-0">
              <h6 className="pro-font-md pro-mb-0 pro-fw-medium">
                {idx === 0
                  ? "Horoscope Documents"
                  : idx === 1
                  ? "House Documents"
                  : idx === 2
                  ? "ID Proof Documents"
                  : ""}
              </h6>
            </div>
            {isNonProfileImageDataSuccess ? (
              <div className="row row-cols-6 g-2 pro-mb-4">
                {nonProfileImagesData?.data?.non_profile_documents
                  ?.filter((data, index) => data?.pdf_category === idx + 1)
                  .map((item, i) => {
                    return (
                      <div className={`${style.document_wrapper}`} key={i}>
                        <div className={style.image_wrapper_main}>
                          <span
                            onClick={() =>
                              window.open(item?.user_document_path)
                            }
                          >
                            {/* <Image
                              width={100}
                              height={100}
                              src={Assets?.PdfLogo}
                              alt={""}
                              key={i}
                            /> */}

                            <Image
                              width={100}
                              height={100}
                              src={Assets?.PdfLogo}
                              alt={"#"}
                              key={i + 1}
                              propStyle={{
                                root: style.document_img,
                              }}
                            />
                            <span className={style.name}>
                              {sliceUrl(item?.user_document_path?.toString())}
                              {item?.user_document_path}
                            </span>
                          </span>
                        </div>

                        <div className={style.delet_btn}>
                          <span
                            className="material-symbols-outlined"
                            onClick={() =>
                              handleDeletePdf(item?.id, item?.pdf_category)
                            }
                          >
                            delete
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              // FIXME aneesh add shimmer for image loading
              <div>loading...</div>
            )}
          </div>
        </>
      ))}
      {/* adding agreement documents with this */}
      {/* additional functionality */}
      <div className="input-wrap pro-mb-0">
        <h6 className="pro-font-md pro-mb-0 pro-fw-medium">
          Agreement Documents{" "}
        </h6>
      </div>
      {isAgreementDocumentSuccess ? (
        AgreementDocumentData?.data !== null && (
          <div className="row row-cols-6 g-2 pro-mb-4">
            {AgreementDocumentData?.data?.user_agreement_data
              ?.user_image_path !== "" && (
              <div className={`${style.document_wrapper}`}>
                <div className={style.image_wrapper_main}>
                  <span
                    onClick={() =>
                      window.open(
                        AgreementDocumentData?.data?.user_agreement_data
                          ?.user_image_path
                      )
                    }
                  >
                    {/* <Image
                              width={100}
                              height={100}
                              src={Assets?.PdfLogo}
                              alt={""}
                              key={i}
                            /> */}

                    <Image
                      width={100}
                      height={100}
                      src={Assets?.PdfLogo}
                      alt={"#"}
                      key={AgreementDocumentData?.data?.user_agreement_data?.id}
                      propStyle={{
                        root: style.document_img,
                      }}
                    />
                    <span className={style.name}>
                      {sliceUrl(
                        AgreementDocumentData?.data?.user_agreement_data?.user_image_path?.toString()
                      )}
                      {
                        AgreementDocumentData?.data?.user_agreement_data
                          ?.user_image_path
                      }
                    </span>
                  </span>
                </div>

                <div className={style.delet_btn}>
                  <span
                    className="material-symbols-outlined"
                    onClick={() =>
                      handleDeleteAgreement(
                        AgreementDocumentData?.data?.user_agreement_data?.id
                      )
                    }
                  >
                    delete
                  </span>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        // FIXME aneesh add shimmer for image loading
        <div>loading...</div>
      )}
    </div>
  );
};

export default NonProfileDocuments;
