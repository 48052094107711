import {
  Button,
  DatePickerWithInput,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import useAssignForm from "./useAssignForm";
import moment from "moment";

const AssignForm = ({ checked, handleCancel, isUpdate, refetch,setChecked }) => {
  const { optionsData, formik, isSuccess } = useAssignForm({
    checked,
    handleCancel,
    isUpdate,
    refetch,
    setChecked
  });

  return (
    <div className={`pro-p-0 pro-w-100`}>
      {isSuccess ? (
        <>
          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="branch"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Branch*
            </label>
            <Select
              id="branch"
              placeholder={"Select"}
              isClearable={true}
              className={`pro-input lg ${
                formik.errors.branch && formik.touched.branch ? "error" : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData.branchList}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.branch_name}
              value={optionsData.branchList?.filter(
                (m) => formik.values.branch?.toString?.() === m.id?.toString?.()
              )}
              onBlur={() => formik.handleBlur("branch")}
              onChange={(value) => {
                formik.setFieldValue("branch", value?.id?.toString() || null);
                formik.setFieldValue("staff_id", null);
              }}
            />
            {formik.errors.branch && formik.touched.branch && (
              <span className="error-text">{formik.errors.branch}</span>
            )}
          </div>
          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="user_type"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              User Type*
            </label>
            <Select
              id="user_type"
              placeholder={"Select"}
              isClearable={true}
              className={`pro-input lg ${
                formik.errors.user_type && formik.touched.user_type
                  ? "error"
                  : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData.userTypesList}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.user_type}
              value={optionsData.userTypesList?.filter(
                (m) =>
                  formik.values.user_type?.toString?.() === m.id?.toString?.()
              )}
              onChange={(value) => {
                formik.setFieldValue("user_type", value?.id?.toString() || null);
              }}
            />
            {formik.errors.user_type && formik.touched.user_type && (
              <span className="error-text">{formik.errors.user_type}</span>
            )}
          </div>
          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="staff_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Staff Name*
            </label>
            <Select
              id="staff_id"
              placeholder={"Select"}
              isClearable={true}
              isDisabled={
                formik.values.branch && formik.values.user_type ? false : true
              }
              className={`pro-input lg ${
                formik.values.branch &&
                formik.values.user_type &&
                (formik.errors.staff_id && formik.touched.staff_id
                  ? "error"
                  : "")
              }`}
              classNamePrefix="pro-input"
              options={optionsData.staffList?.filter(
                (m) =>
                  formik.values.branch?.toString() ===
                    m.branches_id?.toString() &&
                  formik.values.user_type?.toString() ===
                    m.user_types_id?.toString()
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.staff_name}
              value={optionsData.staffList?.filter(
                (m) =>
                  formik.values.staff_id?.toString?.() === m.id?.toString?.()
              )}
              onBlur={() => formik.handleBlur("staff_id")}
              onChange={(value) => {
                formik.setFieldValue("staff_id", value?.id?.toString() || null);
              }}
            />
            {formik.values.branch &&
              formik.values.user_type &&
              formik.errors.staff_id &&
              formik.touched.staff_id && (
                <span className="error-text">{formik.errors.staff_id}</span>
              )}
          </div>
          {!isUpdate && (
            <div className={"input-wrap pro-mb-4"}>
              <label
                htmlFor="schedule_id"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Schedule*
              </label>
              <Select
                id="schedule_id"
                placeholder={"Select"}
                isClearable={true}
                className={`pro-input lg ${
                  formik.errors.schedule_id && formik.touched.schedule_id
                    ? "error"
                    : ""
                }`}
                classNamePrefix="pro-input"
                options={optionsData.scheduleList}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.schedule_name}
                value={optionsData.scheduleList?.filter(
                  (m) =>
                    formik.values.schedule_id?.toString?.() ===
                    m.id?.toString?.()
                )}
                onBlur={() => formik.handleBlur("schedule_id")}
                onChange={(value) => {
                  formik.setFieldValue(
                    "schedule_id",
                    value?.id?.toString() || null
                  );
                }}
              />
              {formik.errors.schedule_id && formik.touched.schedule_id && (
                <span className="error-text">{formik.errors.schedule_id}</span>
              )}
            </div>
          )}
          {!isUpdate && (
            <div className="input-wrap pro-mb-4 ">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Schedule Start Date*
              </label>
              <div className={`pro-w-100 pro-d-flex`}>
                <DatePickerWithInput
                  CalendarOutlineIcon={
                    <span className="material-symbols-outlined ">
                      calendar_month
                    </span>
                  }
                  minDate={new Date()}
                  id="job_start_date"
                  placeholder={"Select Date"}
                  className={`pro-input lg pro-min-full ${
                    formik.errors.job_start_date &&
                    formik.touched.job_start_date &&
                    " error"
                  }`}
                  onDateChange={(value) => {
                    formik.setFieldValue(
                      "job_start_date",
                      moment(value).format("YYYY-MM-DD")
                    );
                  }}
                  isError={
                    formik.errors.job_start_date &&
                    formik.touched.job_start_date
                  }
                  errorMessage={formik.errors.job_start_date}
                />
              </div>
            </div>
          )}
          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(5)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default AssignForm;
