import { NavGroup } from "@wac-ui-dashboard/wac_component_library";
import useCallHistory from "./useCallHistory";
import { Link, Outlet } from "react-router-dom";
import Style from "./callhistory.module.scss";

const CallHistory = () => {
  const { navigations } = useCallHistory();
  return (
    <>
      {/* <NavGroup navigations={navigations} type={"type-2"} />
      <p>Helloo</p> */}

      <div className={`${Style.main_container}`}>
        <div className={`${Style.main_container_inner}`}>
          <NavGroup navigations={navigations} type={"type-2"} Link={Link} />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default CallHistory;
