import { getAxiosInstance } from "../../../api";



   export const exportRegistrationReport = async (body) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `report/registartion-report-export`,   
        body,
        {
       
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      return error.response.data;
    }
  };