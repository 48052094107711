import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useObserver from "../../../utils/hooks/useObserver";
import {
  getFullSearch,
  getGlobalSearch,
  getRegistrationSearch,
  updateConfig,
} from "../../../store/slices/Search/GlobalSearch";
import { updateProfileConfig } from "../../../store/slices/Profile";
import { useSearchParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import moment from "moment";

const useGlobalSearch = () => {
  const dispatch = useDispatch();
  const printRef = useRef();
  const [params] = useSearchParams();
  const state = useSelector((state) => state.globalSearch);
  const [showScroll, setShowScroll] = useState(false);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(true);
  const [checked, setChecked] = useState(true);
  const [isFetchLoading, setIsFetchLoading] = useState();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [searchedKey, setSearchedKey] = useState();
  const { selectedProfiles, showPdf } = useSelector((state) => state.profile);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  useEffect(() => {
    return () => {
      dispatch(
        updateConfig((state) => {
          state.globalSearchStatus = "idle";
          state.globalSearchData = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (state.globalSearchStatus === "fulfilled") {
      if (state.global_search.searchKey) {
        setSearchedKey(state.global_search.searchKey);
      } else if (state.full_search.searchKey) {
        setSearchedKey(state.full_search.searchKey);
      } else if (state.reg_search.searchKey) {
        setSearchedKey(state.reg_search.searchKey);
      }
    }
    // eslint-disable-next-line
  }, [state.globalSearchStatus]);

  const handleSearch = () => {
    dispatch(
      updateConfig((state) => {
        state.globalSearchStatus = "idle";
        state.globalSearchData = {};
      })
    );
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    if (state?.global_search?.searchKey?.length > 0) {
      dispatch(
        getGlobalSearch({
          web_id: state.global_search.searchKey,
          page: page + 1,
        })
      );
    } else if (state?.full_search?.searchKey?.length > 0) {
      dispatch(
        getFullSearch({
          web_id: state.full_search.searchKey,
          page: page + 1,
        })
      );
    } else {
      dispatch(
        getRegistrationSearch({
          register_no: state.reg_search?.searchKey,
          page: page + 1,
        })
      );
    }
  };

  const { lastElement } = useObserver({
    loading: state?.globalSearchStatus === "pending",
    hasMore:
      state.globalSearchData?.per_page * state.globalSearchData?.current_page <
      state.globalSearchData?.total_count,
    callback: handleLoadMore,
  });

  const handleSelect = (id, checked, name) => {
    if (checked) {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [...state.selectedProfiles, id];
          state.selectedProfileNames = [...state.selectedProfileNames, name];
        })
      );
    } else {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = state.selectedProfiles.filter(
            (prevID) => prevID !== id
          );
          state.selectedProfileNames = state.selectedProfileNames.filter(
            (prevID) => prevID !== name
          );
        })
      );
    }
  };

  const handleDownloadClick = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = state.selectedProfiles.filter(
          (a) => a !== activeProfile
        );
        state.showPdf = true;
      })
    );
    setSkip(false);
  };

  const handleCheckBox = (checked) => {
    setChecked((state) => !checked);
  };

  const closePdfModal = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showPdf = false;
      })
    );
  };

  // const handleDownloadPdf = async () => {
  //   const element = printRef.current;

  //   html2pdf(element, {
  //     margin: [0, 0, 10, 0],
  //     filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
  //     image: { type: "jpeg", quality: 1 },
  //     html2canvas: {
  //       dpi: 100,
  //       letterRendering: true,
  //       useCORS: true,
  //       logging: true,
  //       scale: 4,
  //       scrollY: 0,
  //     },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
  //     DisablePdfCompression: true,
  //   });

  //   setSkip(true);
  //   // dispatch(
  //   //   updateProfileConfig((state) => {
  //   //     state.selectedProfiles = [];
  //   //   })
  //   // );
  // };

  const handleDownloadPdf = async () => {
    setPdfLoading(true);
    const element = printRef.current;
    const pdfPromise = new Promise((resolve) => {
      html2pdf(element, {
        margin: [0, 0, 0, 0],
        filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          width: window?.width,
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 3,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      }).then(() => {
        // Resolve the Promise when the pdf is generated
        resolve();
      });
    });

    // Wait for the Promise to resolve before showing "Download finished" toast
    await pdfPromise;

    setPdfLoading(false);
    setSkip(true);
  };

  return {
    state,
    showScroll,
    selectedProfiles,
    activeProfile,
    searchedKey,
    checked,
    showPdf,
    isFetchLoading,
    printRef,
    skip,
    pdfLoading,
    handleScrollToTop,
    lastElement,
    handleSearch,
    handleSelect,
    handleDownloadClick,
    handleCheckBox,
    closePdfModal,
    setIsFetchLoading,
    handleDownloadPdf,
  };
};

export default useGlobalSearch;
