import { useDispatch } from "react-redux";
import { updateProfileConfig } from "../../../store/slices/Profile";
import { useMemo } from "react";
import { toast } from "react-toastify";
import {
  useActivateProfileMutation,
  useSuggestPartnerMutation,
  useSuggestProfileMutation,
  useUpdateLastBoostedMutation,
} from "../../../store/queries/Profile";
import { useSelector } from "react-redux";

import {
  getAutoMatchDetails,
  updateConfig as updateAutoMatchConfig,
} from "../../../store/slices/Profile/AutoMatch";
import {
  getPartnerMatchDetails,
  updateConfig as updatePartnerMatchConfig,
} from "../../../store/slices/Profile/PartnerMatch";
import {
  getAutoStarMatchDetails,
  updateConfig as updateAutoStarMatchConfig,
} from "../../../store/slices/Profile/AutoStarMatch";
import { useLocation } from "react-router-dom";
import { getFormatedDate } from "../../../utils/functions/table";

const useRightActionDrawer = ({
  isProfileDeleted,
  userId,
  hasDeletePermission,
  hasActivatePermission,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  let path = location.pathname.slice(9);
  const [sendRequest] = useActivateProfileMutation();
  const [suggestProfile] = useSuggestProfileMutation();
  const [suggestPartner] = useSuggestPartnerMutation();
  const [updateLastBoosted] = useUpdateLastBoostedMutation();

  const { selectedProfiles, selectedProfileNames } = useSelector(
    (state) => state.profile
  );

  const handleLogin = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "login";
      })
    );
  };

  const handleMultipleShareProfile = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "share_multiple_profile";
      })
    );
  };
  const handleProfileLink = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "profile_link";
      })
    );
  };

  const handleFollowUp = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "follow_up";
      })
    );
  };
  const handleTlSupport = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "tl_support";
      })
    );
  };
  const handlePossibility = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "posibility";
      })
    );
  };
  const handleShComplete = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "sh_complete";
      })
    );
  };
  const handleDoNotCallLater = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "do_not_call_later";
      })
    );
  };
  const handleSelectionRequest = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "selection_request";
      })
    );
  };
  const handleScheduleTermination = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "schedule_termination";
      })
    );
  };

  const handleDeleteProfile = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "delete_profile";
      })
    );
  };

  const refetchData = (partnerMatchFilters, path) => {
    if (path === "partner-match") {
      dispatch(
        updatePartnerMatchConfig((state) => {
          state.partnerMatchData = {};
        })
      );

      dispatch(
        getPartnerMatchDetails({
          page: 1,
          user_id: userId,
          agreement: 1,
          entire_data: 0,
          star: false,
          ...partnerMatchFilters,
        })
      );
    }
    if (path === "auto-match") {
      dispatch(
        updateAutoMatchConfig((state) => {
          state.autoMatchData = {};
        })
      );
      dispatch(
        getAutoMatchDetails({
          page: 1,
          id: userId,
          filter: 0,
          entire_data: 0,
          agreement: 1,
        })
      );
    }
    if (path === "auto-star-match") {
      dispatch(
        updateAutoStarMatchConfig((state) => {
          state.autoStarMatchData = {};
        })
      );
      dispatch(
        getAutoStarMatchDetails({
          page: 1,
          id: userId,
          filter: 0,
          entire_data: 0,
          agreement: 1,
        })
      );
    }
    if (path === "partner-star-match") {
      dispatch(
        updatePartnerMatchConfig((state) => {
          state.partnerMatchData = {};
        })
      );
      dispatch(
        getPartnerMatchDetails({
          page: 1,
          user_id: userId,
          agreement: 1,
          entire_data: 0,
          star: true,
          ...partnerMatchFilters,
        })
      );
    }
  };

  const handleSuggestMultipleProfile = (
    selectedIds,
    partnerMatchFilters,
    path
  ) => {
    suggestProfile({ user_id: userId, client_id: selectedIds })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Profile  Suggested Successfully!");
          refetchData?.(partnerMatchFilters, path);
        } else if (response?.error?.data?.status_code === 422) {
          toast.error(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Suggest Profile!");
        }
      })
      .catch(() => {
        toast.error("Failed to Suggest Profile!");
      });
  };
  const handleSuggestPartner = (selectedIds, partnerMatchFilters, path) => {
    suggestPartner({ user_id: userId, client_id: selectedIds })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Partner  Suggested Successfully!");
          refetchData?.(partnerMatchFilters, path);
        } else if (response?.error?.data?.status_code === 422) {
          toast.error(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Suggest Partner!");
        }
      })
      .catch(() => {
        toast.error("Failed to Suggest Partner!");
      });
  };

  const handleActivateProfile = () => {
    sendRequest({ profile_type: 2, user_id: userId })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Profile  Activated Successfully!");
        } else if (response?.error?.data?.status_code === 422) {
          toast.error(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Activate Profile!");
        }
      })
      .catch(() => {
        toast.error("Failed to Activate Profile!");
      });
  };

  const handleBoostProfile = () => {
    const currentDate = new Date();
    const formattedDate = getFormatedDate(currentDate);
    updateLastBoosted({ user_id: userId, last_boosted: formattedDate }).then(
      (res) => {
        if (res?.data?.status_code === 200) {
          toast.success(res?.data?.message ?? "Profile boosted Successfully!");
        } else if (res?.data?.status_code === 400) {
          toast.error(res?.data?.message ?? "Failed to Boost Profile!");
        } else {
          toast.error("Failed to Boost Profile");
        }
      }
    );
  };

  const handleCompleteFollowUp = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "follow_up_complete";
      })
    );
  };

  let object = {
    login: handleLogin,
    share_multiple_profile: handleMultipleShareProfile,
    profile_link: handleProfileLink,
    delete_profile: handleDeleteProfile,
    activate_profile: handleActivateProfile,
    follow_up: handleFollowUp,
    tl_support: handleTlSupport,
    posibility: handlePossibility,
    sh_complete: handleShComplete,
    do_not_call_later: handleDoNotCallLater,
    selection_request: handleSelectionRequest,
    schedule_termination: handleScheduleTermination,
    suggest_multiple_profile: handleSuggestMultipleProfile,
    suggest_partner: handleSuggestPartner,
    boost_profile: handleBoostProfile,
    follow_up_complete: handleCompleteFollowUp,
  };

  const handleClick = (label, selectedProfiles, partnerMatchFilters, path) => {
    if (label === "suggest_multiple_profile") {
      handleSuggestMultipleProfile(selectedProfiles, partnerMatchFilters, path);
    } else if (label === "suggest_partner") {
      handleSuggestPartner(selectedProfiles, partnerMatchFilters, path);
    } else {
      object[label]();
    }
  };

  let profileActions = [
    {
      label: "boost_profile",
      title: "Boost Profile",
      icon: "rocket_launch",
      handleClick,
    },
    {
      label: "login",
      title: "Login Credentials",
      icon: "login",
      handleClick,
    },
    {
      label: "share_multiple_profile",
      title: "Share Profile",
      icon: "person",
      handleClick,
    },
    {
      label: "suggest_multiple_profile",
      title: "Suggest Profile",
      icon: "assistant",
      handleClick,
    },
    {
      label: "suggest_partner",
      title: "Suggest Partner",
      icon: "assistant",
      handleClick,
    },
    {
      label: "profile_link",
      title: "Share Contact",
      icon: "link",
      handleClick,
    },
    {
      label: "delete_profile",
      title: "Delete Profile",
      icon: "delete",
      handleClick,
    },
    {
      label: "activate_profile",
      title: "Activate Profile",
      icon: "visibility",
      handleClick,
    },
  ];

  let profileFunctions = [
    {
      label: "follow_up",
      title: "Follow Up",
      icon: "calendar_add_on",
      handleClick,
    },
    {
      label: "follow_up_complete",
      title: "Follow Up Complete",
      icon: "event_available",
      handleClick,
    },
    {
      label: "tl_support",
      title: "TL Support",
      icon: "headset_mic",
      handleClick,
    },
    {
      label: "posibility",
      title: "Posibility",
      icon: "alarm",
      handleClick,
    },
    {
      label: "sh_complete",
      title: "SH Complete",
      icon: "work_history",
      handleClick,
    },
    {
      label: "do_not_call_later",
      title: "Do Not Call Later",
      icon: "phone_paused",
      handleClick,
    },
    {
      label: "selection_request",
      title: "Selection Request",
      icon: "edit_document",
      handleClick,
    },
    {
      label: "schedule_termination",
      title: "Schedule Termination",
      icon: "manage_accounts",
      handleClick,
    },
  ];

  const filteredProfileActions1 = useMemo(() => {
    // if (hasDeletePermission === false) {
    //   return profileActions.filter((n) => !n.title.includes("Delete Profile"));
    // } else if (hasActivatePermission === false) {
    //   return profileActions.filter(
    //     (n) => !n.title.includes("Activate Profile")
    //   );
    // } else {
    //   if (isProfileDeleted === 1) {
    //     return profileActions.filter(
    //       (n) => !n.title.includes("Delete Profile")
    //     );
    //   } else {
    //     return profileActions.filter(
    //       (n) => !n.title.includes("Activate Profile")
    //     );
    //   }
    // }

    if (isProfileDeleted === 1) {
      if (hasActivatePermission === false) {
        return profileActions.filter(
          (n) =>
            !n.title.includes("Delete Profile") &&
            !n.title.includes("Activate Profile")
        );
      } else {
        return profileActions.filter(
          (n) => !n.title.includes("Delete Profile")
        );
      }
    } else {
      if (hasDeletePermission === false) {
        return profileActions.filter(
          (n) =>
            !n.title.includes("Delete Profile") &&
            !n.title.includes("Activate Profile")
        );
      } else {
        return profileActions.filter(
          (n) => !n.title.includes("Activate Profile")
        );
      }
    }

    // eslint-disable-next-line
  }, [isProfileDeleted]);

  const filteredProfileActions2 = useMemo(() => {
    if (selectedProfiles.length === 0 && selectedProfileNames.length === 0) {
      return filteredProfileActions1.filter(
        (n) =>
          !n.title.includes("Share Profile") &&
          !n.title.includes("Suggest Profile") &&
          !n.title.includes("Suggest Partner")
      );
    } else {
      return filteredProfileActions1;
    }
    // eslint-disable-next-line
  }, [selectedProfiles, selectedProfileNames, isProfileDeleted, path]);

  return { profileActions: filteredProfileActions2, profileFunctions };
};

export default useRightActionDrawer;
