import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useSendProfileDetailsMutation,
  useGetProfileDataQuery,
  useGetWhatsappShareProfileMutation,
} from "../../../../store/queries/Profile";
import { phoneRegExp } from "../../../../utils/functions/table";

const useShareProfileForm = ({ userId, handleCancel, ProfileName }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetProfileDataQuery(userId);

  const [sendRequest] = useSendProfileDetailsMutation();
  const [getShareProfileDetails] = useGetWhatsappShareProfileMutation();

  let shareProfileFormSchema = Yup.object({
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(10, "phone number must be 10 digits")
      .max(10, "phone number must be 10 digits")
      .required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      user_id: userId,
      user_name: ProfileName,
      mobile_number: null,
    },
    validationSchema: shareProfileFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    sendRequest(values)
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success("Profile Send Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Send Profile!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Send Profile!");
      });
  };

  const handleWhatsappShare = () => {
    if (formik.values.mobile_number) {
      getShareProfileDetails({ user_id: userId, user_name: ProfileName }).then(
        (response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Profile Sent Successfully!");
            handleCancel();
            let mob = formik.values.mobile_number;

            window.open(
              `${`https://api.whatsapp.com/send?phone=${mob}&text=${response?.data?.data?.message}&type=custom_url&app_absent=0`}`
            );
          } else if (response?.error?.data?.status_code === 422) {
            formik.setErrors(response?.error?.data?.errors);
          } else {
            toast.error("Failed to Share Profile!");
            handleCancel();
          }
        }
      );
    } else {
      toast.error("Failed to Share Profile!");
    }
  };

  return { formik, optionsData, isOptionDataSuccess, handleWhatsappShare };
};

export default useShareProfileForm;
