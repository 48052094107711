import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useCreateNewTaskMutation,
  useGetNewTaskFormDataQuery,
} from "../../../../store/queries/Profile";

const useTLSupportForm = ({ userId, handleCancel }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetNewTaskFormDataQuery({ id: userId });

  const [createTask] = useCreateNewTaskMutation();

  let tlSupportFormFormSchema = Yup.object({
    description: Yup.string().required("*Required"),
    staff_id: Yup.string().required("*Required"),
    user_type : Yup.string().required("*Required"),
    branch : Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      task_type: 2,
      user_id: userId,
      description: null,
      staff_id: null,
      schedule_id: null,
      branch: null,
      user_type: null,
    },
    validationSchema: tlSupportFormFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    createTask(values)
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success("Task Created Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        }
      })
      .catch(() => {
        toast.error("Failed to Add Task!");
      });
  };
  return { formik, optionsData, isOptionDataSuccess };
};

export default useTLSupportForm;
