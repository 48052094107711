import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import Select from "react-select";
import useScheduleTerminationForm from "./useScheduleTerminationForm";
import style from "./scheduleTerminationForm.scss";

const ScheduleTerminationForm = ({
  handleCancel,
  userId,
  profileId,
  isSuccess,
}) => {
  const { formik, optionsData, isOptionDataSuccess } =
    useScheduleTerminationForm({
      handleCancel,
      userId,
    });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Schedule Termination</h4>
        <h6 className="pro-ttl  pro-mb-5">{`Profile Id : ${profileId}`}</h6>
      </div>
      {isSuccess && isOptionDataSuccess ? (
        <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Team Lead
            </label>
            <Select
              id="user_type"
              placeholder={"Select"}
              isClearable={true}
              options={optionsData?.data?.userTypesList}
              getOptionLabel={(option) => `${option.user_type}`}
              getOptionValue={(option) => `${option["id"]}`}
              className={`pro-input lg multi-select ${
                formik.touched.user_type && formik.errors.user_type && " error"
              }`}
              value={optionsData?.data?.userTypesList?.filter(
                (p) => p.id?.toString() === formik.values.user_type?.toString()
              )}
              classNamePrefix="pro-input"
              onBlur={() => formik.handleBlur("user_type")}
              onChange={(value) => formik.setFieldValue("user_type", value?.id || null)}
            ></Select>
            {formik.touched.user_type && formik.errors.user_type && (
              <span className="error-text">{formik.errors.user_type}</span>
            )}
          </div>

          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="schedule_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Service
            </label>
            <Select
              id="schedule_id"
              placeholder={"Select"}
              isClearable={true}
              className={`pro-input lg ${
                formik.errors.schedule_id && formik.touched.schedule_id
                  ? "error"
                  : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData.data.scheduleList}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.schedule_name}
              value={optionsData.data.scheduleList?.filter(
                (m) =>
                  formik.values.schedule_id?.toString?.() === m.id?.toString?.()
              )}
              onBlur={() => formik.handleBlur("schedule_id")}
              onChange={(value) => {
                formik.setFieldValue("schedule_id", value?.id?.toString() || null);
              }}
            />
            {formik.errors.schedule_id && formik.touched.schedule_id && (
              <span className="error-text">{formik.errors.schedule_id}</span>
            )}
          </div>

          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="branch"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Branch
            </label>
            <Select
              id="branch"
              placeholder={"Select"}
              isClearable={true}
              isDisabled={formik.values.user_type ? false : true}
              className={`pro-input lg ${
                formik.errors.branch && formik.touched.branch ? "error" : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData.data.branchList}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.branch_name}
              value={optionsData.data.branchList?.filter(
                (m) => formik.values.branch?.toString?.() === m.id?.toString?.()
              )}
              onBlur={() => formik.handleBlur("branch")}
              onChange={(value) => {
                formik.setFieldValue("branch", value?.id?.toString() || null);
                formik.setFieldValue("staff_id", null);
              }}
            />
            {formik.errors.branch && formik.touched.branch && (
              <span className="error-text">{formik.errors.branch}</span>
            )}
          </div>

          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="staff_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Staff Name
            </label>
            <Select
              id="staff_id"
              placeholder={"Select"}
              isClearable={true}
              isDisabled={
                formik.values.branch && formik.values.user_type ? false : true
              }
              className={`pro-input lg ${
                formik.errors.staff_id && formik.touched.staff_id ? "error" : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData.data.staffList?.filter(
                (m) =>
                  formik.values.branch?.toString() ===
                    m.branches_id?.toString() &&
                  formik.values.user_type?.toString() ===
                    m.user_types_id?.toString()
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.staff_name}
              value={optionsData.staffList?.filter(
                (m) =>
                  formik.values.staff_id?.toString?.() === m.id?.toString?.()
              )}
              onBlur={() => formik.handleBlur("staff_id")}
              onChange={(value) => {
                formik.setFieldValue("staff_id", value?.id?.toString()|| null);
              }}
            />
            {formik.values.branch && formik.values.user_type && formik.errors.staff_id && formik.touched.staff_id && (
              <span className="error-text">{formik.errors.staff_id}</span>
            )}
          </div>
          <Input
            label={"Description"}
            type="text"
            id="description
          "
            name="description
          "
            className={`pro-input lg ${
              formik.touched.description &&
              formik.errors.description &&
              " error"
            }`}
            {...formik.getFieldProps("description")}
            error={formik.touched.description && formik.errors.description}
            errorMessage={
              formik.touched.description && formik.errors.description
            }
          />

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(5)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default ScheduleTerminationForm;
