import { SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import React from "react";
import style from './listingFilter.module.scss'
const ListingFilter = ({
 
  
  optionsData,
  handleRoleFilter,
  handleBranchFilter,
  handleStatusFilter,
  staffState,
  handleEditFieldsClick,
  handleClearFilters,
  handleSearch
}) => {


  return (
    <div className={`row g-2 ${style.root}`}>
      <div className={`col-auto `}>

        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}

          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          searchInputProps={{ value: staffState?.search }}
        />
      </div>
      <div className="col-2">
        <Select
          id="branch"
          isMulti
          isClearable={true}
          placeholder={"Select"}
          options={optionsData?.data?.branchList}
          getOptionLabel={(option) => option.branch_name}
          getOptionValue={(option) => option.id}
          className={`pro-input lg multi-select`}
          classNamePrefix="pro-input"
          value={optionsData?.data?.branchList?.filter(
            (p) =>

              staffState?.currentBranchFilter?.includes(p.id?.toString())
          )}

          onChange={(value) => handleBranchFilter(value.map((v) => v.id?.toString()))}
        ></Select>
      </div>

      <div className="col-2 ">
        <Select
          id="role"
          isMulti
          isClearable={true}
          isDisabled={staffState?.currentBranchFilter ? false : true}
          options={optionsData?.data?.userTypeList}
          getOptionLabel={(option) => option.user_type}
          getOptionValue={(option) => option.id}
          className={`pro-input lg multi-select`}
          classNamePrefix="pro-input"
          placeholder={"Role"}
          value={optionsData?.data?.userTypeList?.filter(
            (p) =>
              staffState?.currentRoleFilter?.includes(p.id?.toString())
          )}

          onChange={(value) => handleRoleFilter(value.map((v) => v.id?.toString()))}
        ></Select>
      </div>

      <div className="col-2">
        <Select
          id="status"
          isMulti
          isClearable={true}
          placeholder={"Select"}
          options={optionsData?.data?.status}
          getOptionLabel={(option) => option.status_name}
          getOptionValue={(option) => option.id}
          className={`pro-input lg multi-select`}
          classNamePrefix="pro-input"
          value={optionsData?.data?.status?.filter(
            (p) =>

              staffState?.currentStatusFilter?.includes(p.id?.toString())
          )}

          onChange={(value) => handleStatusFilter(value.map((v) => v.id?.toString()))}
        ></Select>
      </div>

      <div className={`col-auto`}>
        <button
          className="pro-btn pro-btn-outline lg"
          onClick={handleClearFilters}
        >
          Clear Filters
        </button>
      </div>

      <div className={`col-auto`}>
        <button
          className="pro-btn pro-btn-outline lg"
          onClick={handleEditFieldsClick}
        >
          Edit Columns
        </button>
      </div>
    </div>
  );
};

export default ListingFilter;
