import {
  Button,
  ConfirmationBox,
  IconText,
  Image,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import CropModal from "./CropModal";
import DeleteModal from "./DeleteModal";
import NonProfileImages from "./NonProfileImages";
import useGallery from "./useGallery";
import style from "./gallery.module.scss";
import NonProfileDocuments from "./NonProfileDocuments";
import { PdfCard } from "../../../Global/PdfCard";
import { AgreementMultipleImage } from "../../../Global/AgreementMultipleImage";

const Gallery = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const {
    formik,
    errors,
    isUpdating,
    imagesData,
    isImageDataSuccess,
    showDeleteModal,
    showCrop,
    crop,
    image,
    nonImage,
    imageType,
    loading,
    imgRef,
    showPdfModal,
    fileType,
    multipleFiles,
    agreementImageModal,
    AgreementDocumentData,
    AgreementImageData,
    isAgreementDocumentSuccess,
    isAgreementImageSuccess,
    showDeleteAgreementModal,
    agreementImageUploading,
    handleDeleteAgreementItem,
    closeDeleteAgreementModal,
    handleDeleteAgreement,
    setMultipleFiles,
    handleConfirmUploadAgreementImage,
    closeAgreementImageModal,
    handleAgreementImageChange,
    handleRemoveNonProfileDocument,
    handleDeletePdf,
    closePdfModal,
    setCrop,
    closeCropModal,
    handleVerifyProfileCheckbox,
    handlePhotoPasswordCheckbox,
    handleFeaturedProfileCheckbox,
    handleRemoveProfileImage,
    handleRemoveNonProfileImage,
    handleDeleteClick,
    closeDeleteModal,
    handleDeleteData,
    handleProfileImageUpload,
    handleNonProfileImageUpload,
    handleNonProfileDocumentUpload,
    handleProfileImageChange,
    handleNonProfileImageChange,
    handleNonProfileDocumentChange,
    handleNonProfileImageClick,
    handleNonProfileDocumentClick,
  } = useGallery({
    optionsData,
    isOptionDataSuccess,
    activeUser,
    edit,
    toTab,
  });

  //filtering agreement option from the dropdown to upload image /document only at a time
  const isAgreementUploaded =
    AgreementImageData?.data?.user_agreement_data?.length >= 1 ||
    AgreementDocumentData?.data !== null;

  return (
    <div className={`pro-p-0 pro-w-100`} id="gallery-info">
      <form action="">
        <div className={`row`}>
          <div className="col-12">
            <div className="input-wrap pro-mb-5">
              <h6 className="pro-font-md pro-mb-1 pro-fw-medium">
                Image Upload
              </h6>
            </div>
            <div className="input-wrap pro-mb-5">
              <label
                htmlFor=""
                className={`pro-font-sm pro-mb-1 pro-fw-medium`}
              >
                File Browser (Minimum : 600 * 600 )
              </label>
              <div className="input-drag">
                <input
                  type="file"
                  placeholder="placeholder"
                  accept="image/png,image/jpeg,image/jpg"
                  className={`pro-input ${
                    formik.touched.image && formik.errors.image && " error"
                  }`}
                  id="image"
                  onBlur={formik.handleBlur("image")}
                  onChange={(e) => handleProfileImageChange(e)}
                />
                <span className="input-drag-box">
                  <IconText title={`Drop files to attach or browse`} />
                </span>
                {formik?.values?.image?.name && (
                  <Button className={`pro-btn-link pro-mt-3`}>
                    {`${formik?.values?.image?.name}`}
                    <span
                      className="material-symbols-outlined"
                      onClick={() => handleRemoveProfileImage()}
                    >
                      close
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className={`col-4 pro-mt-3 pro-mb-5`}>
            <div className="pro-check-box">
              <input
                type="checkbox"
                className="pro-check "
                id="verify_profile"
                name="verify_profile"
                checked={formik.values?.verify_profile ?? false}
                onChange={(e) =>
                  handleVerifyProfileCheckbox(
                    formik.values?.verify_profile ?? false
                  )
                }
              />
              <label htmlFor="verify_profile" className="pro-check-label">
                {" "}
                Click here to Activate Profile{" "}
              </label>
            </div>
          </div>

          <div className={`col-4 pro-mt-3 pro-mb-5`}>
            <div className="pro-check-box">
              <input
                type="checkbox"
                className="pro-check "
                id="featured_profile"
                name="featured_profile"
                checked={formik.values?.featured_profile ?? false}
                onChange={(e) =>
                  handleFeaturedProfileCheckbox(
                    formik.values?.featured_profile ?? false
                  )
                }
              />
              <label htmlFor="featured_profile" className="pro-check-label">
                {" "}
                Featured Profile Activation{" "}
              </label>
            </div>
          </div>

          <div className={`col-4 pro-mt-3 pro-mb-5`}>
            <div className="pro-check-box">
              <input
                type="checkbox"
                className="pro-check "
                id="photo_password"
                name="photo_password"
                checked={formik.values?.photo_password ?? false}
                onChange={(e) =>
                  handlePhotoPasswordCheckbox(
                    formik.values?.photo_password ?? false
                  )
                }
              />
              <label htmlFor="photo_password" className="pro-check-label">
                {" "}
                Click here to set Photo Password{" "}
              </label>
            </div>
          </div>
        </div>

        {imagesData?.data?.user_images?.length > 0 && (
          <>
            <div className="row pro-mt-1 row-cols-6 g-2 pro-mb-4">
              {isImageDataSuccess ? (
                <>
                  {imagesData?.data?.user_images?.map((item, i) => {
                    return (
                      <div className={`${style.image_wrapper}`} key={i + "w"}>
                        <div className={style.image_wrapper_main}>
                          <Image
                            width={200}
                            height={200}
                            src={item?.user_image}
                            alt={""}
                            key={i}
                          />
                          <Image
                            width={200}
                            height={200}
                            src={item?.user_image}
                            alt={""}
                            key={i + 1}
                          />
                        </div>
                        <div className={style.delet_btn}>
                          <span
                            className="material-symbols-outlined"
                            onClick={() => handleDeleteClick(item?.id, 1)}
                          >
                            delete
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                // FIXME aneesh add shimmer for image loading
                <>
                  <div>loading...</div>
                </>
              )}
            </div>
          </>
        )}

        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
          <Button
            disabled={isUpdating}
            className={`pro-btn-primary lg pro-mx-3 ${
              isUpdating ? "loading" : ""
            }`}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {isUpdating ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            {toTab ? "Save" : "Submit"}
          </Button>
        </div>
        {edit && (
          <>
            <NonProfileImages
              optionsData={optionsData}
              formik={formik}
              handleRemoveNonProfileImage={handleRemoveNonProfileImage}
              isNonProfileImageDataSuccess={isImageDataSuccess}
              nonProfileImagesData={imagesData}
              handleDeleteClick={handleDeleteClick}
              handleNonProfileImageChange={handleNonProfileImageChange}
              handleNonProfileImageClick={handleNonProfileImageClick}
              style={style}
              errors={errors}
              handleAgreementImageChange={handleAgreementImageChange}
              multipleFiles={multipleFiles}
              AgreementImageData={AgreementImageData}
              isAgreementImageSuccess={isAgreementImageSuccess}
              isAgreementUploaded={isAgreementUploaded}
              handleDeleteAgreement={handleDeleteAgreement}
            />
          </>
        )}
        <hr style={{ border: "1px solid #DFE3EB" }} />
        {edit && (
          <>
            <NonProfileDocuments
              optionsData={optionsData}
              formik={formik}
              handleRemoveNonProfileDocument={handleRemoveNonProfileDocument}
              isNonProfileImageDataSuccess={isImageDataSuccess}
              nonProfileImagesData={imagesData}
              handleDeletePdf={handleDeletePdf}
              handleNonProfileDocumentChange={handleNonProfileDocumentChange}
              handleNonProfileDocumentClick={handleNonProfileDocumentClick}
              style={style}
              errors={errors}
              AgreementDocumentData={AgreementDocumentData}
              isAgreementDocumentSuccess={isAgreementDocumentSuccess}
              isAgreementUploaded={isAgreementUploaded}
              handleDeleteAgreement={handleDeleteAgreement}
            />
          </>
        )}

        {errors && <span className="error-message">{errors}</span>}
      </form>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDeleteData={handleDeleteData}
        fileType={fileType}
        errors={errors}
      />

      <CropModal
        ref={imgRef}
        showCrop={showCrop}
        closeModal={closeCropModal}
        image={image}
        imageType={imageType}
        crop={crop}
        setCrop={setCrop}
        handleNonProfileImageUpload={handleNonProfileImageUpload}
        handleProfileImageUpload={handleProfileImageUpload}
        loading={loading}
      />

      <PdfCard
        imageData={nonImage}
        handleClose={closePdfModal}
        showPdfModal={showPdfModal}
        handleNonProfileDocumentUpload={handleNonProfileDocumentUpload}
      />

      {multipleFiles?.["files"]?.length > 0 && (
        <ModalLayout
          show={agreementImageModal}
          handleClose={closeAgreementImageModal}
          title={"Preview"}
          closeIcon={
            <span className="material-symbols-outlined x5">close</span>
          }
        >
          <AgreementMultipleImage
            data={multipleFiles}
            agreementImageUploading={agreementImageUploading}
            setMultipleFiles={setMultipleFiles}
            handleClose={closeAgreementImageModal}
            handleConfirmUploadAgreementImage={
              handleConfirmUploadAgreementImage
            }
          />
        </ModalLayout>
      )}

      <ModalLayout
        centered
        show={showDeleteAgreementModal}
        handleClose={closeDeleteAgreementModal}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={`Are you sure you want to delete?`}
            subTitle={`You won’t be able to reverse this action.`}
            isRight={true}
            cancelText={`Cancel`}
            submitText={`Delete`}
            cancelAction={closeDeleteAgreementModal}
            submitAction={handleDeleteAgreementItem}
          >
            ConfirmationBox
          </ConfirmationBox>
        </div>
      </ModalLayout>
    </div>
  );
};

export default Gallery;
