import { format } from "date-fns";
import Select from "react-select";

const RowThree = ({ optionsData, formik }) => {
  const Booleans = [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" },
  ];

  const PhotoStatus = [
    { label: "With Photo", value: 1 },
    { label: "Without Photo", value: 2 },
  ];

  return (
    <>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Main Education
          </label>
          <Select
            id="main_education"
            isMulti
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.parentEducationCategory}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.parent_category}
            value={optionsData.parentEducationCategory?.filter((m) =>
              formik.values.main_education?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) => {
              let newVal = value.map((v) => v.id?.toString?.());
              formik.setFieldValue("main_education", newVal);
              formik.setFieldValue(
                "user_education",
                optionsData.educationCategory
                  .filter((edu) =>
                    newVal?.includes(edu?.parent_id?.toString?.())
                  )
                  .map?.((j) => j.id?.toString?.())
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Select Education
          </label>
          <Select
            id="user_education"
            isMulti
            placeholder={"Select"}
            isDisabled={
              formik.values.main_education?.length === 0 ? true : false
            }
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.educationCategory}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.category_title}
            value={optionsData.educationCategory?.filter((m) =>
              formik.values.user_education?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "user_education",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Main Job
          </label>
          <Select
            id="main_job"
            isMulti
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.parentJobCategory}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.parent_job_category}
            value={optionsData.parentJobCategory?.filter((m) =>
              formik.values.main_job?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) => {
              let newVal = value.map((v) => v.id?.toString?.());
              formik.setFieldValue("main_job", newVal);
              formik.setFieldValue(
                "user_job",
                optionsData.jobCategory
                  .filter((job) =>
                    newVal?.includes(job?.parent_id?.toString?.())
                  )
                  .map?.((j) => j.id?.toString?.())
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Select Job
          </label>
          <Select
            id="user_job"
            isMulti
            placeholder={"Select"}
            isDisabled={formik.values.main_job?.length === 0 ? true : false}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.jobCategory}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.job_title}
            value={optionsData.jobCategory?.filter((m) =>
              formik.values.user_job?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "user_job",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Agreement Status
          </label>
          <Select
            id="agreement_status"
            isClearable={true}
            placeholder={"Select"}
            options={Booleans}
            value={Booleans?.filter(
              (m) =>
                formik.values.agreement_status?.toString?.() ===
                m.value?.toString?.()
            )}
            onChange={(value) =>
              formik.setFieldValue("agreement_status", value?.value || null)
            }
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Photo Status
          </label>
          <Select
            id="have_photo"
            isClearable={true}
            placeholder={"Select"}
            options={PhotoStatus}
            value={PhotoStatus?.filter(
              (m) =>
                formik.values.have_photo?.toString?.() === m.value?.toString?.()
            )}
            onChange={(value) =>
              formik.setFieldValue("have_photo", value?.value || null)
            }
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Select Registration Package
          </label>
          <Select
            id="registration_type"
            isMulti
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.packageList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.package_title}
            value={optionsData.packageList?.filter((m) =>
              formik.values.registration_type?.includes?.(m.id?.toString?.())
            )}
            // value={optionsData?.packageList?.filter((m) => m.id.toString() !== "1")}
            onChange={(value) =>
              formik.setFieldValue(
                "registration_type",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>

      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="date_type"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Date Type
          </label>
          <Select
            id="date_type"
            isClearable={false}
            placeholder={"Select"}
            options={optionsData.dateTypeList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.date_type}
            value={optionsData.dateTypeList?.filter(
              (m) =>
                formik.values.date_type?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) =>
              formik.setFieldValue("date_type", value?.id || null)
            }
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Registration From
          </label>
          <input
            value={formik.values.register_from_date ?? ""}
            type={"date"}
            max={new Date().toLocaleDateString("fr-ca")}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "register_from_date",
                format(new Date(e.target.value), "yyyy-MM-dd"),
                new Date().toLocaleTimeString([], { timeStyle: "short" })
              );
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Registration To
          </label>
          <input
            value={formik.values.register_to_date ?? ""}
            type={"date"}
            max={new Date().toLocaleDateString("fr-ca")}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "register_to_date",
                format(new Date(e.target.value), "yyyy-MM-dd"),
                new Date().toLocaleTimeString([], { timeStyle: "short" })
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RowThree;
