import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../store/queries/Dashboard";
import DoNotCallLaterList from "./DoNotCallLaterList";
import FollowUpList from "./FollowUpList";
import JobAssignList from "./JobAssignList";
import PossibilityList from "./PossibilityList";
import ReportsList from "./ReportsList";
import ScheduleCompleteList from "./ScheduleCompleteList";
import ScheduleTerminationList from "./ScheduleTerminationList";
import SelectionRequestList from "./SelectionRequestList";
import TlSupportList from "./TlSupportList";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCronStatus } from "./api";
import PendingCalls from "./PendingCalls/PendingCallsListing";
import ServiceFollowUpList from "./ServiceFollowUpList";

const useDashboard = () => {
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {})?.[0] ?? "");
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  const handleFollowUp = () => {
    setListingType("follow_up_profiles");
  };
  const handleDoNotCallLater = () => {
    setListingType("do_not_call_later");
  };
  const handlePossibility = () => {
    setListingType("posibility");
  };
  const handleTlSupport = () => {
    setListingType("tl_support_request");
  };
  const handleSelectionRequest = () => {
    setListingType("selection_request");
  };
  const handleScheduleTermination = () => {
    setListingType("schedule_termination");
  };
  const handleJobAssign = () => {
    setListingType("assigned_jobs");
  };
  const handleReports = () => {
    setListingType("reports");
  };
  const handleScheduleComplete = () => {
    setListingType("schedule_complete");
  };

  const handleRunCron = () => {
    getCronStatus().then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Schedule Run successfully");
      } else {
        toast.error("Failed to Run Schedule");
      }
    });
  };
  const handleStaffPendingCalls = () => {
    setListingType("staff_pending_calls");
  };

  const handleServiceFollowUp = () => {
    setListingType("service_follow_up");
  };

  let object = {
    follow_up_profiles: handleFollowUp,
    do_not_call_later: handleDoNotCallLater,
    posibility: handlePossibility,
    tl_support_request: handleTlSupport,
    selection_request: handleSelectionRequest,
    schedule_termination: handleScheduleTermination,
    assigned_jobs: handleJobAssign,
    reports: handleReports,
    schedule_complete: handleScheduleComplete,
    run_cron: handleRunCron,
    staff_pending_calls: handleStaffPendingCalls,
    service_follow_up: handleServiceFollowUp,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    follow_up_profiles: {
      label: "follow_up_profiles",
      title: "Follow Up Profiles",
      icon: "calendar_month",
      handleClick,
      component: <FollowUpList activeProfile={activeProfile} params={params} />,
    },
    tl_support_request: {
      label: "tl_support_request",
      title: "TL Support Request",
      icon: "headset_mic",
      handleClick,
      component: (
        <TlSupportList activeProfile={activeProfile} params={params} />
      ),
    },
    selection_request: {
      label: "selection_request",
      title: "Selection Request",
      icon: "edit_document",
      handleClick,
      component: (
        <SelectionRequestList activeProfile={activeProfile} params={params} />
      ),
    },
    schedule_termination: {
      label: "schedule_termination",
      title: "Schedule Termination",
      icon: "manage_accounts",
      handleClick,
      component: (
        <ScheduleTerminationList
          activeProfile={activeProfile}
          params={params}
        />
      ),
    },
    assigned_jobs: {
      label: "assigned_jobs",
      title: "Pending Calls",
      icon: "work",
      handleClick,
      component: (
        <JobAssignList activeProfile={activeProfile} params={params} />
      ),
    },
    reports: {
      label: "reports",
      title: "Reports",
      icon: "insert_chart",
      handleClick,
      component: <ReportsList activeProfile={activeProfile} params={params} />,
    },
    do_not_call_later: {
      label: "do_not_call_later",
      title: "Do Not Call Later",
      icon: "phone_paused",
      handleClick,
      component: (
        <DoNotCallLaterList activeProfile={activeProfile} params={params} />
      ),
    },
    schedule_complete: {
      label: "schedule_complete",
      title: "Schedule Complete",
      icon: "schedule",
      handleClick,
      component: (
        <ScheduleCompleteList activeProfile={activeProfile} params={params} />
      ),
    },
    posibility: {
      label: "posibility",
      title: "Possibility",
      icon: "alarm",
      handleClick,
      component: (
        <PossibilityList activeProfile={activeProfile} params={params} />
      ),
    },
    run_cron: {
      label: "run_cron",
      title: "Run Schedule",
      icon: "work",
      handleClick,
    },
    staff_pending_calls: {
      label: "staff_pending_calls",
      title: "Staff Pending Calls",
      icon: "call",
      handleClick,
      component: <PendingCalls activeProfile={activeProfile} params={params} />,
    },
    service_follow_up: {
      label: "service_follow_up",
      title: "Service Follow Up",
      icon: "free_cancellation  ",
      handleClick,
      component: (
        <ServiceFollowUpList activeProfile={activeProfile} params={params} />
      ),
    },
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
  };
};

export default useDashboard;
