import React from "react";
import TimePicker from "react-time-picker";
import useFilterForm from "./useFilterForm";
import style from "./filterform.module.scss";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const FilterForm = ({ closeFilterModal }) => {
  const {
    onChange,
    onEndTimeChange,
    handleClearEndClick,
    handleClearStartClick,
    time,
    endTime,
    formik,
  } = useFilterForm({ closeFilterModal });
  return (
    <div>
      <div className={`row`}>
        {/* //FIXME change timePicker package */}
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Start Time
          </label>
          <TimePicker
            format="HH:mm"
            id="start_time"
            hourPlaceholder="00"
            minutePlaceholder="00"
            //   secondPlaceholder="00"
            disableClock
            className={`pro-input lg ${style.timePicker}  ${
              formik.touched.start_time && formik.errors.start_time && " error"
            }`}
            onBlur={formik.handleBlur("start_time")}
            onChange={(value) => onChange(value)}
            clearIcon={
              <span
                onClick={() => handleClearStartClick()}
                className="material-symbols-outlined x3"
              >
                close
              </span>
            }
            value={formik?.values?.start_time === "00:00" ? null : time}
            maxTime={"24:00:00"}
            minTime={"00:00:00"}
          />

          {formik.touched.start_time && formik.errors.start_time && (
            <span className="error-text">` `{formik.errors.start_time}</span>
          )}
        </div>
      </div>

      <div className={`row`}>
        {/* //FIXME change timePicker package */}
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            End Time
          </label>
          <TimePicker
            format="HH:mm"
            id="end_time"
            hourPlaceholder="00"
            minutePlaceholder="00"
            //   secondPlaceholder="00"
            disableClock
            className={`pro-input lg ${style.timePicker}  ${
              formik.touched.end_time && formik.errors.end_time && " error"
            }`}
            onBlur={formik.handleBlur("end_time")}
            onChange={(value) => onEndTimeChange(value)}
            clearIcon={
              <span
                onClick={() => handleClearEndClick()}
                className="material-symbols-outlined x3"
              >
                close
              </span>
            }
            value={formik?.values?.end_time === "00:00" ? null : endTime}
          />

          {formik.touched.end_time && formik.errors.end_time && (
            <span className="error-text">` `{formik.errors.end_time}</span>
          )}
        </div>
      </div>
      <div className={`col-12 pro-d-flex pro-justify-end`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeFilterModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {"Submit"}
        </Button>
      </div>
    </div>
  );
};

export default FilterForm;
