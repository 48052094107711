import Select from "react-select";
import { format } from "date-fns";

const RowTwo = ({ formik, optionsData }) => {
  return (
    <>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="subscription"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Subscription Plan
          </label>
          <Select
            id="subscription"
            isClearable={true}
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.packageList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.package_title}
            value={optionsData.packageList?.filter(
              (m) =>
                formik.values.subscription?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("subscription", value?.id || null);
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Service Created From
          </label>
          <input
            value={formik.values.service_create_from ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "service_create_from",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Service Created To
          </label>
          <input
            value={formik.values.service_create_to ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "service_create_to",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Service Follow From
          </label>
          <input
            value={formik.values.service_followup_from ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "service_followup_from",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Service Follow To
          </label>
          <input
            value={formik.values.service_followup_to ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "service_followup_to",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Service Dated On From
          </label>
          <input
            value={formik.values.service_date_on_from ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "service_date_on_from",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Service Dated On To
          </label>
          <input
            value={formik.values.service_date_on_to ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "service_date_on_to",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            From Date
          </label>
          <input
            value={formik.values.from_date ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "from_date",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            To Date
          </label>
          <input
            value={formik.values.to_date ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg"}
            onChange={(e) => {
              formik.setFieldValue(
                "to_date",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RowTwo;
