import Dropdown from "react-bootstrap/Dropdown";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import Assets from "../../../assets/Assets";
import { ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import CallModal from "../../../components/Global/CallModal";
import { useNavigate } from "react-router-dom";

const HeaderActions = () => {
  const {
    handleLogout,
    handleWebIdChange,
    handleSearch,
    handleFullSearch,
    handleRegSearch,
    handleFullSearchChange,
    handleSearchClick,
    handleRegSearchChange,
    handleRegSearchClick,
    handleFullSearchClick,
    closeCallModal,
    IsAgent,
    globalState,
    loggedStaffData,
    fullSearchValue,
    globalSearchValue,
    regSearchValue,
  } = useHeaderActions();
  const navigate = useNavigate();
  return (
    <div
      className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root} ${
        /**#FIXME Aneesh, change style.root if need be */ ""
      } `}
    >
      {IsAgent === "true" && (
        <span
          className={` pro-me-4 material-symbols-outlined x5 ${Style.call_history_button}`}
          onClick={() => navigate("/call-history")}
        >
          call_log
        </span>
      )}

      {/* Registration number search */}
      <div className="input-wrap pro-me-3 ">
        <input
          type="text"
          placeholder="Registration Search"
          className="pro-input icon-l lg"
          value={regSearchValue}
          onClick={() => handleRegSearchClick()}
          onKeyDown={(e) => handleRegSearch(e.code)}
          onChange={(e) => handleRegSearchChange(e.target.value)}
        />
        <span className="pro-icon">
          <span className="material-symbols-outlined"> search </span>
        </span>
      </div>
      {/* Full search */}
      <div className="input-wrap pro-me-3 ">
        <input
          type="text"
          placeholder="Full Search"
          className="pro-input icon-l lg"
          value={fullSearchValue}
          onClick={() => handleFullSearchClick()}
          onKeyDown={(e) => handleFullSearch(e.code)}
          onChange={(e) => handleFullSearchChange(e.target.value)}
        />
        <span className="pro-icon">
          <span className="material-symbols-outlined"> search </span>
        </span>
      </div>

      <div className="input-wrap pro-me-3 ">
        <input
          type="text"
          placeholder="Search"
          className="pro-input icon-l lg"
          value={globalSearchValue}
          onClick={() => handleSearchClick()}
          onKeyDown={(e) => handleSearch(e.code)}
          onChange={(e) => handleWebIdChange(e.target.value)}
        />
        <span className="pro-icon">
          <span className="material-symbols-outlined"> search </span>
        </span>
      </div>
      <Dropdown className={`pro-d-flex pro-items-center`}>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            <img
              src={
                loggedStaffData?.data?.staff_image === null
                  ? Assets.avatar
                  : loggedStaffData?.data?.staff_image
              }
              alt=""
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={Style.dropdown}>
          <Dropdown.Item>
            <div className={Style.user_wrapper}>
              <div className={Style.image_wrapper}>
                <img
                  src={
                    loggedStaffData?.data?.staff_image === null
                      ? Assets.avatar
                      : loggedStaffData?.data?.staff_image
                  }
                  alt=""
                />
              </div>
              <div className={Style.content_wrapper}>
                <h3 className={Style.staff_name}>
                  {loggedStaffData?.data?.staff_name}
                </h3>
                <p className={Style.staff_email}>
                  {loggedStaffData?.data?.staff_email}
                </p>
                <p className={Style.staff_number}>
                  <a href={`tel:${loggedStaffData?.data?.staff_mobile}`}>
                    {loggedStaffData?.data?.staff_mobile}
                  </a>
                </p>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className={`${Style.logout} pro-btn pro-btn-outline `}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ModalLayout
        show={globalState.call_active}
        // centered
        // title={"Incoming Call"}
        // closeIcon={<span class="material-symbols-outlined x4">close</span>}
        handleClose={closeCallModal}
        // backdrop="static"
        propStyle={{ root: Style.call_modal }}
      >
        <CallModal data={globalState?.call_data} handleClose={closeCallModal} />
      </ModalLayout>
    </div>
  );
};

export default HeaderActions;
