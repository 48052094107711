import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetScheduleHistoryQuery } from "../../../store/queries/Profile";
import { updateConfig } from "../../../store/slices/Profile/ScheduledHistory";
import { useState } from "react";

const useScheduledHistory = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [showAssignForm, setShowAssignForm] = useState(false);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const scheduledHistoryState = useSelector((state) => state.scheduledHistory);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: scheduledHistory = {},
    isFetching,
    refetch,
  } = useGetScheduleHistoryQuery({
    page_size: scheduledHistoryState.currentPageSize,
    page: scheduledHistoryState.currentPage,
    sort_by: scheduledHistoryState.sortBy,
    sort_order: scheduledHistoryState.sortOrder,
    user_id: activeProfile,
  });

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (scheduledHistoryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            scheduledHistoryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "desc";
        })
      );
    }
  };

  const handleAddSchedule = () => {
    setShowAssignForm(true);
  };

  const handleClose = () => {
    setShowAssignForm(false);
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    scheduledHistory,
    isFetching,
    currentPage: scheduledHistoryState?.currentPage,
    paginationOptions,
    activeProfile,
    showAssignForm,
    refetch,
 
    handleClose,
    handleAddSchedule,
    handleSort,
    getRow,
    handlePagination,
    handlePageSize,
  };
};
export default useScheduledHistory;
