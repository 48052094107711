import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getAutoMatchDetails,
  updateConfig,
} from "../../../store/slices/Profile/AutoMatch";
import useObserver from "../../../utils/hooks/useObserver";
import { updateProfileConfig } from "../../../store/slices/Profile";

const useAutoMatch = () => {
  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const state = useSelector((state) => state.autoMatch);
  const { selectedProfiles } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const autoMatchState = state?.autoMatchData?.[activeProfile];

  const [activeFilter, setActiveFilter] = useState(0);
  const [checked, setChecked] = useState(false);
  const [entireData, setEntireData] = useState(true);
  const [adminVerified, setAdminVerified] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [page, setPage] = useState(1);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    if (state?.autoMatchStatus !== "pending") {
      dispatch(
        getAutoMatchDetails({
          page: page,
          id: activeProfile,
          filter: activeFilter,
          entire_data: +entireData,
          agreement: +checked,
          admin_verified: +adminVerified,
          registered: +registered,
        })
      );
    }
    //eslint-disable-next-line
  }, [
    activeProfile,
    activeFilter,
    page,
    checked,
    entireData,
    adminVerified,
    registered,
  ]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
          state.selectedProfileNames = [];
        })
      );
      dispatch(
        updateConfig((state) => {
          if (state?.autoMatchData?.[activeProfile]?.data) {
            state.autoMatchData[activeProfile].data = [];
            state.autoMatchData[activeProfile].page = 1;
          }
        })
      );
    };
    //eslint-disable-next-line
  }, [
    activeProfile,
    activeFilter,
    checked,
    entireData,
    adminVerified,
    registered,
  ]);

  useEffect(() => {
    setPage(state.autoMatchData?.[activeProfile]?.page || 1);

    //eslint-disable-next-line
  }, [activeProfile]);

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    dispatch(
      updateConfig((state) => {
        state.autoMatchData[activeProfile].page += 1;
      })
    );
  };

  const handleChange = (item) => {
    if (item.value !== activeFilter) {
      dispatch(
        updateConfig((state) => {
          if (state.autoMatchData?.[activeProfile]?.data) {
            state.autoMatchData[activeProfile].data = [];
            state.autoMatchData[activeProfile].page = 1;
          }
        })
      );
      setActiveFilter(item.value);
    }
  };

  const handleDownloadClick = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = state.selectedProfiles.filter(
          (a) => a !== activeProfile
        );
        state.showPdf = true;
        state.skip = false;
      })
    );
  };
  const handleSelect = (id, checked, name) => {
    if (checked) {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [...state.selectedProfiles, id];
          state.selectedProfileNames = [...state.selectedProfileNames, name];
        })
      );
    } else {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = state.selectedProfiles.filter(
            (prevID) => prevID !== id
          );
          state.selectedProfileNames = state.selectedProfileNames.filter(
            (prevID) => prevID !== name
          );
        })
      );
    }
  };

  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Contact Not Viewed ", value: 0 },
        { name: "Contact Viewed ", value: 1 },
      ],
    },
  ];

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleCheckBox = (checked) => {
    setChecked((state) => !checked);
  };

  const handleEntireDataCheckBox = (entireData) => {
    setEntireData((state) => !entireData);
  };

  const handleAdminVerifiedCheckbox = (adminVerified) => {
    setAdminVerified((state) => !adminVerified);
  };

  const handleRegisteredCheckbox = (registered) => {
    setRegistered((state) => !registered);
  };

  const { lastElement } = useObserver({
    loading: state?.autoMatchStatus === "pending",
    hasMore:
      autoMatchState?.per_page * autoMatchState?.current_page <
      autoMatchState?.total_count,
    callback: handleLoadMore,
  });

  return {
    autoMatchState,
    state,
    filters,
    activeFilter,
    showScroll,
    checked,
    selectedProfiles,
    activeProfile,
    entireData,
    adminVerified,
    registered,
    handleRegisteredCheckbox,
    handleAdminVerifiedCheckbox,
    handleDownloadClick,
    handleEntireDataCheckBox,
    handleSelect,
    handleCheckBox,
    lastElement,
    handleChange,
    handleScrollToTop,
  };
};

export default useAutoMatch;
