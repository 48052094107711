import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  useGetStarMatchFiltersQuery,
  useGetUserPreferenceQuery,
} from "../../../../../store/queries/Profile";
import {
  getPartnerMatchDetails,
  savePartnerMatchDetails,
  updateConfig,
} from "../../../../../store/slices/Profile/PartnerMatch";
import { updateProfileConfig } from "../../../../../store/slices/Profile";
import { toast } from "react-toastify";
import { registration } from "../../../../../store/queries/Registration";
import { profile } from "../../../../../store/queries/Profile";

const usePartnerForm = ({
  star,
  checked,
  entireData,
  adminVerified,
  registered,
  activeFilter,
}) => {
  let location = useLocation();
  const [params] = useSearchParams();

  const dispatch = useDispatch();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const { data: optionsData, isSuccess: isOptionDataSuccess } =
    useGetStarMatchFiltersQuery();

  const { data: preferenceData, isSuccess: isPreferenceDataSuccess } =
    useGetUserPreferenceQuery(activeProfile);

  useEffect(() => {
    if (isOptionDataSuccess && isPreferenceDataSuccess) {
      handleSubmit({
        ...formik?.values,
        user_id: activeProfile,
        agreement: +checked,
        entire_data: +entireData,
        viewed_status: activeFilter,
        admin_verified: +adminVerified,
        registered: +registered,
      });
    }
    // eslint-disable-next-line
  }, [
    isOptionDataSuccess,
    isPreferenceDataSuccess,
    location,
    activeFilter,
    checked,
    entireData,
    adminVerified,
    registered,
  ]);

  // useEffect(() => {
  //   if (isOptionDataSuccess && isPreferenceDataSuccess) {
  //     Object.keys(preferenceData?.data || {}).forEach((key) => {

  //       formik.setFieldValue(key, preferenceData?.data?.[key]);
  //     });
  //     handleSubmit({
  //       ...preferenceData.data,
  //       user_id: activeProfile,
  //       agreement: +checked,
  //       entire_data: +entireData,
  //       viewed_status: activeFilter,
  //       admin_verified: +adminVerified,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [
  //   isOptionDataSuccess,
  //   isPreferenceDataSuccess,
  //   location,
  //   // activeFilter,
  //   // checked,
  //   // entireData,
  // ]);
  // useEffect(() => {
  //   if (isPreferenceDataSuccess && isOptionDataSuccess) {
  //     Object.keys(preferenceData?.data || {}).forEach((key) => {
  //       console.log(key, "data");
  //     });
  //     handleSubmit({
  //       ...formik.values,
  //       user_id: activeProfile,
  //       agreement: +checked,
  //       entire_data: +entireData,
  //       admin_verified: +adminVerified,
  //       viewed_status: activeFilter,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [activeFilter, checked, entireData, adminVerified]);

  const preferenceDataValue = preferenceData?.data;

  const formik = useFormik({
    initialValues: {
      user_id: activeProfile,
      marital_status: preferenceDataValue?.marital_status
        ? preferenceDataValue?.marital_status
        : [],
      height_from: preferenceDataValue?.height_from
        ? preferenceDataValue?.height_from
        : null,
      agreement: true,
      height_to: preferenceDataValue?.height_to
        ? preferenceDataValue?.height_to
        : null,
      age_from: preferenceDataValue?.age_from
        ? preferenceDataValue?.age_from
        : null,
      age_to: preferenceDataValue?.age_to ? preferenceDataValue?.age_to : null,
      religion: preferenceDataValue?.religion
        ? preferenceDataValue?.religion
        : null,
      caste: preferenceDataValue?.caste ? preferenceDataValue?.caste : [],
      parent_education: preferenceDataValue?.parent_education
        ? preferenceDataValue?.parent_education
        : [],
      education: preferenceDataValue?.education
        ? preferenceDataValue?.education
        : [],
      parent_occupation: preferenceDataValue?.parent_occupation
        ? preferenceDataValue?.parent_occupation
        : [],
      job: preferenceDataValue?.job ? preferenceDataValue?.job : [],
      country: preferenceDataValue?.country
        ? preferenceDataValue?.country
        : null,
      state: preferenceDataValue?.state ? preferenceDataValue?.state : [],
      district: preferenceDataValue?.district
        ? preferenceDataValue?.district
        : [],
      special_case: preferenceDataValue?.special_case
        ? preferenceDataValue?.special_case
        : null,
      jathakam: preferenceDataValue?.jathakam
        ? preferenceDataValue?.jathakam
        : [],
      matching_stars: preferenceDataValue?.matching_stars
        ? preferenceDataValue?.matching_stars
        : [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit({
        ...values,
        agreement: +checked,
        entire_data: +entireData,
        admin_verified: +adminVerified,
        registered: +registered,
        viewed_status: activeFilter,
      });
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
    },
  });

  const handleSubmit = (values) => {
    dispatch(
      updateConfig((state) => {
        if (state.partnerMatchData?.[activeProfile]?.data) {
          state.partnerMatchData[activeProfile].data = [];
          state.partnerMatchData[activeProfile].page = 1;
        }
        state.partnerMatchFilters = values;
      })
    );
    dispatch(getPartnerMatchDetails({ ...values, star }));
  };

  const handleSave = () => {
    dispatch(savePartnerMatchDetails({ ...formik?.values })).then(
      (response) => {
        if (response?.payload?.status === 200) {
          toast.success("Partner preference updated successfully");
          dispatch(registration.util.invalidateTags(["Registration"]));
          dispatch(registration.util.invalidateTags(["Registration_FormData"]));
          dispatch(profile.util.invalidateTags(["Overview"]));
        } else {
          toast.error("failed to update partner preference");
        }
      }
    );
  };

  return {
    formik,
    handleSave,
    optionsData: optionsData?.data || {},
    isOptionDataSuccess,
    isPreferenceDataSuccess,
  };
};

export default usePartnerForm;
