import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentServiceFilter: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  dateOnRange: [],
  followUpdateRange: [],
  serviceSearch: "",
  serviceValue: [],
};

const viewService = createSlice({
  name: "viewService",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = viewService.actions;

export default viewService.reducer;
