import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showCreateModal: false,
  partner_client_web_id: "",
  client_web_id: "",
  showActionsModal: false,
  actionFormType: "",
  prevTab: {},
  pdfVisibility: {
    phone: false,
    address: false,
    route: false,
    horoscope: false,
  },
  selectedProfiles: [],
  selectedProfileNames: [],
  showPdf: false,
  skip: true,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfileConfig: (state, action) => {
      action.payload(state);
    },
    saveUserTab: (state, action) => {
      state.prevTab[action.payload.id] = action.payload.path;
    },
  },
});

export const { updateProfileConfig, saveUserTab } = profileSlice.actions;

export default profileSlice.reducer;
