import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";

const RowTwo = ({
  formik,
  optionsData,
  search,
  childClass,
  handleWebIdChange,
  handleOnBlur,
}) => {
  
  return (
    <>
      {search && (
        <div className={childClass}>
          <Input
            label={" Bride's / Groom's Web ID"}
            type="text"
            id="client_web_id"
            name="client_web_id"
            disabled={true}
            className={`pro-input lg ${
              formik.touched.client_web_id &&
              formik.errors.client_web_id &&
              "error"
            }`}
            value={formik.values.client_web_id}
            {...formik.getFieldProps("client_web_id")}
            error={formik.touched.client_web_id && formik.errors.client_web_id}
            errorMessage={formik.errors.client_web_id}
          />
        </div>
      )}
      {formik.values.service_type !== 1 ? (
        <div className={childClass}>
          <Input
            label={"Partner Bride's / Groom's ID*"}
            type="text"
            disabled={search ? true : false}
            id="partner_client_web_id"
            name="partner_client_web_id"
            className={`pro-input lg ${
              formik.touched.partner_client_web_id &&
              formik.errors.partner_client_web_id &&
              "error"
            }`}
            value={formik.values.partner_client_web_id}
            onBlur={(e) =>
              handleOnBlur(e.target.value, formik.values.service_id)
            }
            onChange={(e) => handleWebIdChange(e.target.value)}
            //{...formik.getFieldProps("partner_client_web_id")}
            error={
              formik.touched.partner_client_web_id &&
              formik.errors.partner_client_web_id
            }
            errorMessage={formik.errors.partner_client_web_id}
          />
        </div>
      ) : (
        <></>
      )}

      <div className={childClass}>
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="looking_for"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Service List*
          </label>
          <Select
            id="service_id"
            placeholder={"Select"}
            options={optionsData?.service_list?.filter(
              (p) =>
                p.parent_id?.toString() ===
                formik.values.service_type?.toString()
            )}
            getOptionLabel={(option) => `${option.service_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            value={optionsData.service_list?.filter(
              (m) => m.id?.toString() === formik.values.service_id?.toString()
            )}
            onChange={(value) => {
              formik.setFieldValue("service_id", value?.id || null);
              handleWebIdChange(formik.values.partner_client_web_id);
              handleOnBlur(formik.values.partner_client_web_id, value?.id);
            }}
            className={`pro-input lg ${
              !formik.values.service_type && "disabled"
            } ${
              formik.values.service_type &&
              formik.touched.service_id &&
              formik.errors.service_id &&
              "error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.values.service_type &&
            formik.touched.service_id &&
            formik.errors.service_id && (
              <span className="error-text">{formik.errors.service_id}</span>
            )}
        </div>
      </div>

      <div className={"col-12"}>
        <Input
          label={"Service Remark*"}
          type="text"
          id="service_remark"
          name="service_remark"
          className={`pro-input lg ${
            formik.touched.service_remark &&
            formik.errors.service_remark &&
            "error"
          }`}
          value={formik.values.service_remark}
          {...formik.getFieldProps("service_remark")}
          error={formik.touched.service_remark && formik.errors.service_remark}
          errorMessage={formik.errors.service_remark}
        />
      </div>
    </>
  );
};

export default RowTwo;
