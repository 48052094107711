import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  staff,
  useCreateStaffMutation,
  useGetStaffCreationFormDataQuery,
  useUpdateStaffMutation,
} from "../../../store/queries/Staff";
import {
  emailRegExp,
  getFormatedDate,
  phoneRegExp,
} from "../../../utils/functions/table";
import { useGetStaffUpdationFormDataQuery } from "../../../store/queries/Staff";
import { useNavigate, useSearchParams } from "react-router-dom/dist";
import { useDispatch } from "react-redux";

const useStaffCreationForm = () => {
  const dispatch = useDispatch();
  const { data: optionsData = {} } = useGetStaffCreationFormDataQuery({});
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [createStaff] = useCreateStaffMutation();

  useEffect(() => {
    setSkip(false);
    // eslint-disable-next-line
  }, [params.get("id")]);

  const { data: editStaff = {} } = useGetStaffUpdationFormDataQuery(
    {
      id: params.get("id"),
    },
    { skip: skip }
  );

  useEffect(() => {
    Object.keys(editStaff?.data || {}).forEach((key) => {
      formik.setFieldValue(key, editStaff?.data?.[key]);
    });
    // eslint-disable-next-line
  }, [skip, editStaff]);

  const [updateStaff] = useUpdateStaffMutation();

  let createStaffVal = Yup.object({
    staff_name: Yup.string().required("*Required"),
    nick_name: Yup.string().required("*Required"),
    gender: Yup.string().required("*Required"),
    marital_status: Yup.string().required("*Required"),
    staff_email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    staff_mobile: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(10, "phone number must be 10 digits")
      .max(10, "phone number must be 10 digits")
      .required("*Required"),
    office_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(10, "phone number must be 10 digits")
      .max(10, "phone number must be 10 digits")
      .required("*Required"),
    present_address: Yup.string().required("*Required"),
    permanent_address: Yup.string().required("*Required"),
    education_category_id: Yup.string().required("*Required"),
    designation: Yup.string().required("*Required"),
    user_type: Yup.string().required("*Required"),
    branch_id: Yup.string().required("*Required"),
    password: Yup.string()
      .min(6, "Password too short")
      .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
      .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
      .matches(/^(?=.*[0-9])/, "Must contain at least one number")
      .matches(/^(?=.*[!@#%&])/, "Must contain at least one special character")
      .required("*Required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("*Required"),
    photo: Yup.string().required("*Required"),
    id_proof: Yup.string().required("*Required"),
    certificate: Yup.string().required("*Required"),
    agent_number: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      staff_name: "",
      nick_name: "",
      gender: null,
      marital_status: null,
      staff_email: "",
      staff_mobile: "",
      office_number: "",
      present_address: "",
      permanent_address: "",
      education_category_id: null,
      education_info: "",
      designation: null,
      joining_date: "",
      remark: "",
      user_type: null,
      branch_id: null,
      password: "",
      confirm_password: "",
      photo: null,
      id_proof: null,
      certificate: null,
      agent_number: "",
    },

    validationSchema: !params.get("id") ? createStaffVal : null,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values,
          joining_date: getFormatedDate(values.joining_date),
        };

        let formData = new FormData();

        if (!params.get("id")) {
          Object.keys(obj).forEach((key) => {
            return formData.append(key, obj[key]);
          });

          createStaff(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              dispatch(staff.util.invalidateTags(["StaffUpdateForm"]));
              submitForm(resetForm);
            } else if (response?.error?.status === 403) {
              toast.error(response?.error?.data?.message);
            } else if (response?.error?.status === 422) {
              formik.setErrors(response?.error?.data?.errors);
            }
          });
        } else {
          obj = { ...obj, staff_id: params.get("id") };

          if (!formik?.values?.photo?.name) {
            delete obj.photo;
          }
          if (!formik?.values?.id_proof?.name) {
            delete obj.id_proof;
          }
          if (!formik?.values?.certificate?.name) {
            delete obj.certificate;
          }

          Object.keys(obj).forEach((key) => {
            return formData.append(key, obj[key]);
          });
          updateStaff(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              submitForm(resetForm);
            } else if (response?.error?.status === 422) {
              formik.setErrors(response?.error?.data?.errors);
            } else if (response?.error?.status === 403) {
              toast.error(response?.error?.data?.message);
            } else toast.error(response?.data?.message);
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = (resetForm) => {
    resetForm();

    setLoading(false);

    if (!params.get("id")) {
      toast.success("Successfully submitted");
    } else toast.success("Successfully udpated");

    navigate("/dashboard/staff");
  };

  const handleCancel = (e) => {
    if (!params.get("id")) {
      e.preventDefault();
      formik.resetForm();
    } else navigate("/dashboard/staff");
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };
  const handleRemoveIdProofImage = (e) => {
    formik.setFieldValue("id_proof", "");
  };
  const handleRemoveCertificateImage = (e) => {
    formik.setFieldValue("certificate", "");
  };

  return {
    errors,
    params,
    formik,
    loading,
    optionsData,
    showPassword,
    handleShowPassword,
    handleCancel,
    handleRemovePhotoImage,
    handleRemoveIdProofImage,
    handleRemoveCertificateImage,
  };
};

export default useStaffCreationForm;
