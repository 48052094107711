import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
};

/**
 * @example {
 *
 *  "sort_by":"name"
 *  "sort_order":"asc"
 *  "page_size":"10"
 *  "page":"1"
 *  }
 */

const ServiceNotificationSlice = createSlice({
  name: "serviceNotification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = ServiceNotificationSlice.actions;

export default ServiceNotificationSlice.reducer;
