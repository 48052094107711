import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const UserImage = ({ image, onClick }) => {
  if (!image) {
    return "";
  }
  return (
    <div>
      <Image width={10} height={10} src={image} alt={""} />
    </div>
  );
};
