import React from "react";
import useViewTableDetails from "./useViewTableDetails";

const ViewTableDetailsSS = React.forwardRef((props, ref) => {
  const { existingServiceList } = useViewTableDetails();

  const title = [
    "Branch",
    "Name",
    "Partner Name",
    "Partner WebID",
    "Remark",
    "SC Date/Time",
    "Service",
    "WebID",
  ];

  return (
    <div ref={ref}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          fontWeight: 400,
          fontSize: 10,
        }}
      >
        <thead>
          <tr>
            {title.map((key, index) => (
              <th
                key={index}
                style={{
                  backgroundColor: "#304053",
                  color: "#ffffff",
                  fontWeight: 600,
                  textAlign: "center",
                  padding: "5px 5px",
                }}
              >
                {key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {existingServiceList?.data?.map((item, index) => (
            <>
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f3f3f3" : "#ffffff",
                }}
              >
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["Branch"][0]}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["Name"][0]}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["Partner Name"][0]}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["Partner Web Id"][0]}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["Remark"][0]}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["SC Date/Time"][0]}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["Service"][0]}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {item?.["Web Id"][0]}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default ViewTableDetailsSS;
