import React from "react";
import Select from "react-select";

const JobLocationPreference = ({
  formik,
  optionsData,
  handleSelectJobCountry,
  handleSelectJobDistrict,
  handleSelectJobState,
  stateJobRef,
  districtJobRef,
  locationJobRef,
}) => {
  return (
    <div className="row" id="job-location-preference">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">
          Job Location Preference
        </h6>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Country
          </label>
          <Select
            id="prefer_job_country"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.countryList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.country_name}
            value={optionsData?.data?.countryList?.filter?.((c) =>
              formik.values.prefer_job_country?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              handleSelectJobCountry(value);
            }}
            onBlur={formik.handleBlur("prefer_job_country")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_job_country &&
              formik.errors.prefer_job_country &&
              " error"
            } `}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_job_country &&
            formik.errors.prefer_job_country && (
              <span className="error-text">
                {formik.errors.prefer_job_country}
              </span>
            )}
        </div>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="prefer_job_state"
            isMulti
            placeholder={"Select"}
            ref={stateJobRef}
            options={optionsData?.data?.stateList?.filter((p) =>
              formik.values.prefer_job_country?.includes?.(
                p.country_id?.toString()
              )
            )}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.state_name}
            value={optionsData?.data?.stateList?.filter?.((c) =>
              formik.values.prefer_job_state?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              handleSelectJobState(value);
            }}
            onBlur={formik.handleBlur("prefer_job_state")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_job_state &&
              formik.errors.prefer_job_state &&
              " error"
            } ${!formik.values.prefer_job_country && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_job_state &&
            formik.errors.prefer_job_state && (
              <span className="error-text">
                {formik.errors.prefer_job_state}
              </span>
            )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            District
          </label>
          <Select
            id="prefer_job_district"
            isMulti
            placeholder={"Select"}
            ref={districtJobRef}
            options={optionsData?.data?.districtsList?.filter((p) =>
              formik.values.prefer_job_state?.includes?.(p.state_id?.toString())
            )}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.district_name}
            value={optionsData?.data?.districtsList?.filter?.((c) =>
              formik.values.prefer_job_district?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              handleSelectJobDistrict(value);
            }}
            onBlur={formik.handleBlur("prefer_job_district")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_job_district &&
              formik.errors.prefer_job_district &&
              " error"
            } ${!formik.values.prefer_job_state && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_job_district &&
            formik.errors.prefer_job_district && (
              <span className="error-text">
                {formik.errors.prefer_job_district}
              </span>
            )}
        </div>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Panchayat / Municipality
          </label>
          <Select
            id="prefer_job_location"
            isMulti
            placeholder={"Select"}
            ref={locationJobRef}
            options={
              !formik.values.prefer_job_location?.includes("all")
                ? optionsData?.data?.locationsList?.filter(
                    (p) =>
                      formik.values.prefer_job_district?.includes?.(
                        p.district_id?.toString()
                      ) || p.district_id === "all"
                  )
                : []
            }
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.location_name}
            value={optionsData?.data?.locationsList?.filter?.((c) =>
              formik.values.prefer_job_location?.includes?.(c?.id?.toString())
            )}
            onChange={(value) => {
              formik.setFieldValue(
                "prefer_job_location",
                value.map((a) => a.id?.toString())
              );
            }}
            onBlur={formik.handleBlur("prefer_job_location")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_job_location &&
              formik.errors.prefer_job_location &&
              " error"
            }  ${!formik.values.prefer_job_state && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_job_location &&
            formik.errors.prefer_job_location && (
              <span className="error-text">
                {formik.errors.prefer_job_location}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default JobLocationPreference;
