import React from "react";
import Assets from "../../../assets/Assets";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import AstrologyTableNew from "../../Global/AstrologyTable/AstrologyTableNew";

const ProfileData = ({
  index,
  item,
  religionValue,
  pdfVisibility,
  itemData,
}) => {
  const imageRef = useRef(null);
  const [wH, setwH] = useState({ w: 0, h: 0 });

  useEffect(() => {
    if (imageRef?.current) {
      const { naturalWidth, naturalHeight } = imageRef?.current;
      // console.log(imageRef?.current?.naturalWidth, naturalHeight);
      const widthOrHeight = naturalHeight > naturalWidth ? `hight` : `width`;
      const imageRatio =
        widthOrHeight === `hight`
          ? naturalHeight / naturalWidth
          : naturalWidth / naturalHeight;
      // const assignValue = widthOrHeight === `hight` ? naturalHeight : naturalWidth;
      const getOppositeValue = 260 / imageRatio;
      const retruValue =
        widthOrHeight === `hight`
          ? { h: 260, w: getOppositeValue }
          : { w: 260, h: getOppositeValue };
      // console.log(retruValue,"retruValue");
      setwH(retruValue);
    }
  }, [itemData, imageRef]);

  return (
    <>
      <table
        width="100%"
        key={index}
        id="my-website"
        style={{
          maxWidth: "850px",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
          border: "none",
          backgroundColor: "#5B0B31",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "0 0 0 0" }}>
              <table
                width="100%"
                style={{
                  border: "none",
                  backgroundColor: "white",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      height={0}
                      style={{
                        opacity: 0,
                        maxHeight: 0,
                        height: 0,
                        width: "100%",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        width="100%"
                        style={{
                          backgroundImage: `url(${Assets.bgPfd})`,
                          backgroundPosition: "center center",
                          backgroundSize: "100%",
                          backgroundRepeat: "no-repeat",
                          border: "none",
                          backgroundColor: "white",
                          height: 85,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src={Assets.bgLogo}
                                width={850}
                                height={85}
                                alt="Nest"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "20px 15px 0px 15px" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td
                              // colSpan={3}
                              style={{ padding: "0 10px 0 25px" }}
                              valign="top"
                            >
                              <table width="350px">
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={2}
                                      style={{
                                        paddingBottom: "8px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#131A24",
                                        fontSize: "22px",
                                        fontWeight: 700,
                                        lineHeight: "20px",
                                      }}
                                    >
                                      {item?.primary_details?.name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan={2}
                                      style={{
                                        paddingBottom: "18px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#131A24",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "9px",
                                      }}
                                    >
                                      {item?.primary_details?.register_id}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      rowSpan={2}
                                      width="30px"
                                      style={{
                                        borderTop: "1px solid #ECECEC",
                                        paddingTop: "18px",
                                      }}
                                    >
                                      <Image
                                        src={Assets.ageHeight}
                                        alt={"age height"}
                                        width={15}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        paddingBottom: "2px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#7B7B7B",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "16px",
                                        borderTop: "1px solid #ECECEC",
                                        paddingTop: "18px",
                                      }}
                                    >
                                      Age and height
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        paddingBottom: "8px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#131A24",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        lineHeight: "16px",
                                      }}
                                    >
                                      {item?.primary_details?.age},
                                      {item?.primary_details?.height}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td rowSpan={2} width="30px">
                                      <Image
                                        src={Assets.religionInfo}
                                        alt={"religion"}
                                        width={15}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        paddingBottom: "2px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#7B7B7B",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "16px",
                                      }}
                                    >
                                      Religion info
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        paddingBottom: "8px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#131A24",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        lineHeight: "16px",
                                      }}
                                    >
                                      {`${religionValue.religion}`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      rowSpan={2}
                                      width="30px"
                                      style={{
                                        borderBottom: "1px solid #ECECEC",
                                      }}
                                    >
                                      <Image
                                        src={Assets.location}
                                        alt={"location"}
                                        width={15}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        paddingBottom: "2px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#7B7B7B",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "16px",
                                      }}
                                    >
                                      District
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        paddingBottom: "18px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#131A24",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        lineHeight: "16px",
                                        borderBottom: "1px solid #ECECEC",
                                      }}
                                    >
                                      {" "}
                                      {item?.primary_details?.district}{" "}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td valign="top" style={{ paddingLeft: 20 }}>
                              <table
                                width="250px"
                                style={{
                                  width: 250,
                                  maxWidth: 250,
                                  height: 250,
                                  borderRadius: "8px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    {/* <td style={{
                                            width : 250,
                                            height : 250,
                                            overflow : "hidden",
                                            backgroundImage : `url('${
                                              item?.is_locked
                                                ? Assets.locked
                                                : item?.user_image[0]
                                                    ?.user_image ??
                                                  (item?.basic_details
                                                    ?.gender === "Female"
                                                    ? Assets.FemaleProfile
                                                    : Assets.MaleProfile)
                                            }')`,
                                            backgroundSize :"contain",
                                            backgroundRepeat : "no-repeat",
                                            backgroundPosition : "center center",
                                          }}>
                                            <img
                                              src={
                                                item?.is_locked
                                                  ? Assets.locked
                                                  : item?.user_image[0]
                                                      ?.user_image ??
                                                    (item?.basic_details
                                                      ?.gender === "Female"
                                                      ? Assets.FemaleProfile
                                                      : Assets.MaleProfile)
                                              }
                                              // src={Assets.testImg}
                                              alt="profile-pic"
                                              height={`300px`}
                                              width={"auto"}
                                              style={{
                                                maxHeight: 200,
                                                maxWidth: 300,
                                                objectFit: "contain",
                                                objectPosition: "center"
                                              }}
                                            />
                                          </td> */}
                                    <td>
                                      <table
                                        width={`260px`}
                                        height={`260px`}
                                        style={{
                                          width: `260px`,
                                          height: `260px`,
                                          position: `relative`,
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td style={{ width: `100%` }}>
                                              <img
                                                src={
                                                  item?.is_locked
                                                    ? Assets.locked
                                                    : item?.user_image[0]
                                                        ?.user_image ??
                                                      (item?.basic_details
                                                        ?.gender === "Female"
                                                        ? Assets.FemaleProfile
                                                        : Assets.MaleProfile)
                                                }
                                                // src={Assets.testImg}
                                                alt="profile-pic"
                                                // height={`300px`}
                                                ref={imageRef}
                                                // style={{
                                                //   maxHeight: `100%`,
                                                //   maxWidth: `100%`,
                                                //   objectFit: "contain",
                                                //   objectPosition: "center"
                                                // }}
                                                width={wH?.w}
                                                height={wH?.h}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0 28px" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 600,
                                lineHeight: "9px",
                                paddingTop: "16px",
                              }}
                              colSpan={6}
                            >
                              Professional info
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              width="130px"
                            >
                              Edu. Qualification
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.professional_info?.education}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Edu. Details
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.professional_info?.education_details}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Job
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.professional_info?.job}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Job Details
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {" "}
                              {item?.professional_info?.job_details}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 600,
                                lineHeight: "9px",
                                paddingTop: "16px",
                              }}
                              colSpan={6}
                            >
                              Basic Details
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Date of Birth
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.primary_details?.date_of_birth}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Gender / Status
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.basic_details?.gender +
                                "/" +
                                item?.basic_details?.marital_status}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Complexion
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.basic_details?.complexion}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Country / State
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.basic_details?.country +
                                "/" +
                                item?.basic_details?.state}
                            </td>
                          </tr>
                          {pdfVisibility?.phone ||
                          pdfVisibility?.route ||
                          pdfVisibility?.address ? (
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 600,
                                  lineHeight: "9px",
                                  paddingTop: "16px",
                                }}
                                colSpan={6}
                              >
                                Contact Details
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 600,
                                  lineHeight: "9px",
                                  paddingTop: "16px",
                                }}
                                colSpan={6}
                              ></td>
                            </tr>
                          )}

                          {pdfVisibility?.phone && (
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "9px",
                                }}
                              >
                                Phone
                              </td>
                              <td
                                width="25px"
                                style={{
                                  paddingLeft: "5px",
                                  fontSize: "11px",
                                }}
                                valign="top"
                              >
                                :
                              </td>
                              <td
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "9px",
                                }}
                                colSpan={4}
                              >
                                {item?.contact_details?.mobile +
                                  "," +
                                  item?.contact_details?.phone_no +
                                  "," +
                                  item?.contact_details?.whatsapp_no}
                              </td>
                            </tr>
                          )}

                          {pdfVisibility?.address && (
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "9px",
                                }}
                              >
                                Address
                              </td>
                              <td
                                width="25px"
                                style={{
                                  paddingLeft: "5px",
                                  fontSize: "11px",
                                }}
                                valign="top"
                              >
                                :
                              </td>
                              <td
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "9px",
                                }}
                                colSpan={4}
                              >
                                {item?.contact_details?.address}
                              </td>
                            </tr>
                          )}

                          {pdfVisibility?.route && (
                            <tr>
                              <td
                                valign="top"
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "9px",
                                }}
                              >
                                Route to home
                              </td>
                              <td
                                width="25px"
                                style={{
                                  paddingLeft: "5px",
                                  fontSize: "11px",
                                }}
                                valign="top"
                              >
                                :
                              </td>
                              <td
                                valign="top"
                                style={{
                                  paddingBottom: "8px",
                                  fontFamily: '"Plus Jakarta Sans", sans-serif',
                                  color: "#131A24",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "9px",
                                  height: "27px",
                                }}
                                colSpan={4}
                              >
                                {" "}
                                {item?.contact_details?.route_to_home}{" "}
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 600,
                                lineHeight: "9px",
                                paddingTop: "0px",
                              }}
                              colSpan={6}
                            >
                              More Details
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Father Name
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.more_details?.father_name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Father’s Job
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.more_details?.father_job}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Mother Name
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.more_details?.mother_name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Mother’s Job
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.more_details?.mother_job}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                            >
                              Siblings Info
                            </td>
                            <td
                              width="25px"
                              style={{
                                paddingLeft: "5px",
                                fontSize: "11px",
                              }}
                              valign="top"
                            >
                              :
                            </td>
                            <td
                              style={{
                                paddingBottom: "8px",
                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                color: "#131A24",
                                fontSize: "11px",
                                fontWeight: 500,
                                lineHeight: "9px",
                              }}
                              colSpan={4}
                            >
                              {item?.more_details?.siblings_info}
                            </td>
                          </tr>

                          <tr>
                            <td
                              colSpan={6}
                              style={{ padding: "0px 0 20px 0" }}
                              valign="top"
                            >
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    {pdfVisibility?.horoscope && (
                                      <td
                                        style={{
                                          paddingBottom: "8px",
                                          fontFamily:
                                            '"Plus Jakarta Sans", sans-serif',
                                          color: "#131A24",
                                          fontSize: "11px",
                                          fontWeight: 600,
                                          lineHeight: "9px",
                                          paddingTop: "16px",
                                        }}
                                        width="50%"
                                      >
                                        Horoscope Details
                                      </td>
                                    )}
                                    <td
                                      style={{
                                        paddingBottom: "8px",
                                        fontFamily:
                                          '"Plus Jakarta Sans", sans-serif',
                                        color: "#131A24",
                                        fontSize: "11px",
                                        fontWeight: 600,
                                        lineHeight: "9px",
                                        paddingTop: "16px",
                                      }}
                                      width="50%"
                                    >
                                      Other Details
                                    </td>
                                  </tr>
                                  <tr>
                                    {pdfVisibility?.horoscope && (
                                      <td valign="top">
                                        <table width="100%">
                                          <tbody>
                                            <tr>
                                              <td
                                                width="130px"
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                Star
                                              </td>
                                              <td
                                                width="25px"
                                                style={{
                                                  paddingLeft: "5px",
                                                  fontSize: "11px",
                                                }}
                                                valign="top"
                                              >
                                                :
                                              </td>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                {" "}
                                                {
                                                  item?.horoscope_details?.star
                                                }{" "}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                Date of birth (Mal)
                                              </td>
                                              <td
                                                width="25px"
                                                style={{
                                                  paddingLeft: "5px",
                                                  fontSize: "11px",
                                                }}
                                                valign="top"
                                              >
                                                :
                                              </td>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                {" "}
                                                {
                                                  item?.horoscope_details
                                                    ?.date_of_birth_mal
                                                }{" "}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                Time of birth
                                              </td>
                                              <td
                                                width="25px"
                                                style={{
                                                  paddingLeft: "5px",
                                                  fontSize: "11px",
                                                }}
                                                valign="top"
                                              >
                                                :
                                              </td>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                {
                                                  item?.horoscope_details
                                                    ?.time_of_birth
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                Janma sista dasa
                                              </td>
                                              <td
                                                width="25px"
                                                style={{
                                                  paddingLeft: "5px",
                                                  fontSize: "11px",
                                                }}
                                                valign="top"
                                              >
                                                :
                                              </td>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                {
                                                  item?.horoscope_details
                                                    ?.janma_sista_dasa
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                Janma sista dasa end
                                              </td>
                                              <td
                                                width="25px"
                                                style={{
                                                  paddingLeft: "5px",
                                                  fontSize: "11px",
                                                }}
                                                valign="top"
                                              >
                                                :
                                              </td>
                                              <td
                                                style={{
                                                  paddingBottom: "8px",
                                                  fontFamily:
                                                    '"Plus Jakarta Sans", sans-serif',
                                                  color: "#131A24",
                                                  fontSize: "11px",
                                                  fontWeight: 500,
                                                  lineHeight: "9px",
                                                }}
                                              >
                                                {
                                                  item?.horoscope_details
                                                    ?.janma_sista_dasa_end
                                                }
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    )}
                                    <td valign="top">
                                      <table width="100%">
                                        <tbody>
                                          <tr>
                                            <td
                                              width="120px"
                                              style={{
                                                paddingBottom: "8px",
                                                fontFamily:
                                                  '"Plus Jakarta Sans", sans-serif',
                                                color: "#131A24",
                                                fontSize: "11px",
                                                fontWeight: 500,
                                                lineHeight: "9px",
                                              }}
                                            >
                                              Staf Name
                                            </td>
                                            <td
                                              width="25px"
                                              style={{
                                                paddingLeft: "5px",
                                                fontSize: "11px",
                                              }}
                                              valign="top"
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                paddingBottom: "8px",
                                                fontFamily:
                                                  '"Plus Jakarta Sans", sans-serif',
                                                color: "#131A24",
                                                fontSize: "11px",
                                                fontWeight: 500,
                                                lineHeight: "9px",
                                              }}
                                            >
                                              {item?.other_details?.staff_name}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                paddingBottom: "8px",
                                                fontFamily:
                                                  '"Plus Jakarta Sans", sans-serif',
                                                color: "#131A24",
                                                fontSize: "11px",
                                                fontWeight: 500,
                                                lineHeight: "9px",
                                              }}
                                            >
                                              Staf Contact No
                                            </td>
                                            <td
                                              width="25px"
                                              style={{
                                                paddingLeft: "5px",
                                                fontSize: "11px",
                                              }}
                                              valign="top"
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                paddingBottom: "8px",
                                                fontFamily:
                                                  '"Plus Jakarta Sans", sans-serif',
                                                color: "#131A24",
                                                fontSize: "11px",
                                                fontWeight: 500,
                                                lineHeight: "9px",
                                              }}
                                            >
                                              {
                                                item?.other_details
                                                  ?.staff_mobile
                                              }
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                paddingBottom: "8px",
                                                fontFamily:
                                                  '"Plus Jakarta Sans", sans-serif',
                                                color: "#131A24",
                                                fontSize: "11px",
                                                fontWeight: 500,
                                                lineHeight: "9px",
                                              }}
                                            >
                                              Staf Branch
                                            </td>
                                            <td
                                              width="25px"
                                              style={{
                                                paddingLeft: "5px",
                                                fontSize: "11px",
                                              }}
                                              valign="top"
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                paddingBottom: "8px",
                                                fontFamily:
                                                  '"Plus Jakarta Sans", sans-serif',
                                                color: "#131A24",
                                                fontSize: "11px",
                                                fontWeight: 500,
                                                lineHeight: "9px",
                                              }}
                                            >
                                              {
                                                item?.other_details
                                                  ?.staff_branch
                                              }
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  {pdfVisibility?.horoscope && (
                                    <tr>
                                      <td style={{ paddingTop: "10px" }}>
                                        <table
                                          style={{
                                            maxWidth: "350px",
                                            width: "100%",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "20px",
                                                }}
                                              >
                                                <AstrologyTableNew
                                                  data={
                                                    item?.horoscope_graha?.data
                                                  }
                                                  horizontal
                                                  name={"Grahanila"}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td style={{ paddingTop: "10px" }}>
                                        <table
                                          style={{
                                            maxWidth: "350px",
                                            width: "100%",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <AstrologyTableNew
                                                  data={
                                                    item?.horoscope_navamshakam
                                                      ?.data
                                                  }
                                                  horizontal
                                                  name={"Navamshakam"}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
        {/* <div class="html2pdf__page-break"></div> */}
      </table>
    </>
  );
};

export default ProfileData;
