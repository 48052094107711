import { useEffect, useState } from "react";
import Style from "./callBox.module.scss";
import Select from "react-select";
import useCallBox from "./useCallBox";

const CallBox = ({
  options,
  isAgent,
  callIntiateStatus,
  setCallInitiateStatus,
}) => {
  const [activeNumber, setActiveNumber] = useState({ value: "", label: "" });
  const { handleCall } = useCallBox({ setCallInitiateStatus });
  useEffect(() => {
    options?.[0] && setActiveNumber(options[0]);
  }, [options]);
  return (
    <div className={Style.root}>
      {options && (
        <div className={"input-wrap"}>
          {/**#FIXME Same mobile numbers from backend */}
          <Select
            options={options}
            value={activeNumber}
            className={"pro-input lg pro-me-3"}
            classNamePrefix="pro-input"
            defaultValue={options?.[0]}
            onChange={(e) => setActiveNumber(e)}
          />
          <button
            onClick={() => {
              if (isAgent) {
                handleCall(activeNumber);
              } else {
                window.location.href = `tel:${activeNumber.value}`;
              }
            }}
            className={`pro-btn pro-btn-primary lg ${
              callIntiateStatus ? "loading" : ""
            }`}
          >
            <span className="material-symbols-outlined"> call </span>
            <span>Call Now</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CallBox;
