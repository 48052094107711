import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./HoroscopeModal.module.scss";

const HoroscopeModal = ({ closeHoroscopeModal, horoscopeImageData, downloadImage }) => {
  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={`pro-w-100 pro-mb-3 `}>
        <h4 className="pro-ttl h4 pro-mb-0">Horoscope Image</h4>
      </div>

      <div className="pro-w-100">
        <Image 
          src={horoscopeImageData?.value || "https://picsum.photos/200/300"}
          alt={"horoscopeImageData?.value"}
          width={100}
          height={100}
          propStyle={{
            root: Style.image
          }}
        />
        <div className={`pro-pt-5 pro-d-flex pro-justify-end`}>
        <button className="pro-btn pro-btn-link" onClick={closeHoroscopeModal}>Close</button>
        <button className="pro-btn pro-btn-primary pro-ms-3" onClick={downloadImage}>Download</button>
        </div>
      </div>
    </div>
  );
};

export default HoroscopeModal;
