import React from "react";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";

const FamilyInfo = ({
  formik,

  optionsData,
  handleSelectFatherJob,
  fatherJobRef,
  handleSelectMotherJob,
  motherJobRef,
}) => {
  return (
    <div className="row" id="family-info">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">Family Info</h6>
      </div>
       {/**
       * Father Name
       * Father's Job
       * Father - Main Job Category
       * Father - Sub Job Category
       * Mother Name
       * Mother's Job
       * Mother - Main Job Category
       * Mother - Sub Job Category
       * Parents Job Details
       */}
      <RowOne
        formik={formik}
       
        optionsData={optionsData}
        handleSelectFatherJob={handleSelectFatherJob}
          handleSelectMotherJob={handleSelectMotherJob}
          fatherJobRef={fatherJobRef}
          motherJobRef={motherJobRef}
      />

        {/**
       * Land Info
       *  Land Details
       * House SqFt
       * House Details
       * Dowry Details
       * Vehicle Info
       * Agreement At
       * Share Details
       * Family Income
       * Siblings Info
       */}
      <RowTwo
        formik={formik}
    
        optionsData={optionsData}

      />
     
    </div>
  );
};

export default FamilyInfo;
