import React from "react";

export const WebIdRow = ({ data, activeProfile, params }) => {
  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const newIds = ids.filter((id) => id !== data?.user_id).join(",");
  if (!data?.register_id) {
    return "";
  }

  return (
    <p
      className="pro-mb-0 pro-pnt"
      onClick={() =>
        window.open(
          `${window.location.origin}/${`profile?active=${data?.user_id}&id=${newIds}`}`
        )
      }
    >
      {data?.register_id}
    </p>
  );
};
