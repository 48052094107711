import { Table } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useDeleteHistory from "./useDeleteHistory";
import { FaSort } from "react-icons/fa";

const DeleteHistory = () => {
  const { deleteHistoryData, isFetching, getRow, handleSort } =
    useDeleteHistory();
  return (
    <>
      <div className={`col-auto pro-p-5 pro-pt-3`}>
        <Table
          data={deleteHistoryData?.data?.data || []}
          uniqueID={"id"}
          fields={deleteHistoryData?.data?.field ?? []}
          showCheckBox={false}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          deletable={false}
          multiSelect={false}
        />
      </div>
    </>
  );
};

export default DeleteHistory;
