import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
  Button,
} from "@wac-ui-dashboard/wac_component_library";
import useRegistrationReport from "./useRegistrationReport";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import ListingFilter from "../ListingFilter";
import EmptyData from "../../Global/EmptyData";

const RegistrationReport = () => {
  const {
    registrationReport,
    sortedTableFields,
    isFetching,
    optionsData,
    registrationReportState,
    currentPage,
    paginationOptions,
    showTableFieldsModal,
    initialDateRange,
    formik,
    handleSearch,
    handleExportClick,
    updateRegistrationReportFields,
    closeEditModal,
    refetch,
    handleSort,
    getRow,
    // handleTableEditClick,
    handleBranchFilter,
    // handleStaffFilter,
    // handleDistrictFilter,
    handleCurrentCountryFilter,
    handleCurrentStateFilter,
    handleCurrentDistrictFilter,
    handleCalenderTypeFilter,
    handleCurrentSubscriptionFilter,
    handlePlatformFilter,
    handleCurrentReligionFilter,
    handleCurrentCasteFilter,
    handleCurrentLookingFor,
    // handleClearFilters,
    handleEditFieldsClick,
    handlePagination,
    handlePageSize,
    handleDateChange,
  } = useRegistrationReport();

  return (
    <>
      <div className="pro-d-flex">
        <HeadingGroup title={"Registration Report"} className={`pro-mb-4`} />

        <div className={`col-auto pro-me-2`}>
          <button
            className="pro-btn pro-btn-outline lg"
            onClick={handleExportClick}
          >
            Export
          </button>
        </div>

        <div className={`col-auto pro-me-2`}>
          <button
            className="pro-btn pro-btn-outline lg"
            onClick={handleEditFieldsClick}
          >
            Edit Columns
          </button>
        </div>
        <Button
          type="submit"
          className={`pro-btn pro-btn-primary ${isFetching ? "loading" : ""}`}
          onClick={formik?.handleSubmit}
        >
          Search
        </Button>
      </div>

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <ListingFilter
          handleDateChange={handleDateChange}
          optionsData={optionsData}
          // handleStaffFilter={handleStaffFilter}
          handleBranchFilter={handleBranchFilter}
          handleCurrentSubscriptionFilter={handleCurrentSubscriptionFilter}
          handleCurrentReligionFilter={handleCurrentReligionFilter}
          handleCurrentCasteFilter={handleCurrentCasteFilter}
          // handleDistrictFilter={handleDistrictFilter}
          handleCalenderTypeFilter={handleCalenderTypeFilter}
          handleCurrentCountryFilter={handleCurrentCountryFilter}
          handleCurrentStateFilter={handleCurrentStateFilter}
          handleCurrentDistrictFilter={handleCurrentDistrictFilter}
          handleCurrentLookingFor={handleCurrentLookingFor}
          registrationReportState={registrationReportState}
          isFetching={isFetching}
          handlePlatformFilter={handlePlatformFilter}
          handleEditFieldsClick={handleEditFieldsClick}
          // handleClearFilters={handleClearFilters}
          handleExportClick={handleExportClick}
          initialDateRange={initialDateRange}
          handleSearch={handleSearch}
          formik={formik}
        />

        <div className="pro-mt-4 pro-scroll-lg">
          {!registrationReport?.data?.data ||
          registrationReport?.data?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={registrationReport?.data?.data || []}
              uniqueID={"id"}
              fields={sortedTableFields}
              showCheckBox={false}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={registrationReportState?.currentPageSize}
              // handleEdit={handleTableEditClick}
              assignable={false}
              editable={false}
              // deletable={false}
              multiSelect={false}
            />
          )}
        </div>

        {registrationReport?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              registrationReport?.data?.total_count /
                registrationReportState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={registrationReport?.data?.table_id}
              updateData={updateRegistrationReportFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default RegistrationReport;
