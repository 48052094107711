import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../store/slices/userAgreementSlice/userAgreementSlice";

const useUserAgreement = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = activeTab;
      })
    );
    // eslint-disable-next-line
  }, [activeTab]);

  const tabs = [
    {
      label: "Pending",
      link: `/dashboard/user-agreement?activeTab=pending`,
      isButton: false,
      active: activeTab === "pending",
    },
    {
      label: "Approved",
      link: `/dashboard/user-agreement?activeTab=approved`,
      isButton: false,
      active: activeTab === "approved",
    },
  ];
  return { tabs };
};

export default useUserAgreement;
