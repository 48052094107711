import {
  HeadingGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useRoles from "./useRoles";

const Roles = () => {
  const {
    roles,
    rolesState,
    isFetching,
    fields,
    paginationOptions,
    handleCreateClick,
    handleSort,
    handleEdit,
    handlePagination,
    handlePageSize,
    getRow,
  } = useRoles();

  return (
    <>
      <HeadingGroup
        title={"Role"}
        className={`pro-mb-4`}
        buttonTitle={"Create"}
        handleClick={handleCreateClick}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <Table
          data={roles?.data?.data || []}
          uniqueID={"id"}
          fields={fields}
          showCheckBox
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          handleEdit={handleEdit}
          loading={isFetching}
          perpage={rolesState?.currentPageSize}
          assignable={false}
          deletable={false}
          multiSelect={false}
        />
      </div>

      {roles?.data?.data?.length > 0 && (
        <Pagination
          currentPage={roles?.data?.current_page}
          totalPageCount={Math.ceil(
            roles?.data?.total_count / rolesState.currentPageSize
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
    </>
  );
};

export default Roles;
