import { Image } from "@wac-ui-dashboard/wac_component_library";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Style from "./ProfileCard.module.scss";
import useProfileCard from "./useProfileCard";
import ImageCrop from "./ImageCrop";
import DeleteModal from "./DeleteModal";

export const ProfileCard = ({ data, userId, handleClose, refetch }) => {
  const {
    errors,
    activeImageIndex,
    showCrop,
    crop,
    image,
    imgRef,
    showDeleteModal,
    profileUploadStatus,
    cropProfileUploadStatus,
    handleDeleteData,
    setCrop,
    handleThumbClick,
    handleCropClick,
    handleCropCancel,
    handlePrimary,
    handleUpload,
    handleNewImage,
    handleDeleteClick,
    closeDeleteModal,
    handleUploadCropPrimary,
  } = useProfileCard({
    data,
    userId,
    refetch,
    handleClose,
  });

  return (
    <div className={`${Style.root} pro-p-2`}>
      {!showCrop && !data?.[0]?.isDefault ? (
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <>
              <div className={Style.action_icon}>
                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={() => handleCropClick()}
                >
                  <span className="material-symbols-outlined">crop</span>
                </button>
                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={() => zoomIn()}
                >
                  <span className="material-symbols-outlined">zoom_in</span>
                </button>

                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={() => zoomOut()}
                >
                  <span className="material-symbols-outlined">zoom_out</span>
                </button>

                <button
                  className={`${Style.icon} pro-btn pro-p-0`}
                  onClick={() =>
                    handleDeleteClick(data?.[activeImageIndex]?.id)
                  }
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </div>
              <TransformComponent>
                <div className={`${Style.root_image} pro-mb-1`}>
                  <div className={Style.root_figure}>
                    <figure className={`${Style.image}`}>
                      <img
                        src={data?.[activeImageIndex]?.image ?? image}
                        alt={"profile"}
                        heigh={`100%`}
                        width={`100%`}
                      />
                    </figure>
                  </div>
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) : (
        <div className={`${Style.root_image} pro-mb-1`}>
          <div className={Style.root_figure}>
            <figure className={`${Style.image}`}>
              <ImageCrop
                ref={imgRef}
                image={data?.[activeImageIndex]?.image ?? image}
                crop={crop}
                setCrop={setCrop}
              />
            </figure>
          </div>
        </div>
      )}
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {!data?.[0]?.isDefault &&
            data?.map?.((profile, index) => (
              <div
                className={`col-2`}
                key={index}
                onClick={() => !showCrop && handleThumbClick(index)}
              >
                <div
                  className={`${Style.root_image_inner} ${
                    activeImageIndex === index ? Style.active : ""
                  } ${profile.isPrimary === 1 ? Style.checked : ""}`}
                >
                  <Image
                    width={100}
                    height={100}
                    src={
                      profile?.thumbnail_image
                        ? profile?.thumbnail_image
                        : profile?.image
                    }
                    alt={`image - 01`}
                  />
                </div>
              </div>
            ))}
          {image && (
            <div
              className={`col-2`}
              onClick={() => !showCrop && handleThumbClick(data.length)}
            >
              <div className={`${Style.root_image_inner} ${Style.active}`}>
                <Image
                  width={100}
                  height={100}
                  src={image}
                  alt={`image - 01`}
                />
              </div>
            </div>
          )}
          <div className={`col-2`}>
            <label
              htmlFor="add_image"
              className={`${Style.root_image_inner} ${Style.add_image} `}
            >
              <span className={Style.add_image_box}>
                <p>+</p>
                <input type="file" id="add_image" onChange={handleNewImage} />
              </span>
            </label>
          </div>
        </div>
      </div>
      <div
        className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-between  `}
      >
        <div className="pro-d-flex">
          {/* button to make the image cropped and upload */}

          <button
            // className={`pro-btn pro-btn-primary`}
            className={`pro-btn pro-btn-primary ${
              profileUploadStatus === false ? "loading" : ""
            }`}
            onClick={showCrop ? handleUpload : handlePrimary}
          >
            {showCrop ? "Crop & Upload" : "Set as primary"}
          </button>

          {/* button to make the image Cropped and make it as  primary image in same time */}

          {showCrop && (
            <button
              className={`pro-btn pro-btn-primary pro-ms-2 ${
                cropProfileUploadStatus === false ? "loading" : ""
              }`}
              onClick={handleUploadCropPrimary}
            >
              {"Crop & Set as Primary"}
            </button>
          )}
        </div>

        <button
          className={`pro-btn pro-btn-secondary`}
          onClick={showCrop ? handleCropCancel : handleClose}
        >
          {showCrop ? "Cancel" : "Close"}
        </button>
      </div>{" "}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDeleteData={handleDeleteData}
        errors={errors}
      />
    </div>
  );
};
