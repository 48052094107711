import React from "react";
import Style from "./PreLoader.module.scss";

const PreLoader = () => {
  return (
    <div className={Style.preloader}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default PreLoader;
