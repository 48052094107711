import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  updateFormData,
} from "../../../../store/slices/Registration/createProfileSlice";
import { useCreateProfileFamilyInfoMutation } from "../../../../store/queries/Registration";
import { useNavigate } from "react-router-dom";
import { profile } from "../../../../store/queries/Profile";

const useFamily = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [saveAndNext, setSaveAndNext] = useState(false);
  const fatherJobRef = useRef(" ");
  const motherJobRef = useRef(" ");
  const districtRef = useRef(" ");
  const stateRef = useRef(" ");

  const { currentFormDataFamily } = useSelector((state) => state.createProfile);

  const [registerProfile, { isLoading: isUpdating }] =
    useCreateProfileFamilyInfoMutation();

  const dispatch = useDispatch();

  let createProfileFamilyVal = Yup.object({
    father_name: Yup.string().required("*Required"),
    mother_name: Yup.string().required("*Required"),
    communication_address: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      user_id: activeUser,
      father_name: "",
      father_job: "",
      father_job_main_category: "",
      father_job_category_id: "",
      mother_name: "",
      mother_job: "",
      mother_job_main_category: "",
      mother_job_category_id: "",
      parent_job_description: "",
      land_info: "",
      land_details: "",
      house_sqft: "",
      house_details: "",
      dowry_info: "",
      vehicle_info: "",
      agreement_at: "",
      share_info: "",
      family_income: "",
      sibilings_info: "",
      communication_address: "",
      country: "1",
      state: "",
      district: "",
      job_location_address: "",
      latitude: "",
      longitude: "",
      residence_route: "",
      hide_address: true,
    },

    validationSchema: edit !== "true" ? createProfileFamilyVal : null,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values,
        };

        dispatch(
          updateFormData((state) => {
            state.currentFormDataFamily = {
              ...state.currentFormDataFamily,
              ...obj,
            };
            state.completedTabs = {
              ...state.completedTabs,
              Family: true,
              Religious: true,
            };
          })
        );

        if (formik.values.user_id) {
          registerProfile(obj).then((response) => {
            formik.setErrors("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              submitForm();
            } else if (response?.error?.data?.status_code === 422) {
              formik.setErrors(response?.error?.data?.errors);
            }
          });
        } else {
          setErrors("Fill required fields in all  Forms");
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = () => {
    dispatch(profile.util.invalidateTags(["Profile", "Overview"]));
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Religious";
      })
    );

    toast.success("Successfully updated");

    if (toTab && !saveAndNext) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (Object.keys(currentFormDataFamily).length === 0) {
      dispatch(
        getUserDetails({
          endpoint: "user-family-info",
          id: activeUser,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormDataFamily || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormDataFamily?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormDataFamily]);

  useEffect(() => {
    let totalFields = Object.values(formik.values).filter(
      (f) => f !== "" && f !== null
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Family: Math.ceil(
            (totalFields / Object.keys(formik.values).length) * 100
          ),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const handleSelectFatherJob = (value) => {
    fatherJobRef.current.setValue([], "clear");
    formik.setFieldValue("father_job_main_category", value?.id || null);
  };
  const handleSelectMotherJob = (value) => {
    motherJobRef.current.setValue([], "clear");
    formik.setFieldValue("mother_job_main_category", value?.id || null);
  };
  const handleSelectCountry = (value) => {
    stateRef.current.setValue([], "clear");
    districtRef.current.setValue([], "clear");
    formik.setFieldValue("country", value?.id || null);
  };
  const handleSelectState = (value) => {
    districtRef.current.setValue([], "clear");
    formik.setFieldValue("state", value?.id || null);
  };
  const handleCheckbox = (isChecked) => {
    formik.setFieldValue("hide_address", !isChecked);
  };

  return {
    errors,
    formik,
    isUpdating,
    fatherJobRef,
    currentFormDataFamily,
    motherJobRef,
    districtRef,
    stateRef,
    setSaveAndNext,
    handleSelectFatherJob,
    handleSelectMotherJob,
    handleCheckbox,
    handleSelectCountry,
    handleSelectState,
  };
};

export default useFamily;
