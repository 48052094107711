import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useCompleteFollowUpMutation,
  useGetNewTaskFormDataQuery,
} from "../../../../store/queries/Profile";

const useCompleteFollowUpForm = ({ userId, handleCancel }) => {
  const {
    data: optionsData = {},
    refetch,
    isSuccess: isOptionDataSuccess,
  } = useGetNewTaskFormDataQuery({ id: userId });
  const [completeFollowUp] = useCompleteFollowUpMutation();

  let followUpCompleteValidation = Yup.object({
    task_id: Yup.array().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      user_id: userId,
      task_id: [],
    },
    validationSchema: followUpCompleteValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    completeFollowUp(values)
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success(
            response?.message ?? "Follow Up Completed Successfully!"
          );
          handleCancel();
          refetch();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        }
      })
      .catch(() => {
        toast.error("Failed to Add Task!");
      });
  };
  return { formik, optionsData, isOptionDataSuccess };
};

export default useCompleteFollowUpForm;
