import axios from "axios";

const BASE_URL =
  process.env.REACT_APP_PRODUCTION === "TRUE"
    ? process.env.REACT_APP_PRODURL
    : process.env.REACT_APP_APIURL;

let token = "";

export const getAxiosInstance = async () => {
  try {
    token = localStorage.getItem("USER_ACCESS_TOKEN");
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.request.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    //checking whether the token is expired or not ,if expired it will navigates to login
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) {
          if (error?.response?.status === 401) {
            localStorage.removeItem("USER_ACCESS_TOKEN");
            localStorage.removeItem("pusherTransportTLS");
            localStorage.removeItem("LoggedStaffData");
            localStorage.removeItem("dashboard_permissions");
            localStorage.removeItem("profile_permissions");
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/login";
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
