import React from "react";
import Select from "react-select";

const RowOne = ({
  formik,
  optionsData,
  serviceRef,
  handleSeviceType,
  handleBranchChange,
  staffRef,
  loggedData,
}) => {
  return (
    <>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="service_type"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Service Type*
          </label>
          <Select
            id="service_type"
            placeholder={"Select"}
            isClearable={true}
            name="service_type"
            className={`pro-input lg ${
              formik.touched.service_type && formik.errors.service_type
                ? "error"
                : ""
            }`}
            classNamePrefix="pro-input"
            options={optionsData.serviceTypeList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.service_type}
            value={optionsData.serviceTypeList?.filter(
              (m) =>
                formik.values.service_type?.toString?.() === m.id?.toString?.()
            )}
            onBlur={formik.handleBlur("service_type")}
            onChange={(value) => {
              handleSeviceType(value);
            }}
          />
          {formik.touched.service_type && formik.errors.service_type && (
            <span className="error-text">{formik.errors.service_type}</span>
          )}
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="service"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Service
          </label>
          <Select
            id="service"
            placeholder={"Select"}
            ref={serviceRef}
            isClearable={true}
            isDisabled={formik.values.service_type ? false : true}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.serviceList?.filter?.(
              (c) =>
                c.parent_id?.toString?.() ===
                formik.values.service_type?.toString?.()
            )}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.service_title}
            value={optionsData.serviceList?.filter(
              (m) => formik.values.service?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) =>
              formik.setFieldValue("service", value?.id || null)
            }
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="branch"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Branch*
          </label>
          <Select
            id="branch"
            isMulti
            placeholder={"Select"}
            className={`pro-input lg ${
              formik.errors.branch && formik.touched.branch ? "error" : ""
            }`}
            isDisabled={loggedData?.data?.is_admin ? false : true}
            classNamePrefix="pro-input"
            options={optionsData.branchList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.branch_name}
            value={optionsData.branchList?.filter((m) =>
              formik.values.branch?.includes?.(m.id?.toString?.())
            )}
            onBlur={() => formik.handleBlur("branch")}
            onChange={(value) => {
              handleBranchChange(value);
            }}
          />
          {formik.errors.branch && formik.touched.branch && (
            <span className="error-text">{formik.errors.branch}</span>
          )}
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="staff_type"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Staff Type
          </label>
          <Select
            id="staff_type"
            placeholder={"Select"}
            isClearable={true}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.staff_types}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.user_type}
            value={optionsData.staff_types?.filter(
              (m) =>
                formik.values.staff_type?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("staff_type", value?.id || null);
              formik.setFieldValue("staff", "");
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label htmlFor="staff" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Staff
          </label>
          <Select
            id="staff"
            isClearable={true}
            placeholder={"Select"}
            ref={staffRef}
            isDisabled={
              formik.values.branch && formik.values.staff_type ? false : true
            }
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.staffList?.filter?.(
              (c) =>
                formik.values.branch?.includes?.(c.branches_id?.toString?.()) &&
                formik.values.staff_type?.toString() ===
                  c.user_types_id?.toString()
            )}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.staff_name}
            value={optionsData.staffList?.filter(
              (m) => formik.values.staff?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("staff", value?.id || null);
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="religion_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Religion
          </label>
          <Select
            id="religion_id"
            isClearable={true}
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.religionList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.religion_name}
            value={optionsData.religionList?.filter(
              (m) =>
                formik.values.religion_id?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("religion_id", value?.id || null);
              formik.setFieldValue("caste_id", []);
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="caste_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Caste
          </label>
          <Select
            id="caste_id"
            isClearable={true}
            placeholder={"Select"}
            isDisabled={formik.values.religion_id ? false : true}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.casteList?.filter?.(
              (c) =>
                c.religion_id?.toString?.() ===
                formik.values.religion_id?.toString?.()
            )}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.caste_name}
            value={optionsData.casteList?.filter(
              (m) => formik.values.caste_id?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("caste_id", value?.id || null);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RowOne;
