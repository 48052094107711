import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  globalSearchStatus: "idle",
  globalSearchData: {},
  fullSearchStatus: "idle",
  fullSearchData: {},
  regsitrationSearchStatus: "idle",
  registrationSearchData: {},
  global_search: { searchKey: "" },
  full_search: { searchKey: "" },
  reg_search: { searchKey: "" },
};

export const getGlobalSearch = createAsyncThunk(
  "globalSearch/getGlobalSearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.post(`search/global-search`, {
        ...params,
        per_page: 20,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFullSearch = createAsyncThunk(
  "globalSearch/getFullSearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.post(`search/full-search`, {
        ...params,
        per_page: 20,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRegistrationSearch = createAsyncThunk(
  "globalSearch/getRegistrationsSearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.post(`search/register-number-search`, {
        ...params,
        per_page: 20,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalSearch.pending, (state) => {
      state.globalSearchStatus = "pending";
    });
    builder.addCase(getGlobalSearch.fulfilled, (state, action) => {
      state.globalSearchStatus = "fulfilled";
      if (state.globalSearchData?.data) {
        state.globalSearchData = {
          ...action.payload.data.data,
          data: [
            ...state.globalSearchData?.data,
            ...action.payload.data.data.data,
          ],
        };
      } else {
        state.globalSearchData = action.payload.data.data;
      }
    });
    builder.addCase(getGlobalSearch.rejected, (state) => {
      state.globalSearchStatus = "rejected";
    });

    builder.addCase(getFullSearch.pending, (state) => {
      state.globalSearchStatus = "pending";
    });
    builder.addCase(getFullSearch.fulfilled, (state, action) => {
      state.globalSearchStatus = "fulfilled";
      if (state.globalSearchData?.data) {
        state.globalSearchData = {
          ...action.payload.data.data,
          data: [
            ...state.globalSearchData?.data,
            ...action.payload.data.data.data,
          ],
        };
      } else {
        state.globalSearchData = action.payload.data.data;
      }
    });
    builder.addCase(getFullSearch.rejected, (state) => {
      state.globalSearchStatus = "rejected";
    });

    builder.addCase(getRegistrationSearch.pending, (state) => {
      state.globalSearchStatus = "pending";
    });
    builder.addCase(getRegistrationSearch.fulfilled, (state, action) => {
      state.globalSearchStatus = "fulfilled";
      if (state.globalSearchData?.data) {
        state.globalSearchData = {
          ...action.payload.data.data,
          data: [
            ...state.globalSearchData?.data,
            ...action.payload.data.data.data,
          ],
        };
      } else {
        state.globalSearchData = action.payload.data.data;
      }
    });
    builder.addCase(getRegistrationSearch.rejected, (state) => {
      state.globalSearchStatus = "rejected";
    });
  },
});

export const { updateConfig } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
