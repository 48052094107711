import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api[method](`${endpoint}`, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const socialMediaReport = createApi({
  reducerPath: "socialMediaReportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["SocialMedia-Report"],
  endpoints: (builder) => ({
    getSocialMediaReport: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `social-report/social-media-report-list`,
      }),
      providesTags: ["SocialMedia-Report"],
    }),

    saveSocialMediaReport: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/social-report/social-media-report`,
      }),
      // invalidatesTags: ["SocialMedia-Report"],
    }),

    updateSocialMediaReport: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/social-report/social-media-report-update`,
      }),
      invalidatesTags: ["SocialMedia-Report"],
    }),
    updateSocialMediaFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),
      invalidatesTags: ["SocialMedia-Report"],
    }),
  }),
});

export const {
  useSaveSocialMediaReportMutation,
  useGetSocialMediaReportQuery,
  useUpdateSocialMediaReportMutation,
  useUpdateSocialMediaFieldsMutation,
} = socialMediaReport;
