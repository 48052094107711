import { getAxiosInstance } from "../../../../api";

export const exportActivitySearch = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `search/activity-search-excel
    `,
      body,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
