import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getGlobalSearch,
  getFullSearch,
  getRegistrationSearch,
  updateConfig,
} from "../../../store/slices/Search/GlobalSearch";
import {
  staff,
  useGetLoggedInStaffProfileDataQuery,
} from "../../../store/queries/Staff";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";

import { profile } from "../../../store/queries/Profile";
import { schedules } from "../../../store/queries/Schedule";
import { search } from "../../../store/queries/Search";
import { report } from "../../../store/queries/Report";
import { reportStatus } from "../../../store/queries/ReportStatus";
import { registration } from "../../../store/queries/Registration";
import { dashboard } from "../../../store/queries/Dashboard";
import { service } from "../../../store/queries/Service";
import { useState } from "react";
import { updateProfileConfig } from "../../../store/slices/Profile";
// import { useEffect, useState } from "react";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.globalSearch);
  const globalState = useSelector((state) => state.global);
  const [globalSearchValue, setGlobalSearchValue] = useState("");
  const [fullSearchValue, setFullSearchValue] = useState("");
  const [regSearchValue, setRegSearchValue] = useState("");
  // const [searchedKey, setSearchedKey] = useState();
  const { data: loggedStaffData } = useGetLoggedInStaffProfileDataQuery();
  const IsAgent = localStorage.getItem("Is_Agent");
  // useEffect(() => {
  //   if (state.globalSearchStatus === "fulfilled") {
  //     setSearchedKey(state.global_search.searchKey);
  //   }
  //   // eslint-disable-next-line
  // }, [state.globalSearchStatus]);

  const handleLogout = () => {
    dispatch(profile.util.resetApiState());
    dispatch(schedules.util.resetApiState());
    dispatch(service.util.resetApiState());
    dispatch(staff.util.resetApiState());
    dispatch(search.util.resetApiState());
    dispatch(report.util.resetApiState());
    dispatch(reportStatus.util.resetApiState());
    dispatch(registration.util.resetApiState());
    dispatch(dashboard.util.resetApiState());
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");
    localStorage.removeItem("profile_permissions");
    localStorage.removeItem("LoggedStaffId");
    localStorage.clear();
    sessionStorage.clear();

    navigate("/");
  };

  const handleWebIdChange = (search) => {
    setGlobalSearchValue(search);
    dispatch(
      updateConfig((state) => {
        state.global_search.searchKey = search;
      })
    );
  };

  const handleFullSearchChange = (search) => {
    setFullSearchValue(search);
    dispatch(
      updateConfig((state) => {
        state.full_search.searchKey = search;
      })
    );
  };
  const handleRegSearchChange = (search) => {
    setRegSearchValue(search);
    dispatch(
      updateConfig((state) => {
        state.reg_search.searchKey = search;
      })
    );
  };

  const handleRegSearchClick = () => {
    setGlobalSearchValue("");
    setFullSearchValue("");
    dispatch(
      updateConfig((state) => {
        state.global_search.searchKey = "";
        state.full_search.searchKey = "";
      })
    );
  };

  const handleFullSearchClick = () => {
    setGlobalSearchValue("");
    setRegSearchValue("");
    dispatch(
      updateConfig((state) => {
        state.global_search.searchKey = "";
        state.reg_search.searchKey = "";
      })
    );
  };

  const handleSearchClick = () => {
    setFullSearchValue("");
    setRegSearchValue("");
    dispatch(
      updateConfig((state) => {
        state.full_search.searchKey = "";
        state.reg_search.searchKey = "";
      })
    );
  };

  const handleSearch = (key) => {
    if (
      key === "Enter" ||
      key === "NumpadEnter"
      //  || state.global_search.searchKey !== searchedKey
    ) {
      dispatch(
        updateConfig((state) => {
          state.globalSearchStatus = "idle";
          state.globalSearchData = {};
        })
      );
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
      dispatch(
        getGlobalSearch({ web_id: state.global_search.searchKey, page: 1 })
      );
      navigate("/dashboard/global-search");
    }
  };

  const handleFullSearch = (key) => {
    if (
      key === "Enter" ||
      key === "NumpadEnter"
      //  || state.global_search.searchKey !== searchedKey
    ) {
      dispatch(
        updateConfig((state) => {
          state.globalSearchStatus = "idle";
          state.globalSearchData = {};
        })
      );
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
      dispatch(getFullSearch({ web_id: state.full_search.searchKey, page: 1 }));
      navigate("/dashboard/global-search");
    }
  };

  const handleRegSearch = (key) => {
    if (
      key === "Enter" ||
      key === "NumpadEnter"
      //  || state.global_search.searchKey !== searchedKey
    ) {
      dispatch(
        updateConfig((state) => {
          state.globalSearchStatus = "idle";
          state.globalSearchData = {};
        })
      );
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
      dispatch(
        getRegistrationSearch({
          register_no: state.reg_search.searchKey,
          page: 1,
        })
      );
      navigate("/dashboard/global-search");
    }
  };

  const closeCallModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.call_active = false;
        state.call_data = {};
      })
    );
  };

  return {
    handleLogout,
    handleWebIdChange,
    handleSearch,
    handleFullSearch,
    handleRegSearch,
    handleFullSearchChange,
    handleRegSearchChange,
    handleRegSearchClick,
    handleFullSearchClick,
    handleSearchClick,
    closeCallModal,
    IsAgent,
    globalState,
    loggedStaffData,
    fullSearchValue,
    globalSearchValue,
    regSearchValue,
  };
};

export default useHeaderActions;
