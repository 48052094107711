import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("sort_by")}&sort=${
          params?.sort_order || "desc"
        }&per_page=${params?.page_size || "10"}&per_page=${
          params?.per_page || "10"
        }&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const staff = createApi({
  reducerPath: "staffApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Staff",
    "StaffFormData",
    "Roles",
    "JobAssignFilters",
    "StaffLoggedIn",
    "StaffUpdateForm",
  ],
  endpoints: (builder) => ({
    getStaffListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `staff/staffs`,
      }),
      providesTags: ["Staff"],
    }),

    updateStaffsListFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["Staff"],
    }),
    getStaffCreationFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `staff/staff-form`,
      }),
      providesTags: ["StaffFormData"],
    }),
    createStaff: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/staff/staff-create`,
      }),

      invalidatesTags: ["Staff"],
    }),
    getjobAssignFilters: builder.query({
      query: (body) => ({
        method: "get",
        body,
        endpoint: `job/assign-job-form`,
      }),

      providesTags: ["JobAssignFilters"],
    }),

    deactivateStaff: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/staff/staff-deactivate`,
      }),

      invalidatesTags: ["ReportStatus"],
    }),
    getStaffUpdationFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `staff/staff-details/${values.id}`,
      }),
      providesTags: ["StaffUpdateForm"],
    }),
    updateStaff: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/staff/staff-update`,
      }),

      invalidatesTags: ["Staff", "StaffUpdateForm"],
    }),
    getAllRoles: builder.query({
      query: (params) => ({
        method: "get",
        params,
        endpoint: `permissions/user-types-list`,
      }),

      invalidatesTags: ["Roles"],
    }),

    getLoggedInStaffProfileData: builder.query({
      query: (params) => ({
        method: "get",
        params,
        endpoint: `staff/my-profile`,
      }),
      providesTags: ["StaffLoggedIn"],
    }),

    filterData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/staff/staff-filter`,
      }),
      providesTags: ["StaffFilter"],
    }),
  }),
});

export const {
  useGetStaffListDataQuery,
  useUpdateStaffsListFieldsMutation,
  useCreateStaffMutation,
  useGetStaffCreationFormDataQuery,
  useGetjobAssignFiltersQuery,
  useDeactivateStaffMutation,
  useGetStaffUpdationFormDataQuery,
  useUpdateStaffMutation,
  useGetAllRolesQuery,
  useGetLoggedInStaffProfileDataQuery,
  useFilterDataQuery,
} = staff;
