import {
  SearchFilters,
  // ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useViewService from "./useViewService";
import ViewServiceList from "./ViewServiceList";
import ViewServiceListFilters from "./ViewServiceListFilters";
import style from "./viewService.module.scss";
// import AddedServiceExistList from "../AddService/AddedServiceExistList";

const ViewService = () => {
  const {
    serviceList,
    isFetching,
    currentPage,
    paginationOptions,
    filters,
    activeFilter,
    // addedServiceExistState,
    // closeModal,
    searchValue,
    optionsData,
    viewService,
    handleServiceChange,
    handleServiceSearchChange,
    getRow,
    handleChangeTab,
    handleDateChangeDateOn,
    handleDateChangeFollowUp,
    handleSort,
    handlePagination,
    handlePageSize,
    handleAddService,
  } = useViewService();
  return (
    <div className={`pro-m-5 font-inc ${style.root}`}>
      <SearchFilters
        data={filters}
        searchable={false}
        showActions={false}
        activeFilter={activeFilter}
        handleButtonGroupChange={handleChangeTab}
      />

      <ViewServiceListFilters
        handleDateChangeDateOn={handleDateChangeDateOn}
        handleDateChangeFollowUp={handleDateChangeFollowUp}
        handleAddService={handleAddService}
        handleServiceSearchChange={handleServiceSearchChange}
        handleServiceChange={handleServiceChange}
        searchValue={searchValue}
        optionsData={optionsData}
        viewService={viewService}
      />

      <ViewServiceList
        list={serviceList}
        isFetching={isFetching}
        currentPage={currentPage}
        paginationOptions={paginationOptions}
        getRow={getRow}
        handleSort={handleSort}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
      />

      {/* <ModalLayout
        show={addedServiceExistState.showAddedServiceModal}
        handleClose={closeModal}
        propStyle={{ root: style.root_modal }}
      >
        <AddedServiceExistList />
        
      </ModalLayout> */}
    </div>
  );
};

export default ViewService;
