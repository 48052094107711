import Style from "./astrologyTable.module.scss";

const AstrologyTableNew = ({
  data,
  name,
  horizontal,
  extraClassName,
  isRegistration = false,
}) => {
  
  return (
    <div className={`${Style.root} ${extraClassName || ""}`}>
      <table>
        <tbody>
          <tr>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{12}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[12]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{1}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[0]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{2}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[1]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{3}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[2]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{11}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[11]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td rowSpan={2} colSpan={2}>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {" "}
                {name}
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{4}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[3]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{10}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[10]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{5}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[4]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{9}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[9]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{8}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[8]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{7}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[7]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
            <td>
              <span
                className={`pro-fw-medium pro-mb-0 ${
                  horizontal ? "pro-d-flex pro-justify-center" : ""
                }`}
              >
                {isRegistration ? (
                  <span className={Style.number}>{6}</span>
                ) : (
                  <></>
                )}
                <p className={`pro-mb-0`}>{`${data?.[6]
                  .toString()
                  .replace(/,/g, " ")}`}</p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AstrologyTableNew;
