import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import useDeleteProfileForm from "./useDeleteProfileForm";
import style from "./deleteProfileForm.scss";
import Select from "react-select";

const DeleteProfileForm = ({
  handleCancel,
  userId,
  isSuccess,
  ProfileName,
}) => {
  const { formik, isOptionDataSuccess, deleteOptionsData } =
    useDeleteProfileForm({
      handleCancel,
      userId,
      ProfileName,
    });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Delete Profile</h4>
      </div>

      {isSuccess && isOptionDataSuccess ? (
        <>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="reason_id"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Delete Reason
              </label>
              <Select
                id="reason_id"
                isClearable={true}
                placeholder={"Select"}
                options={deleteOptionsData.data}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.reason}
                value={deleteOptionsData.data?.filter(
                  (m) =>
                    formik.values.reason_id?.toString?.() === m.id?.toString?.()
                )}
                onChange={(value) =>
                  formik.setFieldValue("reason_id", value?.id || null)
                }
                className={"pro-input lg"}
                classNamePrefix="pro-input"
              />
            </div>
          </div>
          <Input
            label={"Reason"}
            type="text"
            id="reason"
            name="reason"
            className={`pro-input lg ${
              formik.touched.reason && formik.errors.reason && " error"
            }`}
            {...formik.getFieldProps("reason")}
            error={formik.touched.reason && formik.errors.reason}
            errorMessage={formik.errors.reason}
          />

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(1)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default DeleteProfileForm;
