import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useSelectionRequestList from "./useSelectionRequestList";

const SelectionRequestList = ({activeProfile, params}) => {
  const {
    queryData,
    dashboardState,
    filters,
    currentPage,
    activeFilter,
    paginationOptions,
    isFetching,
    isLoading,
    errors,
    showTableEditModal,
    showTableFieldsModal,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleSort,
    handleEditClick,
    closeEditModal,
    getRow,
    updateTableFields,
    handleTableEditClick,
    closeTableEditModal,
    handleUpdateStatus,
  } = useSelectionRequestList({activeProfile, params});

  return (
    <>
      <HeadingGroup title={"Selection Request"} extraClassName={`pro-mb-3`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={filters}
          activeFilter={activeFilter}
          handleButtonGroupChange={handleFilter}
          loading={isLoading}
          showActions={true}
          handleActionClick={handleEditClick}
          searchable={false}
        />

        <Table
          data={queryData?.data?.data || []}
          uniqueID={"id"}
          fields={sortedTableFields}
          showCheckBox
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={dashboardState.currentPageSize}
          assignable={false}
          deletable={false}
          handleEdit={handleTableEditClick}
        />
        {queryData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              queryData?.data?.total_count / dashboardState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          centered
          show={showTableEditModal}
          handleClose={closeTableEditModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to change status?`}
              isRight={true}
              cancelText={`Cancel`}
              submitText={`Update`}
              cancelAction={closeTableEditModal}
              submitAction={handleUpdateStatus}
            >
              ConfirmationBox
            </ConfirmationBox>

            {errors && <span className="error-message">{errors}</span>}
          </div>
        </ModalLayout>

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={queryData?.data?.table_id}
              updateData={updateTableFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default SelectionRequestList;
