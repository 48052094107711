import { useFormik } from "formik";
// import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUtmReportMutation } from "../../../store/queries/UtmReport";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/UtmReport/utmReportSlice";

const useUtmReportForm = ({ handleClose, refetch }) => {
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => state.utmReports);
  const [updateUtmReport] = useUpdateUtmReportMutation();
  const formik = useFormik({
    initialValues: {
      others:
        selectedData?.others !== ""
          ? selectedData?.others === "1" || selectedData?.others === "No"
            ? 0
            : 1
          : "",
      existed:
        selectedData?.existed !== ""
          ? selectedData?.existed === "1" || selectedData?.existed === "No"
            ? 0
            : 1
          : "",
      other_state:
        selectedData?.other_state !== ""
          ? selectedData?.other_state === "1" ||
            selectedData?.other_state === "No"
            ? 0
            : 1
          : "",
    },
    // validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const obj = {
        others: values?.others ? 2 : 1,
        existed: values?.existed ? 2 : 1,
        other_state: values?.other_state ? 2 : 1,
        user_id: selectedData?.user_id,
      };

      updateUtmReport(obj).then((res) => {
        if (res?.data?.status_code === 200) {
          handleClose();
          refetch();
          toast.success("Successfully Updated");
          dispatch(
            updateConfig((state) => {
              state.selectedData = [];
              state.clearSelection = true;
            })
          );
        } else {
          toast.error("Failed to update the report");
          handleClose();
          dispatch(
            updateConfig((state) => {
              state.selectedData = [];
              state.clearSelection = true;
            })
          );
        }
      });
    },
  });

  return { formik };
};

export default useUtmReportForm;
