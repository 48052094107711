import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBlockUsers from "./useBlockUsers";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../Global/OrderColumn";

const BlockUsers = () => {
  const {
    blockedUsersData,
    blockUsersState,
    isLoading,
    isFetching,
    paginationOptions,
    showTableFieldsModal,
    sortedTableFields,
    initialDateRange,
    handleDateChange,
    updateBlockedFields,
    refetch,
    closeEditModal,
    handleEditFieldsClick,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  } = useBlockUsers();
  return (
    <>
      <div className="pro-mt-2">
        <HeadingGroup title={"Block Users"} extraClassName={`pro-mb-2`} />

        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                blockUsersState?.searchKey === "" ? false : true
              }
              showActions={true}
              handleActionClick={handleEditFieldsClick}
              handleClear={handleClearClick}
              searchInputProps={{ value: blockUsersState?.searchKey }}
              //date picker props
              showDateRange={true}
              initialDateRange={initialDateRange}
              onDateChange={handleDateChange}
            />
          </div>
        </div>

        <div className={`col-auto pro-pb-6`}>
          <Table
            data={blockedUsersData?.data?.data || []}
            uniqueID={"id"}
            fields={sortedTableFields}
            showCheckBox={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={blockUsersState?.currentPageSize}
            // handleEdit={handleTableEditClick}
            assignable={false}
            deletable={false}
            multiSelect={false}
          />
        </div>
        <div>
          {blockedUsersData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={blockUsersState.currentPage}
              totalPageCount={Math.ceil(
                blockedUsersData?.data?.total_count /
                  blockUsersState?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={blockedUsersData?.data?.table_id}
              updateData={updateBlockedFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default BlockUsers;
