import { Button } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import Select from "react-select";
import useCompleteFollowUpForm from "./useCompleteFollowUpForm";
import style from "./completeFollowUpForm.scss";

const CompleteFollowUpForm = ({
  handleCancel,
  userId,
  profileId,
  isSuccess,
}) => {
  const { formik, optionsData, isOptionDataSuccess } = useCompleteFollowUpForm({
    handleCancel,
    userId,
  });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        {/* <h4 className="pro-ttl h4 pro-mb-2">Scheduled Job Completed</h4> */}
        <h6 className="pro-ttl  pro-mb-5">{`Profile Id : ${profileId}`}</h6>
      </div>
      {isSuccess && isOptionDataSuccess ? (
        <>
          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="task_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Task
            </label>
            <Select
              id="task_id"
              isMulti
              placeholder={"Select"}
              isClearable={true}
              className={`pro-input lg ${
                formik.errors.task_id && formik.touched.task_id ? "error" : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData?.data?.followUpList}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.description}
              onBlur={() => formik.handleBlur("task_id")}
              value={optionsData?.data?.followUpList?.filter((m) =>
                formik.values.task_id.includes(m.id?.toString?.())
              )}
              onChange={(value) =>
                formik.setFieldValue(
                  "task_id",
                  value.map((v) => v.id?.toString?.())
                )
              }
            />
            {formik.errors.task_id && formik.touched.task_id && (
              <span className="error-text">{formik.errors.task_id}</span>
            )}
          </div>

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(1)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default CompleteFollowUpForm;
