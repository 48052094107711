import HeaderLogo from "./images/Header/logo.svg";
import User from "./images/Header/avatar.png";
import MaleProfile from "./images/male.png";
import FemaleProfile from "./images/female.png";
import Nodata from "./images/no_data.png";
import bgLogo from "./images/bg_logo.svg";
import bgPfd from "./images/bg_pdf.png";
import locked from "./images/locked.png";
import avatar from "./images/Header/avatar.png";
import ageHeight from "./images/icon-age-and-height.svg";
import religionInfo from "./images/icon-religion-info.svg";
import location from "./images/icon-location.svg";
import MatchBadge from "./images/Matched.svg";
import PdfLogo from "./images/pdfLogo.png";
import UnknownCall from "./images/unknownCall.png";
import NoDocument from "./images/error-file.png";

const Assets = {
  HeaderLogo,
  User,
  MaleProfile,
  FemaleProfile,
  Nodata,
  bgLogo,
  bgPfd,
  locked,
  avatar,
  ageHeight,
  religionInfo,
  location,
  MatchBadge,
  PdfLogo,
  UnknownCall,
  NoDocument,
};

export default Assets;
