import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useGetAddedServiceExistListQuery } from "../../../../store/queries/Profile";
import { ClientWebIdRow, UserWebIdRow } from "./Rows";
import { updateConfig } from "../../../../store/slices/Profile/AddedServiceExist";
import { updateProfileConfig } from "../../../../store/slices/Profile";
import html2pdf from "html2pdf.js";
import moment from "moment";

const useAddedServiceExistList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [params] = useSearchParams();
  const tableRef = useRef();
  const [pdf2Loading, setPdf2Loading] = useState(false);
  const profileState = useSelector((state) => state.profile);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const addedServiceExistState = useSelector(
    (state) => state.addedServiceExist
  );

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: list = {},
    isFetching,
    refetch,
  } = useGetAddedServiceExistListQuery({
    per_page: addedServiceExistState.currentPageSize,
    page: addedServiceExistState.currentPage,
    client_web_id:
      addedServiceExistState.client_web_id ?? profileState?.client_web_id,
    partner_client_web_id:
      addedServiceExistState.partner_client_web_id ??
      profileState?.partner_client_web_id,
  });

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      user_web_id: (_, data) => (
        <UserWebIdRow
          data={data}
          activeProfile={activeProfile}
          params={params}
        />
      ),
      client_web_id: (_, data) => (
        <ClientWebIdRow
          data={data}
          activeProfile={activeProfile}
          params={params}
        />
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleIgnore = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddedServiceModal = false;
      })
    );
    if (location?.pathname?.slice(9) !== "add-service") {
      dispatch(
        updateProfileConfig((state) => {
          state.showCreateModal = true;
        })
      );
    }
  };

  const handleDownloadPdf = async () => {
    setPdf2Loading(true);
    const element = tableRef.current;
    const pdfPromise = new Promise((resolve) => {
      html2pdf(element, {
        margin: [5, 5],
        filename: `"Existing Service_${moment(Date.now()).format(
          "_MM_DD_YYYY_HH_mm_ss"
        )}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          width: window?.width,
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 3,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      }).then(() => {
        // Resolve the Promise when the pdf is generated
        resolve();
      });
    });

    // Wait for the Promise to resolve before showing "Download finished" toast
    await pdfPromise;
    setPdf2Loading(false);
  };

  return {
    list,
    isFetching,
    currentPage: addedServiceExistState?.currentPage,
    paginationOptions,
    tableRef,
    pdf2Loading,
    handleDownloadPdf,
    getRow,
    handlePagination,
    handlePageSize,
    handleIgnore,
    refetch,
  };
};
export default useAddedServiceExistList;
