import { combineReducers, configureStore } from "@reduxjs/toolkit";

import profileSlice from "./slices/Profile";
import autoMatchSlice from "./slices/Profile/AutoMatch";
import createScheduleSlice from "./slices/Schedules/createScheduleSlice";
import scheduleSlice from "./slices/Schedules/scheduleSlice";
import staffSlice from "./slices/Staff/staffSlice";
import roleSlice from "./slices/Staff/roleSlice";
import autoStarMatchSlice from "./slices/Profile/AutoStarMatch";
import PartnerMatchSlice from "./slices/Profile/PartnerMatch";
import registrationSlice from "./slices/Registration/registrationSlice";
import createProfileSlice from "./slices/Registration/createProfileSlice";
import advancedSearchSlice from "./slices/Search/AdvancedSearch";
import AddServiceSlice from "./slices/Profile/AddService";
import reportSlice from "./slices/Report/reportSlice";
import reportStatusSlice from "./slices/Report/reportStatusSlice";
import profileSearchSlice from "./slices/Search/ProfileSearch";
import ActivitySearch from "./slices/Search/ActivitySearch";
import serviceSearchSlice from "./slices/Search/ServiceSearch";
import activitySlice from "./slices/Profile/Activity";
import scheduledHistorySlice from "./slices/Profile/ScheduledHistory";
import viewServiceSlice from "./slices/Profile/ViewService";
import myServiceSlice from "./slices/Service/MyService";
import orderColumnSlice from "./slices/OrderColumns/orderColumnSlice";
import dashboardSlice from "./slices/Dashboard/dashboardSlice";
import globalSlice from "./slices/Global";
import { report } from "./queries/Report";
import { service } from "./queries/Service";
import { reportStatus } from "./queries/ReportStatus";
import { dashboard } from "./queries/Dashboard";
import { profile } from "./queries/Profile";
import { search } from "./queries/Search";
import { schedules } from "./queries/Schedule";
import { staff } from "./queries/Staff";
import { registration } from "./queries/Registration";
import globalSearchSlice from "./slices/Search/GlobalSearch";
import ServiceNotification from "./slices/Service/ServiceNotification";
import ServiceStatusAlert from "./slices/Service/ServiceStatusAlert";
import AddedServiceExist from "./slices/Profile/AddedServiceExist";
import ServiceChat from "./slices/Service/ServiceChat";
import registrationReportSlice from "./slices/registrationReport/registrationReportSlice";
import { registrationReport } from "./queries/RegistrationReports";
import registrationTypeReportSlice from "./slices/registrationTypeReport/registrationTypeReportSlice.js";
import { registrationTypeReport } from "./queries/RegistrationTypeReports";
import ServiceFollowUpSlice from "./slices/Dashboard/ServiceFollowUpSlice.js";
import missedCallSlice from "./slices/CallHistory/missedCallSlice.js";
import incomingCallSlice from "./slices/CallHistory/incomingCallSlice.js";
import outgoingCallSlice from "./slices/CallHistory/outgoingCallSlice.js";
import { callHistory } from "./queries/CallHistory/index.js";
import socialMediaReportSlice from "./slices/socialMediaReport/socialMediaReportSlice.js";
import { socialMediaReport } from "./queries/SocialMediaReport/index.js";
import utmReportSlice from "./slices/UtmReport/utmReportSlice.js";
import { utmReport } from "./queries/UtmReport/index.js";
import blockUsersSlice from "./slices/BlockUsers/blockUsersSlice.js";
import { blockUsers } from "./queries/BlockUsers/index.js";
import userAgreementSlice from "./slices/userAgreementSlice/userAgreementSlice.js";
import { userAgreement } from "./queries/UserAgreement/index.js";
import { deleteHistory } from "./queries/DeleteHistory/index.js";
import deleteHistorySlice from "./slices/DeleteHistory/deleteHistorySlice.js";

const appReducer = combineReducers({
  [profile.reducerPath]: profile.reducer,
  [schedules.reducerPath]: schedules.reducer,
  [service.reducerPath]: service.reducer,
  [staff.reducerPath]: staff.reducer,
  [search.reducerPath]: search.reducer,
  [report.reducerPath]: report.reducer,
  [reportStatus.reducerPath]: reportStatus.reducer,
  [registration.reducerPath]: registration.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [registrationReport.reducerPath]: registrationReport.reducer,
  [registrationTypeReport.reducerPath]: registrationTypeReport.reducer,
  [callHistory.reducerPath]: callHistory.reducer,
  [socialMediaReport.reducerPath]: socialMediaReport.reducer,
  [utmReport.reducerPath]: utmReport.reducer,
  [blockUsers.reducerPath]: blockUsers.reducer,
  [userAgreement.reducerPath]: userAgreement.reducer,
  [deleteHistory.reducerPath]: deleteHistory.reducer,
  report: reportSlice,
  reportStatus: reportStatusSlice,
  schedules: scheduleSlice,
  createSchedule: createScheduleSlice,
  staff: staffSlice,
  roles: roleSlice,
  registration: registrationSlice,
  createProfile: createProfileSlice,
  profile: profileSlice,
  autoMatch: autoMatchSlice,
  autoStarMatch: autoStarMatchSlice,
  partnerMatch: PartnerMatchSlice,
  activity: activitySlice,
  scheduledHistory: scheduledHistorySlice,
  advancedSearch: advancedSearchSlice,
  globalSearch: globalSearchSlice,
  addService: AddServiceSlice,
  addedServiceExist: AddedServiceExist,
  profileSearch: profileSearchSlice,
  activitySearch: ActivitySearch,
  serviceSearch: serviceSearchSlice,
  viewService: viewServiceSlice,
  myService: myServiceSlice,
  serviceNotification: ServiceNotification,
  serviceStatusAlert: ServiceStatusAlert,
  serviceChat: ServiceChat,
  dashboard: dashboardSlice,
  orderColumn: orderColumnSlice,
  global: globalSlice,
  registrationReport: registrationReportSlice,
  registrationTypeReport: registrationTypeReportSlice,
  serviceFollowUp: ServiceFollowUpSlice,
  missedCall: missedCallSlice,
  outgoingCall: outgoingCallSlice,
  incomingCall: incomingCallSlice,
  socialMediaReport: socialMediaReportSlice,
  utmReports: utmReportSlice,
  blockUsers: blockUsersSlice,
  userAgreement: userAgreementSlice,
  deleteHistory: deleteHistorySlice,
});

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(profile.middleware)
      .concat(schedules.middleware)
      .concat(staff.middleware)
      .concat(registration.middleware)
      .concat(search.middleware)
      .concat(report.middleware)
      .concat(reportStatus.middleware)
      .concat(service.middleware)
      .concat(dashboard.middleware)
      .concat(registrationReport.middleware)
      .concat(registrationTypeReport.middleware)
      .concat(callHistory.middleware)
      .concat(socialMediaReport.middleware)
      .concat(utmReport.middleware)
      .concat(blockUsers.middleware)
      .concat(userAgreement.middleware)
      .concat(deleteHistory.middleware),
});

export default appReducer;
