import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import AssignForm from "../../JobAssign/Forms/AssignForm";
import ListingFilter from "../ListingFilter";
import useReport from "./useReport";


const Report = () => {
  const {
    report,
    reportState,
    currentPage,
    paginationOptions,
    isFetching,
    showTableFieldsModal,
    optionsData,
    jobId,
    sortedTableFields,
    hasReAssignJobPermission,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    updateReportFields,
    handleDateChange,
    handleStaffFilter,
    handleBranchFilter,
    handleEditFieldsClick,
    closeEditFieldsModal,
    handleTableEditClick,
    handleCloseOffCanvas,
    handleClearFilters
  } = useReport();

  return (
    <>
      <HeadingGroup title={"Allotted List"} className={`pro-mb-4`} />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <ListingFilter
          handleDateChange={handleDateChange}
          optionsData={optionsData}
          handleStaffFilter={handleStaffFilter}
          handleBranchFilter={handleBranchFilter}
          reportState={reportState}
          handleEditFieldsClick={handleEditFieldsClick}
          handleClearFilters={handleClearFilters}
        />
        <Table
          data={report?.data?.data || []}
          uniqueID={"id"}
          fields={sortedTableFields}
          showCheckBox={hasReAssignJobPermission}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={reportState?.currentPageSize}
          handleEdit={handleTableEditClick}
          assignable={false}
          deletable={false}
          multiSelect={true}
        />

        {report?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              report?.data?.total_count / reportState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showTableFieldsModal}
          handleClose={closeEditFieldsModal}
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={report?.data?.table_id}
              updateData={updateReportFields}
              handleCancel={closeEditFieldsModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={reportState.showJobAssign}
          handleClose={handleCloseOffCanvas}
          title={"Schedule Re-Assign"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          body={
            <AssignForm
              isUpdate
              handleCancel={handleCloseOffCanvas}
              checked={jobId}
              refetch={refetch}
            />
          }
        />
      </div>
    </>
  );
};

export default Report;
