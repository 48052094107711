import { useState } from "react";

const useAgreementMultipleImage = ({
  data,
  userId,
  refetch,
  handleClose,
  setMultipleFiles,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const handleClickImage = (index) => {
    setActiveImageIndex(index);
  };

  const handleDeleteClick = (index) => {
    const updatedDataFiles = data?.["files"].filter((_, i) => i !== index);
    const updatedDataBlobs = data?.["fileUrls"].filter((_, i) => i !== index);
    setMultipleFiles((state) => ({
      ...state,
      files: updatedDataFiles,
      fileUrls: updatedDataBlobs,
    }));

    if (
      index === data?.["files"]?.length - 1 &&
      data?.["files"]?.length !== 1
    ) {
      setActiveImageIndex(index - 1);
    }
  };

  return {
    activeImageIndex,
    handleClickImage,
    handleDeleteClick,
  };
};

export default useAgreementMultipleImage;
