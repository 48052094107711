import { Button, HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useStaffCreationForm from "./useStaffCreationForm";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";
import RowThree from "./RowThree";
const StaffCreationForm = () => {
  const {
    formik,
    errors,
    loading,
    optionsData,
    showPassword,
    params,
    handleShowPassword,
    handleCancel,
    handleRemovePhotoImage,
    handleRemoveIdProofImage,
    handleRemoveCertificateImage,
   
  } = useStaffCreationForm();

  return (
    <div className={`pro-w-100`}>
      <form action="" onSubmit={formik.handleSubmit}>
        <div className="col-12  pro-mb-2 pro-d-flex pro-justify-end pro-items-center">
          <HeadingGroup
            title={"Staff Registration"}
            extraClassName={`pro-mb-2`}
          />

          {/**#FIXME Sooraj please check this button is working or not */}
          
          <Button
            className={"pro-btn-link pro-px-5 pro-mx-2"}
            onClick={(e) => handleCancel(e)}
          >
            {!params.get("id") ? "Reset" : "Cancel"}
          </Button>

          <Button className={"pro-btn-primary"} type="submit">
            {loading ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            {!params.get("id") ? "Create" : "Update"}
          </Button>
        </div>

        {errors && <span className="error-message">{errors}</span>}

        <div className={`row`}>
          {/**
             
       * Name
       * Nick Name
       * Gender
       * Marrital Status
       * Email
       * Mobile
       * Office Number
       * Present Address
    
       */}
          <RowOne formik={formik} optionsData={optionsData} />
        {/**
           
       * Permanent Address
       * Education Category
       * Education Details
       * Designation
       * Joining Date
       * Remarks
       * User Type
       * Branch
           
        */}

          <RowTwo formik={formik} optionsData={optionsData} />
          {/**
       * Password
       * Confirm Password
       * Photo
       * Id Proof
       * Certificate
    
       */}
          
        </div>
        <RowThree
            formik={formik}
            params={params}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
            handleRemovePhotoImage={handleRemovePhotoImage}
            handleRemoveIdProofImage={handleRemoveIdProofImage}
            handleRemoveCertificateImage={handleRemoveCertificateImage}
          />
      </form>
    </div>
  );
};

export default StaffCreationForm;
