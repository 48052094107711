import React from "react";
import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layout/CommonLayout";
import useDashboard from "../../pages/Dashboard/useDashboard";

const CallHistoryMain = () => {
  const { drawerMenu } = useDashboard();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default CallHistoryMain;
