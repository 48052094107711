import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEditAboutMyselfMutation } from "../../../../store/queries/Profile";

const useAboutMyselfForm = ({ userId, handleCancel, data,refetch }) => {


  const [sendRequest] = useEditAboutMyselfMutation();

  const formik = useFormik({
    initialValues: {
      user_id: userId,
      about_me: data?.data?.label,
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    sendRequest(values)
      .then((response) => {
       
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          refetch()
          toast.success("Profile Updated Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Update Profile!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Update Profile!");
      });
  };
  return { formik };
};

export default useAboutMyselfForm;
