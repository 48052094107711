import { useSelector } from "react-redux";
import { useGetAdvancedSearchTableQuery } from "../../../../store/queries/Search";

const useViewTableDetails = ({ skip }) => {
  const advanceSearchState = useSelector((state) => state.advancedSearch);

  const { data: advancedSearchTable } = useGetAdvancedSearchTableQuery(
    {
      ...advanceSearchState.searchValues,
      page: advanceSearchState.currentPage,
      per_page: advanceSearchState.currentPageSize,
    },
    { skip: skip }
  );

  return { advancedSearchTable };
};

export default useViewTableDetails;
