import {
  Image,
  MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./userDetailTable.module.scss";
import useUserDetailTable from "./useUserDetailTable";
import Assets from "../../../assets/Assets";
import { MultiColoumnTableActivity } from "../MultiColoumnTableActivity";

const UserDetailTable = ({
  data,
  search,
  checked,
  partnerWebId,
  activity,
  handleSelect,
  handleSearchAddService,
  lastElement,
  extraClassNames,
  showCheck,
  matchDetails,
  matched,
  ActivityProfile,
  handleViewHistoryModal,
  profileData,
}) => {
  const { handleClick, handleAddService } = useUserDetailTable({
    search,
    data,
  });

  return (
    <div
      className={`${Style.root} ${extraClassNames} ${
        data?.service_status ? "light_primary_bg" : ""
      }`}
      onClick={handleClick}
      ref={lastElement || null}
    >
      <div className={`${Style.root_inner} pro-p-3 pro-p-xxl-5`}>
        <div className={`row`}>
          <div className={Style.image_wrapper}>
            {showCheck && (
              <div className="pro-check-box checkbox">
                <input
                  type={"checkbox"}
                  className={"pro-check"}
                  checked={checked.includes(data?.profile_data?.id)}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleSelect(
                      data?.profile_data?.id,
                      e.target.checked,
                      data?.profile_data?.name
                    )
                  }
                />
              </div>
            )}
            <div className={Style.image_inner}>
              <Image
                src={
                  data?.is_locked
                    ? Assets.locked
                    : data?.profile_images?.[0]?.user_image_thumbnail ??
                      (data?.gender === "Female"
                        ? Assets.FemaleProfile
                        : Assets.MaleProfile)
                }
                width={165}
                height={165}
              />
              <Image
                src={
                  data?.is_locked
                    ? Assets.locked
                    : data?.profile_images?.[0]?.user_image_thumbnail ??
                      (data?.gender === "Female"
                        ? Assets.FemaleProfile
                        : Assets.MaleProfile)
                }
                width={165}
                height={165}
              />
            </div>
            <div className={`${Style.text_action} pro-pt-4`}>
              <div className={Style.name}>
                <h6 className="pro-ttl h6 pro-mb-1">
                  {data?.profile_data?.name ?? ""}
                </h6>
              </div>
              <div className={Style.user_id}>
                <p className={`pro-fw-medium pro-mb-1`}>
                  {data?.profile_data?.web_id ?? ""}
                </p>
              </div>

              {!search && (
                <div className={`${Style.btn}`}>
                  <button
                    className={`pro-btn pro-btn-outline`}
                    onClick={(e) =>
                      activity
                        ? handleSearchAddService(
                            e,
                            data?.profile_data?.web_id,
                            partnerWebId
                          )
                        : handleAddService(e, data?.profile_data?.web_id)
                    }
                  >
                    Add Service
                  </button>
                  {matched && (
                    <span className={Style.match_badge_static}>
                      <img src={Assets.MatchBadge} alt="matched" />
                    </span>
                  )}
                </div>
              )}
            </div>
            <div
              className={`pro-ms-auto pro-d-inline-flex pro-flex-column pro-gap-2 ${Style.badge_wrapper}`}
            >
              {data?.deleted === 1 && (
                <span className="pro-badge badge-danger pro-align-self-start">
                  Deleted
                </span>
              )}
              {data?.hidden === "1" && (
                <span className="pro-badge badge-warning pro-align-self-start">
                  Hidden
                </span>
              )}
            </div>
          </div>
          {ActivityProfile ? (
            <div className={`${Style.table_wrapper} pro-ps-5`}>
              <MultiColoumnTableActivity
                ActivityProfile={ActivityProfile}
                data={data || []}
                handleViewHistoryModal={handleViewHistoryModal}
              />
              {data?.service_status && (
                <MultiColumnTable data={data?.user_service || []} />
              )}
            </div>
          ) : (
            <div className={`${Style.table_wrapper} pro-ps-5`}>
              <MultiColumnTable data={data?.basic_details || []} />
              {data?.service_status && (
                <MultiColumnTable data={data?.user_service || []} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetailTable;
