import { getAxiosInstance } from "../../../api";



   export const exportAdvancedSearch = async (body) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `search/advanced-search-excel`,   
        body,
        {
       
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      return error.response.data;
    }
  };