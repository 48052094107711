import { useMemo } from "react";
import Select from "react-select";
import style from "./horoscopeBox.module.scss";
import AstrologyTableNew from "../../../../Global/AstrologyTable/AstrologyTableNew";

const HoroscopeBox = ({ title, options, formik }) => {
  const updatedOptions = useMemo(() => {
    let currentNilaKeys = Object.keys(formik.values).filter((key) =>
      key.includes(title.toLowerCase())
    );

    let selectedValues = currentNilaKeys
      .map((key) => formik.values[key])
      .flat();

    return options.filter((option) => !selectedValues.includes(option.id));
    // eslint-disable-next-line
  }, [formik.values, options]);

  const astrologyTableData = useMemo(() => {
    return [...Array(13)].map((_, i) => {
      const currentData =
        formik.values[`${title.toLowerCase()}%${i > 5 ? i : i + 1}`];
      if (i === 5) {
        return [];
      } else {
        if (currentData?.length === 0 || !currentData?.length) {
          return [];
        } else {
          return options
            .filter((option) => currentData?.includes?.(option.id))
            .map((option) => option.graha_name);
        }
      }
    });
    // eslint-disable-next-line
  }, [formik.values]);

  return (
    <>
      <div className="row pro-py-3">
        <div className="input-wrap pro-mb-5">
          <h4 className="pro-font pro-mb-1 pro-fw-medium">{title}</h4>
        </div>
        <div className="col-3">
          {[...Array(12)].map((_, i) => (
            <div className="pro-mb-2" key={i}>
              <Select
                id={`${title}${i}`}
                isMulti
                options={updatedOptions}
                placeholder={i + 1}
                menuPlacement={
                  i >= 7 && title === "Navamshakam" ? "top" : "auto"
                }
                getOptionLabel={(option) => option["graha_name"]}
                getOptionValue={(option) => option["id"]}
                value={options.filter((opt) =>
                  formik.values[`${title.toLowerCase()}%${i + 1}`]?.includes(
                    opt.id
                  )
                )}
                onChange={(value) => {
                  if (
                    formik.values[`${title.toLowerCase()}%${i + 1}`]?.length <
                      5 ||
                    formik.values[`${title.toLowerCase()}%${i + 1}`] === null
                  ) {
                    formik.setFieldValue(
                      `${title.toLowerCase()}%${i + 1}`,
                      value.map((v) => v.id)
                    );
                  }
                }}
              />
            </div>
          ))}
        </div>
        <div className="col-1"></div>
        <div className="col-5">
          <AstrologyTableNew
            data={astrologyTableData}
            extraClassName={style.horoscope_table_large}
            isRegistration={true}
            name={title}
          />
        </div>
      </div>
    </>
  );
};

export default HoroscopeBox;
