import React from "react";
import Select from "react-select";

const PhysicalInfo = ({ formik, optionsData }) => {
  return (
    <div className={`row`} id="physical-info">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">Physical Info</h6>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Height*
          </label>
          <Select
            id="height"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.heightList}
            getOptionLabel={(option) => `${option.height}`}
            getOptionValue={(option) => `${option["height_value"]}`}
            className={`pro-input  lg multi-select ${
              formik.touched.height && formik.errors.height && " error"
            }`}
            value={optionsData?.data?.heightList?.filter(
              (p) => p.id?.toString() === formik.values.height?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("height")}
            onChange={(value) =>
              formik.setFieldValue("height", value?.id || null)
            }
          ></Select>
          {formik.touched.height && formik.errors.height && (
            <span className="error-text">{formik.errors.height}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Complexion*
          </label>
          <Select
            id="complexion"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.complexionList}
            getOptionLabel={(option) => `${option.complexion_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.complexion && formik.errors.complexion && " error"
            }`}
            value={optionsData?.data?.complexionList?.filter(
              (p) => p.id?.toString() === formik.values.complexion?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("complexion")}
            onChange={(value) =>
              formik.setFieldValue("complexion", value?.id || null)
            }
          ></Select>
          {formik.touched.complexion && formik.errors.complexion && (
            <span className="error-text">{formik.errors.complexion}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Body Type*
          </label>
          <Select
            id="body_type"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.bodyTypes}
            getOptionLabel={(option) => `${option.body_type}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.body_type && formik.errors.body_type && " error"
            }`}
            value={optionsData?.data?.bodyTypes?.filter(
              (p) => p.id?.toString() === formik.values.body_type?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("body_type")}
            onChange={(value) =>
              formik.setFieldValue("body_type", value?.id || null)
            }
          ></Select>
          {formik.touched.body_type && formik.errors.body_type && (
            <span className="error-text">{formik.errors.body_type}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Special Cases (If Any)*
          </label>
          <Select
            id="special_case"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.specialCaseList}
            getOptionLabel={(option) => `${option.special_case}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.special_case &&
              formik.errors.special_case &&
              " error"
            }`}
            value={optionsData?.data?.specialCaseList?.filter(
              (p) => p.id?.toString() === formik.values.special_case?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("special_case")}
            onChange={(value) =>
              formik.setFieldValue("special_case", value?.id || null)
            }
          ></Select>
          {formik.touched.special_case && formik.errors.special_case && (
            <span className="error-text">{formik.errors.special_case}</span>
          )}
        </div>
      </div>
      {formik.values.special_case && formik.values.special_case !== 1 && (
        <div className={`col-12`}>
          <div className="input-wrap pro-mb-5">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Specify Details
            </label>
            <textarea
              id="special_case_details"
              name="special_case_details"
              className={`pro-input lg ${
                formik.touched.special_case_details &&
                formik.errors.special_case_details &&
                " error"
              }`}
              {...formik.getFieldProps("special_case_details")}
            ></textarea>

            {formik.touched.special_case_details &&
              formik.errors.special_case_details && (
                <span className="error-text">
                  {formik.errors.special_case_details}
                </span>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhysicalInfo;
