import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import Select from "react-select";
import useSelectionRequestForm from "./useSelectionRequestForm";
import style from "./selectionRequestForm.scss";

const SelectionRequestForm = ({
  handleCancel,
  userId,
  profileId,
  isSuccess,
}) => {
  const { formik, optionsData, isOptionDataSuccess } = useSelectionRequestForm({
    handleCancel,
    userId,
  });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Selection Request</h4>
        <h6 className="pro-ttl  pro-mb-5">{`Profile Id : ${profileId}`}</h6>
      </div>
      {isSuccess && isOptionDataSuccess ? (
        <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Team Lead
            </label>
            <Select
              id="user_type"
              placeholder={"Select"}
              isClearable={true}
              options={optionsData?.data?.userTypesList}
              getOptionLabel={(option) => `${option.user_type}`}
              getOptionValue={(option) => `${option["id"]}`}
              className={`pro-input lg multi-select ${
                formik.touched.user_type && formik.errors.user_type && " error"
              }`}
              value={optionsData?.data?.userTypesList?.filter(
                (p) => p.id?.toString() === formik.values.user_type?.toString()
              )}
              classNamePrefix="pro-input"
              onBlur={() => formik.handleBlur("user_type")}
              onChange={(value) => formik.setFieldValue("user_type", value?.id || null)}
            ></Select>
            {formik.touched.user_type && formik.errors.user_type && (
              <span className="error-text">{formik.errors.user_type}</span>
            )}
          </div>

          <div className={"input-wrap pro-mb-4"}>
            <label
              htmlFor="staff_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Staff Name
            </label>
            <Select
              id="staff_id"
              placeholder={"Select"}
              isClearable={true}
              isDisabled={formik.values.user_type ? false : true}
              className={`pro-input lg ${
                formik.errors.staff_id && formik.touched.staff_id ? "error" : ""
              }`}
              classNamePrefix="pro-input"
              options={optionsData.data.staffList?.filter(
                (m) =>
                  formik.values.user_type?.toString() ===
                  m.user_types_id?.toString()
              )}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.staff_name}
              value={optionsData.staffList?.filter(
                (m) =>
                  formik.values.staff_id?.toString?.() === m.id?.toString?.()
              )}
              onBlur={() => formik.handleBlur("staff_id")}
              onChange={(value) => {
                formik.setFieldValue("staff_id", value?.id?.toString() || null);
              }}
            />
            {formik.values.user_type && formik.errors.staff_id && formik.touched.staff_id && (
              <span className="error-text">{formik.errors.staff_id}</span>
            )}
          </div>
          <Input
            label={"Description"}
            type="text"
            id="description
          "
            name="description
          "
            className={`pro-input lg ${
              formik.touched.description &&
              formik.errors.description &&
              " error"
            }`}
            {...formik.getFieldProps("description")}
            error={formik.touched.description && formik.errors.description}
            errorMessage={
              formik.touched.description && formik.errors.description
            }
          />

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(3)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default SelectionRequestForm;
