import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  useGetUserAgreementQuery,
  useApproveUserAgreementMutation,
  useUpdateAgreementFieldsMutation,
} from "../../../store/queries/UserAgreement";
import { updateConfig } from "../../../store/slices/userAgreementSlice/userAgreementSlice";
import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import { getFormatedDate } from "../../../utils/functions/table";
import Select from "react-select";
import { toast } from "react-toastify";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";
import { AgreementIdRow } from "./Rows";
import Style from "../userAgreement.module.scss";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useListing = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");

  const userAgreementState = useSelector((state) => state.userAgreement);

  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -30),
    endDate: new Date(),
    key: "selection",
  });

  const activeProfile = sessionStorage.getItem("active");
  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);
  const [updateAgreementFields] = useUpdateAgreementFieldsMutation();
  const {
    data: userAgreementData,
    isFetching,
    isLoading,
    refetch,
  } = useGetUserAgreementQuery({
    search: userAgreementState.search,
    per_page: userAgreementState.currentPageSize,
    page: userAgreementState.currentPage,
    sort_by: userAgreementState?.sortBy,
    sort: userAgreementState?.sortOrder,
    date: userAgreementState.dateRange,
    active_tab: activeTab,
  });

  const [approveUserAgreement] = useApproveUserAgreementMutation();

  const StatusOptions = [
    {
      label: "Pending",
      id: 1,
    },
    {
      label: "Approved",
      id: 2,
    },
  ];

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (userAgreementState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            userAgreementState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      register_id: (_, data) => (
        <AgreementIdRow data={data} activeProfile={activeProfile} />
      ),

      user_image_path: (field, data) => (
        <div className="no-wrap  pro-pnt">
          <IconText
            icon={<span className="material-symbols-outlined x4">task</span>}
            title={
              data?.agreement_type === "image" ? "View Images" : "View Document"
            }
            onClick={() => handleViewImagePdf(data)}
          />
        </div>
      ),
      status: (field, data) => {
        let statusValue = data[field] === 1 ? 2 : 1;
        const selectVal = StatusOptions?.find(
          (item) => item?.id === statusValue
        );
        return (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input pro-badge ${Style.select_badge} ${
              statusValue === 2
                ? "badge-success-outline"
                : "badge-warning-outline"
            }`}
            classNamePrefix="pro-input"
            options={StatusOptions}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.id}
            value={selectVal}
            onChange={(selectedOption) => {
              handleChangeStatus(selectedOption?.id, data?.users_id);
            }}
            menuPlacement="auto"
            menuPosition="fixed"
          />
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleViewImagePdf = (data) => {
    const imageType = data?.agreement_type;
    if (imageType === "pdf") {
      dispatch(
        updateConfig((state) => {
          state.showImageModal = true;
          state.imageData = data?.["user_image_path"]?.[0]?.user_image_path;
          state.document_type = "pdf"; // only sending pdf to make it work it as a pdf viewer
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.showMultipleImageModal = true;
          state.multipleImageData = data?.["user_image_path"];
        })
      );
    }
  };

  const closeImageModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showImageModal = false;
      })
    );
  };

  const closeMultipleImageModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showMultipleImageModal = false;
      })
    );
  };

  const handleChangeStatus = (selectedId, dataId) => {
    const obj = {
      user_id: dataId,
      status: selectedId === 2 ? 1 : 0,
    };
    approveUserAgreement(obj).then((res) => {
      if (res?.data?.status_code === 200) {
        toast.success(
          res?.data?.message ?? "User Agreement Updated Sucessfully"
        );
        refetch();
      } else {
        toast.error("Failed to update User Agreement");
      }
    });
  };

  const handleDateChange = (range) => {
    setInitialDateRange(range);

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;

        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const obj = userAgreementData?.data?.field || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  return {
    activeTab,
    isLoading,
    isFetching,
    initialDateRange,
    userAgreementData,
    paginationOptions,
    sortedTableFields,
    userAgreementState,
    showTableFieldsModal,
    refetch,
    getRow,
    handleSort,
    handleSearch,
    closeEditModal,
    handlePageSize,
    closeImageModal,
    handleDateChange,
    handleClearClick,
    handlePagination,
    updateAgreementFields,
    handleEditFieldsClick,
    closeMultipleImageModal,
  };
};

export default useListing;
