import React from "react";
import Select from "react-select";
import Style from "./editModal.module.scss";
import useEditModal from "./useEditModal";
import { Input, Button } from "@wac-ui-dashboard/wac_component_library";

const EditModal = ({ title, selectedUpdateRows, closeUpdateModal,refetch }) => {
  const { optionsData, formik, errors } = useEditModal({
    selectedUpdateRows,refetch
  });
  return (
    <div className={Style.root}>
      <div className={Style.heading}>
        {title && <h5 className="pro-ttl h5 pro-mb-5">{title}</h5>}
      </div>
      <div className={`${Style.body} pro-mb-4`}>
        <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <div className={"input-wrap pro-mb-4 "}>
          <Select
            id="status"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.id}
            value={optionsData?.filter(
              (m) => formik.values.status?.toString() === m.id?.toString()
            )}
            onChange={(value) => formik.setFieldValue("status", value.id ?? null)}
            onBlur={formik.handleBlur("status")}
            className={`pro-input lg multi-select ${
              formik.touched.status && formik.errors.status && " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.status && formik.errors.status && (
            <span className="error-text">{formik.errors.status}</span>
          )}
        </div>

        <Input
          label={"Comments"}
          type="text"
          id="description"
          name="description"
          className={`pro-input lg  ${
            formik.touched.description &&
            formik.errors.description &&
            " error"
          }`}
          {...formik.getFieldProps("description")}
          error={formik.touched.description && formik.errors.description}
          errorMessage={formik.errors.description}
        />

       
      </div>
      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeUpdateModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          <i
            className="spinner-border spinner-border-sm me-3"
            role="status"
            aria-hidden="true"
          ></i>
          Update
        </Button>
      </div>
      {errors && <span className="error-message">{errors}</span>}
    </div>
  );
};

export default EditModal;
