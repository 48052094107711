import React from "react";
import useViewTableDetails from "./useViewTableDetails";

const ViewTableDetails = React.forwardRef((props, ref, skip) => {
  const { advancedSearchTable } = useViewTableDetails({ skip });

  const title = ["Photo", "ID", "Name", "Details", "Contact", "Membership"];

  const breakWords = (content) => {
    return content?.split(",")?.join(", ")?.split(":")?.join(": ");
  };

  return (
    <div ref={ref}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          fontWeight: 400,
          fontSize: 10,
        }}
      >
        <thead>
          <tr>
            {title.map((key, index) => (
              <th
                key={index}
                style={{
                  backgroundColor: "#304053",
                  color: "#ffffff",
                  fontWeight: 600,
                  textAlign: "center",
                  padding: "5px 5px",
                }}
              >
                {key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {advancedSearchTable?.data?.map((item, index) => (
            <>
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f3f3f3" : "#ffffff",
                }}
              >
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  <img
                    src={
                      item?.profile_images?.[0]?.["user_image_thumbnail"] || ""
                    }
                    alt="profile"
                    width={80}
                    style={{
                      width: 80,
                      maxWidth: 80,
                    }}
                  />
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {breakWords(item?.id[0])}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {breakWords(item?.name[0])}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {breakWords(item?.details[0])}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {breakWords(item?.contact[0])}
                </td>
                <td
                  style={{
                    padding: "5px 5px",
                  }}
                >
                  {breakWords(item?.memebership[0])}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default ViewTableDetails;
