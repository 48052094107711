import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPageSize: 10,
  currentPage: 1,
  SearchData: {},
};

const profileSearchSlice = createSlice({
  name: "profileSearch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = profileSearchSlice.actions;

export default profileSearchSlice.reducer;
