import { Input } from "@wac-ui-dashboard/wac_component_library";
import { format } from "date-fns";
import Select from "react-select";

const SearchForm = ({ formik, optionsData }) => {
  return (
    <div className="row pro-mb-2">
      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="start_date"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Start Period
          </label>
          <input
            id="start_date"
            value={formik.values.start_date ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg  "}
            onChange={(e) => {
              formik.setFieldValue(
                "start_date",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="end_date"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            End Period
          </label>
          <input
            id="end_date"
            value={formik.values.end_date ?? ""}
            type={"date"}
            max={"9999-12-31"}
            className={"pro-input lg  "}
            onChange={(e) => {
              formik.setFieldValue(
                "end_date",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
          />
        </div>
      </div>
      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="religion"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Religion
          </label>
          <Select
            id="religion"
            placeholder={"Select"}
            isClearable={true}
            className={"pro-input lg  "}
            classNamePrefix="pro-input"
            options={optionsData.religionList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.religion_name}
            value={optionsData.religionList?.filter(
              (m) => formik.values.religion?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("religion", value?.id?.toString?.() || null);
              formik.setFieldValue("caste", []);
            }}
          />
        </div>
      </div>
      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="caste" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Caste
          </label>
          <Select
            id="caste"
            placeholder={"Select"}
            isMulti
            isDisabled={formik.values.religion ? false : true}
            className={"pro-input lg  "}
            classNamePrefix="pro-input"
            options={optionsData.casteList?.filter?.(
              (c) =>
                c.religion_id?.toString?.() ===
                formik.values.religion?.toString?.()
            )}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.caste_name}
            value={optionsData.casteList?.filter((m) =>
              formik.values.caste.includes(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "caste",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>
      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="gender"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Gender
          </label>
          <Select
            id="gender"
            placeholder={"Select"}
            isMulti
            options={optionsData.genderList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.gender_name}
            value={optionsData.genderList?.filter((m) =>
              formik.values.gender.includes(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "gender",
                value.map((v) => v.id?.toString())
              )
            }
            className={"pro-input lg  "}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
           Package
          </label>
          <Select
            id="package"
            placeholder={"Select"}
            isMulti
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.packageList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.package_title}
            value={optionsData.packageList?.filter((m) =>
              formik.values.package?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) => {
              let newVal = value.map((v) => v.id?.toString?.());
              formik.setFieldValue("package", newVal);
            }}
          />
        </div>
      </div>
      <div className="col-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="branch"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Select Branch
          </label>
          <Select
            id="branch"
            placeholder={"Select"}
            isMulti
            options={optionsData.branches}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.branch_name}
            value={optionsData.branches?.filter((m) =>
              formik.values.branch.includes(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "branch",
                value.map((v) => v.id?.toString())
              )
            }
            className={"pro-input lg  "}
            classNamePrefix="pro-input"
          />
        </div>
      </div>

      <div className="col-3">
        <Input
          label={"Web ID"}
          type="text"
          id="register_id"
          name="register_id"
          className={`pro-input lg ${
            formik.touched.register_id && formik.errors.register_id && " error"
          }`}
          {...formik.getFieldProps("register_id")}
          error={formik.touched.register_id && formik.errors.register_id}
          errorMessage={formik.errors.register_id}
        />
      </div>
    </div>
  );
};

export default SearchForm;
