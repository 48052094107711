import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getActivitySearch,
} from "../../../store/slices/Search/ActivitySearch";

import useObserver from "../../../utils/hooks/useObserver";
import { updateProfileConfig } from "../../../store/slices/Profile";
import { updateConfig as updateAddedServiceConfig } from "../../../store/slices/Profile/AddedServiceExist";
import { useGetLoggedInStaffProfileDataQuery } from "../../../store/queries/Staff";
import { useGetServiceTypeQuery } from "../../../store/queries/Profile";
import moment from "moment";
import { exportActivitySearch } from "./ActivitySearchForm/api";

const useActivitySearch = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.activitySearch);
  const profileState = useSelector((state) => state.profile);
  const [showScroll, setShowScroll] = useState(false);
  const [page, setPage] = useState(1);
  const [horoscope, setHoroscope] = useState(false);
  const [exportValue, setExportValue] = useState(50);
  const [exportLoading, setExportLoading] = useState(false);

  const addedServiceExistState = useSelector(
    (state) => state.addedServiceExist
  );

  const { data: loggedStaffData } = useGetLoggedInStaffProfileDataQuery();
  const { data: optionsData, isSuccess } = useGetServiceTypeQuery();

  const formik = useFormik({
    initialValues: {
      user_activity: null,
      horoscope_matches: false,
      branch: !loggedStaffData?.data?.nest_admin
        ? [`${loggedStaffData?.data?.staff_branch}`]
        : [],
      religion_id: null,
      caste_id: [],
      date_from: null,
      date_to: null,
      status: null,
    },
    validate: (values) => {
      let errors = {};
      if (!values.user_activity) {
        errors.user_activity = "*Required";
      }

      return errors;
    },

    enableReinitialize: true,
    onSubmit: async (values) => {
      setPage(1);
      dispatch(
        updateConfig((state) => {
          state.activitySearchValues = { ...values };
        })
      );
      dispatch(getActivitySearch({ ...values, page: 1 }));
    },
  });

  const handleSearch = () => {
    dispatch(
      updateConfig((state) => {
        state.activitySearchStatus = "idle";
        state.activitySearchData = {};
      })
    );
    formik.handleSubmit();
  };
  const handleReset = () => {
    formik.resetForm();
    dispatch(
      updateConfig((state) => {
        state.activitySearchStatus = "idle";
        state.activitySearchData = {};
      })
    );
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    dispatch(getActivitySearch({ ...formik.values, page: page + 1 }));
  };
  const handleSearchAddService = (e, partner_web_id, client_web_id) => {
    e?.stopPropagation();
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = true;
        state.partner_client_web_id = partner_web_id;
        state.client_web_id = client_web_id;
      })
    );
  };
  const handleCloseOffCanvas = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.activitySearchStatus === "pending",
    hasMore:
      state.activitySearchData?.per_page *
        state.activitySearchData?.current_page <
      state.activitySearchData?.total_count,
    callback: handleLoadMore,
  });

  const closeModal = () => {
    dispatch(
      updateAddedServiceConfig((state) => {
        state.showAddedServiceModal = false;
      })
    );
  };

  const handleChangeToggle = () => {
    setHoroscope(!horoscope);
  };

  const exportOptions = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 2,
      value: 100,
    },
    {
      id: 3,
      value: 500,
    },
    {
      id: 5,
      value: 1000,
    },
  ];

  const handleExportActivityResult = () => {
    setExportLoading(true);
    exportActivitySearch({
      ...state.activitySearchValues,
      export_count: exportValue,
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `activity_search_${moment(Date.now()).format(
          "MM_DD_YYYY_HH_mm_ss"
        )}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setExportLoading(false);
    });
  };

  const hasExportPermission = useMemo(() => {
    let permission = state?.activitySearchData?.permissions?.filter((p) =>
      Object.keys(p).includes("can_activity_export")
    );
    return permission?.[0]?.can_activity_export ?? false;
    // eslint-disable-next-line
  }, [state.activitySearchData]);

  return {
    formik,
    state,
    showScroll,
    profileState,
    addedServiceExistState,
    horoscope,
    optionsData,
    isSuccess,
    exportOptions,
    exportValue,
    exportLoading,
    hasExportPermission,
    setExportValue,
    handleExportActivityResult,
    handleChangeToggle,
    setHoroscope,
    closeModal,
    handleScrollToTop,
    handleCloseOffCanvas,
    handleSearchAddService,
    lastElement,
    handleSearch,
    handleReset,
  };
};

export default useActivitySearch;
