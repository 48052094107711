import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getAxiosInstance } from "../../../../api";
import { useGetjobAssignFiltersQuery } from "../../../../store/queries/Staff";

const useAssignForm = ({
  checked,
  handleCancel,
  isUpdate,
  refetch,
  setChecked,
}) => {
  const { data: optionsData, isSuccess } = useGetjobAssignFiltersQuery();

  let assignFormSchema = Yup.object({
    branch: Yup.string().required("*Required"),
    user_type: Yup.string().required("*Required"),
    staff_id: Yup.string().required("*Required"),
    schedule_id: Yup.string().required("*Required"),
    job_start_date: Yup.string().required("*Required"),
  });

  let updateAssignFormSchema = Yup.object({
    branch: Yup.string().required("*Required"),
    user_type: Yup.string().required("*Required"),
    staff_id: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      user_id: checked,
      user_type: null,
      branch: null,
      staff_id: null,
      schedule_id: null,
      job_start_date: null,
    },
    validationSchema: !isUpdate ? assignFormSchema : updateAssignFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    const api = await getAxiosInstance();

    if (!isUpdate) {
      api
        .post("job/create-job", values)
        .then((response) => {
          formik.setErrors("");
          if (response?.data?.status_code === 200) {
            refetch?.();
            toast.success("Job Assigned Successfully!");
            handleCancel();
          } else if (response?.error?.data?.status_code === 422) {
            formik.setErrors(response?.error?.data?.errors);
          }
        })
        .catch((error) => {
          toast.error("Failed to Assign Job!");
        });
    } else {
      let obj = { job_id: checked, staff_id: values.staff_id };
      api
        .post("job/reassign-job", obj)
        .then((response) => {
          
          formik.setErrors("");
          if (response?.data?.status_code === 200) {
            refetch();
            setChecked?.([]);
            toast.success("Job Reassigned Successfully!");
            handleCancel();
          } else if (response?.error?.data?.status_code === 422) {
            formik.setErrors(response?.error?.data?.errors);
          }
        })
        .catch((error) => {
          
          toast.error("Failed to Assign Job!");
        });
    }
  };
  return { optionsData: optionsData?.data, formik, isSuccess };
};

export default useAssignForm;
