import { useDispatch, useSelector } from "react-redux";
import { useGetDeleteHistoryDataQuery } from "../../store/queries/DeleteHistory";
import { updateConfig } from "../../store/slices/DeleteHistory/deleteHistorySlice";
import { useSearchParams } from "react-router-dom";

const useDeleteHistory = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const deleteHistoryState = useSelector((state) => state.deleteHistory);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const { data: deleteHistoryData, isFetching } = useGetDeleteHistoryDataQuery({
    user_id: activeProfile,
    // sort_by: deleteHistoryState?.sortBy,
    sort_order: deleteHistoryState?.sortOrder,
  });

  const handleSort = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.sortOrder =
          deleteHistoryState.sortOrder === "asc" ? "desc" : "asc";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    deleteHistoryData,
    deleteHistoryState,
    isFetching,
    getRow,
    handleSort,
  };
};

export default useDeleteHistory;
