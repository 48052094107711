import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api[method](`${endpoint}`, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const reportStatus = createApi({
  reducerPath: "reportStatusApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["ReportStatus"],
  endpoints: (builder) => ({
    getReportStatusListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/report/call-history-list`,
      }),
      providesTags: ["ReportStatus"],
    }),

    updateReportStatusListFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["ReportStatus"],

      // async onQueryStarted({},{ dispatch, queryFulfilled }) {

      //   await queryFulfilled;

      //   setTimeout(() => {
      //     dispatch(schedules.util.invalidateTags(["Schedules"]));
      //   }, 1000);

      // },
    }),

    deleteReportStatusData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/report/call-history-delete`,
      }),

      invalidatesTags: ["ReportStatus"],
    }),

    editReportStatusData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/report/call-history-update`,
      }),

      invalidatesTags: ["ReportStatus"],
    }),
  }),
});

export const {
  useGetReportStatusListDataQuery,
  useUpdateReportStatusListFieldsMutation,
  useDeleteReportStatusDataMutation,
  useEditReportStatusDataMutation,
} = reportStatus;
