import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetRegistrationReportListQuery,
  useGetRegistrationReportFiltersQuery,
  useUpdateRegistrationReportFieldsMutation,
} from "../../../store/queries/RegistrationReports";
import { updateConfig } from "../../../store/slices/registrationReport/registrationReportSlice";
import { RegisterIdRow } from "./Rows";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { exportRegistrationReport } from "./api";
import moment from "moment";
import { addDays } from "date-fns";

const useRegistrationReport = () => {
  const activeProfile = sessionStorage.getItem("active");
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(true);
  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -15),
    endDate: new Date(),
    key: "selection",
  });

  const registrationReportState = useSelector(
    (state) => state.registrationReport
  );
  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);
  const [updateRegistrationReportFields] =
    useUpdateRegistrationReportFieldsMutation();

  const formik = useFormik({
    initialValues: {
      branch: [],
      staff: [],
      looking_for: "",
      subscription: [],
      religion: "",
      caste: [],
      registered_platform: "",
      country: 1,
      state: 18,
      district: [],
      date_type: 3,
      date: [
        moment(Date.now()).add(-15, "days").format("YYYY-MM-DD"),
        moment(Date.now()).format("YYYY-MM-DD"),
      ],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.searchValues = { ...values };
          state.currentPage = 1;
          state.currentPageSize = 20;
        })
      );
      setSkip(false);
    },
  });

  const {
    data: registrationReport = {},
    isFetching,
    refetch,
  } = useGetRegistrationReportListQuery(
    {
      ...registrationReportState.searchValues,
      page: registrationReportState?.currentPage,
      per_page: registrationReportState?.currentPageSize,
      sort_by: registrationReportState?.sortBy,
      sort: registrationReportState?.sortOrder,
    },
    { skip: skip }
  );

  const { data: optionsData = {} } = useGetRegistrationReportFiltersQuery();

  const obj = registrationReport?.data?.field || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const handleSort = (label) => {
    if (registrationReportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            registrationReportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      register_id: (_, data) => (
        <RegisterIdRow data={data} activeProfile={activeProfile} />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleBranchFilter = (ids) => {
    formik?.setFieldValue("branch", ids);
    formik?.setFieldValue("staff", []);
  };

  const handleCalenderTypeFilter = (id) => {
    formik?.setFieldValue("date_type", id);
  };

  const handleCurrentLookingFor = (id) => {
    formik?.setFieldValue("looking_for", id);
  };

  const handleCurrentSubscriptionFilter = (ids) => {
    formik?.setFieldValue("subscription", ids);
  };

  const handleCurrentReligionFilter = (id) => {
    formik?.setFieldValue("religion", id);
    formik?.setFieldValue("caste", []);
  };

  const handleCurrentCasteFilter = (ids) => {
    formik?.setFieldValue("caste", ids);
  };

  const handleCurrentCountryFilter = (id) => {
    formik?.setFieldValue("country", id);
    formik?.setFieldValue("state", "");
    formik?.setFieldValue("district", "");
  };

  const handleCurrentStateFilter = (id) => {
    formik?.setFieldValue("state", id);
    formik?.setFieldValue("district", []);
  };

  const handleCurrentDistrictFilter = (ids) => {
    formik?.setFieldValue("district", ids);
  };

  const handlePlatformFilter = (id) => {
    formik?.setFieldValue("registered_platform", id);
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const handlePagination = (page) => {
    // console.log(page,"page")
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    setInitialDateRange(range);
    formik?.setFieldValue("date", [
      getFormatedDate(range.startDate),
      getFormatedDate(range.endDate),
    ]);
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const handleExportClick = () => {
    exportRegistrationReport({
      ...registrationReportState?.searchValues,
      page: registrationReportState?.currentPage,
      per_page: registrationReportState?.currentPageSize,
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  return {
    currentPage: registrationReportState?.currentPage,
    registrationReport,
    sortedTableFields,
    isFetching,
    formik,
    registrationReportState,
    optionsData,
    paginationOptions,
    showTableFieldsModal,
    initialDateRange,
    handleExportClick,
    updateRegistrationReportFields,
    closeEditModal,
    refetch,
    handleSort,
    getRow,
    handleBranchFilter,
    handleCurrentCountryFilter,
    handleCurrentStateFilter,
    handleCurrentDistrictFilter,
    handlePlatformFilter,
    handleCurrentSubscriptionFilter,
    handleCurrentReligionFilter,
    handleCurrentCasteFilter,
    handleCalenderTypeFilter,
    handleCurrentLookingFor,
    handleEditFieldsClick,
    handlePagination,
    handlePageSize,
    handleDateChange,
  };
};

export default useRegistrationReport;
