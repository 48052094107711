import { useDispatch, useSelector } from "react-redux";
import { useGetScheduleCreationFormDataQuery } from "../../../store/queries/Schedule";
import { updateFormData } from "../../../store/slices/Schedules/createScheduleSlice";

const useScheduleCreationForm = () => {
  const dispatch = useDispatch();
  const { activeTab, CompletedPercentage, completedTabs } = useSelector(
    (state) => state.createSchedule
  );

  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetScheduleCreationFormDataQuery({});

  let tabs = [
    { label: "Basic", completed: CompletedPercentage.Basic },
    { label: "Interval", completed: CompletedPercentage.Interval },
  ];

  const handleTabClick = (tab) => {
    if (completedTabs[tab.label] === true) {
      dispatch(
        updateFormData((state) => {
          state.activeTab = tab.label;
          
        })
      );
    }
  };

  return { tabs, activeTab, optionsData, handleTabClick, isOptionDataSuccess };
};

export default useScheduleCreationForm;
