import React, { forwardRef } from "react";
import { ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import ImageCrop from "../../../../Global/ProfileCard/ImageCrop";

const CropModal = forwardRef(
  (
    {
      showCrop,
      imageType,
      closeModal,
      image,
      crop,
      setCrop,
      loading,
      handleNonProfileImageUpload,
      handleProfileImageUpload,
    },
    ref
  ) => {
    return (
      <ModalLayout show={showCrop} handleClose={closeModal} backdrop="static">
        <ImageCrop ref={ref} image={image} crop={crop} setCrop={setCrop} />
        <div className={`pro-d-flex pro-mt-2 pro-m-1 pro-justify-between  `}>
          <button
            className={`pro-btn pro-btn-primary  ${loading ? "loading" : ""}`}
            onClick={() =>
              imageType === 1
                ? handleProfileImageUpload()
                : imageType === 2
                ? handleNonProfileImageUpload()
                : ""
            }
          >
            Crop & Upload
          </button>
          <button
            className={`pro-btn pro-btn-secondary`}
            onClick={() => closeModal()}
          >
            Cancel
          </button>
        </div>
      </ModalLayout>
    );
  }
);

export default CropModal;
