import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetJobAssignDataQuery,
  useGetProfileSearchFiltersQuery,
} from "../../store/queries/Search";
import { updateConfig } from "../../store/slices/Search/ProfileSearch";
import { search } from "../../store/queries/Search";

const useJobAssign = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.profileSearch);

  const [skip, setSkip] = useState(true);
  const [checked, setChecked] = useState([]);
  const [showScroll, setShowScroll] = useState(false);
  const [showAssignForm, setShowAssignForm] = useState(false);

  const { data: optionsData, isSuccess } = useGetProfileSearchFiltersQuery();

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const formik = useFormik({
    initialValues: {
      date_type: null,
      start_date: null,
      end_date: null,
      religion: null,
      package: null,
      branch: [],
      caste: [],
      gender: [],
      agreement: true,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setSkip(() => false);
      dispatch(
        updateConfig((state) => {
          state.SearchData = { ...values };
          state.currentPage = 1;
        })
      );
    },
  });

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleSearch = () => {
    formik.handleSubmit();
  };

  const handleReset = () => {
    setSkip(() => true);

    dispatch(
      updateConfig((state) => {
        state.SearchData = {};
      })
    );

    dispatch(search.util.invalidateTags(["JobAssign"]));

    formik.resetForm();
  };

  const {
    data: JobAssignData = {},
    isError,
    isFetching,
    isSuccess: isDataSuccess,
  } = useGetJobAssignDataQuery(
    {
      ...state.SearchData,
      page: state.currentPage,
      per_page: state.currentPageSize,
    },
    { skip }
  );

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSelect = (id, checked) => {
    if (checked) {
      setChecked((state) => [...state, id]);
    } else {
      setChecked((state) => state.filter((prevID) => prevID !== id));
    }
  };

  const handleCancelAssign = () => {
    setChecked([]);
  };

  const handleAssign = () => {
    setShowAssignForm(true);
  };

  const handleClose = () => {
    setShowAssignForm(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return {
    formik,
    state,
    JobAssignData,
    checked,
    optionsData: optionsData?.data,
    isSuccess,
    showAssignForm,
    showScroll,
    paginationOptions,
    isError,
    isFetching,
    isDataSuccess,
    handlePagination,
    handlePageSize,
    setChecked,
    handleScrollToTop,
    handleReset,
    handleSearch,
    handleSelect,
    handleCancelAssign,
    handleAssign,
    handleClose,
  };
};

export default useJobAssign;
