import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
};

const scheduledHistorySlice = createSlice({
  name: "scheduledHistory",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = scheduledHistorySlice.actions;

export default scheduledHistorySlice.reducer;
