import React from "react";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import useActivitySearchForm from "./useActivitySearchForm";
import RowOne from "./RowOne";

const ActivitySearchForm = ({ formik, handleChangeToggle, horoscope }) => {
  const { optionsData, isSuccess, loggedStaffData } = useActivitySearchForm();
  return isSuccess ? (
    <div className="pro-my-5">
      <div className="row">
        {/*
         * Contact View
         * Search Branch
         * Religion
         * Caste
         * Date From
         * Date To
         * All
         */}
        <RowOne
          optionsData={optionsData}
          formik={formik}
          loggedStaffData={loggedStaffData}
          handleChangeToggle={handleChangeToggle}
          horoscope={horoscope}
        />
      </div>
    </div>
  ) : (
    <div className={`row pro-my-5`}>
      {[...Array(28)].map((_, i) => (
        <div className={`col-3`} key={i}>
          <InputShimmer />
        </div>
      ))}
    </div>
  );
};

export default ActivitySearchForm;
