import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllRolesQuery } from "../../../../store/queries/Staff";
import { updateConfig } from "../../../../store/slices/Staff/roleSlice";

const useRoles = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const refetch = params.get("refecth");
  const dispatch = useDispatch();
  const rolesState = useSelector((state) => state.roles);

  const {
    data: roles = {},
    isFetching,
    refetch: refecthApi,
  } = useGetAllRolesQuery({
    page_size: rolesState.currentPageSize,
    page: rolesState.currentPage,
  });

  const hasCreatePermission = useMemo(() => {
    let permission = roles?.permissions?.filter((Obj) =>
    Object.keys(Obj).includes("can_create")
  )[0].can_create;

  return permission;
  }, [roles]);

  useEffect(() => {
    if (refetch === "true") {
      refecthApi();
    }
    // eslint-disable-next-line
  }, []);

  const handleSort = (label) => {
    if (rolesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rolesState.sortOrder === "asc" ? "asc" : "desc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    navigate("/dashboard/roles/manage-roles");
  };

  const handleEdit = (item) => {
    navigate({
      pathname: "/dashboard/roles/manage-roles",
      search: `role=${item[0]}&update=true`,
    });
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  //FIXME remove static feilds from below
  let fields = { id: { label: "ID" }, user_type: { label: "Role" } };

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  return {
    roles,
    rolesState,
    isFetching,
    hasCreatePermission,
    fields,
    paginationOptions,
    handleCreateClick,
    handleSort,
    handleEdit,
    handlePagination,
    handlePageSize,
    getRow,
  };
};

export default useRoles;
