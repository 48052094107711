import React from "react";
import { ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import useActionForms from "./useActionForms";

const ActionForms = ({ userId, isSuccess, profileId, ProfileName }) => {
  const { showActionsModal, handleCloseActionsModal, actionFormType, forms } =
    useActionForms({ userId, isSuccess, profileId, ProfileName });
  return (
    <ModalLayout
      show={showActionsModal}
      handleClose={handleCloseActionsModal}
      children={forms[actionFormType]}
      centered
    />
  );
};

export default ActionForms;
