import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import useHideProfileForm from "./useHideProfileForm";
import style from "./hideProfileForm.scss";

const HideProfileForm = ({ handleCancel, userId, isSuccess, ProfileName }) => {
  const { formik, isOptionDataSuccess } = useHideProfileForm({
    handleCancel,
    userId,
    ProfileName,
  });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Hide Profile</h4>
      </div>

      {isSuccess && isOptionDataSuccess ? (
        <>
          <Input
            label={"Reason"}
            type="text"
            id="reason"
            name="reason"
            className={`pro-input lg ${
              formik.touched.reason &&
              formik.errors.reason &&
              " error"
            }`}
            {...formik.getFieldProps("reason")}
            error={formik.touched.reason && formik.errors.reason}
            errorMessage={formik.errors.reason}
          />

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(1)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default HideProfileForm;
