import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  currentPage: 1,
  currentBranchFilter: [],
  currentSubscriptionFilter: [],
  currentDistrictFilter: [],
  currentReliginFilter: "",
  currentCasteFilter : [],
  currentCalenderFilter: 3,
  currentLookingFor : "",
  currentPlatformFilter: "",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  dateRange: [
    moment(Date.now()).add(-15, "days").format("YYYY-MM-DD"),
    moment(Date.now()).format("YYYY-MM-DD"),
  ],
  showJobAssign: false,
};

const registrationTypeReportSlice = createSlice({
  name: "registrationTypeReport",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = registrationTypeReportSlice.actions;

export default registrationTypeReportSlice.reducer;
