import { DatePickerWithInput } from "@wac-ui-dashboard/wac_component_library";
import { addDays } from "date-fns";
import Select from "react-select";
import React from "react";
import style from './listingFilter.module.scss'
const ListingFilter = ({
  initialDateRange = {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  },
  handleDateChange,
  optionsData,
  handleStaffFilter,
  handleBranchFilter,
  reportState,
  handleEditFieldsClick,
  handleClearFilters
}) => {
  return (
    <div className={`row g-2 ${style.root}`}>
      <div className={`col-3 `}>
        <DatePickerWithInput
          CalendarOutlineIcon={
            <span className="material-symbols-outlined">calendar_month</span>
          }
          range
          initialValue={initialDateRange}
          onDateChange={handleDateChange}
        ></DatePickerWithInput>
      </div>
      <div className="col-3 ">
        <Select
          id="branch"
          isClearable={true}
          placeholder={"Select"}
          options={optionsData?.data?.branchList}
          getOptionLabel={(option) => `${option.branch_name}`}
          getOptionValue={(option) => `${option["id"]}`}
          className={`pro-input lg pro-min-full multi-select`}
          value={optionsData?.data?.branchList?.filter(
            (p) =>
              p.id?.toString() === reportState?.currentBranchFilter?.toString()
          )}
          classNamePrefix="pro-input"
          onChange={(value) => handleBranchFilter(value?.id)}
        ></Select>
      </div>
      <div className="col-3 ">
        <Select
          id="staff"
          isClearable={true}
          isDisabled={reportState?.currentBranchFilter ? false : true}
          options={optionsData?.data?.staffList?.filter(
            (p) =>
              p.branches_id?.toString() ===
              reportState?.currentBranchFilter?.toString()
          )}
          getOptionLabel={(option) => `${option.staff_name}`}
          getOptionValue={(option) => `${option["id"]}`}
          className={`pro-input lg multi-select`}
          placeholder={"Staff"}
          value={optionsData?.data?.staffList?.filter(
            (p) =>
              p.id?.toString() === reportState?.currentStaffFilter?.toString()
          )}
          classNamePrefix="pro-input"
          onChange={(value) => handleStaffFilter(value?.id)}
        ></Select>
      </div>

      <div className={`col-auto`}>
        <button
          className="pro-btn pro-btn-outline lg"
          onClick={handleClearFilters}
        >
          Clear Filters
        </button>
      </div>

      <div className={`col-auto`}>
        <button
          className="pro-btn pro-btn-outline lg"
          onClick={handleEditFieldsClick}
        >
          Edit Columns
        </button>
      </div>
    </div>
  );
};

export default ListingFilter;
