import { intializeCall } from "./api";
import { toast } from "react-toastify";
const useCallBox = ({ setCallInitiateStatus }) => {
  const handleCall = (activeNumber) => {
    setCallInitiateStatus(true);
    if (activeNumber?.value) {
      intializeCall({
        mobile: activeNumber?.value,
      }).then((res) => {
        setCallInitiateStatus(false);
        if (res.data.status_code === 200) {
          toast.success(res?.data?.message ?? "Call Initiated Successfully");
        } else {
          toast.error(res?.data?.message ?? "Failed to initiate the call");
        }
      });
    } else {
      toast.error("mobile number not found");
    }
  };
  return { handleCall };
};

export default useCallBox;
