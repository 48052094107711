import React, { useState } from "react";

import Style from "./multicoloumntableactivity.module.scss";

export const MultiColoumnTableActivity = ({
  extraClassNames,
  title,
  editIcon,
  showreadmore,
  sliceLength,
  handleEdit,
  data,
  minWidth,
  handleInitiateCall,
  outgoingCallStatus,
  loadingLabel,
  ActivityProfile,
  handleViewHistoryModal,
}) => {
  const [readmore, setReadmore] = useState(() => (showreadmore ? false : true));

  const handleReadmore = () => {
    setReadmore((state) => !state);
  };
  return (
    <div className={`${Style.root} ${extraClassNames}`}>
      <div className={`${Style.root_header} pro-pb-4`}>
        {title && <h6 className="pro-ttl h6 pro-mb-0">{title}</h6>}
        {editIcon && (
          <button
            onClick={handleEdit}
            className={`${Style.root_Icon} pro-btn pro-p-2 border-rounded`}
          >
            {editIcon}
          </button>
        )}
      </div>

      <div className={`${Style.root_body} pro-pb-0`}>
        <table className="pro-table no-border">
          <tbody>
            {data?.basic_details?.map?.((item, index) => (
              <tr key={index}>
                <td width={minWidth || `auto`} colSpan={item?.value ? 1 : 2}>
                  <p className={`pro-mb-0 ${item?.bold ? "pro-fw-bold " : ""}`}>
                    {item?.label}
                  </p>
                </td>
                {item?.value && !item?.is_tel ? (
                  <td>
                    <p
                      className={`pro-font-sm pro-mb-0 ${
                        item?.bold ? "pro-fw-bolder" : ""
                      }`}
                    >
                      {showreadmore &&
                      (item.value?.length > sliceLength ||
                        item.value?.length > 100) ? (
                        <>
                          {readmore ? (
                            <>
                              {item?.value}
                              <span
                                onClick={handleReadmore}
                                className={`pro-btn-link pro-fw-medium pro-mt-2 ${Style.btn_read_more}`}
                              >
                                read less
                              </span>
                            </>
                          ) : (
                            <>
                              {item?.value?.slice(0, sliceLength ?? 100)}
                              <span
                                onClick={handleReadmore}
                                className={`pro-btn-link pro-fw-medium pro-mt-2 ${Style.btn_read_more}`}
                              >
                                read more
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className={Style.value_box}>
                            {item?.value}

                            {ActivityProfile &&
                              item?.value?.trim()?.endsWith(")") &&
                              item.label === "Contact Viewed On" && (
                                <button
                                  className={`pro-btn  ${Style.button_history}`}
                                  onClick={(e) => {
                                    handleViewHistoryModal(e, data);
                                  }}
                                >
                                  <span
                                    title="View History"
                                    className="material-symbols-outlined x5 pro-btn-link"
                                  >
                                    history
                                  </span>
                                </button>
                              )}
                          </div>
                        </>
                      )}
                    </p>
                  </td>
                ) : (
                  <td>
                    <a href={`tel:${item?.mobile}`} className="pro-btn-link">
                      {item?.value}
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
