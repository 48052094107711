import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useGetSchedulesListDataQuery,
  useGetScheduleUpdationFormDataQuery,
  useUpdateSchedulesListFieldsMutation,
} from "../../../store/queries/Schedule";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";
import { updateFormData } from "../../../store/slices/Schedules/createScheduleSlice";
import { updateConfig } from "../../../store/slices/Schedules/scheduleSlice";
import { ScheduleForRow } from "./Rows";

const useSchedule = () => {
  const scheduleState = useSelector((state) => state.schedules);
  const [skip, setSkip] = useState(true);
  const { showCreateModal } = useSelector((state) => state.schedules);
  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  const {
    data: schedules = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetSchedulesListDataQuery({
    page_size: scheduleState.currentPageSize,
    page: scheduleState.currentPage,
    user_type_sort: scheduleState.currentFilter,
    sort_by: scheduleState.sortBy,
    sort_order: scheduleState.sortOrder,
  });

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const obj = schedules?.data?.field || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const { data: editSchedules = {}, isFetching: isFormFetching } =
    useGetScheduleUpdationFormDataQuery(
      {
        id: scheduleState?.scheduleUpdateId,
      },
      { skip: skip }
    );

  useEffect(() => {
    if (scheduleState?.scheduleUpdateId) {
      dispatch(
        updateFormData((state) => {
          state.currentFormData = editSchedules?.data;
          state.completedTabs = {
            ...state.completedTabs,
            Basic: true,
            Interval: true,
          };
        })
      );
    }

    // eslint-disable-next-line
  }, [isFormFetching, scheduleState?.scheduleUpdateId]);

  const [updateScheduleFields] = useUpdateSchedulesListFieldsMutation();

  const dispatch = useDispatch();

  const hasCreatePermission = useMemo(() => {
    let permission = schedules?.data?.permission?.filter((Obj) =>
      Object.keys(Obj).includes("can_create")
    )[0].can_create;

    return permission;
    // eslint-disable-next-line
  }, [schedules]);

  const hasEditPermission = useMemo(() => {
    let permission = schedules?.data?.permission?.filter((Obj) =>
      Object.keys(Obj).includes("can_edit")
    )[0].can_edit;

    return permission;
    // eslint-disable-next-line
  }, [schedules]);

  const filters = useMemo(() => {
    if (schedules?.data?.filter) {
      const buttonGroups = schedules?.data?.filter?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (scheduleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = scheduleState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateFormData((state) => {
        state.currentFormData = [];
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Interval: 0,
        };
        state.activeTab = "Basic";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.scheduleUpdateId = null;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Interval: 0,
        };
        state.currentFormData = [];
        state.activeTab = "Basic";
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      schedule_for: (_, data) => <ScheduleForRow data={data} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleTableEditClick = (item) => {
    setSkip(false);
    dispatch(
      updateConfig((state) => {
        state.scheduleUpdateId = item[0];
        state.showCreateModal = true;
      })
    );
  };

  return {
    schedules,
    scheduleState,
    filters,
    currentPage: scheduleState.currentPage,
    activeFilter: scheduleState.currentFilter,
    showCreateModal,
    showTableFieldsModal,
    hasCreatePermission,
    hasEditPermission,
    paginationOptions,
    isFetching,
    isLoading,
    sortedTableFields,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleSort,
    handleCreateClick,
    handleEditClick,
    closeEditModal,
    closeModal,
    getRow,
    refetch,
    updateScheduleFields,
    handleTableEditClick,
  };
};

export default useSchedule;
