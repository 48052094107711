import { getAxiosInstance } from "../../api";

export const getCronStatus = async () => {

    const api = await getAxiosInstance();
    try {
      const response = await api.get("report/call-history-cron");
  
      return response;
    } catch (error) {
  
      return error.response.data
    }
  };