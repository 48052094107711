import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const ContactInfo = ({
  formik,
  optionsData,
  handleSelectCountry,
  handleSelectState,
  stateRef,
  districtRef,
  handleCheckbox,
}) => {
  return (
    <div className="row" id="contact-info">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">Contact Info</h6>
      </div>

      <div className={`col-12`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Communication Address*
          </label>
          <textarea
            id="communication_address"
            name="communication_address"
            className={`pro-input lg ${
              formik.touched.communication_address &&
              formik.errors.communication_address &&
              " error"
            }`}
            {...formik.getFieldProps("communication_address")}
          ></textarea>

          {formik.touched.communication_address &&
            formik.errors.communication_address && (
              <span className="error-text">
                {formik.errors.communication_address}
              </span>
            )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Country
          </label>
          <Select
            id="country"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.countryList}
            getOptionLabel={(option) => `${option.country_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.country && formik.errors.country && " error"
            }`}
            value={optionsData?.data?.countryList?.filter(
              (p) => p.id?.toString() === formik.values.country?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("country")}
            onChange={(value) => handleSelectCountry(value)}
          ></Select>
          {formik.touched.country && formik.errors.country && (
            <span className="error-text">{formik.errors.country}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="state"
            isClearable={true}
            placeholder={"Select"}
            ref={stateRef}
            options={optionsData?.data?.stateList?.filter(
              (p) =>
                p.country_id?.toString() === formik.values.country?.toString()
            )}
            getOptionLabel={(option) => `${option.state_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.state && formik.errors.state && " error"
            } ${!formik.values.country && "disabled"}`}
            value={optionsData?.data?.stateList?.filter(
              (p) => p.id?.toString() === formik.values.state?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("state")}
            onChange={(value) => handleSelectState(value)}
          ></Select>
          {formik.touched.state && formik.errors.state && (
            <span className="error-text">{formik.errors.state}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            District
          </label>
          <Select
            id="district"
            isClearable={true}
            placeholder={"Select"}
            ref={districtRef}
            options={optionsData?.data?.districtsList?.filter(
              (p) => p.state_id?.toString() === formik.values.state?.toString()
            )}
            getOptionLabel={(option) => `${option.district_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.district && formik.errors.district && " error"
            } ${!formik.values.state && "disabled"}`}
            value={optionsData?.data?.districtsList?.filter(
              (p) => p.id?.toString() === formik.values.district?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("district")}
            onChange={(value) => formik.setFieldValue("district", value?.id || null)}
          ></Select>
          {formik.touched.district && formik.errors.district && (
            <span className="error-text">{formik.errors.district}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-6`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Communication Address(Specify If Country Not India)
          </label>
          <textarea
            id="job_location_address"
            name="job_location_address"
            className={`pro-input lg ${
              formik.touched.job_location_address &&
              formik.errors.job_location_address &&
              " error"
            }`}
            {...formik.getFieldProps("job_location_address")}
          ></textarea>

          {formik.touched.job_location_address &&
            formik.errors.job_location_address && (
              <span className="error-text">
                {formik.errors.job_location_address}
              </span>
            )}
        </div>
      </div>

      <div className={`col-lg-6`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Route To Residence
          </label>
          <textarea
            id="residence_route"
            name="residence_route"
            className={`pro-input lg ${
              formik.touched.residence_route &&
              formik.errors.residence_route &&
              " error"
            }`}
            {...formik.getFieldProps("residence_route")}
          ></textarea>

          {formik.touched.residence_route && formik.errors.residence_route && (
            <span className="error-text">{formik.errors.residence_route}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <Input
          label={"Latitude"}
          type="text"
          id="latitude"
          name="latitude"
          className={`pro-input lg ${
            formik.touched.latitude && formik.errors.latitude && " error"
          }`}
          {...formik.getFieldProps("latitude")}
          error={formik.touched.latitude && formik.errors.latitude}
          errorMessage={formik.errors.latitude}
        />
      </div>

      <div className={`col-3`}>
        <Input
          label={"Longitude"}
          type="text"
          id="longitude"
          name="longitude"
          className={`pro-input lg ${
            formik.touched.longitude && formik.errors.longitude && " error"
          }`}
          {...formik.getFieldProps("longitude")}
          error={formik.touched.longitude && formik.errors.longitude}
          errorMessage={formik.errors.longitude}
        />
      </div>

      <div className={`col-12 pro-mt-3 pro-mb-5`}>
        <div className="pro-check-box">
          <input
            type="checkbox"
            className="pro-check "
            id="hide_address"
            name="hide_address"
            checked={formik.values?.hide_address ?? false}
            onChange={(e) =>
              handleCheckbox(formik.values?.hide_address ?? false)
            }
          />
          <label htmlFor="hide_address" className="pro-check-label">
            {" "}
            Hide Address{" "}
          </label>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
