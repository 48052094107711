import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useInterval from "./useInterval";

const Interval = ({ optionsData, isOptionDataSuccess,refetch }) => {
  const {
    formik,
    errors,
    loading,
    backendErrors,
    intervalArrayCount,
    handleCloseModal,
    getOrdinalNumbers,
    //handleIntervalChange,
    scheduleUpdateId,
  } = useInterval({ optionsData, isOptionDataSuccess,refetch });

  return (
    <div className={`pro-p-0 pro-w-100`}>
      <form action="" onSubmit={formik.handleSubmit}>
        {intervalArrayCount?.map((interval, index) => {
          return (
            <Input
              key={index}
              label={`${getOrdinalNumbers(index + 1)} call on*`}
              type="text"
              id={`interval[${index}]`}
              name={`interval[${index}]`}
              className={`pro-input lg ${
                formik.touched.interval?.[index] &&
                formik.errors.interval?.[index] &&
                " error"
              }`}
              // onChange={(e)=>handleIntervalChange(e.target.value,index)}
              // onBlur={formik.handleBlur}
               {...formik.getFieldProps(`interval[${index}]`)}
              error={
                formik.touched.interval?.[index] &&
                formik.errors.interval?.[index]
              }
              errorMessage={formik.errors.interval?.[index]}
            />
          );
        })}

        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={(e) => handleCloseModal(e)}
          >
            Cancel
          </Button>

          <Button className={"pro-btn-primary lg pro-mx-3"} type="submit">
            {loading ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            {!scheduleUpdateId ? "Schedule" : " Update Schedule"}
          </Button>
        </div>

        {errors && <span className="common-error">{errors}</span>}

        {Object.keys(backendErrors)?.map((key, index) => {
          return (
            <div className="common-error">
              {index + 1 + " : " + backendErrors[key]}{" "}
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default Interval;
