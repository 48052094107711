import { useFormik } from "formik";
import { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  // getAdvancedSearch,
  updateConfig,
} from "../../../store/slices/Search/AdvancedSearch";
// import useObserver from "../../../utils/hooks/useObserver";
import { useSearchParams } from "react-router-dom";
import { updateProfileConfig } from "../../../store/slices/Profile";
import html2pdf from "html2pdf.js";
import moment from "moment";
import {
  useGetAdvancedSearchResultsQuery,
  useGetSearchFiltersQuery,
} from "../../../store/queries/Search";
import { exportAdvancedSearch } from "./api";

const useAdvancedSearch = () => {
  const [params] = useSearchParams();
  const printRef = useRef();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.advancedSearch);
  const [skip, setSkip] = useState(true);
  const [searchSkip, setSearchSkip] = useState(true);
  const [isFetchLoading, setIsFetchLoading] = useState();
  const { selectedProfiles, showPdf } = useSelector((state) => state.profile);
  const [showScroll, setShowScroll] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdf2Loading, setPdf2Loading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [exportOption, setExportOption] = useState(100);
  const [exportLoading, setExportLoading] = useState(false);
  // const [page, setPage] = useState(1);

  const { data: optionsData, isSuccess: isSearchFilterSuccess } =
    useGetSearchFiltersQuery();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  //setting date variables
  let today = new Date();
  let pastDate = new Date();
  pastDate.setDate(today.getDate() - 30);

  const formik = useFormik({
    initialValues: {
      looking_for: null,
      age_from: null,
      age_to: null,
      height_from: null,
      height_to: null,
      religion: null,
      branch: [],
      registered_platform: null,
      caste: [],
      residing_country: null,
      residing_state: null,
      residing_district: [],
      special_case: null,
      job_location: [],
      star_id: null,
      registration_type: ["1", "3", "4", "5", "6", "7", "8", "9"],
      admin_verified: false,
      have_photo: null,
      main_education: [],
      user_education: [],
      marital_status: [],
      main_job: [],
      user_job: [],
      date_type: 1,
      children: null,
      agreement_status: null,
      // register_from_date: getFormatedDate(pastDate),
      // register_to_date: getFormatedDate(today),
      register_from_date: null,
      register_to_date: null,
      entire_data: true,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.searchValues = { ...values };
        })
      );
      setSearchSkip(false);
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
    },
  });

  const {
    data: advancedSearchResults = {},
    isError,
    isSuccess,
    isFetching,
  } = useGetAdvancedSearchResultsQuery(
    {
      ...state.searchValues,
      page: state.currentPage,
      per_page: state.currentPageSize,
    },
    { skip: searchSkip }
  );

  useEffect(() => {
    if (
      selectedProfiles?.length === advancedSearchResults?.data?.data?.length
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    //eslint-disable-next-line
  }, [selectedProfiles, advancedSearchResults?.data?.data]);

  let paginationOptions = [
    { label: "15 Per page", value: 15 },
    { label: "30 Per page", value: 30 },
    { label: "45 Per page", value: 45 },
  ];

  const handlePagination = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
      dispatch(
        updateConfig((state) => {
          state.advancedSearchStatus = "idle";
          state.advancedSearchData = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleSearch = () => {
    dispatch(
      updateConfig((state) => {
        state.advancedSearchStatus = "idle";
        state.advancedSearchData = {};
        state.currentPage = 1;
      })
    );
    formik.handleSubmit();
  };
  const handleReset = () => {
    formik.resetForm();
    dispatch(
      updateConfig((state) => {
        state.advancedSearchStatus = "idle";
        state.advancedSearchData = {};
      })
    );
  };

  const handleDownloadClick = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = state.selectedProfiles.filter(
          (a) => a !== activeProfile
        );
        state.showPdf = true;
      })
    );
    setSkip(false);
  };

  const handleDownloadPdf = async () => {
    setPdfLoading(true);
    const element = printRef.current;
    const pdfPromise = new Promise((resolve) => {
      html2pdf(element, {
        margin: [0, 0, 0, 0],
        filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          width: window?.width,
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 3,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      }).then(() => {
        // Resolve the Promise when the pdf is generated
        resolve();
      });
    });

    // Wait for the Promise to resolve before showing "Download finished" toast
    await pdfPromise;

    setPdfLoading(false);
    setSkip(true);
  };

  const handleSelect = (id, checked) => {
    if (checked) {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [...state.selectedProfiles, id];
        })
      );
    } else {
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = state.selectedProfiles.filter(
            (prevID) => prevID !== id
          );
        })
      );
    }
  };

  const closePdfModal = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showPdf = false;
      })
    );
  };

  const handleSelectAll = () => {
    // Toggle the isChecked state
    setIsChecked((prev) => !prev);

    if (!isChecked) {
      // If isChecked is true, select all items
      const selectedProfileIds = advancedSearchResults?.data?.data?.map(
        (item) => item?.profile_data?.id
      );

      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = selectedProfileIds;
        })
      );
    } else {
      // If isChecked is false, deselect all items
      dispatch(
        updateProfileConfig((state) => {
          state.selectedProfiles = [];
        })
      );
    }
  };

  const handleExportClick = () => {
    setExportLoading(true);
    exportAdvancedSearch({
      ...state.searchValues,
      export_count: exportOption,
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `advanced_search_users_${moment(Date.now()).format(
          "MM_DD_YYYY_HH_mm_ss"
        )}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setExportLoading(false);
    });
  };

  const hasExportPermission = useMemo(() => {
    let permission = advancedSearchResults?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_export")
    );
    return permission?.[0]?.can_export ?? false;
    // eslint-disable-next-line
  }, [advancedSearchResults]);

  const hasDownloadPdfPermission = useMemo(() => {
    let permission = advancedSearchResults?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_download")
    );
    return permission?.[0]?.can_download ?? false;
    // eslint-disable-next-line
  }, [advancedSearchResults]);

  const handleDownloadTable = async () => {
    setPdf2Loading(true);
    const element = tableRef.current;
    const pdfPromise = new Promise((resolve) => {
      html2pdf(element, {
        margin: [5, 5],
        filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          width: window?.width,
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 3,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      }).then(() => {
        // Resolve the Promise when the pdf is generated
        resolve();
      });
    });

    // Wait for the Promise to resolve before showing "Download finished" toast
    await pdfPromise;
    setPdf2Loading(false);
  };

  return {
    handlePagination,
    handlePageSize,
    setIsFetchLoading,
    closePdfModal,
    handleDownloadPdf,
    handleScrollToTop,
    // lastElement,
    handleSearch,
    handleReset,
    handleSelect,
    handleDownloadClick,
    setExportOption,
    handleSelectAll,
    handleExportClick,
    handleDownloadTable,
    searchSkip,
    hasDownloadPdfPermission,
    hasExportPermission,
    exportLoading,
    optionsData,
    isSearchFilterSuccess,
    exportOption,
    formik,
    state,
    showScroll,
    selectedProfiles,
    activeProfile,
    printRef,
    tableRef,
    showPdf,
    skip,
    isFetchLoading,
    paginationOptions,
    currentPage: state?.currentPage,
    advancedSearchResults,
    isFetching,
    isSuccess,
    isError,
    pdfLoading,
    pdf2Loading,
    isChecked,
  };
};

export default useAdvancedSearch;
