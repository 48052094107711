import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useCreateProfileReligiousInfoMutation } from "../../../../store/queries/Registration";
import {
  getUserDetails,
  updateFormData,
} from "../../../../store/slices/Registration/createProfileSlice";
import { getMatchedStars } from "./api";
import { useNavigate } from "react-router-dom";
import { profile } from "../../../../store/queries/Profile";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const useReligious = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [params] = useSearchParams();

  const [saveAndNext, setSaveAndNext] = useState(false);
  const [format, setFormat] = useState("HH:mm:ss aa");

  const { currentFormDataReligious } = useSelector(
    (state) => state.createProfile
  );

  const initialTime = {
    time: new Date(),
  };
  const initialTimeSet = new Date(initialTime.time.setHours(0, 0, 0, 0));
  const [time, setTime] = useState(initialTimeSet);

  const [registerProfile, { isLoading: isUpdating }] =
    useCreateProfileReligiousInfoMutation();

  const dispatch = useDispatch();
  let createProfileReligiousVal = Yup.object({
    malayalam_dob: Yup.string(),
    time_of_birth: Yup.string(),
    jathakam_type: Yup.string(),
    stars_id: Yup.string(),
    match_star: Yup.array(),
    dasa_name: Yup.string(),
    sista_dasa_year: Yup.string(),
    sista_dasa_month: Yup.string(),
    sista_dasa_day: Yup.string(),
    other_religious_info: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      malayalam_dob: "",
      time_of_birth: "12:00:00 AM",
      jathakam_type: "",
      stars_id: "",
      match_star: [],
      dasa_name: "",
      sista_dasa_year: "",
      sista_dasa_month: "",
      sista_dasa_day: "",
      other_religious_info: "",
    },

    validationSchema: edit !== "true" ? createProfileReligiousVal : null,

    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values,
          user_id: activeUser ?? params.get("user"),
        };

        dispatch(
          updateFormData((state) => {
            state.currentFormDataReligious = {
              ...state.currentFormDataReligious,
              ...obj,
            };
            state.completedTabs = {
              ...state.completedTabs,
              Religious: true,
              Horoscope: true,
            };
          })
        );

        if (formik?.values?.user_id) {
          registerProfile(obj).then((response) => {
            formik.setErrors("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              submitForm(resetForm);
            } else if (response?.error?.data?.status_code === 422) {
              formik.setErrors(response?.error?.data?.errors);
            }
          });
        } else {
          setErrors("Fill required fields in all  Forms");
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = () => {
    dispatch(profile.util.invalidateTags(["Profile", "Overview"]));
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Horoscope";
      })
    );

    toast.success("Successfully updated");
    if (toTab && !saveAndNext) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (Object.keys(currentFormDataReligious).length === 0) {
      dispatch(
        getUserDetails({
          endpoint: "user-religious-info",
          id: activeUser,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormDataReligious || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormDataReligious?.[key]);
      });
      const timeOfBirth = currentFormDataReligious["time_of_birth"];

      function convertTimeFormat(timeString) {
        // two possibilities of receiving time which in hh:mm:ss A and HH:MM,
        // here in this function checkingTime  we are checking in which format the time is receiving,
        //  and if its in HH:MM format then we are converting it into hh:mm:ss A format and
        // if not we are returning the same time which we received
        const checkingTime = (ReceivedTime) => {
          const timeRegex =
            /^(?:(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])|(0:00))|(?:([0-9]|1[0-1]):([0-5][0-9]) ?([APap][Mm]))|(?:([0-9]|1[0-1])\.([0-5][0-9]) ?([APap][Mm]))$/;
          if (timeRegex?.test(ReceivedTime)) {
            const parsedTime = moment(ReceivedTime, ["h:mm A", "HH:mm"]);
            let formattedTime = parsedTime.format("hh:mm:ss A");
            // Check if the hour is greater than or equal to 12 to determine AM or PM
            if (parsedTime.hours() >= 12) {
              formattedTime = formattedTime.replace("AM", "PM");
            }
            return formattedTime;
          } else {
            return ReceivedTime;
          }
        };

        //after making the received time in required format we are making the time converted
        //according to the requirement of the timepicker
        const timeRecievedConverted = checkingTime(timeString);
        const timeParts = timeRecievedConverted.split(":");
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
        const seconds = parseInt(timeParts[2].split(" ")[0], 10);
        const ampm = timeParts[2].split(" ")[1];

        const currentDate = new Date();

        let adjustedHours = hours;

        if (ampm === "AM") {
          adjustedHours = hours === 12 ? 0 : hours; // Set 12 AM to 0
        } else if (ampm === "PM" && hours !== 12) {
          adjustedHours = hours + 12; // Adjust for hours after noon in PM
        }

        const ttt = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          adjustedHours,
          minutes,
          seconds
        );

        return ttt;
      }

      if (timeOfBirth) {
        const formatTime = (date) => {
          try {
            const formattedTime = moment(date).format("hh:mm:ss A");
            return formattedTime;
          } catch (error) {
            console.error("Invalid date format:", error);
            return "Invalid Time";
          }
        };

        const convertedTimeOfBirth = convertTimeFormat(timeOfBirth);
        const formattedRecievedTime = formatTime(convertedTimeOfBirth);

        if (formattedRecievedTime !== "12:00:00 AM") {
          setFormat("hh:mm:ss aa");
        } else {
          setFormat("HH:mm:ss aa");
        }

        setTime(convertedTimeOfBirth);
        formik.setFieldValue("time_of_birth", formattedRecievedTime);
      }
    }
    //eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormDataReligious]);

  useEffect(() => {
    let totalFields = Object.values(formik.values).filter(
      (f) => f !== "" && f !== null
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Religious: Math.ceil(
            (totalFields / Object.keys(formik.values).length) * 100
          ),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  // const onChange = (newTime) => {
  //   console.log(newTime, "newtime");
  //   if (newTime !== null) {
  //     setTime(newTime);
  //     let [hour, minute] = newTime.split(":");
  //     let now = new Date();
  //     now.setHours(hour, minute);

  //     let time12 = now.toLocaleString("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: true,
  //       hourCycle: "h12",
  //     });

  //     let ConvertedTime12 = (time12 = time12
  //       .replace(/^(\d{1}):/, "0$1:")
  //       .replace(/^(\d{1}) /, "0$1 "));

  //     formik.setFieldValue("time_of_birth", ConvertedTime12);
  //   }
  // };

  const onChange = (value) => {
    const formatTime = (date) => {
      const formattedTime = moment(date).format("hh:mm:ss A");
      return formattedTime;
    };
    const formattedTime = formatTime(value);
    if (value) {
      if (formattedTime !== "12:00:00 AM") {
        setFormat("hh:mm:ss aa");
      } else {
        setFormat("HH:mm:ss aa");
      }
    }
    setTime(value);
    formik.setFieldValue("time_of_birth", formattedTime);
  };

  const handleSelectStar = (value) => {
    formik.setFieldValue("stars_id", value?.id || null);
    getMatchedStars({ user_id: activeUser, star_id: value?.id }).then(
      (response) => {
        formik.setFieldValue(
          "match_star",
          response?.data?.data?.integratedStarList
        );
      }
    );
  };

  // const handleClearClick = () => {
  //   setTime("00:00:00 AM");
  //   formik.setFieldValue("time_of_birth", "00:00:00 AM");
  // };

  const handleClearTimeValues = () => {
    setFormat("HH:mm:ss aa");
    setTime(initialTimeSet);
    formik.setFieldValue("time_of_birth", "12:00:00 AM");
  };

  return {
    errors,
    formik,
    isUpdating,
    time,
    initialTimeSet,
    format,
    handleClearTimeValues,
    // handleClearClick,
    setSaveAndNext,
    onChange,
    handleSelectStar,
  };
};

export default useReligious;
