import {
  DatePickerWithInput,
  HeadingGroup,
  // ModalLayout,
  OffCanvasLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useSocialMediaReport from "./useSocialMediaReport";
import EmptyData from "../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import SocialMediaForm from "./SocialMediaForm";
// import OrderColumn from "../Global/OrderColumn";

const SocialMediaReport = () => {
  const {
    initialDateRange,
    formik,
    socialMediaState,
    showEditModal,
    socialMediaData,
    isFetching,
    paginationOptions,
    // showTableFieldsModal,
    exportLoading,
    handleExportClick,
    // handleEditFieldsClick,
    handlePagination,
    handlePageSize,
    refetch,
    closeEditModal,
    handleEditAction,
    handleSort,
    getRow,
    handleSyncReport,
    handleDateChange,
  } = useSocialMediaReport();
  return (
    <>
      <HeadingGroup title={"Social Media Report"} className={`pro-mb-0`} />
      <div className="pro-d-flex pro-justify-between pro-mt-2">
        <div className={`col-auto`}>
          <DatePickerWithInput
            CalendarOutlineIcon={
              <span className="material-symbols-outlined">calendar_month</span>
            }
            range
            initialValue={initialDateRange}
            onDateChange={handleDateChange}
          ></DatePickerWithInput>
        </div>

        <div className="pro-d-flex">
          <div className={`col-auto`}>
            <button
              className={`pro-btn pro-btn-outline pro-px-5 lg ${
                exportLoading ? "loading" : ""
              }`}
              onClick={handleExportClick}
            >
              Export
            </button>
          </div>
          <div className={`col-auto pro-ms-2`}>
            <button
              className={`pro-btn pro-btn-primary lg pro-px-5 ${
                formik?.isSubmitting ? "loading" : ""
              }`}
              onClick={formik.handleSubmit}
            >
              Save
            </button>
          </div>

          <div className={`col-auto pro-ms-2`}>
            <button
              className={`pro-btn pro-btn-primary pro-px-5 lg`}
              onClick={handleSyncReport}
            >
              Sync
            </button>
          </div>
        </div>
      </div>

      <div className="pro-pb-6 pro-scroll-lg">
        {!socialMediaData?.data?.data ||
        socialMediaData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={socialMediaData?.data?.data || []}
            uniqueID={"date_id"}
            fields={socialMediaData?.data?.field}
            showCheckBox={true}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={socialMediaState?.currentPageSize}
            assignable={false}
            editable={true}
            deletable={false}
            multiSelect={false}
            handleEdit={handleEditAction}
          />
        )}
      </div>

      <OffCanvasLayout
        title={"Edit Report"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        show={showEditModal}
        handleClose={closeEditModal}
      >
        <SocialMediaForm handleClose={closeEditModal} refetch={refetch} />
      </OffCanvasLayout>

      {socialMediaData?.data?.data?.length > 0 && (
        <Pagination
          currentPage={socialMediaState?.currentPage}
          totalPageCount={Math.ceil(
            socialMediaData?.data?.total_count /
              socialMediaState?.currentPageSize
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
    </>
  );
};

export default SocialMediaReport;
