import {
  HeadingGroup,
  Pagination,
  Table,
  Button,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useScheduledHistory from "./useScheduledHistory";
import AssignForm from "../../JobAssign/Forms/AssignForm";

const ScheduledHistory = () => {
  const {
    scheduledHistory,
    isFetching,
    currentPage,
    paginationOptions,
    activeProfile,
    showAssignForm,
    refetch,
    handleClose,
    handleAddSchedule,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
  } = useScheduledHistory();

  return (
    <div className="pro-p-5">
      <HeadingGroup title={"Schedule"} extraClassName={`pro-mb-2`}>
        <div className={`pro-w-100 pro-d-flex`}>
          <Button
            type="submit"
            className={`pro-btn pro-btn-primary pro-ms-auto ${
              false === "pending" ? "loading" : ""
            }`}
            onClick={() => handleAddSchedule()}
          >
            Add Schedule
          </Button>
        </div>
      </HeadingGroup>

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <Table
          data={scheduledHistory?.data?.data || []}
          fields={scheduledHistory?.data?.fields}
          showCheckBox={false}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={10}
        />

        {scheduledHistory?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              scheduledHistory?.data?.total_count /
                scheduledHistory?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          title={"Job Assigned to"}
          show={showAssignForm}
          handleClose={handleClose}
          body={
            <AssignForm
              checked={[activeProfile]}
              handleCancel={handleClose}
              refetch={refetch}
            
            />
          }
        />
      </div>
    </div>
  );
};

export default ScheduledHistory;
