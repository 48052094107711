import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useServiceStatusAlert from "./useServiceStatusAlert";
import OrderColumn from "../Global/OrderColumn";

const ServiceStatusAlert = () => {
  const {
    serviceStatusAlert,
    serviceStatusAlertState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showTableFieldsModal,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    updateServiceStatusAlertFields,
  } = useServiceStatusAlert();

  return (
    <>
      <HeadingGroup
        title={"Service Status Alert"}
        extraClassName={`pro-mb-2`}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          searchable={false}
        />

        <Table
          data={serviceStatusAlert?.data?.data || []}
          uniqueID={"Image"}
          fields={sortedTableFields}
          showCheckBox={false}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          deletable={false}
          multiSelect={false}
          perpage={serviceStatusAlertState.currentPageSize}
        />

        {serviceStatusAlert?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              serviceStatusAlert?.data?.total_count /
                serviceStatusAlert?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={serviceStatusAlert?.data?.table_id}
              updateData={updateServiceStatusAlertFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default ServiceStatusAlert;
