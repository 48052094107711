import { DatePickerWithInput } from "@wac-ui-dashboard/wac_component_library";

import Select from "react-select";
import React from "react";
import style from "./listingFilter.module.scss";
const ListingFilter = ({
  initialDateRange,
  handleDateChange,
  optionsData,
  isFetching,
  formik,
  handleBranchFilter,
  handleCurrentLookingFor,
  handleCurrentSubscriptionFilter,
  handlePlatformFilter,
  handleCurrentReligionFilter,
  handleCurrentCountryFilter,
  handleCurrentStateFilter,
  handleCurrentDistrictFilter,
  handleCurrentCasteFilter,
  handleCalenderTypeFilter,
}) => {
  return (
    <>
      <div className={`row g-2 ${style.root}`}>
        <div className="col-3">
          <Select
            id="calender_type"
            isClearable={false}
            placeholder={"Calender Type"}
            options={optionsData?.data?.dateTypeList}
            getOptionLabel={(option) => option.date_type}
            getOptionValue={(option) => option.id}
            className={`pro-input lg  `}
            classNamePrefix="pro-input"
            value={optionsData?.data?.dateTypeList?.filter(
              (p) => formik?.values?.date_type?.toString() === p.id?.toString()
            )}
            onChange={(value) => handleCalenderTypeFilter(value?.id)}
          />
        </div>
        <div className={`col-3`}>
          <DatePickerWithInput
            CalendarOutlineIcon={
              <span className="material-symbols-outlined">calendar_month</span>
            }
            range
            initialValue={initialDateRange}
            onDateChange={handleDateChange}
          ></DatePickerWithInput>
        </div>
        <div className="col-3">
          <Select
            id="looking_for"
            isClearable={true}
            placeholder={"Looking For"}
            options={optionsData?.data?.LookingFor}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.LookingFor?.filter(
              (p) =>
                formik?.values?.looking_for?.toString() === p.id?.toString()
            )}
            onChange={(value) => handleCurrentLookingFor(value?.id)}
          ></Select>
        </div>
        <div className="col-3">
          <Select
            id="branch"
            isMulti
            isClearable={true}
            placeholder={"Branch"}
            options={optionsData?.data?.branchList}
            getOptionLabel={(option) => option.branch_name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.branchList?.filter((p) =>
              formik?.values?.branch?.includes(p.id?.toString())
            )}
            onChange={(value) =>
              handleBranchFilter(value.map((v) => v.id?.toString()))
            }
          ></Select>
        </div>
        <div className="col-3">
          <Select
            id="subscription"
            isMulti
            isClearable={true}
            placeholder={"Subscription"}
            options={optionsData?.data?.packageList}
            getOptionLabel={(option) => option.subscription_title}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.packageList?.filter((p) =>
              formik?.values?.subscription?.includes(p.id?.toString())
            )}
            onChange={(value) =>
              handleCurrentSubscriptionFilter(
                value.map((v) => v.id?.toString())
              )
            }
          />
        </div>

        <div className="col-3">
          <Select
            id="platform"
            isClearable={true}
            placeholder={"Platform"}
            options={optionsData?.data?.registeredPlatformList}
            getOptionLabel={(option) => option.platform_name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.registeredPlatformList?.find(
              (p) => formik?.values?.registered_platform === p.id?.toString()
            )}
            onChange={(value) => handlePlatformFilter(value?.id)}
          ></Select>
        </div>

        <div className="col-3">
          <Select
            id="country"
            isClearable={false}
            placeholder={"Country"}
            options={optionsData?.data?.countryList}
            getOptionLabel={(option) => option.country_name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.countryList?.find(
              (p) => formik?.values?.country?.toString() === p.id?.toString()
            )}
            onChange={(value) => handleCurrentCountryFilter(value?.id)}
          ></Select>
        </div>

        <div className="col-3">
          <Select
            id="state"
            isClearable={false}
            isDisabled={!formik?.values?.country}
            placeholder={"State"}
            options={optionsData?.data?.stateList?.filter(
              (item) => item?.country_id === formik?.values?.country
            )}
            getOptionLabel={(option) => option.state_name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.stateList?.find(
              (p) => formik?.values?.state?.toString() === p.id?.toString()
            )}
            onChange={(value) => handleCurrentStateFilter(value?.id)}
          ></Select>
        </div>

        <div className="col-3">
          <Select
            id="district"
            isMulti
            isClearable={true}
            isDisabled={!formik?.values?.state}
            placeholder={"District"}
            options={optionsData?.data?.districtList?.filter(
              (item) => item.state_id === formik?.values?.state
            )}
            getOptionLabel={(option) => option.district_name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.districtsList?.filter((p) =>
              formik?.values?.district?.includes(p.id?.toString())
            )}
            onChange={(value) =>
              handleCurrentDistrictFilter(value.map((v) => v.id?.toString()))
            }
          />
        </div>

        <div className="col-3">
          <Select
            id="religion"
            isClearable={true}
            placeholder={"Religion"}
            options={optionsData?.data?.religionList}
            getOptionLabel={(option) => option.religion_name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.religionList?.filter(
              (p) => formik?.values?.religion?.toString() === p.id?.toString()
            )}
            onChange={(value) => handleCurrentReligionFilter(value?.id)}
          />
        </div>

        <div className="col-3">
          <Select
            id="caste"
            isMulti
            isClearable={true}
            isDisabled={!formik?.values?.religion}
            placeholder={"Caste"}
            options={optionsData?.data?.casteList?.filter(
              (item) => item?.religion_id === formik?.values?.religion
            )}
            getOptionLabel={(option) => option.caste_name}
            getOptionValue={(option) => option.id}
            className={`pro-input lg multi-select`}
            classNamePrefix="pro-input"
            value={optionsData?.data?.casteList?.filter((p) =>
              formik?.values?.caste?.includes(p.id?.toString())
            )}
            onChange={(value) =>
              handleCurrentCasteFilter(value.map((v) => v.id?.toString()))
            }
          ></Select>
        </div>
      </div>
    </>
  );
};

export default ListingFilter;
