import { useSelector, useDispatch } from "react-redux";
import { useGetOutgoingCallQuery } from "../../../../store/queries/CallHistory";
import { updateConfig } from "../../../../store/slices/CallHistory/outgoingCallSlice";
import { CallHistoryIdRow } from "../Rows";
import { useState } from "react";
import { intializeCall } from "../api";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import { getFormatedDate } from "../../../../utils/functions/table";
import moment from "moment";

const useOutgoingCall = () => {
  const dispatch = useDispatch();
  const [outgoingCallStatus, setOutGoingCallStatus] = useState(false);
  const outgoingCallState = useSelector((state) => state.outgoingCall);
  const activeProfile = sessionStorage.getItem("active");
  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -30),
    endDate: new Date(),
    key: "selection",
  });

  const time = {
    startTime: new Date(),
    endTime: new Date(),
  };

  const initialTimeRange = [
    new Date(time.startTime.setHours(0, 0, 0, 0)),
    new Date(time.endTime.setHours(23, 59, 59, 999)),
  ];

  const {
    data: outgoingCallData,
    isFetching,
    isLoading,
  } = useGetOutgoingCallQuery({
    search: outgoingCallState.searchKey,
    per_page: outgoingCallState.currentPageSize,
    page: outgoingCallState.currentPage,
    date: outgoingCallState.dateRange,
    sort_by: outgoingCallState?.sortBy,
    sort: outgoingCallState?.sortOrder,
    time: outgoingCallState?.timeFilter,
  });
  const handleSort = (label) => {
    if (outgoingCallState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            outgoingCallState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleInitiateCall = (value) => {
    setOutGoingCallStatus(true);
    if (value) {
      intializeCall({
        mobile: value,
      }).then((res) => {
        setOutGoingCallStatus(false);
        if (res.data.status_code === 200) {
          toast.success(res?.data?.message ?? "Call Initiated Successfully");
        } else {
          toast.error(res?.data?.message ?? "Failed to initiate the call");
        }
      });
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      user_register_id: (_, data) => (
        <CallHistoryIdRow data={data} activeProfile={activeProfile} />
      ),
      cust_number: (_, data) => (
        <p
          onClick={() =>
            !outgoingCallStatus && handleInitiateCall(data?.cust_number)
          }
          className="pro-mb-0 pro-pnt"
        >
          {"*".repeat(Math.max(0, data.cust_number.length - 3)) +
            data.cust_number.slice(-3)}
        </p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    setInitialDateRange(range);
    // formik?.setFieldValue("date", [
    //   getFormatedDate(range.startDate),
    //   getFormatedDate(range.endDate),
    // ]);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const handleGetValues = (values) => {
    if (values) {
      const formatTime = (date) => {
        const formattedTime = moment(date).format("HH:mm");
        if (formattedTime === "00:00") {
          return "00:00";
        } else {
          return formattedTime;
        }
      };
      let formattedTimes = [formatTime(values[0]), formatTime(values[1])];
      dispatch(
        updateConfig((state) => {
          state.timeFilter = formattedTimes;
          state.timeFilterValue = values;
        })
      );
    }
  };

  const handleClearValues = () => {
    dispatch(
      updateConfig((state) => {
        state.timeFilter = ["00:00", "23:59"];
        state.timeFilterValue = [
          new Date(time.startTime.setHours(0, 0, 0, 0)),
          new Date(time.endTime.setHours(23, 59, 59, 999)),
        ];
      })
    );
  };

  return {
    outgoingCallData,
    outgoingCallState,
    isFetching,
    paginationOptions,
    isLoading,
    initialDateRange,
    initialTimeRange,
    handleGetValues,
    handleClearValues,
    handleDateChange,
    handleSearch,
    handleClearClick,
    handlePageSize,
    handlePagination,
    getRow,
    handleSort,
  };
};

export default useOutgoingCall;
