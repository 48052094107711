import React from "react";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";
// import useAddServiceForm from "./useAddServiceForm";

const AddServiceForm = ({
  formik,
  search,
  parentClass,
  childClass,
  time,
  optionsData,
  handleClearClick,
  isSuccess,
  handleTimeChange,
  handleWebIdChange,
  handleOnBlur,
}) => {
  // const { optionsData, isSuccess } = useAddServiceForm();

  return isSuccess ? (
    <div className="pro-my-5">
      <div className={parentClass}>
        {/*
         * Service Dated On
         * Follow Up Date
         * Follow Up Time
         * Type of Service
         */}
        <RowOne
          formik={formik}
          optionsData={optionsData.data}
          childClass={childClass}
          isSuccess={isSuccess}
          handleTimeChange={handleTimeChange}
          time={time}
          handleClearClick={handleClearClick}
        />
        {/*
         *Partner Bride's / Groom's ID
         *Service List
         *Service Remark
         */}
        <RowTwo
          formik={formik}
          search={search}
          optionsData={optionsData.data}
          parentClass={parentClass}
          childClass={childClass}
          isSuccess={isSuccess}
          handleWebIdChange={handleWebIdChange}
          handleOnBlur={handleOnBlur}
        />
      </div>
    </div>
  ) : (
    <div className={parentClass}>
      {" "}
      {[...Array(7)].map((_, i) => (
        <div
          key={i}
          className={`${
            childClass === "col-6" ? (i === 6 ? 12 : 6) : childClass
          }`}
        >
          <InputShimmer />
        </div>
      ))}
    </div>
  );
};

export default AddServiceForm;
