import {
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import ErrorMessage from "../../Global/ErrorMessage";
import UserDetailTableShimmer from "../../Global/Shimmers/UserDetailTableShimmer";
import UserDetailTableListing from "../../Global/UserDetailTableListing";
import useActivity from "./useActivity";
import style from "./activity.module.scss";
import { FaSort } from "react-icons/fa";

const Activity = () => {
  const {
    activityState,
    state,
    filters,
    activeFilter,
    viewHistoryModal,
    viewHistoryData,
    isFetching,
    handleSort,
    getRow,
    closeViewHistoryModal,
    handleViewHistoryModal,
    lastElement,
    handleChange,
  } = useActivity();

  return (
    <div className={`pro-m-5 font-inc ${style.root}`}>
      <SearchFilters
        data={filters}
        searchable={false}
        showActions={false}
        activeFilter={activeFilter}
        handleButtonGroupChange={handleChange}
      />
      {activityState?.total_count !== undefined &&
        (activeFilter === 1 || activeFilter === 2) && (
          <h4 className="pro-mt-3 pro-fw-bold">{`${activityState?.total_count} results found`}</h4>
        )}
      <UserDetailTableListing
        data={activityState?.data || []}
        lastElement={lastElement}
        handleViewHistoryModal={handleViewHistoryModal}
        ActivityProfile
      />

      <ModalLayout
        show={viewHistoryModal}
        centered
        handleClose={closeViewHistoryModal}
        propStyle={{
          root: style.call_history_modal,
          root_body: style.call_history_modal_body,
        }}
      >
        <div className="pro-m-2">
          {!isFetching ? (
            <Table
              data={viewHistoryData?.data?.data || []}
              uniqueID={"id"}
              fields={viewHistoryData?.data?.field || []}
              showCheckBox={false}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              // loading={isFetching}
              // perpage={registrationTypeReportState?.currentPageSize}
              // handleEdit={handleTableEditClick}
              assignable={false}
              editable={false}
              // deletable={false}
              multiSelect={false}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </ModalLayout>

      {state?.activityStatus === "idle" ||
      state?.activityStatus === "pending" ? (
        <div className={`col-12 pro-mt-4`}>
          <div className={`row g-4`}>
            {[...Array(activityState?.data ? 2 : 6)].map((_, i) => (
              <div className={`col-6`} key={i}>
                <UserDetailTableShimmer />
              </div>
            ))}
          </div>
        </div>
      ) : (
        activityState?.data?.length === 0 && <ErrorMessage />
      )}
    </div>
  );
};

export default Activity;
