import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useCreateNewTaskMutation } from "../../../../store/queries/Profile";

const useFollowUpForm = ({ userId, handleCancel }) => {
  const [createTask] = useCreateNewTaskMutation();
  const currentTime = new Date();
  const timeAfterTenMinutes = new Date(currentTime.getTime() + 10 * 60000);

  let followUpFormSchema = Yup.object({
    description: Yup.string().required("*Required"),
    call_date: Yup.string().required("*Required"),
    call_time: Yup.string()
      .required("*Required")
      .test("null check", "Time cannot be empty", function (value) {
        if (value === "00:00 AM") {
          return false;
        }
        return true;
      }),
  });

  function getCurrentTimeFormatted(now = timeAfterTenMinutes) {
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, "0");

    const isPM = hours >= 12;
    const ampm = isPM ? "PM" : "AM";

    // Adjusting hours for 12-hour format
    hours = hours % 12;
    hours = hours || 12; // Convert 0 to 12 for midnight

    // Apply padding after adjusting for AM/PM to ensure correct 12-hour format
    const formattedHours = hours.toString().padStart(2, "0");

    return `${formattedHours}:${minutes} ${ampm}`;
  }

  const [time, setTime] = useState(timeAfterTenMinutes);

  const formik = useFormik({
    initialValues: {
      task_type: 1,
      user_id: userId,
      description: null,
      call_date: null,
      call_time: getCurrentTimeFormatted(),
    },
    validationSchema: followUpFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const onChange = (newTime) => {
    if (newTime !== null) {
      setTime(newTime);
      let [hour, minute] = newTime.split(":");
      let now = new Date();
      now.setHours(hour, minute);

      let time12 = now.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        hourCycle: "h12",
      });

      let ConvertedTime12 = time12
        .replace(/^(\d{1}):/, "0$1:")
        .replace(/\s/, " ");

      formik.setFieldValue("call_time", ConvertedTime12);
    }
  };

  const handleSubmit = async (values) => {
    createTask(values)
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success("Task Created Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        }
      })
      .catch(() => {
        toast.error("Failed to Add Task!");
      });
  };

  const handleClearClick = () => {
    setTime("00:00 AM");
    formik.setFieldValue("call_time", "00:00 AM");
  };
  return { formik, onChange, time, handleClearClick };
};

export default useFollowUpForm;
