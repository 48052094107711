import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  search: "",
  sortBy: "",
  sortOrder: "",
  dateRange: [
    moment(Date.now()).add(-30, "days").format("YYYY-MM-DD"),
    moment(Date.now()).format("YYYY-MM-DD"),
  ],
  activeTab: "pending",
  //for image/pdf modal
  showImageModal: false,
  imageData: "",
  document_type: "",

  //for multiple imag modal
  showMultipleImageModal: false,
  multipleImageData: [],
};

const userAgreementSlice = createSlice({
  name: "userAgreement",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = userAgreementSlice.actions;

export default userAgreementSlice.reducer;
