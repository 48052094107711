import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  autoStarMatchStatus: "idle",
  autoStarMatchData: {},
};

export const getAutoStarMatchDetails = createAsyncThunk(
  "autoStarMatch/getAutoStarMatchDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `matches/auto-star-matches/${params.id}?page=${params.page}&per_page=20&viewed_status=${params?.filter}&agreement=${params?.agreement}&entire_data=${params?.entire_data}&admin_verified=${params?.admin_verified}&registered=${params?.registered}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const autoStarMatchSlice = createSlice({
  name: "autoStarMatch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getAutoStarMatchDetails.pending]: (state) => {
      state.autoStarMatchStatus = "pending";
    },
    [getAutoStarMatchDetails.fulfilled]: (state, action) => {
      if (state.autoStarMatchData?.[action.meta?.arg?.id]) {
        state.autoStarMatchData[action.meta?.arg?.id] = {
          ...action.payload.data?.data,
          data: [
            ...state.autoStarMatchData[action.meta?.arg?.id].data,
            ...action.payload.data?.data?.data,
          ],
        };
      } else {
        state.autoStarMatchData[action.meta?.arg?.id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.autoStarMatchStatus = "fulfilled";
    },
    [getAutoStarMatchDetails.rejected]: (state) => {
      state.autoStarMatchStatus = "failed";
    },
  },
});

export const { updateConfig } = autoStarMatchSlice.actions;

export default autoStarMatchSlice.reducer;
