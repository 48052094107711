import React from "react";
import useOutboundCalls from "./useOutboundCalls";
import ErrorMessage from "../../Global/ErrorMessage";
import style from "./outboundCalls.module.scss";

const OutboundCalls = () => {
  const { error } = useOutboundCalls();
  return (
    <div className={style.user_not_found}>
      {!error && <div className="app-loading fill"> </div>}

      {error && <ErrorMessage message={`User not found`} />}
    </div>
  );
};

export default OutboundCalls;
