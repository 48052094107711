import { HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import DashboardHero from "./DashboardHero";

import useDashboard from "./useDashboard";

const Dashboard = () => {
  const { dashboardItems, dashboardData, isFetching, children, listingType } =
    useDashboard();
  return (
    <div>
      <HeadingGroup title={"Dashboard"} extraClassName={`pro-mb-4`} />

      <div className={`pro-w-100 pro-mb-5`}>
        <DashboardHero
          data={dashboardData?.data}
          obj={dashboardItems}
          loading={isFetching}
          listingType={listingType}
        />
      </div>
      {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <></>
      )}
    </div>
  );
};

export default Dashboard;
