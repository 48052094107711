import Select from "react-select";
import React from "react";
import style from "./listingFilter.module.scss";
const ListingFilter = ({
  optionsData,
  handleStaffFilter,
  handleBranchFilter,
  dashboardState,
  handleEditFieldsClick,
  handleClearFilters,
}) => {
  return (
    <div className={`row g-2 ${style.root}`}>
      <div className="col-3 ">
        <Select
          id="branch"
          isClearable={true}
          placeholder={"Select"}
          options={optionsData?.data?.branchList}
          getOptionLabel={(option) => `${option.branch_name}`}
          getOptionValue={(option) => `${option["id"]}`}
          className={`pro-input lg pro-min-full multi-select`}
          value={optionsData?.data?.branchList?.filter(
            (p) =>
              p.id?.toString() ===
              dashboardState?.currentBranchFilter?.toString()
          )}
          classNamePrefix="pro-input"
          onChange={(value) => handleBranchFilter(value?.id)}
        ></Select>
      </div>
      <div className="col-3 ">
        <Select
          id="staff"
          isMulti
          isDisabled={dashboardState?.currentBranchFilter ? false : true}
          options={optionsData?.data?.staffList?.filter(
            (p) =>
              dashboardState?.currentBranchFilter?.toString() ===
              p.branches_id?.toString?.()
          )}
          getOptionLabel={(option) => `${option.staff_name}`}
          getOptionValue={(option) => `${option["id"]}`}
          className={`pro-input lg multi-select`}
          placeholder={"Staff"}
          value={optionsData?.data?.staffList?.filter((p) =>
            dashboardState?.currentStaffFilter?.includes?.(p.id?.toString?.())
          )}
          classNamePrefix="pro-input"
          onChange={(value) => handleStaffFilter(value)}
        ></Select>
      </div>

      <div className={`col-auto`}>
        <button
          className="pro-btn pro-btn-outline lg"
          onClick={handleClearFilters}
        >
          Clear Filters
        </button>
      </div>

      <div className={`col-auto`}>
        <button
          className="pro-btn pro-btn-outline lg"
          onClick={handleEditFieldsClick}
        >
          Edit Columns
        </button>
      </div>
    </div>
  );
};

export default ListingFilter;
