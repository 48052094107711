import Style from "./overviewShimmer.module.scss";
const OverviewShimmer = () => {
  return (
    <div className={`pro-p-4 pro-px-5 ${Style.shimmer_container}`}>
      <div className="pro-justify-between row pro-mb-2 pro-w-100">
        <div className="col-5">
          <span className="shimmer letter para"></span>
        </div>
        <div className="col-1">
          <span className="shimmer letter para"></span>
        </div>
      </div>
      <div className="pro-w-100">
        <table className="pro-table no-border">
          <tbody>
            <tr>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
            </tr>
            <tr>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
            </tr>
            <tr>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
            </tr>
            <tr>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
            </tr>
            <tr>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
            </tr>
            <tr>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
              <td width="auto">
                <div className="shimmer letter para sub"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverviewShimmer;
