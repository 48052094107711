import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ endpoint, method, body }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api[method](`${endpoint}`, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const search = createApi({
  reducerPath: "searchApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Search",
    "AdvancedSearch",
    "ProfileSearch",
    "AcitivitySearch",
    "ServiceSearch",
    "JobAssign",
  ],
  endpoints: (builder) => ({
    getSearchFilters: builder.query({
      query: () => ({
        method: "get",
        endpoint: `search/advanced-search-filters`,
      }),
      providesTags: ["Search"],
    }),
    getProfileSearchFilters: builder.query({
      query: () => ({
        method: "get",
        endpoint: `search/search-profile-filters`,
      }),
      providesTags: ["ProfileSearch"],
    }),

    getUserActivitySearchFilters: builder.query({
      query: () => ({
        method: "get",
        endpoint: `search/user-activity-search-filters`,
      }),
      invalidatesTags: ["AcitivitySearch"],
    }),
    getServiceSearchFilters: builder.query({
      query: () => ({
        method: "get",
        endpoint: `search/user-service-search-filters`,
      }),
      providesTags: ["ServiceSearch"],
    }),

    getJobAssignData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/search/search-profile`,
      }),
      providesTags: ["JobAssign"],
    }),

    getUserActivitySearch: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `search/user-activity-search`,
      }),

      providesTags: ["AcitivitySearchData"],

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        newItems = newItems.data.data;
        currentCache?.data?.data?.push(...newItems);
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getAdvancedSearchResults: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `search/advanced-search`,
      }),

      providesTags: ["AdvanedSearch"],
      skip: true,
    }),

    getAdvancedSearchTable: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `search/advanced-search-pdf`,
      }),

      providesTags: ["AdvanedSearch"],
      skip: true,
    }),
  }),
});

export const {
  useGetSearchFiltersQuery,
  useGetProfileSearchFiltersQuery,
  useGetUserActivitySearchFiltersQuery,
  useGetUserActivitySearchQuery,
  useGetServiceSearchFiltersQuery,
  useGetJobAssignDataQuery,
  useGetAdvancedSearchResultsQuery,
  useGetAdvancedSearchTableQuery,
} = search;
