import React, { useState } from "react";
import Style from "./simpleImageSlider.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Image } from "@wac-ui-dashboard/wac_component_library";

/**import "swiper/css" when using this component */
export const SimpleImageSlider = ({
  data,
  extraClassName,
  width,
  height,
  propStyle,
  premiumStatus,
  isLocked,
  status,
  handleClick,
}) => {
  const [counter, setCounter] = useState(1);

  return (
    <div className={`${Style.root} ${extraClassName ?? ""}`}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setCounter(swiper?.activeIndex + 1);
        }}
        modules={[Navigation]}
        navigation={!isLocked}
      >
        {data &&
          data.map((item) => {
            return (
              <SwiperSlide key={item?.id} onClick={handleClick}>
                {item?.isPrimary ? (
                  <span
                    className={`${Style.isPrimary} pro-py-1 pro-px-3 pro-font-sm pro-fw-bold`}
                  >
                    Primary Image
                  </span>
                ) : (
                  <></>
                )}

                <Image
                  src={item?.image || ""}
                  width={width || ""}
                  height={height || ""}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>

      {premiumStatus !== null && (
        <span
          className={`${Style.isPremium} pro-py-1 pro-px-3 pro-font-sm`}
          style={{ fontVariationSettings: ` 'FILL' 1` }}
        >
          <span className="material-symbols-outlined">star</span>
          <span>{premiumStatus}</span>
        </span>
      )}

      {!isLocked && data && (
        <div className={Style.counter}>
          <span>
            {counter}/{data?.length}
          </span>
        </div>
      )}
    </div>
  );
};
