import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  autoMatchStatus: "idle",
  autoMatchData: {},
};

export const getAutoMatchDetails = createAsyncThunk(
  "autoMatch/getAutoMatchDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `matches/auto-select-matches/${params.id}?page=${params.page}&per_page=20&viewed_status=${params?.filter}&agreement=${params?.agreement}&entire_data=${params?.entire_data}&admin_verified=${params?.admin_verified}&registered=${params?.registered}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const autoMatchSlice = createSlice({
  name: "autoMatch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getAutoMatchDetails.pending]: (state) => {
      state.autoMatchStatus = "pending";
    },
    [getAutoMatchDetails.fulfilled]: (state, action) => {
      if (state.autoMatchData?.[action.meta?.arg?.id]) {
        state.autoMatchData[action.meta?.arg?.id] = {
          ...action.payload.data?.data,
          data: [
            ...state.autoMatchData[action.meta?.arg?.id].data,
            ...action.payload.data?.data?.data,
          ],
        };
      } else {
        state.autoMatchData[action.meta?.arg?.id] = {
          ...action.payload?.data?.data,
          page: 1,
        };
      }
      state.autoMatchStatus = "fulfilled";
    },
    [getAutoMatchDetails.rejected]: (state) => {
      state.autoMatchStatus = "failed";
    },
  },
});

export const { updateConfig } = autoMatchSlice.actions;

export default autoMatchSlice.reducer;
