import { useNavigate, useSearchParams } from "react-router-dom";
import { InboundOutboundCalls } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const useInboundCalls = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const phone = params.get("from");
  const [error, setError] = useState(false);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

    useEffect(() => {
      InboundOutboundCalls({
        mobile_number: phone,
      }).then((response) => {
        if (response?.data?.status_code === 200) {
          navigate({
            pathname: "/profile",
            search: `?active=${response?.data?.data?.user_id}&id=${activeProfile}`,
          });
        } else {
          setError(() => true);
          toast.error("User Not Found");
        }
      });
      // eslint-disable-next-line
    }, [])
    

 

  return { error };
};

export default useInboundCalls;
