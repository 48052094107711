import { useMemo } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { updateProfileConfig } from "../../../../store/slices/Profile";
import { useDispatch } from "react-redux";
import { useGetLoggedInStaffProfileDataQuery } from "../../../../store/queries/Staff";

const usePdfModalForm = ({ handleCancel, handleDownloadPdf }) => {
  const { pdfVisibility } = useSelector((state) => state.profile);
  const { data: loggedStaffData } = useGetLoggedInStaffProfileDataQuery();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: pdfVisibility,
    onSubmit: async (values) => {
      dispatch(
        updateProfileConfig((state) => {
          state.pdfVisibility = values;
          state.skip = true;
        })
      );

      try {
        await handleDownloadPdf();
        // The code below will only execute after handleDownloadPdf is completed
        handleCancel?.();
      } catch (error) {
        // Handle errors from handleDownloadPdf
      }
    },
  });
  const hasPhonePermission = useMemo(() => {
    let permission = loggedStaffData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("phone")
    );
    return permission?.[0]?.phone ?? 0;
    // eslint-disable-next-line
  }, [loggedStaffData]);

  const hasAddressPermission = useMemo(() => {
    let permission = loggedStaffData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("address")
    );
    return permission?.[0]?.address ?? 0;
    // eslint-disable-next-line
  }, [loggedStaffData]);

  const hasRoutePermission = useMemo(() => {
    let permission = loggedStaffData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("route")
    );
    return permission?.[0]?.route ?? 0;
    // eslint-disable-next-line
  }, [loggedStaffData]);

  const hasHoroscopePermission = useMemo(() => {
    let permission = loggedStaffData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("horoscope")
    );
    return permission?.[0]?.horoscope ?? 0;
    // eslint-disable-next-line
  }, [loggedStaffData]);

  return {
    formik,
    loggedStaffData,
    hasAddressPermission,
    hasPhonePermission,
    hasRoutePermission,
    hasHoroscopePermission,
  };
};

export default usePdfModalForm;
