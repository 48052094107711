import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ContactInfo from "./ContactInfo";
import FamilyInfo from "./FamilyInfo";
import useFamily from "./useFamily";

const Family = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const {
    formik,
    errors,
    isUpdating,
    fatherJobRef,
    motherJobRef,
    isChecked,
    stateRef,
    districtRef,
    currentFormDataFamily,
    setSaveAndNext,
    handleSelectFatherJob,
    handleSelectMotherJob,
    handleSelectCountry,
    handleSelectState,
    handleCheckbox,
  } = useFamily({ optionsData, isOptionDataSuccess, activeUser, edit, toTab });

  return (
    <div className={`pro-p-0 pro-w-100`}>
      <form action="" onSubmit={formik.handleSubmit}>
        {/**
         * Father Name
         * Father's Job
         * Father - Main Job Category
         * Father - Sub Job Category
         * Mother Name
         * Mother's Job
         * Mother - Main Job Category
         * Mother - Sub Job Category
         * Parents Job Details
         * Land Info
         * Land Details
         * House SqFt
         * House Details
         * Dowry Details
         * Vehicle Info
         * Agreement At
         * Share Details
         * Family Income
         * Siblings Info
         */}
        <FamilyInfo
          formik={formik}
          optionsData={optionsData}
          currentFormDataFamily={currentFormDataFamily}
          handleSelectFatherJob={handleSelectFatherJob}
          handleSelectMotherJob={handleSelectMotherJob}
          fatherJobRef={fatherJobRef}
          motherJobRef={motherJobRef}
        />

        {/**
       * Communication Address
       * Country
       * State
       * District
       * Communication Address
       * Route To Residence
       * Latitude
       * Longtitude
       * Hide Address
       
       */}

        <ContactInfo
          formik={formik}
          optionsData={optionsData}
          currentFormDataFamily={currentFormDataFamily}
          handleSelectCountry={handleSelectCountry}
          handleSelectState={handleSelectState}
          stateRef={stateRef}
          districtRef={districtRef}
          handleCheckbox={handleCheckbox}
          isChecked={isChecked}
        />

        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
          {toTab && (
            <Button
              disabled={isUpdating}
              className={`pro-btn-primary lg pro-mx-3 ${isUpdating ? "loading" : ""}`}
              onClick={() => setSaveAndNext(true)}
              type="submit"
            >
              {isUpdating ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {toTab && "Save & Next"}
            </Button>
          )}

          <Button
            disabled={isUpdating}
            className={`pro-btn-primary lg pro-mx-3 ${isUpdating ? "loading" : ""}`}
            type="submit"
          >
            {isUpdating ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            {toTab ? "Save" : "Save & Next"}
          </Button>
        </div>

        {errors && <span className="error-message">{errors}</span>}
      </form>
    </div>
  );
};

export default Family;
