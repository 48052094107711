import React from "react";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const RowOne = ({
  formik,
  optionsData,
  handleSelectFatherJob,
  fatherJobRef,
  handleSelectMotherJob,
  motherJobRef,
}) => {
  return (
    <>
      <div className={`col-lg-3`}>
        <Input
          label={"Father Name*"}
          type="text"
          id="father_name"
          name="father_name"
          className={`pro-input lg ${
            formik.touched.father_name && formik.errors.father_name && " error"
          }`}
          {...formik.getFieldProps("father_name")}
          error={formik.touched.father_name && formik.errors.father_name}
          errorMessage={formik.errors.father_name}
        />
      </div>

      <div className={`col-lg-3`}>
        <Input
          label={"Father's Job"}
          type="text"
          id="father_job"
          name="father_job"
          className={`pro-input lg ${
            formik.touched.father_job && formik.errors.father_job && " error"
          }`}
          {...formik.getFieldProps("father_job")}
          error={formik.touched.father_job && formik.errors.father_job}
          errorMessage={formik.touched.father_job && formik.errors.father_job}
        />
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Father - Main Job Category
          </label>
          <Select
            id="father_job_main_category"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.parentJobCategory}
            getOptionLabel={(option) => `${option.parent_job_category}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.father_job_main_category &&
              formik.errors.father_job_main_category &&
              " error"
            }`}
            value={optionsData?.data?.parentJobCategory?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.father_job_main_category?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("father_job_main_category")}
            onChange={(value) => handleSelectFatherJob(value)}
          ></Select>
          {formik.touched.father_job_main_category &&
            formik.errors.father_job_main_category && (
              <span className="error-text">
                {formik.errors.father_job_main_category}
              </span>
            )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Father - Sub Job Category
          </label>
          <Select
            id="father_job_category_id"
            isClearable={true}
            ref={fatherJobRef}
            placeholder={"Select"}
            options={optionsData?.data?.jobCategory?.filter(
              (p) =>
                p.parent_id?.toString() ===
                formik.values.father_job_main_category?.toString()
            )}
            getOptionLabel={(option) => `${option.job_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.father_job_category_id &&
              formik.errors.father_job_category_id &&
              " error"
            } ${!formik.values.father_job_main_category && "disabled"}`}
            value={optionsData?.data?.jobCategory?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.father_job_category_id?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("father_job_category_id")}
            onChange={(value) =>
              formik.setFieldValue("father_job_category_id", value?.id || null)
            }
          ></Select>
          {formik.touched.father_job_category_id &&
            formik.errors.father_job_category_id && (
              <span className="error-text">
                {formik.errors.father_job_category_id}
              </span>
            )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <Input
          label={"Mother Name*"}
          type="text"
          id="mother_name"
          name="mother_name"
          className={`pro-input lg ${
            formik.touched.mother_name && formik.errors.mother_name && " error"
          }`}
          {...formik.getFieldProps("mother_name")}
          error={formik.touched.mother_name && formik.errors.mother_name}
          errorMessage={formik.errors.mother_name}
        />
      </div>

      <div className={`col-lg-3`}>
        <Input
          label={"Mother's Job"}
          type="text"
          id="mother_job"
          name="mother_job"
          className={`pro-input lg ${
            formik.touched.mother_job && formik.errors.mother_job && " error"
          }`}
          {...formik.getFieldProps("mother_job")}
          error={formik.touched.mother_job && formik.errors.mother_job}
          errorMessage={formik.touched.mother_job && formik.errors.mother_job}
        />
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mother - Main Job Category
          </label>
          <Select
            id="mother_job_main_category"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.parentJobCategory}
            getOptionLabel={(option) => `${option.parent_job_category}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.mother_job_main_category &&
              formik.errors.mother_job_main_category &&
              " error"
            }`}
            value={optionsData?.data?.parentJobCategory?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.mother_job_main_category?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("mother_job_main_category")}
            onChange={(value) => handleSelectMotherJob(value)}
          ></Select>
          {formik.touched.mother_job_main_category &&
            formik.errors.mother_job_main_category && (
              <span className="error-text">
                {formik.errors.mother_job_main_category}
              </span>
            )}
        </div>
      </div>

      <div className={`col-lg-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mother - Sub Job Category
          </label>
          <Select
            id="mother_job_category_id"
            isClearable={true}
            ref={motherJobRef}
            placeholder={"Select"}
            options={optionsData?.data?.jobCategory?.filter(
              (p) =>
                p.parent_id?.toString() ===
                formik.values.mother_job_main_category?.toString()
            )}
            getOptionLabel={(option) => `${option.job_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.mother_job_category_id &&
              formik.errors.mother_job_category_id &&
              " error"
            } ${!formik.values.mother_job_main_category && "disabled"}`}
            value={optionsData?.data?.jobCategory?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.mother_job_category_id?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("mother_job_category_id")}
            onChange={(value) =>
              formik.setFieldValue("mother_job_category_id", value?.id || null)
            }
          ></Select>
          {formik.touched.mother_job_category_id &&
            formik.errors.mother_job_category_id && (
              <span className="error-text">
                {formik.errors.mother_job_category_id}
              </span>
            )}
        </div>
      </div>

      <div className={`col-12`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Parents Job Details
          </label>
          <textarea
            id="parent_job_description"
            name="parent_job_description"
            className={`pro-input lg ${
              formik.touched.parent_job_description &&
              formik.errors.parent_job_description &&
              " error"
            }`}
            {...formik.getFieldProps("parent_job_description")}
          ></textarea>

          {formik.touched.parent_job_description &&
            formik.errors.parent_job_description && (
              <span className="error-text">
                {formik.errors.parent_job_description}
              </span>
            )}
        </div>
      </div>
    </>
  );
};

export default RowOne;
