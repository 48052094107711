import {
  useGetBlockUsersDataQuery,
  useUpdateBlockedFieldsMutation,
} from "../../store/queries/BlockUsers";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../store/slices/BlockUsers/blockUsersSlice";
import { updateConfig as updateOrderConfig } from "../../store/slices/OrderColumns/orderColumnSlice";
import { addDays } from "date-fns";
import { useState } from "react";
import { getFormatedDate } from "../../utils/functions/table";
import { BlockedIdRow, BlockedUserIdRow } from "./Rows";

const useBlockUsers = () => {
  const dispatch = useDispatch();
  const blockUsersState = useSelector((state) => state.blockUsers);
  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);
  const [updateBlockedFields] = useUpdateBlockedFieldsMutation();

  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -30),
    endDate: new Date(),
    key: "selection",
  });
  const activeProfile = sessionStorage.getItem("active");

  const {
    data: blockedUsersData,
    isFetching,
    isLoading,
    refetch,
  } = useGetBlockUsersDataQuery({
    search: blockUsersState.searchKey,
    per_page: blockUsersState.currentPageSize,
    page: blockUsersState.currentPage,
    sort_by: blockUsersState?.sortBy,
    sort: blockUsersState?.sortOrder,
    date: blockUsersState.dateRange,
  });

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (blockUsersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            blockUsersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      register_id: (_, data) => (
        <BlockedIdRow data={data} activeProfile={activeProfile} />
      ),
      blocked_user_register_id: (_, data) => (
        <BlockedUserIdRow data={data} activeProfile={activeProfile} />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const obj = blockedUsersData?.data?.field || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const handleDateChange = (range) => {
    setInitialDateRange(range);

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;

        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  return {
    blockedUsersData,
    blockUsersState,
    isLoading,
    isFetching,
    paginationOptions,
    sortedTableFields,
    showTableFieldsModal,
    initialDateRange,
    handleDateChange,
    updateBlockedFields,
    refetch,
    closeEditModal,
    handleEditFieldsClick,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleClearClick,
  };
};

export default useBlockUsers;
