import React from "react";

const HeadingGroup = ({ title, sub, handleRedirectProfile }) => {
  return (
    <div className={`pro-mb-4`}>
      {title && (
        <h3
          className="pro-ttl h3 pro-my-2"
          onClick={handleRedirectProfile}
        >
          {title}
        </h3>
      )}
      {sub && <span className="pro-my-0">{sub}</span>}
    </div>
  );
};

export default HeadingGroup;
