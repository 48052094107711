import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("sort_by")}&sort=${
          params?.sort_order || "desc"
        }&per_page=${params?.page_size || "10"}&${getParams(
          "search"
        )}&${getParams("type")}&${getParams("user_id")}&page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const registration = createApi({
  reducerPath: "registrationApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Registration",
    "Registration_FormData",
    "Gallery",
    "Agreement-Image",
    "Agreement-Document",
  ],
  endpoints: (builder) => ({
    getRegisteredProfileListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `user/users-list`,
      }),
      invalidatesTags: ["Registration"],
    }),

    updateRegisteredProfileListFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["Registration"],
    }),
    getRegistrationFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `user/registration-form`,
      }),
      providesTags: ["Registration_FormData"],
    }),

    createProfileBasicInfo: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/user-basic-details`,
      }),

      invalidatesTags: ["Registration"],
    }),
    createProfileFamilyInfo: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/user-family-info`,
      }),

      invalidatesTags: ["Registration"],
    }),
    createProfileReligiousInfo: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/user-religious-info`,
      }),

      invalidatesTags: ["Registration"],
    }),
    createProfileHoroscopeInfo: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/user-horoscope`,
      }),

      invalidatesTags: ["Registration"],
    }),
    getGalleryImagesData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `profile/user-image-info/${values.id}`,
      }),
      invalidatesTags: ["Gallery"],
    }),
    createProfileGalleryInfo: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/profile-verification`,
      }),

      invalidatesTags: ["Registration"],
    }),
    uploadProfileImages: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/upload-profile-image`,
      }),

      invalidatesTags: ["Registration"],
    }),
    uploadNonProfileImages: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/non-profile-image-upload`,
      }),

      invalidatesTags: ["Registration"],
    }),
    deleteGalleryImagesData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/image-delete`,
      }),

      invalidatesTags: ["Gallery"],
    }),
    deleteGalleryDocumentsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/document-delete`,
      }),

      invalidatesTags: ["Gallery"],
    }),
    createProfilePartnerInfo: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/user-partner-preference`,
      }),

      invalidatesTags: ["Registration"],
    }),

    onChangeValidation: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/mobile-number-exist`,
      }),

      invalidatesTags: ["Registration"],
    }),

    uploadMultipleImages: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `user-agreement/user-agreement-upload`,
      }),
    }),
    uploadAgreementDocument: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `user-agreement/user-agreement-document-upload`,
      }),
    }),
    getAgreementImages: builder.query({
      query: (body) => ({
        method: "get",
        params: body,
        endpoint: `user-agreement/user-agreement-file-list`,
      }),
      providesTags: ["Agreement-Image"],
    }),

    getAgreementDocuments: builder.query({
      query: (body) => ({
        method: "get",
        params: body,
        endpoint: `user-agreement/user-agreement-file-list`,
      }),
      providesTags: ["Agreement-Document"],
    }),

    removeAgreementDocument: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `user-agreement/delete-user-agreement`,
      }),
      invalidatesTags: ["Agreement-Document", "Agreement-Image"],
    }),
  }),
});

export const {
  useGetRegisteredProfileListDataQuery,
  useUpdateRegisteredProfileListFieldsMutation,
  useCreateProfileBasicInfoMutation,
  useCreateProfileFamilyInfoMutation,
  useCreateProfileGalleryInfoMutation,
  useCreateProfileHoroscopeInfoMutation,
  useCreateProfilePartnerInfoMutation,
  useCreateProfileReligiousInfoMutation,
  useGetRegistrationFormDataQuery,
  useGetGalleryImagesDataQuery,
  useDeleteGalleryImagesDataMutation,
  useDeleteGalleryDocumentsDataMutation,
  useUploadNonProfileImagesMutation,
  useUploadProfileImagesMutation,
  useOnChangeValidationMutation,
  useUploadMultipleImagesMutation,
  useUploadAgreementDocumentMutation,
  useGetAgreementDocumentsQuery,
  useGetAgreementImagesQuery,
  useRemoveAgreementDocumentMutation,
} = registration;
