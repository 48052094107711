import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  serviceSearchStatus: "idle",
  serviceSearchData: {},
  serviceSearchValues: [],
};

export const getServiceSearch = createAsyncThunk(
  "serviceSearch/getServiceSearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`search/user-service-search`, {
        ...params,
        per_page: 20,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const serviceSearchSlice = createSlice({
  name: "serviceSearch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceSearch.pending, (state) => {
      state.serviceSearchStatus = "pending";
    });
    builder.addCase(getServiceSearch.fulfilled, (state, action) => {
      state.serviceSearchStatus = "fulfilled";
      if (state.serviceSearchData?.data) {
        state.serviceSearchData = {
          ...action.payload.data.data,
          data: [
            ...state.serviceSearchData?.data,
            ...action.payload.data.data.data,
          ],
        };
      } else {
        state.serviceSearchData = action.payload.data.data;
      }
    });
    builder.addCase(getServiceSearch.rejected, (state) => {
      state.serviceSearchStatus = "rejected";
    });
  },
});

export const { updateConfig } = serviceSearchSlice.actions;

export default serviceSearchSlice.reducer;
