import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateFormData } from "../../../../store/slices/Schedules/createScheduleSlice";
import { updateConfig } from "../../../../store/slices/Schedules/scheduleSlice";
import {
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
} from "../../../../store/queries/Schedule";

const useInterval = ({ optionsData, isOptionDataSuccess, refetch }) => {
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const { currentFormData, completedTabs, backendErrors } = useSelector(
    (state) => state.createSchedule
  );

  const { scheduleUpdateId } = useSelector((state) => state.schedules);

  const [createSchedule] = useCreateScheduleMutation();
  const [updateSchedule] = useUpdateScheduleMutation();

  const dispatch = useDispatch();

  const elementSchema = Yup.number()
    .typeError("Must be a number")
    .lessThan(
      currentFormData?.schedule_duration,
      `Number must be less than ${currentFormData?.schedule_duration}`
    )
    .required("*Required");

  const schema = Yup.array()
    .of(elementSchema)
    .min(
      currentFormData?.count,
      `Array must have at least ${currentFormData?.count} elements`
    );

  const formik = useFormik({
    initialValues: {
      interval:
        currentFormData.intervals ??
        Array.from({ length: currentFormData?.count }, () => null),
    },

    validationSchema: Yup.object().shape({
      interval: schema,
    }),
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          intervals: values.interval,
        };

        dispatch(
          updateFormData((state) => {
            state.currentFormData = { ...state.currentFormData, ...obj };
            state.completedTabs = { ...state.completedTabs, Interval: true };
          })
        );

        if (completedTabs.Basic) {
          if (!scheduleUpdateId) {
            let formData = {
              ...currentFormData,
              intervals: obj?.intervals?.map(Number),
            };

            createSchedule(formData).then((response) => {
              dispatch(
                updateFormData((state) => {
                  state.backendErrors = "";
                })
              );

              setErrors("");
              if (response?.data?.status_code === 200) {
                refetch();
                submitForm(resetForm);
              } else if (response?.error?.data?.status_code === 422) {
                dispatch(
                  updateFormData((state) => {
                    state.backendErrors = response?.error?.data?.errors;
                  })
                );
              }
            });
          } else {
            let formData = {
              ...currentFormData,
              schedule_id: scheduleUpdateId,
              intervals: obj?.intervals?.map(Number),
            };
            updateSchedule(formData).then((response) => {
              dispatch(
                updateFormData((state) => {
                  state.backendErrors = "";
                })
              );

              setErrors("");
              if (response?.data?.status_code === 200) {
                refetch();
                submitForm(resetForm);
              } else if (response?.error?.data?.status_code === 422) {
                dispatch(
                  updateFormData((state) => {
                    state.backendErrors = response?.error?.data?.errors;
                  })
                );
              }
            });
          }
        } else {
          setErrors("Fill required fields in all  Forms");
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
      // formik.handleSubmit();
    }

    return () => {
      dispatch(
        updateFormData((state) => {
          state.completedTabs = { ...state.completedTabs, Interval: true };
        })
      );
    };
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData, scheduleUpdateId]);

  useEffect(() => {
    let totalFields = Object.values(formik.values.interval).filter(
      (f) => f !== null
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Interval: Math.ceil(
            (totalFields / Object.keys(formik.values.interval).length) * 100
          ),
        };
        // if (formik.values.interval.length !== 0) {
        //   state.currentFormData = {
        //     ...state.currentFormData,
        //     interval: formik.values.interval,
        //   };
        // }
      })
    );

    // eslint-disable-next-line
  }, [formik.values]);

  const submitForm = (resetForm) => {
    resetForm();

    setLoading(false);

    dispatch(
      updateFormData((state) => {
        state.currentFormData = [null];
        state.activeTab = "Basic";
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Interval: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Interval: 0,
        };
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.scheduleUpdateId = null;
      })
    );
    if (!scheduleUpdateId) {
      toast.success("Successfully submitted");
    } else toast.success("Successfully udpated");
  };

  useEffect(() => {
    if (backendErrors) {
      Object.keys(backendErrors).map((keys) =>
        formik.setFieldTouched(keys, true)
      );
      formik.setErrors(backendErrors);
    }
    // eslint-disable-next-line
  }, [formik.values]);

  const handleIntervalChange = (value,index) => {
     
   
    // dispatch(
    //   updateFormData((state) => {
    //     let demo = interval[index]
    //     state.currentFormData = {
    //       ...state.currentFormData,
    //        demo:value
    //     };
    //   })
    // );
  };

  const handleCloseModal = (e) => {
    e.preventDefault();

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Interval: 0,
        };
        state.activeTab = "Basic";
      })
    );
  };

  const intervalArrayCount = Array.from(
    { length: currentFormData?.count },
    (_, index) => index
  );

  const numberOrdinals = { 1: "st", 2: "nd", 3: "rd", all: "th" };

  const getOrdinalNumbers = (index) => {
    return `${index}${numberOrdinals?.[index] ?? numberOrdinals["all"]}`;
  };

  return {
    errors,
    formik,
    loading,
    currentFormData,
    intervalArrayCount,
    backendErrors,
    handleCloseModal,
    getOrdinalNumbers,
    handleIntervalChange,
    scheduleUpdateId,
  };
};

export default useInterval;
