import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useStaff from "./useStaff";
import ListingFilter from "../ListingFilter";

const Staff = () => {
  const {
    staffs,
    errors,
    staffState,
    currentPage,
    hasCreatePermission,
    hasEditPermission,
    paginationOptions,
    isFetching,
    handleSearch,
    sortedTableFields,
    showTableFieldsModal,
    showDeactivateModal,
    handleSelectedIds,
    isMultiSelected,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleCreateClick,
    getRow,
    closeEditModal,
    updateStaffFields,
    handleTableDeactivateClick,
    handleTableEditClick,
    closeDeactivateModal,
    handleDeactivateData,
    optionsData,
    handleRoleFilter,
    handleBranchFilter,
    handleEditFieldsClick,
    handleClearFilters,
    handleStatusFilter
  } = useStaff();


  return (
    <>
      <HeadingGroup
        title={"Staff"}
        buttonTitle={hasCreatePermission ? "Create" : ""}
        extraClassName={`pro-mb-2`}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {/* <SearchFilters
          showActions={true}
          handleActionClick={handleEditClick}
          loading={isLoading}
          searchable={false}
        /> */}

        <ListingFilter
          optionsData={optionsData}
          handleRoleFilter={handleRoleFilter}
          handleBranchFilter={handleBranchFilter}
          handleStatusFilter={handleStatusFilter}
          staffState={staffState}
          handleSearch={handleSearch}
          handleEditFieldsClick={handleEditFieldsClick}
          handleClearFilters={handleClearFilters}
        />

        <Table
          data={staffs?.data?.data || []}
          uniqueID={"id"}
          fields={sortedTableFields}
          editable={isMultiSelected.length === 1 ? true : false}
          showCheckBox={hasEditPermission}
          SortIcon={<FaSort />}
          handleSelect={handleSelectedIds}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={staffState.currentPageSize}
          assignable={false}
          deleteText={"Deactivate"}
          handleEdit={handleTableEditClick}
          handleDelete={handleTableDeactivateClick}
        />

        {staffs?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              staffs?.data?.total_count / staffs?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          centered
          show={showDeactivateModal}
          handleClose={closeDeactivateModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to deactivate?`}
              isRight={true}
              cancelText={`Cancel`}
              submitText={`Deactivate`}
              cancelAction={closeDeactivateModal}
              submitAction={handleDeactivateData}
            >
              ConfirmationBox
            </ConfirmationBox>

            {errors && <span className="error-message">{errors}</span>}
          </div>
        </ModalLayout>

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={staffs?.data?.table_id}
              updateData={updateStaffFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Staff;
