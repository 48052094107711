import React from "react";
import useSocialMediaForm from "./useSocialMediaForm";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const SocialMediaForm = ({ handleClose, refetch }) => {
  const { formik } = useSocialMediaForm({ handleClose, refetch });
  return (
    <>
      <div className={`col-auto`}>
        <Input
          label={"Othes"}
          type="text"
          id="others"
          name="others"
          className={`pro-input lg ${
            formik.touched.others && formik.errors.others && " error"
          }`}
          {...formik.getFieldProps("others")}
          error={formik.touched.others && formik.errors.others}
          errorMessage={formik.errors.others}
        />
      </div>
      <div className={`col-auto`}>
        <Input
          label={"Existed"}
          type="text"
          id="existed"
          name="existed"
          className={`pro-input lg ${
            formik.touched.existed && formik.errors.existed && " error"
          }`}
          {...formik.getFieldProps("existed")}
          error={formik.touched.existed && formik.errors.existed}
          errorMessage={formik.errors.existed}
        />
      </div>
      <div className={`col-auto`}>
        <Input
          label={"Other State"}
          type="text"
          id="other_state"
          name="other_state"
          className={`pro-input lg ${
            formik.touched.other_state && formik.errors.other_state && " error"
          }`}
          {...formik.getFieldProps("other_state")}
          error={formik.touched.other_state && formik.errors.other_state}
          errorMessage={formik.errors.other_state}
        />
      </div>
      <div className="pro-d-flex pro-justify-end pro-mt-4">
        <Button
          className={"pro-btn-link lg pro-px-5 pro-mx-2"}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default SocialMediaForm;
