import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetRegistrationFormDataQuery } from "../../../store/queries/Registration";
import { updateFormData } from "../../../store/slices/Registration/createProfileSlice";

const useProfileCreationForm = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { activeTab, CompletedPercentage, completedTabs,webId } = useSelector(
    (state) => state.createProfile
  );
  const activeUser = params.get("user");
  const edit = params.get("edit");
  const toTab = params.get("tab");
  const scrollTo = params.get("scroll_to");

  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetRegistrationFormDataQuery({});

  useEffect(() => {
  
    if (scrollTo) {
      const element = document.getElementById(scrollTo);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  });



  useEffect(() => {
    if (toTab ) {
      dispatch(
        updateFormData((state) => {
          state.activeTab = toTab;
        })
      );
    }
    if (scrollTo == null) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    return () => {
      dispatch(
        updateFormData((state) => {
          state.currentFormDataBasic = {};
          state.currentFormDataFamily = {};
          state.currentFormDataImage = {};
          state.currentFormDataHoroscope = {};
          state.currentFormDataPartner = {};
          state.currentFormDataReligious = {};
          state.activeTab = "Basic";
          state.CompletedPercentage = {
            Basic: 0,
            Family: 0,
            Gallery: 0,
            Horoscope: 0,
            Partner: 0,
            Religious: 0,
          };
        })
      );
    };
    // eslint-disable-next-line
  }, [edit]);

  let tabs = [
    { label: "Basic", completed: CompletedPercentage.Basic },
    { label: "Family", completed: CompletedPercentage.Family },
    { label: "Religious", completed: CompletedPercentage.Religious },
    { label: "Horoscope", completed: CompletedPercentage.Horoscope },
    { label: "Partner", completed: CompletedPercentage.Partner },
    { label: "Gallery", completed: CompletedPercentage.Gallery },
  ];

  const handleTabClick = (tab) => {
    if (edit === "true" || completedTabs[tab.label] === true) {
      dispatch(
        updateFormData((state) => {
          state.activeTab = tab.label;
        })
      );
    }
  };

  return {
    tabs,
    activeTab,
    optionsData,
    webId,
    handleTabClick,
    isOptionDataSuccess,
    activeUser,
    edit,
    toTab,
  };
};

export default useProfileCreationForm;
