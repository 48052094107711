import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useDashboard = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    {
      title: "Dashboard",
      link: "/dashboard",
      icon: <span className="material-symbols-outlined x4"> dashboard </span>,
      active: checkIfActiveRoute("/dashboard", true),
    },
    {
      title: "Schedule",
      link: "/dashboard/schedule",
      icon: <span className="material-symbols-outlined x4">schedule</span>,
      active: checkIfActiveRoute("/dashboard/schedule"),
    },
    {
      title: "Job Assign",
      link: "/dashboard/job-assign",
      icon: <span className="material-symbols-outlined x4">work</span>,
      active: checkIfActiveRoute("/dashboard/job-assign"),
    },
    {
      title: "Search",
      icon: <span className="material-symbols-outlined x4">search</span>,
      link: "/search",
      subMenu: [
        {
          title: "Advanced Search",
          link: "/dashboard/advanced-search",
          active: checkIfActiveRoute("/dashboard/advanced-search"),
          icon: <span className={`sub-icon`}>AS</span>,
        },
        {
          title: "User Activity Search",
          link: "/dashboard/activity-search",
          active: checkIfActiveRoute("/dashboard/activity-search"),
          icon: <span className={`sub-icon`}>UA</span>,
        },
        {
          title: "User Service Search",
          link: "/dashboard/service-search",
          active: checkIfActiveRoute("/dashboard/service-search"),
          icon: <span className={`sub-icon`}>US</span>,
        },
      ],
    },
    {
      title: "Report",
      link: "/dashboard/report",
      icon: <span className="material-symbols-outlined x4">insert_chart</span>,
      active: checkIfActiveRoute("/dashboard/report"),
    },
    {
      title: "Staff",
      icon: <span className="material-symbols-outlined x4"> person </span>,
      link: "/staff",
      subMenu: [
        {
          title: "Staff Registration",
          link: "/dashboard/staff-create",
          active: checkIfActiveRoute("/dashboard/staff-create", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Staff List",
          link: "/dashboard/staff",
          active: checkIfActiveRoute("/dashboard/staff", true),
          icon: <span className={`sub-icon`}>SL</span>,
        },
        {
          title: "User Type",
          link: "/dashboard/staff-roles",
          active: checkIfActiveRoute("/dashboard/staff-roles"),
          icon: <span className={`sub-icon`}>R</span>,
        },
      ],
      active: checkIfActiveRoute("/dashboard/staff"),
    },
    {
      title: "Registration",
      icon: <span className="material-symbols-outlined x4"> how_to_reg </span>,
      link: "/registration",
      active: checkIfActiveRoute("registration"),
      subMenu: [
        {
          title: "Create Profile",
          link: "/dashboard/profile-registration",
          active: checkIfActiveRoute("/dashboard/profile-registration"),
          icon: <span className={`sub-icon`}>CF</span>,
        },
        {
          title: "Registered Profiles",
          link: "/dashboard/registration",
          active: checkIfActiveRoute("/dashboard/registration"),
          icon: <span className={`sub-icon`}>RF</span>,
        },
      ],
    },
    {
      title: "My Service View",
      icon: (
        <span className="material-symbols-outlined x4"> manage_accounts </span>
      ),
      link: "/dashboard/my-service",
      active: checkIfActiveRoute("/dashboard/my-service"),
    },
    {
      title: "Service Notification",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          mark_email_unread{" "}
        </span>
      ),
      link: "/dashboard/service-notification",
      active: checkIfActiveRoute("/dashboard/service-notification"),
    },
    {
      title: "Service Status Alerts",
      icon: <span className="material-symbols-outlined x4"> campaign </span>,
      link: "/dashboard/service-status",
      active: checkIfActiveRoute("/dashboard/service-status"),
    },
    {
      title: "Service Chat",
      icon: <span className="material-symbols-outlined x4"> sms </span>,
      link: "/dashboard/service-chat",
      active: checkIfActiveRoute("/dashboard/service-chat"),
    },
    {
      title: "Registration Report",
      icon: <span class="material-symbols-outlined x4">bar_chart</span>,
      link: "/dashboard/reg-report",
      active: checkIfActiveRoute("reg-report"),
    },
    {
      title: "Social Media Reports",
      icon: <span class="material-symbols-outlined x4">apps</span>,
      link: "/social-media",
      active: checkIfActiveRoute("social-media"),
      subMenu: [
        {
          title: "Daily Count Reports",
          link: "/dashboard/social-media-report",
          active: checkIfActiveRoute("/dashboard/social-media-report"),
          icon: <span className={`sub-icon`}>CF</span>,
        },
        {
          title: "UTM Reports",
          link: "/dashboard/social-media-utm-report",
          active: checkIfActiveRoute("/dashboard/social-media-utm-report"),
          icon: <span className={`sub-icon`}>RF</span>,
        },
      ],
    },
    {
      title: "Block Users",
      icon: <span class="material-symbols-outlined x4">block</span>,
      link: "/dashboard/block-users",
      active: checkIfActiveRoute("block-users"),
    },
    {
      title: "User Agreement",
      icon: <span class="material-symbols-outlined x4">handshake</span>,
      link: "/dashboard/user-agreement?activeTab=pending",
      active: checkIfActiveRoute("user-agreement"),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.side_menus?.flatMap?.(
      (menu) => [
        menu.menu_name,
        ...menu.sub_menu_list.map((sub_menu) => sub_menu.menu_name),
      ]
    );

    // menus.push("Social Media Report");

    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
          subMenu: menu.subMenu?.filter?.((subMenu) =>
            menus?.includes?.(subMenu.title)
          ),
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu: drawerMenuPermission };
};

export default useDashboard;
