import style from "./dashboardCard.module.scss";
const DashboardCard = ({ title, count, icon, active }) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner} pro-p-3`}>
        <div className={`${style.inner_heading} pro-mb-2`}>
          <div className={`${style.icon}`}>
            <span className="material-symbols-outlined">{icon}</span>
          </div>
          <h6 className="pro-ttl h6 pro-mb-0 pro-fw-normal pro-ps-2">
            {title}
          </h6>
        </div>
        <div className={`${style.body}`}>
          <h3 className="pro-ttl h3 pro-mb-0 pro-fw-bold">{count}</h3>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
