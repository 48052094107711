import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("user_type_sort")}&${getParams(
          "sort_by"
        )}&sort=${params?.sort || "desc"}&per_page=${
          params?.page_size || "10"
        }&page=${params?.page || 1}&${getParams("user_id")}&${getParams(
          "users_id"
        )}&${getParams("contact_profile_id")}&${getParams("service_type")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const profile = createApi({
  reducerPath: "profileApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Profile",
    "Overview",
    "PartnerFilters",
    "Preference",
    "serviceType",
    "viewServiceList",
    "scheduleHistory",
    "NewTaskData",
    "AddedServiceExistList",
    "Delete-Reason",
    "Activity-View-History",
    "AddedServiceExistListPDF",
  ],
  endpoints: (builder) => ({
    getProfileData: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `user/user-data/${id}`,
      }),
      providesTags: ["Profile"],
    }),
    getOverviewData: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `user/user-overview/${id}`,
      }),
      providesTags: ["Overview"],
    }),
    getStarMatchFilters: builder.query({
      query: () => ({
        method: "get",
        endpoint: `matches/partner-star-match-filters`,
      }),
      providesTags: ["PartnerFilters"],
    }),
    getUserPreference: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `user/partner-pref-filter-data/${id}`,
      }),
      providesTags: ["Preference"],
    }),
    getServiceType: builder.query({
      query: () => ({
        method: "get",
        endpoint: `service/service-form`,
      }),
      providesTags: ["serviceType"],
    }),
    getAddedServiceList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `service/user-service-list`,
      }),
      providesTags: ["viewServiceList"],
    }),
    getScheduleHistory: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `user/schedule-history/${values?.user_id}`,
      }),
      providesTags: ["scheduleHistory"],
    }),

    getViewServiceListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/service/user-service-history`,
      }),
      invalidatesTags: ["Report"],
    }),

    getNewTaskFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `task/task-creation-form/${values.id}`,
      }),
      providesTags: ["NewTaskData"],
    }),

    createNewTask: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/task/create-task`,
      }),

      invalidatesTags: ["Profile", "NewTaskData"],
    }),
    sendLoginCredentials: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/share-login-credentials`,
      }),

      invalidatesTags: ["Profile"],
    }),
    sendProfileDetails: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/share-profile`,
      }),

      invalidatesTags: ["Profile"],
    }),
    sendProfileLink: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/share-profile-link`,
      }),

      invalidatesTags: ["Profile"],
    }),
    hideProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/hide-profile`,
      }),

      invalidatesTags: ["Profile"],
    }),
    activateProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/activate-profile`,
      }),

      invalidatesTags: ["Profile"],
    }),

    deleteProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/delete-profile`,
      }),

      invalidatesTags: ["Profile"],
    }),

    getPdfGenerationData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user/user-details-for-pdf`,
      }),
      invalidatesTags: ["Profile"],
    }),

    editAboutMyself: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/about-me`,
      }),

      invalidatesTags: ["Profile"],
    }),

    getWhatsappShareCredentials: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/login-credentials`,
      }),

      invalidatesTags: ["Profile"],
    }),
    getWhatsappShareProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/share-profile-whatsapp`,
      }),

      invalidatesTags: ["Profile"],
    }),
    getMultipleShareProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/profile/multiple-profile-share`,
      }),

      invalidatesTags: ["Profile"],
    }),
    getAddedServiceExistList: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/service/check-service-exist`,
      }),
      providesTags: ["AddedServiceExistList"],
    }),
    suggestProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/service/add-multiple-service`,
      }),

      invalidatesTags: ["Profile"],
    }),
    suggestPartner: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/service/add-multiple-service-to-partners`,
      }),

      invalidatesTags: ["Profile"],
    }),
    updateLastBoosted: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `profile/user-boosted-date`,
      }),
      invalidatesTags: ["Overview"],
    }),
    completeFollowUp: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `task/follow-up-complete`,
      }),
      invalidatesTags: ["NewTaskData"],
    }),
    //delete reason data
    getDeleteReasonData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `profile/delete-reason-data`,
      }),
      providesTags: ["Delete-Reason"],
    }),

    //activity view history
    getActivityViewHistory: builder.query({
      query: (params) => ({
        method: "get",
        params: params,
        endpoint: `activity/view-history`,
      }),
      providesTags: ["Activity-View-History"],
    }),
    //downloading existing services
    getAddedServicePdfData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/service/check-service-exist-pdf`,
      }),
      providesTags: ["AddedServiceExistListPDF"],
    }),
  }),
});

export const {
  useGetProfileDataQuery,
  useGetOverviewDataQuery,
  useGetStarMatchFiltersQuery,
  useGetUserPreferenceQuery,
  useGetServiceTypeQuery,
  useGetAddedServiceListQuery,
  useGetScheduleHistoryQuery,
  useGetViewServiceListDataQuery,
  useGetPdfGenerationDataQuery,
  useCreateNewTaskMutation,
  useGetNewTaskFormDataQuery,
  useGetAddedServiceExistListQuery,
  useSendLoginCredentialsMutation,
  useSendProfileDetailsMutation,
  useSendProfileLinkMutation,
  useHideProfileMutation,
  useActivateProfileMutation,
  useEditAboutMyselfMutation,
  useGetWhatsappShareCredentialsMutation,
  useGetWhatsappShareProfileMutation,
  useGetMultipleShareProfileMutation,
  useDeleteProfileMutation,
  useSuggestPartnerMutation,
  useSuggestProfileMutation,
  useUpdateLastBoostedMutation,
  useCompleteFollowUpMutation,
  useGetDeleteReasonDataQuery,
  useGetActivityViewHistoryQuery,
  useGetAddedServicePdfDataQuery,
} = profile;
