import React from "react";
import {
  Input,
  DatePickerWithInput,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const RowTwo = ({ formik, optionsData }) => {
  return (
    <>
      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Permanent Address*"}
          type="text"
          id="permanent_address"
          name="permanent_address"
          className={`pro-input lg ${
            formik.touched.permanent_address &&
            formik.errors.permanent_address &&
            " error"
          }`}
          {...formik.getFieldProps("permanent_address")}
          error={
            formik.touched.permanent_address && formik.errors.permanent_address
          }
          errorMessage={formik.errors.permanent_address}
        />
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Education Category*
          </label>

          <Select
            id="education_category_id"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.staff_education_category}
            getOptionLabel={(option) => `${option.edu_category_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            value={optionsData?.data?.staff_education_category?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.education_category_id?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("education_category_id", value?.id || null)
            }
            className={`pro-input lg multi-select ${
              formik.touched.education_category_id &&
              formik.errors.education_category_id &&
              " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("education_category_id")}
          ></Select>
          {formik.touched.education_category_id &&
            formik.errors.education_category_id && (
              <span className="error-text">
                {formik.errors.education_category_id}
              </span>
            )}
        </div>
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Education Details"}
          type="text"
          id="education_info"
          name="education_info"
          className={`pro-input lg ${
            formik.touched.education_info &&
            formik.errors.education_info &&
            " error"
          }`}
          {...formik.getFieldProps("education_info")}
          error={formik.touched.education_info && formik.errors.education_info}
          errorMessage={formik.errors.education_info}
        />
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Designation*
          </label>

          <Select
            id="designation"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.designation}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option["id"]}`}
            value={optionsData?.data?.designation?.filter(
              (p) => p.id?.toString() === formik.values.designation?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("designation", value?.id || null)
            }
            className={`pro-input lg multi-select ${
              formik.touched.designation &&
              formik.errors.designation &&
              " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("designation")}
          ></Select>
          {formik.touched.designation && formik.errors.designation && (
            <span className="error-text">{formik.errors.designation}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <div className="input-wrap pro-mb-4 ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Joining Date
          </label>
          <div className={`pro-w-100 pro-d-flex`}>
            <DatePickerWithInput
              CalendarOutlineIcon={
                <span className="material-symbols-outlined ">
                  {" "}
                  calendar_month{" "}
                </span>
              }
              minDate={new Date()}
              id="joining_date"
              placeholder={
                formik.values.joining_date
                  ? formik.values.joining_date
                  : "Select Date"
              }
              onBlur={formik.handleBlur("joining_date")}
              className={`pro-input lg pro-min-full ${
                formik.touched.joining_date &&
                formik.errors.joining_date &&
                " error"
              }`}
              onDateChange={(value) => {
                formik.setFieldValue("joining_date", value);
              }}
              isError={
                formik.touched.joining_date && formik.errors.joining_date
              }
              errorMessage={formik.errors.joining_date}
            />
          </div>
        </div>
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Remarks"}
          type="text"
          id="remark"
          name="remark"
          className={`pro-input lg ${
            formik.touched.remark && formik.errors.remark && " error"
          }`}
          {...formik.getFieldProps("remark")}
          error={formik.touched.remark && formik.errors.remark}
          errorMessage={formik.errors.remark}
        />
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            User Type*
          </label>

          <Select
            id="user_type"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.user_types}
            getOptionLabel={(option) => `${option.user_type}`}
            getOptionValue={(option) => `${option["id"]}`}
            value={optionsData?.data?.user_types?.filter(
              (p) => p.id?.toString() === formik.values.user_type?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("user_type", value?.id || null)
            }
            className={`pro-input lg multi-select ${
              formik.touched.user_type && formik.errors.user_type && " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("user_type")}
          ></Select>
          {formik.touched.user_type && formik.errors.user_type && (
            <span className="error-text">{formik.errors.user_type}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Branch*
          </label>

          <Select
            id="branch_id"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.branches}
            getOptionLabel={(option) => `${option.branch_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            value={optionsData?.data?.branches?.filter(
              (p) => p.id?.toString() === formik.values.branch_id?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("branch_id", value?.id || null)
            }
            className={`pro-input lg multi-select ${
              formik.touched.branch_id && formik.errors.branch_id && " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("branch_id")}
          ></Select>
          {formik.touched.branch_id && formik.errors.branch_id && (
            <span className="error-text">{formik.errors.branch_id}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default RowTwo;
