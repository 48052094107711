import React from "react";
import TimePicker from "react-time-picker";
import Select from "react-select";
import style from "./RowOne.module.scss";
import { format } from "date-fns";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const RowOne = ({
  formik,
  optionsData,
  childClass,
  handleTimeChange,
  time,
  handleClearClick,
}) => {
  let newDate = new Date();
  newDate.setDate(newDate.getDate() + 1);
  return (
    <>
      <div className={childClass}>
        <div className={"input-wrap"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Service Dated Onn*
          </label>
          <Input
            value={formik.values.service_date}
            id="service_date"
            name="service_date"
            type={"date"}
            min={new Date().toISOString().split("T")[0]}
            className={`pro-input lg ${
              formik.touched.service_date &&
              formik.errors.service_date &&
              "error"
            }`}
            onChange={(e) => {
              formik.setFieldValue(
                "service_date",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
            onBlur={formik.handleBlur("service_date")}
            error={formik.touched.service_date && formik.errors.service_date}
            errorMessage={formik.errors.service_date}
          />
        </div>
      </div>
      <div className={childClass}>
        <div className={"input-wrap"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Follow Up Date*
          </label>
          <Input
            value={formik.values.follow_up_date}
            type={"date"}
            id="follow_up_date"
            name="follow_up_date"
            min={newDate.toLocaleDateString("fr-ca")}
            className={`pro-input lg ${
              formik.touched.follow_up_date &&
              formik.errors.follow_up_date &&
              "error"
            }`}
            onChange={(e) => {
              formik.setFieldValue(
                "follow_up_date",
                format(new Date(e.target.value), "yyyy-MM-dd")
              );
            }}
            onBlur={formik.handleBlur("follow_up_date")}
            error={
              formik.touched.follow_up_date && formik.errors.follow_up_date
            }
            errorMessage={formik.errors.follow_up_date}
          />
        </div>
      </div>
      <div className={childClass}>
        {/* //FIXME change timePicker package */}
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Follow Up Time*
          </label>
          <TimePicker
            format="hh:mm a"
            id="follow_up_time"
            hourPlaceholder="00"
            minutePlaceholder="00"
            secondPlaceholder="ss"
            disableClock
            className={`pro-input lg ${style.timePicker} ${
              formik.touched.follow_up_time &&
              formik.errors.follow_up_time &&
              "error"
            } `}
            onBlur={formik.handleBlur("follow_up_time")}
            onChange={(value) => handleTimeChange(value)}
            clearIcon={
              <span
                onClick={() => handleClearClick()}
                className="material-symbols-outlined x3"
              >
                close
              </span>
            }
            value={formik?.values?.follow_up_time === "00:00 AM" ? null : time}
          />

          {formik.touched.follow_up_time && formik.errors.follow_up_time && (
            <span className="error-text">{formik.errors.follow_up_time}</span>
          )}
        </div>
      </div>

      <div className={childClass}>
        <div className={"input-wrap pro-mb-4"}>
          <label
            htmlFor="looking_for"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Type of Service*
          </label>
          <Select
            id="service_type"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData.service_type}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.service_type}
            value={optionsData.service_type?.filter(
              (m) => m.id?.toString() === formik.values.service_type?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("service_type", value?.id)
            }
            onBlur={formik.handleBlur("service_type")}
            className={`pro-input  lg multi-select ${
              formik.touched.service_type &&
              formik.errors.service_type &&
              "error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.service_type && formik.errors.service_type && (
            <span className="error-text">{formik.errors.service_type}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default RowOne;
