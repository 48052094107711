import { Fragment, useEffect, useState } from "react";
import UserDetailTable from "../UserDetailTable";
import style from "./userDetailTableListing.module.scss";
import Assets from "../../../assets/Assets";
//import { MultiColumnTable } from "@wac-ui-dashboard/wac_component_library";
const UserDetailTableListing = ({
  data,
  search,
  service,
  checked,
  showCheck,
  lastElement,
  hide = false,
  handleSearchAddService,
  handleDownload,
  handleSelect,
  activity,
  horoscope,
  ActivityProfile,
  handleViewHistoryModal,
}) => {
  //try
  //filtering the  horoscope matched  profiles
  const [matchedProfiles, setMatchedProfiles] = useState([]);
  useEffect(() => {
    const matched = data.filter((item) => {
      // Find the Horoscope Match object in the basic_details array
      const horoscopeMatchObj = item?.user_data?.basic_details?.find(
        (detail) => detail.label === "Horoscope Match"
      );

      // Check if Horoscope Match value is 'Matched'
      return horoscopeMatchObj && horoscopeMatchObj.value === "Matched";
    });

    setMatchedProfiles(matched);
    //eslint-disable-next-line
  }, [horoscope]);

  return (
    <div className={`wrapper-profile-table pro-mt-4 pro-pb-6`}>
      <div className={`pro-btn-group pro-pb-2 pro-mt-3 ${style.btn_group}`}>
        {!hide && checked && checked?.length !== 0 && (
          <>
            <button className="pro-btn pro-btn-secondary">
              {checked.length} Selected
            </button>
            {/* <button
              className="pro-btn pro-btn-secondary"
              onClick={() => handleShare(checked)}
            >
              <span className="material-symbols-outlined"> ios_share </span>
              Share
            </button> */}
            <button
              className="pro-btn pro-btn-secondary"
              onClick={() => handleDownload(checked)}
            >
              <span className="material-symbols-outlined">cloud_download</span>
              Download
            </button>
          </>
        )}
      </div>
      {/* {(activity || !service) && data && data?.[0]?.user_data && (
        <div
          className={
            "pro-d-flex pro-justify-end pro-py-2 pro-pe-2 pro-gap-2 pro-items-center"
          }
        >
          <label
            htmlFor="flexSwitchCheckDefault"
            className="pro-font-sm pro-mb-0 pro-fw-medium"
          >
            Horoscope Matched
          </label>
          <div className="form-check form-switch pro-mb-0">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={horoscope}
              // onChange={(e) => updateTutorStatus(data?._id)}
              onChange={handleChangeToggle}
            />
          </div>
        </div>
      )} */}
      <div className={`${activity ? "pro-w-100" : "row g-2 g-xxl-4"}`}>
        {(horoscope ? matchedProfiles : data)?.map?.((profile, index) => (
          <Fragment key={index}>
            {activity ? (
              <div
                className={`${style.user_table} ${
                  profile?.service_details?.service_status && !service
                    ? "light_primary_bg"
                    : ""
                }`}
              >
                <div className={`${style.user_table_items} rm-border`}>
                  <UserDetailTable
                    data={activity ? profile?.user_data : profile}
                    key={index}
                    handleSelect={handleSelect}
                    extraClassNames={`${style.main_wrapper}`}
                    lastElement={
                      index === data?.length - 10 ? lastElement : false
                    }
                    search={search}
                    showCheck={showCheck}
                    checked={checked}
                  />
                </div>
                <div
                  className={`${style.user_table_items} rm-border`}
                  key={index + "new"}
                >
                  <UserDetailTable
                    data={profile?.partner_data}
                    partnerWebId={profile?.user_data?.profile_data?.web_id}
                    activity={activity}
                    handleSearchAddService={handleSearchAddService}
                    key={index + "new"}
                    handleSelect={handleSelect}
                    extraClassNames={`${style.main_wrapper} `}
                    lastElement={
                      index === data?.length - 10 ? lastElement : false
                    }
                    showCheck={showCheck}
                    checked={checked}
                  />
                </div>

                {profile?.service_details?.service_status && (
                  <div className={style.data_wrapper}>
                    <h3 className={style.heading}>{`Service Details`}</h3>

                    {profile?.service_details?.user_service?.map((item, i) => {
                      return (
                        <div className={style.data_item}>
                          <div
                            className={`style.data_key ${
                              item?.bold ? "pro-fw-bold " : ""
                            }`}
                          >
                            {item?.label}
                          </div>
                          <span>:</span>
                          <div
                            className={`style.data_value ${
                              item?.bold ? "pro-fw-bold " : ""
                            }`}
                          >
                            {item?.value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profile?.user_data?.basic_details?.some(
                  (i) => i.label === "Horoscope Match" && i.value === "Matched"
                ) ? (
                  <span className={style.match_badge}>
                    <img src={Assets.MatchBadge} alt="matched" />
                  </span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="col-xl-6">
                <UserDetailTable
                  data={activity ? profile?.user_data : profile}
                  key={index}
                  handleSelect={handleSelect}
                  extraClassNames={`${style.main_wrapper} `}
                  lastElement={
                    index === data?.length - 10 ? lastElement : false
                  }
                  search={search}
                  showCheck={showCheck}
                  checked={checked}
                  matched={profile.basic_details.some(
                    (i) =>
                      i.label === "Horoscope Match" && i.value === "Matched"
                  )}
                  //pro for activity tabs in profile
                  ActivityProfile={ActivityProfile}
                  handleViewHistoryModal={handleViewHistoryModal}
                  profileData={profile}
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default UserDetailTableListing;
