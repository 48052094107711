import {
  Button,
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../Global/Shimmers/InputShimmer";
import UserDetailTableShimmer from "../Global/Shimmers/UserDetailTableShimmer";
import UserDetailTableListing from "../Global/UserDetailTableListing";
import AssignForm from "./Forms/AssignForm";
import SearchForm from "./Forms/SearchForm";
import useJobAssign from "./useJobAssign";
import EmptyData from "../Global/EmptyData";

const JobAssign = () => {
  const {
    formik,
    state,
    checked,
    optionsData,
    isSuccess,
    showAssignForm,
    showScroll,
    paginationOptions,
    JobAssignData,
    isError,
    isFetching,
    isDataSuccess,
    setChecked,
    handleScrollToTop,
    handlePagination,
    handlePageSize,
    handleReset,
    handleSearch,
    handleSelect,
    handleCancelAssign,
    handleAssign,
    handleClose,
  } = useJobAssign();

  return (
    <div className={`pro-mb-4`}>
      {showScroll && (
        <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
          <span class="material-symbols-outlined">expand_less</span>
        </button>
      )}
      <HeadingGroup title={"Search Profile For"}>
        <div className="pro-check-box  pro-items-center">
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={checked.length === 0 ? handleReset : handleCancelAssign}
          >
            {checked.length === 0 ? "Reset" : "Cancel"}
          </Button>
          <Button
            type="submit"
            className={`pro-btn pro-btn-primary ${isFetching ? "loading" : ""}`}
            onClick={checked.length === 0 ? handleSearch : handleAssign}
          >
            {checked.length === 0 ? "Search" : "Assign"}
          </Button>
        </div>
      </HeadingGroup>
      <div className="pro-check-box pro-items-center pro-d-flex pro-mb-5 pro-mt-2">
        <div className="pro-check-box pro-mt-3">
          <input
            type="radio"
            className="pro-check"
            id="activation_date"
            name="activation_creation_dates"
            checked={formik.values.date_type === 1 ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue("date_type", 1);
              } else {
                formik.setFieldValue("date_type", null);
              }
            }}
          />
          <label
            htmlFor="activation_date"
            className="pro-check-label pro-flex-grow-0 pro-px-4 pro-text-nowrap"
          >
            Activation Date
          </label>
          <input
            type="radio"
            className="pro-check"
            id="creation_date"
            name="activation_creation_dates"
            checked={formik.values.date_type === 2 ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue("date_type", 2);
              } else {
                formik.setFieldValue("date_type", null);
              }
            }}
          />
          <label
            htmlFor="creation_date"
            className="pro-check-label pro-flex-grow-0 pro-px-4 pro-text-nowrap"
          >
            Creation Date
          </label>

          <input
            type="checkbox"
            className="pro-check "
            id="agreement"
            name="agreement"
            checked={formik.values?.agreement ?? false}
            onChange={(e) =>
              formik.setFieldValue(
                "agreement",
                !formik.values?.agreement ?? false
              )
            }
          />
          <label htmlFor="agreement" className="pro-check-label">
            {" "}
            Agreement{" "}
          </label>
        </div>
      </div>
      {isSuccess ? (
        <>
          <SearchForm formik={formik} optionsData={optionsData} />
          <UserDetailTableListing
            data={JobAssignData?.data?.data || []}
            handleSelect={handleSelect}
            checked={checked}
            search
            showCheck
            hide={true}
          />
          <div className="pro-pb-6"></div>
        </>
      ) : (
        <div className={`row pro-my-5`}>
          {[...Array(7)].map((_, i) => (
            <div className={`col-3`} key={i}>
              <InputShimmer />
            </div>
          ))}
        </div>
      )}
      {isFetching ? (
        <div className={`col-12 pro-mt-4`}>
          <div className={`row g-4`}>
            {[...Array(2)].map((_, i) => (
              <div className={`col-6`} key={i}>
                <UserDetailTableShimmer />
              </div>
            ))}
          </div>
        </div>
      ) : (
        isDataSuccess &&
        JobAssignData?.data?.data?.length === 0 && <EmptyData />
      )}

      {isError && <EmptyData />}
      <OffCanvasLayout
        title={"Job Assigned to"}
        show={showAssignForm}
        handleClose={handleClose}
        body={
          <AssignForm
            checked={checked}
            setChecked={setChecked}
            handleCancel={handleClose}
          />
        }
      />
      {JobAssignData?.data?.data?.length > 0 && (
        <Pagination
          currentPage={state.currentPage}
          totalPageCount={Math.ceil(
            JobAssignData?.data?.total_count / state.currentPageSize
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
    </div>
  );
};

export default JobAssign;
