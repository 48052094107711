import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("user_type_sort")}&${getParams(
          "sort_by"
        )}&sort=${params?.sort_order || "desc"}&per_page=${
          params?.page_size || "10"
        }&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const schedules = createApi({
  reducerPath: "scheduleApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Schedules", "SchedulesForm"],
  endpoints: (builder) => ({
    getSchedulesListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/schedule/schedule-list`,
      }),
      invalidatesTags: ["Schedules"],
    }),

    updateSchedulesListFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["Schedules"],

      // async onQueryStarted({},{ dispatch, queryFulfilled }) {

      //   await queryFulfilled;

      //   setTimeout(() => {
      //     dispatch(schedules.util.invalidateTags(["Schedules"]));
      //   }, 1000);

      // },
    }),

    getScheduleCreationFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `schedule/schedule-form`,
      }),
      providesTags: ["SchedulesForm"],
    }),
    createSchedule: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/schedule/schedule-create`,
      }),

      invalidatesTags: ["Schedules"],
    }),
    getScheduleUpdationFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `schedule/schedule-view/${values.id}`,
      }),
      providesTags: ["SchedulesUpdateForm"],
    }),
    updateSchedule: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/schedule/schedule-update`,
      }),

      invalidatesTags: ["Schedules"],
    }),
  }),
});

export const {
  useGetSchedulesListDataQuery,
  useUpdateSchedulesListFieldsMutation,
  useCreateScheduleMutation,
  useGetScheduleCreationFormDataQuery,
  useGetScheduleUpdationFormDataQuery,
  useUpdateScheduleMutation,
} = schedules;
