import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getServiceSearch,
  updateConfig,
} from "../../../store/slices/Search/ServiceSearch";
import useObserver from "../../../utils/hooks/useObserver";
import { useGetServiceSearchFiltersQuery } from "../../../store/queries/Search";
import { updateProfileConfig } from "../../../store/slices/Profile";
import { updateConfig as updateAddedServiceConfig } from "../../../store/slices/Profile/AddedServiceExist";
import { useGetServiceTypeQuery } from "../../../store/queries/Profile";
import { exportServiceSearch } from "./ServiceSearchForm/api";
import moment from "moment";

const useServiceSearch = () => {
  const dispatch = useDispatch();
  const serviceRef = useRef();
  const staffRef = useRef();
  const state = useSelector((state) => state.serviceSearch);
  const profileState = useSelector((state) => state.profile);
  const [page, setPage] = useState(1);
  const [showScroll, setShowScroll] = useState(false);
  const [serviceType, setServiceType] = useState(true);
  const [horoscope, setHoroscope] = useState(false);
  const [exportValue, setExportValue] = useState(50);
  const [exportLoading, setExportLoading] = useState(false);

  const addedServiceExistState = useSelector(
    (state) => state.addedServiceExist
  );

  const { data: optionsData } = useGetServiceSearchFiltersQuery();
  const { data: serviceOptionsData, isSuccess } = useGetServiceTypeQuery();
  const formik = useFormik({
    initialValues: {
      service_type: "2",
      service: "8",
      branch: [`${optionsData?.data?.defaltValues?.branch_id}`],
      staff_type: optionsData?.data?.defaltValues?.staff_type,
      staff: optionsData?.data?.defaltValues?.staff_id,
      religion_id: null,
      caste_id: null,
      service_create_from: null,
      service_create_to: null,
      service_followup_from: null,
      service_followup_to: null,
      service_date_on_from: null,
      service_date_on_to: null,
      from_date: null,
      to_date: null,
      entire_data: true,
      subscription: "",
    },
    validate: (values) => {
      let errors = {};

      if (values.branch.length === 0) {
        errors.service_type = "*Required";
      }
      if (values.branch.length === 0) {
        errors.branch = "*Required";
      }
      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setPage(1);
      dispatch(
        updateConfig((state) => {
          state.serviceSearchValues = { ...values };
        })
      );
      dispatch(getServiceSearch({ ...values, page: 1 }));
    },
  });

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleSearch = () => {
    setServiceType(formik.values.service_type === 1 ? false : true);
    dispatch(
      updateConfig((state) => {
        state.serviceSearchStatus = "idle";
        state.serviceSearchData = {};
      })
    );
    formik.handleSubmit();
  };
  const handleReset = () => {
    formik.resetForm();
    dispatch(
      updateConfig((state) => {
        state.serviceSearchStatus = "idle";
        state.serviceSearchData = {};
      })
    );
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    dispatch(getServiceSearch({ ...formik.values, page: page + 1 }));
  };

  const handleSearchAddService = (e, partner_web_id, client_web_id) => {
    e?.stopPropagation();
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = true;
        state.partner_client_web_id = partner_web_id;
        state.client_web_id = client_web_id;
      })
    );
    const dateInput1 = document.getElementById("service_date");
    const dateInput2 = document.getElementById("follow_up_date");

    if (dateInput1) {
      dateInput1.value = "";
    }
    if (dateInput2) {
      dateInput2.value = "";
    }

    dispatch(
      updateAddedServiceConfig((state) => {
        state.storedFormikValues = {};
      })
    );
  };
  const handleCloseOffCanvas = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.serviceSearchStatus === "pending",
    hasMore:
      state.serviceSearchData?.per_page *
        state.serviceSearchData?.current_page <
      state.serviceSearchData?.total_count,
    callback: handleLoadMore,
  });

  const handleSeviceType = (value) => {
    serviceRef.current.setValue([], "clear");
    formik.setFieldValue("service_type", value?.id || null);
  };

  const handleBranchChange = (value) => {
    // serviceRef.current.setValue([], "clear");
    formik.setFieldValue(
      "branch",
      value.map((v) => v.id?.toString?.())
    );
    formik.setFieldValue("staff", "");
  };

  const closeModal = () => {
    dispatch(
      updateAddedServiceConfig((state) => {
        state.showAddedServiceModal = false;
      })
    );
  };
  const handleChangeToggle = () => {
    setHoroscope(!horoscope);
  };

  const exportOptions = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 2,
      value: 100,
    },
    {
      id: 3,
      value: 500,
    },
    {
      id: 5,
      value: 1000,
    },
  ];

  const handleExportServiceResult = () => {
    setExportLoading(true);
    exportServiceSearch({
      ...state.serviceSearchValues,
      export_count: exportValue,
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `service_search_${moment(Date.now()).format(
          "MM_DD_YYYY_HH_mm_ss"
        )}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setExportLoading(false);
    });
  };

  const hasExportPermission = useMemo(() => {
    let permission = state?.serviceSearchData?.permissions?.filter((p) =>
      Object.keys(p).includes("can_service_export")
    );
    return permission?.[0]?.can_service_export ?? false;
    // eslint-disable-next-line
  }, [state.serviceSearchData]);

  return {
    formik,
    state,
    serviceRef,
    staffRef,
    serviceType,
    showScroll,
    profileState,
    addedServiceExistState,
    serviceOptionsData,
    isSuccess,
    horoscope,
    exportOptions,
    exportValue,
    exportLoading,
    hasExportPermission,
    handleExportServiceResult,
    setExportValue,
    handleChangeToggle,
    closeModal,
    handleSearchAddService,
    handleCloseOffCanvas,
    handleScrollToTop,
    lastElement,
    handleSearch,
    handleReset,
    handleSeviceType,
    handleBranchChange,
  };
};

export default useServiceSearch;
