import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const RowOne = ({ formik, optionsData }) => {
  return (
    <>
      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Name*"}
          type="text"
          id="staff_name"
          name="staff_name"
          className={`pro-input lg ${
            formik.touched.staff_name && formik.errors.staff_name && " error"
          }`}
          {...formik.getFieldProps("staff_name")}
          error={formik.touched.staff_name && formik.errors.staff_name}
          errorMessage={formik.errors.staff_name}
        />
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Nick Name*"}
          type="text"
          id="nick_name"
          name="nick_name"
          className={`pro-input lg ${
            formik.touched.nick_name && formik.errors.nick_name && " error"
          }`}
          {...formik.getFieldProps("nick_name")}
          error={formik.touched.nick_name && formik.errors.nick_name}
          errorMessage={formik.errors.nick_name}
        />
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Gender*
          </label>

          <Select
            id="gender"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.genderList}
            getOptionLabel={(option) => `${option.gender_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.gender && formik.errors.gender && " error"
            }`}
            value={optionsData?.data?.genderList?.filter(
              (p) => p.id?.toString() === formik.values.gender?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("gender")}
            onChange={(value) => formik.setFieldValue("gender", value?.id || null)}
          ></Select>
          {formik.touched.gender && formik.errors.gender && (
            <span className="error-text">{formik.errors.gender}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Marrital Status*
          </label>

          <Select
            id="marital_status"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.staff_marital_status}
            getOptionLabel={(option) => `${option.staff_marital_status}`}
            getOptionValue={(option) => `${option["id"]}`}
            value={optionsData?.data?.staff_marital_status?.filter(
              (p) =>
                p.id?.toString() === formik.values.marital_status?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("marital_status", value?.id || null)
            }
            className={`pro-input lg multi-select ${
              formik.touched.marital_status &&
              formik.errors.marital_status &&
              " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("marital_status")}
          ></Select>
          {formik.touched.marital_status && formik.errors.marital_status && (
            <span className="error-text">{formik.errors.marital_status}</span>
          )}
        </div>
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Email*"}
          type="email"
          id="staff_email"
          name="staff_email"
          placeholder="name@example.com"
          className={`pro-input lg ${
            formik.touched.staff_email && formik.errors.staff_email && " error"
          }`}
          {...formik.getFieldProps("staff_email")}
          error={formik.touched.staff_email && formik.errors.staff_email}
          errorMessage={formik.errors.staff_email}
        />
      </div>
      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Mobile*"}
          type="text"
          id="staff_mobile"
          name="staff_mobile"
          className={`pro-input lg ${
            formik.touched.staff_mobile &&
            formik.errors.staff_mobile &&
            " error"
          }`}
          {...formik.getFieldProps("staff_mobile")}
          error={formik.touched.staff_mobile && formik.errors.staff_mobile}
          errorMessage={formik.errors.staff_mobile}
        />
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Office Number*"}
          type="text"
          id="office_number"
          name="office_number"
          className={`pro-input lg ${
            formik.touched.office_number &&
            formik.errors.office_number &&
            " error"
          }`}
          {...formik.getFieldProps("office_number")}
          error={formik.touched.office_number && formik.errors.office_number}
          errorMessage={formik.errors.office_number}
        />
      </div>

      <div className={`col-lg-4   col-xl-3`}>
        <Input
          label={"Present Address*"}
          type="text"
          id="present_address"
          name="present_address"
          className={`pro-input lg ${
            formik.touched.present_address &&
            formik.errors.present_address &&
            " error"
          }`}
          {...formik.getFieldProps("present_address")}
          error={
            formik.touched.present_address && formik.errors.present_address
          }
          errorMessage={formik.errors.present_address}
        />
      </div>
    </>
  );
};

export default RowOne;
