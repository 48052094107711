import {
  HeadingGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";

import useServiceChat from "./useServiceChat";

const ServiceChat = () => {
  const {
    serviceChat,
    serviceChatState,
    currentPage,
    paginationOptions,
    sortedTableFields,
    isFetching,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
  } = useServiceChat();

  return (
    <>
      <HeadingGroup title={"Service Chat"} extraClassName={`pro-mb-2`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <Table
          data={serviceChat?.data?.data || []}
          uniqueID={"created_date"}
          fields={sortedTableFields}
          showCheckBox={false}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          deletable={false}
          multiSelect={false}
          perpage={serviceChatState.currentPageSize}
        />

        {serviceChat?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              serviceChat?.data?.total_count / serviceChat?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
    </>
  );
};

export default ServiceChat;
