import React from "react";
import useIncomingCall from "./useIncomingCall";
import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";

import FilterForm from "./FilterForm";
import { DateRangePicker } from "rsuite";
import "../../../../assets/scss/rsuite.scss";

const IncomingCall = () => {
  const {
    incomingCallData,
    incomingCallState,
    isFetching,
    paginationOptions,
    isLoading,
    initialDateRange,
    showFilterModal,
    initialTimeRange,
    handleClearValues,
    // value,
    handleGetValues,
    closeFilterModal,
    // handleFilterAction,
    handleDateChange,
    handleSearch,
    handleClearClick,
    handlePageSize,
    handlePagination,
    getRow,
    handleSort,
  } = useIncomingCall();

  return (
    <>
      <div className="pro-mt-2">
        <HeadingGroup title={"Incoming Calls"} extraClassName={`pro-mb-2`} />
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                incomingCallState?.searchKey === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: incomingCallState?.searchKey }}
              showActions={false}
              //date picker props
              showDateRange={true}
              initialDateRange={initialDateRange}
              onDateChange={handleDateChange}
            />
          </div>
          <div className="col-auto">
            <DateRangePicker
              menuClassName="time-picker"
              placement="bottomEnd"
              format="HH:mm"
              ranges={[]}
              placeholder={"Select Time Range"}
              defaultValue={initialTimeRange}
              onChange={(value) => handleGetValues(value)}
              onClean={() => handleClearValues()}
              showHeader={false}
              value={incomingCallState?.timeFilterValue}
              character={" - "}
              // showWeekNumbers={false}
              // hoverRange={(date) => [subDays(date, 1), addDays(date, 1)]}
            />
            {/* <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn} `}
              onClick={handleFilterAction}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Time Filter</span>
            </button> */}
          </div>
        </div>
        <div className={`col-auto pro-pb-6`}>
          <Table
            data={incomingCallData?.data?.data || []}
            uniqueID={"id"}
            fields={incomingCallData?.data?.field}
            showCheckBox={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={incomingCallState?.currentPageSize}
            // handleEdit={handleTableEditClick}
            assignable={false}
            deletable={false}
            multiSelect={false}
          />
          {incomingCallData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={incomingCallState.currentPage}
              totalPageCount={Math.ceil(
                incomingCallData?.data?.total_count /
                  incomingCallState?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>

        <OffCanvasLayout
          show={showFilterModal}
          handleClose={closeFilterModal}
          title={"Incoming Call Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterForm closeFilterModal={closeFilterModal} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default IncomingCall;
