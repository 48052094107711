import React from "react";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";

const BasicPreference = ({
  formik,
  optionsData,
  casteRef,
  handleSelectReligion,
}) => {
  return (
    <div className="row" id="basic-preference">
      {/**
       * Religion
       * Caste
       * Sub Caste
       * Marital Status
       * Age From
       * Age To
      
       */}
      <RowOne
        formik={formik}
        optionsData={optionsData}
        casteRef={casteRef}
        handleSelectReligion={handleSelectReligion}
      />

      {/**
     
       * Height From
       * Height To
       * Complexion
       * Body Type
       * Special Case
      
       */}
      <RowTwo formik={formik} optionsData={optionsData} />
    </div>
  );
};

export default BasicPreference;
