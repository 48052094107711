import { useFormik } from "formik";
// import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateSocialMediaReportMutation } from "../../../store/queries/SocialMediaReport";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/socialMediaReport/socialMediaReportSlice";

const useSocialMediaForm = ({ handleClose, refetch }) => {
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => state.socialMediaReport);
  const [updateSocialMediaReport] = useUpdateSocialMediaReportMutation();

  const formik = useFormik({
    initialValues: {
      others: selectedData?.others !== "" ? selectedData?.others : "",
      existed: selectedData?.existed !== "" ? selectedData?.existed : "",
      other_state:
        selectedData?.other_state !== "" ? selectedData?.other_state : "",
    },
    // validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const obj = {
        ...values,
        date_id: selectedData?.date_id,
      };
      updateSocialMediaReport(obj).then((res) => {
        if (res?.data?.status_code === 200) {
          handleClose();
          refetch();
          toast.success("Successfully Updated");
          dispatch(
            updateConfig((state) => {
              state.selectedData = [];
            })
          );
        } else {
          toast.error("Failed to update the report");
          handleClose();
          dispatch(
            updateConfig((state) => {
              state.selectedData = [];
            })
          );
        }
      });
    },
  });

  return { formik };
};

export default useSocialMediaForm;
