import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import ListingFilter from "../ListingFilter";
import usePendingCalls from "./usePendingCalls";

const PendingCalls = ({activeProfile, params}) => {
  const {
    queryData,
    dashboardState,
    currentPage,
    paginationOptions,
    isFetching,
    showTableFieldsModal,
    optionsData,
    sortedTableFields,
    hasReAssignJobPermission,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    updatePendingCallsFields,
    handleStaffFilter,
    handleBranchFilter,
    handleEditFieldsClick,
    closeEditFieldsModal,
    handleTableEditClick,
    handleClearFilters,
  } = usePendingCalls({activeProfile, params});

  return (
    <>
      <HeadingGroup title={"Staff Pending Calls"} className={`pro-mb-4`} />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <ListingFilter
          optionsData={optionsData}
          handleStaffFilter={handleStaffFilter}
          handleBranchFilter={handleBranchFilter}
          dashboardState={dashboardState}
          handleEditFieldsClick={handleEditFieldsClick}
          handleClearFilters={handleClearFilters}
        />
        <Table
          data={queryData?.data?.data || []}
          uniqueID={"id"}
          fields={sortedTableFields}
          showCheckBox={hasReAssignJobPermission}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={queryData?.currentPageSize}
          handleEdit={handleTableEditClick}
          assignable={false}
          deletable={false}
          multiSelect={false}
        />

        {queryData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              queryData?.data?.total_count / dashboardState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showTableFieldsModal}
          handleClose={closeEditFieldsModal}
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={queryData?.data?.table_id}
              updateData={updatePendingCallsFields}
              handleCancel={closeEditFieldsModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default PendingCalls;
