import React from "react";
import useUtmReportForm from "./useUtmReportForm";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Style from "./utmReportForm.module.scss";

const UtmReportForm = ({ handleClose, refetch }) => {
  const { formik } = useUtmReportForm({ handleClose, refetch });
  return (
    <>
      <div className={`pro-toggle ${Style.toggle_wrapper}`}>
        <div className={`row pro-items-center pro-w-100`}>
          <div className={`col`}>
            <label className="pro-me-2">Others</label>
          </div>
          <div className={`col-auto`}>
            <div className="pro-toggle-box">
              <input
                id="others"
                name="others"
                type="checkbox"
                checked={Boolean(formik.values?.others) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "others",
                    !Boolean(formik.values?.others) ?? 0
                  );
                }}
              />
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <div className={`pro-toggle pro-pt-5 ${Style.toggle_wrapper}`}>
        <div className={`row pro-items-center pro-w-100`}>
          <div className={`col`}>
            <label className="pro-me-2">Existed</label>
          </div>
          <div className={`col-auto`}>
            <div className="pro-toggle-box">
              <input
                id="existed"
                name="existed"
                type="checkbox"
                checked={Boolean(formik.values?.existed) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "existed",
                    !Boolean(formik.values?.existed) ?? 0
                  );
                }}
              />
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <div className={`pro-toggle pro-pt-5 ${Style.toggle_wrapper}`}>
        <div className={`row pro-items-center pro-w-100`}>
          <div className={`col`}>
            <label className="pro-me-2">Other State</label>
          </div>
          <div className={`col-auto`}>
            <div className="pro-toggle-box ">
              <input
                id="other_state"
                name="other_state"
                type="checkbox"
                checked={Boolean(formik.values?.other_state) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "other_state",
                    !Boolean(formik.values?.other_state) ?? 1
                  );
                }}
              />
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div className="pro-d-flex pro-justify-end pro-mt-6">
        <Button
          className={"pro-btn-link lg pro-px-5 pro-mx-2"}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default UtmReportForm;
