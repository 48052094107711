import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useCreateProfileHoroscopeInfoMutation } from "../../../../store/queries/Registration";
import {
  getUserDetails,
  updateFormData,
} from "../../../../store/slices/Registration/createProfileSlice";
import { useNavigate } from "react-router-dom";
import { profile } from "../../../../store/queries/Profile";

const useHoroscope = ({ isOptionDataSuccess, activeUser, edit, toTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentFormDataHoroscope } = useSelector(
    (state) => state.createProfile
  );
  const [errors, setErrors] = useState("");
  const [saveAndNext, setSaveAndNext] = useState(false);

  const initialValues = useMemo(() => {
    return ["grahanila", "navamshakam"]
      .map((title) => {
        return [...Array(12)].map((_, i) => `${title}%${i + 1}`);
      })
      .flat()
      .reduce((a, v, i) => ({ ...a, [v]: null }), {});
  }, []);

  const [registerProfile, { isLoading: isUpdating }] =
    useCreateProfileHoroscopeInfoMutation();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      try {
        let grahanilaReq = Object.keys(values)
          .filter((key) => key.includes("grahanila"))
          .map((obj) => {
            return { column: obj.split("%")[1], columnValue: values[obj] };
          });

        let navamshakamReq = Object.keys(values)
          .filter((key) => key.includes("navamshakam"))
          .map((obj) => {
            return { column: obj.split("%")[1], columnValue: values[obj] };
          });

        const reqObj = {
          user_id: activeUser,
          grahanila: grahanilaReq,
          navamshakam: navamshakamReq,
        };

        dispatch(
          updateFormData((state) => {
            state.currentFormDataHoroscope = {
              ...state.currentFormDataHoroscope,
              ...reqObj,
            };
            state.completedTabs = {
              ...state.completedTabs,
              Horoscope: true,
              Partner: true,
            };
          })
        );

        registerProfile(reqObj).then((response) => {
          setErrors("");
          if (response?.data?.status_code === 200) {
            submitForm(resetForm);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = () => {
    dispatch(profile.util.invalidateTags(["Profile", "Overview"]));
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Partner";
      })
    );
    toast.success("Successfully updated");
    if (toTab && !saveAndNext) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (Object.keys(currentFormDataHoroscope).length === 0) {
      dispatch(
        getUserDetails({
          endpoint: "user-horoscope-info",
          id: activeUser,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormDataHoroscope || {}).forEach((key) => {
        currentFormDataHoroscope?.[key]?.forEach?.((val) => {
          formik.setFieldValue(`${key}%${val?.column}`, val?.columnValue);
        });
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormDataHoroscope]);

  useEffect(() => {
    let totalFields = Object.values(formik.values).filter(
      (f) => f !== "" && f !== null
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Horoscope: Math.ceil(
            (totalFields / Object.keys(formik.values).length) * 100
          ),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  return {
    errors,
    formik,
    isUpdating,
    setSaveAndNext,
  };
};

export default useHoroscope;
