import {
  HeadingGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useAddedServiceExistList from "./useAddedServiceExistList";
import ViewTableDetailsSS from "./ViewTableDetails";

const AddedServiceExistList = () => {
  const {
    list,
    isFetching,
    currentPage,
    paginationOptions,
    tableRef,
    pdf2Loading,
    handleDownloadPdf,
    handleIgnore,
    handlePagination,
    handlePageSize,
    getRow,
  } = useAddedServiceExistList();

  return (
    <div>
      <HeadingGroup title={"Added Service"} extraClassName={`pro-mb-2`}>
        <div className="pro-d-flex pro-gap-2">
          <button
            className={`pro-btn pro-btn-primary ${
              pdf2Loading ? "loading" : ""
            }`}
            onClick={() => handleDownloadPdf()}
          >
            Download PDF
          </button>
          <button
            className="pro-btn pro-btn-primary"
            onClick={() => handleIgnore()}
          >
            Ignore & Continue
          </button>
        </div>
      </HeadingGroup>
      <div className={`col-auto pro-pb-6`}>
        <Table
          data={list?.data?.data || []}
          uniqueID={"web_id"}
          fields={list?.data?.fields}
          SortIcon={<FaSort />}
          getRow={getRow}
          loading={isFetching}
          perpage={10}
        />

        {list?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              list?.data?.total_count / list?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>

      <div style={{ position: "absolute", left: "-9999px" }}>
        <ViewTableDetailsSS ref={tableRef} />
      </div>
    </div>
  );
};

export default AddedServiceExistList;
