import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentBranchFilter: null,
  currentStaffFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  dateRange:[],
  showJobAssign:false
 
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = reportSlice.actions;

export default reportSlice.reducer;
