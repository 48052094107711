import {
  Button,
  DatePickerWithInput,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { addDays } from "date-fns";
import style from "./ViewServiceListFilters.module.scss";
import Select from "react-select";

const ViewServiceListFilters = ({
  handleDateChangeDateOn,
  handleDateChangeFollowUp,
  handleAddService,
  handleServiceSearchChange,
  handleServiceChange,
  searchValue,
  optionsData,
  viewService,
}) => {
  let initialDateRange = {
    startDate: new Date(),
    endDate: addDays(new Date(), 30),
    key: "selection",
  };
  return (
    <div className="col-auto pro-pt-3">
      <div className="flex-fill gx-2 gy-2 pro-d-flex pro-items-center pro-justify-between row">
        <div className="col-auto">
          <div className={`pro-btn-group ${style.group}`}>
            {/* <div className="pro-btn pro-btn-outline">All (200)</div> */}
            <div className="">
              <DatePickerWithInput
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                }
                range
                initialValue={initialDateRange}
                onDateChange={handleDateChangeDateOn}
              />
            </div>
            <div className="pro-ms-2">
              <DatePickerWithInput
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                }
                range
                initialValue={initialDateRange}
                onDateChange={handleDateChangeFollowUp}
              />
            </div>
            <div className={`input-wrap pro-ms-2 ${style.input_box} `}>
              <input
                type="text"
                placeholder="Service Search"
                className="pro-input icon-l lg"
                value={searchValue}
                // onClick={() => handleRegSearchClick()}
                onChange={(e) => handleServiceSearchChange(e.target.value)}
              />
              <span className="pro-icon">
                <span className="material-symbols-outlined"> search </span>
              </span>
            </div>

            <div className="pro-ms-2 col-3">
              <Select
                id="service"
                isMulti
                isClearable={true}
                placeholder={"Service"}
                options={optionsData?.data?.service_list}
                getOptionLabel={(option) => option.service_title}
                getOptionValue={(option) => option.id}
                className={`pro-input lg multi-select`}
                classNamePrefix="pro-input"
                value={optionsData?.data?.service_list?.filter((p) =>
                  viewService?.serviceValue?.includes(p.id?.toString())
                )}
                onChange={(value) =>
                  handleServiceChange(value.map((v) => v.id?.toString()))
                }
              ></Select>
            </div>
          </div>
        </div>
        <Button
          type="submit"
          className={`pro-btn pro-btn-primary ${
            false === "pending" ? "loading" : ""
          }`}
          onClick={() => handleAddService()}
        >
          Add Service
        </Button>
      </div>
    </div>
  );
};

export default ViewServiceListFilters;
