import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import Select from "react-select";
import useLoginCredentialsForm from "./useLoginCredentialsForm";
import style from "./loginCredentialsForm.scss";
import { FaWhatsapp } from "react-icons/fa";

const LoginCredentialsForm = ({ handleCancel, userId, isSuccess }) => {
  const { formik, optionsData, isOptionDataSuccess, handleWhatsappShare } =
    useLoginCredentialsForm({
      handleCancel,
      userId,
    });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Send Login Details</h4>
      </div>

      {isSuccess && isOptionDataSuccess ? (
        <>
          <div className={"input-wrap pro-mb-5"}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Contact Numbers
            </label>
            <Select
              isMulti
              placeholder={"Select"}
              id="contact_number"
              options={optionsData?.data?.profile_data?.mobile?.data}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              value={optionsData?.data?.profile_data?.mobile?.data?.filter?.(
                (c) =>
                  formik.values.contact_number?.includes?.(c.value?.toString())
              )}
              onChange={(value) => {
                formik.setFieldValue(
                  "contact_number",
                  value.map((a) => a.value?.toString())
                );
              }}
              onBlur={formik.handleBlur("contact_number")}
              className={`pro-input lg multi-select ${
                formik.touched.contact_number &&
                formik.errors.contact_number &&
                " error"
              }`}
              classNamePrefix="pro-input"
            />
            {formik.touched.contact_number && formik.errors.contact_number && (
              <span className="error-text">{formik.errors.contact_number}</span>
            )}
          </div>

          <Input
            label={"Mobile"}
            type="text"
            id="mobile_number"
            name="mobile_number"
            className={`pro-input lg ${
              formik.touched.mobile_number &&
              formik.errors.mobile_number &&
              " error"
            }`}
            {...formik.getFieldProps("mobile_number")}
            error={formik.touched.mobile_number && formik.errors.mobile_number}
            errorMessage={formik.errors.mobile_number}
          />

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary whatsapp lg pro-px-5"}
              onClick={() => handleWhatsappShare()}
            >
              <span>Send</span>
              <FaWhatsapp fontSize={20} />
            </Button>
            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Send SMS
            </Button>
          </div>
        </>
      ) : (
        [...Array(2)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default LoginCredentialsForm;
