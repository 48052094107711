import React from "react";
import {
  ModalLayout,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";

const DeleteModal = ({
  showDeleteModal,
  closeDeleteModal,
  handleDeleteData,
  fileType,
  errors,
}) => {
  return (
    <ModalLayout centered show={showDeleteModal} handleClose={closeDeleteModal}>
      <div className="pro-m-5">
        <ConfirmationBox
          title={`Are you sure you want to delete?`}
          subTitle={`You won’t be able to reverse this action.`}
          isRight={true}
          cancelText={`Cancel`}
          submitText={`Delete`}
          cancelAction={closeDeleteModal}
          submitAction={() => handleDeleteData(fileType)}
        >
          ConfirmationBox
        </ConfirmationBox>

        {errors && <span className="error-message">{errors}</span>}
      </div>
    </ModalLayout>
  );
};

export default DeleteModal;
