import { useEffect, useState } from "react";
import { useGetPdfGenerationDataQuery } from "../../../store/queries/Profile";
import { useSelector } from "react-redux";
// import { useEffect, useState } from "react";

const useViewProfileDetails = (props) => {
  const { pdfVisibility, selectedProfiles } = useSelector(
    (state) => state.profile
  );
  // const [itemData, setItemData] = useState(false);

  const { data: profileData = {}, isFetching } = useGetPdfGenerationDataQuery(
    {
      user_id: selectedProfiles,
    },
    { skip: selectedProfiles?.length > 0 ? props?.skip : true }
    // { skip: props?.skip }
  );

  const [itemData, setItemData] = useState(false)
  
  useEffect(() => {
    let timeoutId;
    if (profileData?.data?.length > 0 && !props?.skip) {
      setItemData(true);

      timeoutId = setTimeout(() => {
        setItemData(false);
      }, 1000);
    }
    return () => {
      // Timeout Function will be cleared when conditions change
      clearTimeout(timeoutId);
    };
    //eslint-disable-next-line
  }, [profileData?.data, props?.skip]);





  // const [url, setUrl] = useState();

  // const getBase64 = (url, callback) => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.onload = function () {
  //     var reader = new FileReader();
  //     reader.onloadend = function () {
  //       callback?.(reader.result);
  //     };
  //     reader.readAsDataURL(xhr.response);
  //   };
  //   xhr.open("GET", url);
  //   xhr.responseType = "blob";
  //   xhr.send();
  // };

  // useEffect(() => {
  //   getBase64(profileData?.data?.[0]?.user_image[0]?.user_image, (res) =>
  //     setUrl(res)
  //   );
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    props.setIsFetchLoading(isFetching)
    //eslint-disable-next-line
  }, [isFetching])

  return {
    profileData: profileData,
    pdfVisibility, //url
    itemData,
  };
};

export default useViewProfileDetails;
