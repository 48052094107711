import {
  HeadingGroup,
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useUserAgreement from "./useUserAgreement";
import { Link } from "react-router-dom";
import Style from "./userAgreement.module.scss";
import UserAgreementPending from "./Listing";

const UserAgreement = () => {
  const { tabs } = useUserAgreement();
  return (
    <>
      <div className="pro-mt-2">
        <HeadingGroup title={"User Agreement"} extraClassName={`pro-mb-2`} />

        <div className={`${Style.main_container}`}>
          <div className={`${Style.main_container_inner}`}>
            <NavGroup navigations={tabs} type={"type-2"} Link={Link} />
            <UserAgreementPending />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAgreement;
