import { createSlice } from "@reduxjs/toolkit";
// import { getAxiosInstance } from "../../../api";
// import moment from "moment";

const initialState = {
  currentPage: 1,
  currentPageSize: 20,
  searchValues: [],
  sortBy: "",
  sortOrder: "",
  socialMediaReportData: [],
  socialMediaReportStatus: "idle",
  showEditModal: false,
  selectedData: [],
};

const socialMediaReportSlice = createSlice({
  name: "socialMediaReport",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = socialMediaReportSlice.actions;

export default socialMediaReportSlice.reducer;
