import {
  Button,
  HeadingGroup,
  // ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import AddedServiceList from "./AddedServiceList";
import AddServiceForm from "./AddServiceForm";
import useAddService from "./useAddService";
// import AddedServiceExistList from "./AddedServiceExistList";
// import Style from "./AddService.module.scss";
const AddService = () => {
  const {
    formik,
    refetchRef,
    time,
    addServiceState,
    optionsData,
    isSuccess,
    handleTimeChange,
    handleReset,
    handleClearClick,
    handleWebIdChange,
    handleOnBlur,
  } = useAddService({});

  return (
    <div className="pro-m-5">
      <HeadingGroup title={"Add Service"} extraClassName={`pro-mb-3`}>
        <div className="pro-check-box pro-items-center">
          <Button className={"pro-btn-link lg pro-px-5"} onClick={handleReset}>
            Cancel
          </Button>
          <Button
            type="submit"
            className={`pro-btn pro-ms-3 pro-btn-primary ${
              addServiceState?.addServiceStatus === "pending" ? "loading" : ""
            }`}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </HeadingGroup>
      <AddServiceForm
        formik={formik}
        optionsData={optionsData}
        isSuccess={isSuccess}
        parentClass={"row"}
        childClass={"col-6"}
        handleWebIdChange={handleWebIdChange}
        handleOnBlur={handleOnBlur}
        time={time}
        handleTimeChange={handleTimeChange}
        handleClearClick={handleClearClick}
      />
      <AddedServiceList refetchRef={refetchRef} formik={formik} />

      {/* <ModalLayout
        show={addedServiceExistState.showAddedServiceModal}
        handleClose={closeModal}
        propStyle={{ root: Style.root }}
      >
        <AddedServiceExistList />
      </ModalLayout> */}
    </div>
  );
};

export default AddService;
