import React from "react";
import {
  Button,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const NonProfileImages = ({
  optionsData,
  formik,
  errors,
  fileRef,
  isNonProfileImageDataSuccess,
  nonProfileImagesData,
  handleRemoveNonProfileImage,
  handleDeleteClick,
  handleNonProfileImageChange,
  handleNonProfileImageClick,
  handleAgreementImageChange,
  style,
  AgreementImageData,
  isAgreementImageSuccess,
  isAgreementUploaded,
  handleDeleteAgreement,
}) => {
  const imageOptions = isAgreementUploaded
    ? optionsData?.data?.imageCategoryList?.filter(
        (item) => item?.name !== "Agreement"
      )
    : optionsData?.data?.imageCategoryList;

  return (
    <div className="row">
      <div className="input-wrap pro-mb-0">
        <h6 className="pro-font-md pro-mb-0 pro-fw-medium">
          Non-Profile Images Upload (Image )
        </h6>
      </div>
      <div className={`col-5`}>
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Choose image Type*
          </label>
          <Select
            id="image_category"
            placeholder={"Select"}
            isClearable={true}
            options={imageOptions}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.image_category &&
              formik.errors.image_category &&
              " error"
            }`}
            value={imageOptions?.filter(
              (p) =>
                p.id?.toString() === formik.values.image_category?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("image_category")}
            onChange={(value) =>
              formik.setFieldValue("image_category", value?.id || null)
            }
          ></Select>
          {formik.touched.image_category && formik.errors.image_category && (
            <span className="error-text">{formik.errors.image_category}</span>
          )}
        </div>
      </div>
      {formik?.values?.image_category !== 4 ? (
        <div className="col-5 pro-d-flex pro-items-end">
          <div className="input-wrap sm pro-mb-0">
            <div
              className="input-drag"
              onClick={() => handleNonProfileImageClick()}
            >
              <input
                type="file"
                ref={fileRef}
                disabled={formik.values.image_category ? false : true}
                placeholder="placeholder"
                accept="image/png,image/jpeg,image/jpg"
                className={`pro-input ${
                  formik.touched.non_profile_image &&
                  formik.errors.non_profile_image &&
                  " error"
                }`}
                id="non_profile_image"
                onBlur={formik.handleBlur("non_profile_image")}
                onChange={(e) => handleNonProfileImageChange(e)}
              />
              <span className="input-drag-box">
                <IconText title={`Drop files to attach or browse`} />
              </span>
              {formik?.values?.non_profile_image?.name && (
                <Button className={`pro-btn-link pro-mt-3`}>
                  {formik?.values?.non_profile_image?.name}
                  <span
                    className="material-symbols-outlined"
                    onClick={(e) => handleRemoveNonProfileImage(e)}
                  >
                    close
                  </span>
                </Button>
              )}
              {formik.touched.non_profile_image &&
                formik.errors.non_profile_image && (
                  <span className="error-text">
                    {formik.errors.non_profile_image}
                  </span>
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="col-5 pro-d-flex pro-items-end">
          <div className="input-wrap sm pro-mb-0">
            <div
              className="input-drag"
              // onClick={() => handleNonProfileImageClick()}
            >
              <input
                type="file"
                multiple
                disabled={formik.values.image_category ? false : true}
                placeholder="placeholder"
                accept="image/png,image/jpeg,image/jpg"
                className={`pro-input ${
                  formik.touched.non_profile_agreement_images &&
                  formik.errors.non_profile_agreement_images &&
                  " error"
                }`}
                id="non_profile_agreement_images"
                onBlur={formik.handleBlur("non_profile_agreement_images")}
                onChange={(e) => handleAgreementImageChange(e)}
              />
              <span className="input-drag-box">
                <IconText title={`Drop files to attach or browse`} />
              </span>

              {formik.touched.non_profile_agreement_images &&
                formik.errors.non_profile_agreement_images && (
                  <span className="error-text">
                    {formik.errors.non_profile_agreement_images}
                  </span>
                )}
            </div>
          </div>
        </div>
      )}
      {[...Array(3)].map((_, idx) => (
        <>
          <div className="col-12 pro-mt-5" key={idx + 1}>
            <div className="input-wrap pro-mb-0">
              <h6 className="pro-font-md pro-mb-0 pro-fw-medium">
                {idx === 0
                  ? "Horoscope Images"
                  : idx === 1
                  ? "House Images"
                  : idx === 2
                  ? "ID Proof Images"
                  : ""}
              </h6>
            </div>

            {isNonProfileImageDataSuccess ? (
              <div className="row row-cols-6 g-2 pro-mb-4">
                {nonProfileImagesData?.data?.non_profile_images
                  ?.filter((data, index) => data?.image_category === idx + 1)
                  .map((item, i) => {
                    return (
                      <div className={`${style.image_wrapper}`} key={i}>
                        <div className={style.image_wrapper_main}>
                          <Image
                            width={200}
                            height={200}
                            src={item?.non_profile_image}
                            alt={""}
                            key={i}
                          />
                          <Image
                            width={200}
                            height={200}
                            src={item?.non_profile_image}
                            alt={""}
                            key={i + 1}
                          />
                        </div>
                        <div className={style.delet_btn}>
                          <span
                            className="material-symbols-outlined"
                            onClick={() => handleDeleteClick(item?.id, 2)}
                          >
                            delete
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              // FIXME aneesh add shimmer for image loading
              <div>loading...</div>
            )}
          </div>
        </>
      ))}
      {/* adding agreement images with this */}
      {/* additional functionality */}
      <div className="input-wrap pro-mb-0">
        <h6 className="pro-font-md pro-mb-0 pro-fw-medium">Agreement Images</h6>
      </div>
      {isAgreementImageSuccess ? (
        <div className="row row-cols-6 g-2 pro-mb-4">
          {AgreementImageData?.data?.user_agreement_data?.map((item, i) => {
            return (
              <div className={`${style.image_wrapper}`} key={i}>
                <div className={style.image_wrapper_main}>
                  <Image width={200} height={200} src={item} alt={""} key={i} />
                  <Image
                    width={200}
                    height={200}
                    src={item?.user_image_path}
                    alt={""}
                    key={i + 1}
                  />
                </div>
                <div className={style.delet_btn}>
                  <span
                    className="material-symbols-outlined"
                    onClick={() => handleDeleteAgreement(item?.id)}
                  >
                    delete
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        // FIXME aneesh add shimmer for image loading
        <div>loading...</div>
      )}
    </div>
  );
};

export default NonProfileImages;
