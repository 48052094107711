import React from "react";
import useInboundCalls from "./useInboundCalls";
import ErrorMessage from "../../Global/ErrorMessage";
import style from "../OutboundCalls/outboundCalls.module.scss";
const InboundCalls = () => {
  const { error } = useInboundCalls();

  return (
    <div className={style.user_not_found}>
      {!error && <div className="app-loading fill"> </div>}
      {error && <ErrorMessage message={`User not found`} />}
    </div>
  );
};

export default InboundCalls;
