import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api[method](`${endpoint}`, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const callHistory = createApi({
  reducerPath: "callHistoryApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["MissedCall", "IncomingCall", "OutgoingCall"],
  endpoints: (builder) => ({
    getMissedCallData: builder.query({
      query: (body) => ({
        //type 1 missed
        method: "post",
        body,
        endpoint: `/vi/call-log`,
      }),
      providesTags: ["MissedCall"],
    }),
    getIncomingCall: builder.query({
      query: (body) => ({
        //type 2 incoming
        method: "post",
        body,
        endpoint: `/vi/call-log`,
      }),
      providesTags: ["IncomingCall"],
    }),
    getOutgoingCall: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/vi/out-call-log`,
      }),
      providesTags: ["OutGoingCall"],
    }),
  }),
});

export const {
  useGetIncomingCallQuery,
  useGetMissedCallDataQuery,
  useGetOutgoingCallQuery,
} = callHistory;
