import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getAxiosInstance } from "../../../api";
import { useDeleteGalleryImagesDataMutation } from "../../../store/queries/Registration";
import { getImage } from "../../../utils/functions/getCroppedImage";
import { useDispatch } from "react-redux";
import { profile } from "../../../store/queries/Profile";

const useProfileCard = ({ data, userId, refetch, handleClose }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showCrop, setShowCrop] = useState(false);
  const [fileName, setfileName] = useState({});
  const [profileUploadStatus, setProfileUploadStatus] = useState(true);
  const [cropProfileUploadStatus, setCropProfileUploadStatus] = useState(true);
  // const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [crop, setCrop] = useState({
    unit: "px",
    width: 300,
    height: 300,
    x: 100,
    y: 90,
    aspect: 1,
  });

  const [image, setImage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageId, setImageId] = useState("");
  const [errors, setErrors] = useState("");

  const handleDeleteClick = (id, type) => {
    setShowDeleteModal(true);
    setImageId(id);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const dispatch = useDispatch();

  const [deleteImage] = useDeleteGalleryImagesDataMutation();

  const handleDeleteData = (value) => {
    deleteImage({
      user_id: userId,
      image_id: imageId ?? "",
      image_type: 1,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(profile.util.invalidateTags(["Profile"]));
        setShowDeleteModal(false);
        handleClose?.();
        refetch();
        toast.success("Successfully deleted");
      } else if (response?.error?.status === 422) {
        setErrors(response?.error?.data?.errors);
      }
    });
  };

  const imgRef = useRef();
  const activeIndex = useRef(0);

  const handleThumbClick = (index) => {
    setActiveImageIndex(index);
    activeIndex.current = index;
  };

  const handleCropClick = () => {
    setShowCrop(true);
    setfileName({
      name: data[activeImageIndex]?.image_name,
      type: data[activeImageIndex]?.type,
    });
  };

  const handleCropCancel = () => {
    setShowCrop(false);
    if (image) {
      setImage(null);
      setActiveImageIndex((prev) => prev - 1);

      activeIndex.current = activeIndex.current - 1;
    }
  };
  const handlePrimary = async () => {
    const api = await getAxiosInstance();
    api
      .post("profile/image-set-primary", {
        user_id: userId,
        image_id: data[activeImageIndex]?.id ?? "",
      })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            profile.util.invalidateTags([
              { type: "Profile" },
              { type: "Overview" },
            ])
          );
          refetch();
          handleClose?.();
          toast.success("Image set to primary!");
        }
      })
      .catch((response) => {
        toast.error(
          response?.data?.message ?? "Couldn't set image to primary!"
        );
      });
  };

  const handleNewImage = (e) => {
    setfileName({ name: e.target.files[0].name, type: e.target.files[0].type });
    setImage(URL.createObjectURL(e.target.files[0]));
    activeIndex.current = data.length;
    setActiveImageIndex(data.length);
    setShowCrop(true);
  };

  const handleUpload = useCallback(async () => {
    setProfileUploadStatus(false);
    const api = await getAxiosInstance();
    try {
      let reqImage = await getImage(imgRef.current, crop, fileName);
      const formdata = new FormData();
      formdata.append("image", reqImage);
      formdata.append("user_id", userId);
      formdata.append("image_id", data[activeIndex.current]?.id ?? "");
      formdata.append("crop_primary", 0);
      api
        .post("profile/upload-profile-image", formdata)
        .then((res) => {
          setProfileUploadStatus(true);
          toast.success("Image Uploaded Successfully!");
          dispatch(
            profile.util.invalidateTags([
              { type: "Profile" },
              { type: "Overview" },
            ])
          );
          setImage(null);
          refetch();
          handleClose();
        })
        .catch((res) => {
          setProfileUploadStatus(true);
          res?.response?.data?.message
            ? toast.error(
                res?.response?.data?.message ?? "Failed to upload image"
              )
            : toast.error("Image failed to upload ");
        });
    } catch (e) {
      setProfileUploadStatus(true);
      toast.error("Failed to Crop Image!");
    }
    // eslint-disable-next-line
  }, [crop]);

  // const handleUploadCropPrimary = async () => {
  //   const api = await getAxiosInstance();

  //   try {
  //     let reqImage = await getImage(imgRef.current, crop, fileName);

  //     const formdata = new FormData();
  //     formdata.append("image", reqImage);
  //     formdata.append("user_id", userId);
  //     formdata.append("image_id", data[activeIndex.current]?.id ?? "");

  //     // Use await to wait for the upload to complete
  //     const uploadResponse = await api.post("profile/upload-profile-image", formdata);

  //     // Set the uploaded image as primary
  //     await api.post("profile/image-set-primary", {
  //       user_id: userId,
  //       image_id: uploadResponse?.data?.data?.id ?? "",
  //     });
  //     // Success handling
  //     toast.success("Image Cropped and Set as Primary Successfully!");
  //     refetch();
  //     handleClose?.();
  //   } catch (error) {
  //     // Error handling
  //     toast.error(error?.response?.data?.message ?? "Couldn't set image to primary!");
  //   }
  // };

  const handleUploadCropPrimary = useCallback(async () => {
    setCropProfileUploadStatus(false);
    const api = await getAxiosInstance();
    try {
      let reqImage = await getImage(imgRef.current, crop, fileName);
      const formdata = new FormData();
      formdata.append("image", reqImage);
      formdata.append("user_id", userId);
      formdata.append("image_id", data[activeIndex.current]?.id ?? "");
      formdata.append("crop_primary", 1);
      api
        .post("profile/upload-profile-image", formdata)
        .then((res) => {
          setCropProfileUploadStatus(true);
          toast.success("Image Cropped and Set as Primary Successfully!");
          dispatch(
            profile.util.invalidateTags([
              { type: "Profile" },
              { type: "Overview" },
            ])
          );
          setImage(null);
          refetch();
          handleClose();
        })
        .catch((res) => {
          setCropProfileUploadStatus(true);
          res?.response?.data?.message
            ? toast.error(
                res?.response?.data?.message ?? "Failed to upload image"
              )
            : toast.error("Image failed to upload ");
        });
    } catch (e) {
      setCropProfileUploadStatus(true);
      toast.error("Failed to Crop Image!");
    }
    // eslint-disable-next-line
  }, [crop]);

  return {
    errors,
    activeImageIndex,
    showCrop,
    crop,
    image,
    imgRef,
    showDeleteModal,
    profileUploadStatus,
    cropProfileUploadStatus,
    handleDeleteClick,
    closeDeleteModal,
    setCrop,
    handleThumbClick,
    handleCropClick,
    handleCropCancel,
    handlePrimary,
    handleUpload,
    handleNewImage,
    handleDeleteData,
    handleUploadCropPrimary,
  };
};

export default useProfileCard;
