import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
    async ({ params, endpoint, method, body }) => {
      const api = await getAxiosInstance();

      try {
        const response = await api[method](`${endpoint}`, body);

        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;

        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };

export const registrationReport = createApi({
  reducerPath: "registrationReportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Registration-Report"],
  endpoints: (builder) => ({
    getRegistrationReportList: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `report/registration-report-list`,
      }),
      providesTags: ["Registration-Report"],
    }),
    getRegistrationReportFilters: builder.query({
      query: () => ({
        method: "get",
        endpoint: `report/registration-report-filters`,
      }),
    }),

    updateRegistrationReportFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),
      invalidatesTags: ["Registration-Report"],
    }),
  }),

});

export const {
  useGetRegistrationReportListQuery,
  useGetRegistrationReportFiltersQuery,
  useUpdateRegistrationReportFieldsMutation,
} = registrationReport;
