import React, { useEffect, useState } from "react";
import ActionButtonList from "../ActionButtonList";
import Style from "./rightActionDrawer.module.scss";
import useRightActionDrawer from "./useRightActionDrawer";

function RightActionDrawer({
  isProfileDeleted,
  userId,
  profileState,
  hasDeletePermission,
  hasActivatePermission,
}) {
  // eslint-disable-next-line
  const [expanded, setExpanded] = useState(false);
  const bodyClass = document.body.classList;
  const { profileActions, profileFunctions } = useRightActionDrawer({
    isProfileDeleted,
    userId,
    profileState,
    hasDeletePermission,
    hasActivatePermission,
  });
  useEffect(() => {
    expanded
      ? bodyClass.add("close-right-drawer")
      : bodyClass.remove("close-right-drawer");
  }, [expanded, bodyClass]);

  return (
    <div className={`${Style.root}`}>
      <button
        onClick={() => setExpanded(!expanded)}
        className={`
            pro-btn 
            pro-btn-outline 
            ${Style.expandButton}`}
      >
        <span className="material-symbols-outlined ">chevron_right</span>
      </button>

      <div className={Style.root_inner}>
        <ActionButtonList
          title={`Profile Actions`}
          data={profileActions}
          expanded={expanded}
        />
        <ActionButtonList
          title={`Profile Functions`}
          data={profileFunctions}
          type={`type-2`}
          expanded={expanded}
        />
      </div>
    </div>
  );
}

export default RightActionDrawer;
