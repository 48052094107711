import Select from "react-select";

const RowTwo = ({ formik, optionsData }) => {
  const Booleans = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];
  return (
    <>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Children
          </label>
          <Select
            id="children"
            placeholder={"Select"}
            isClearable={true}
            options={Booleans}
            value={Booleans?.filter(
              (m) =>
                formik.values.children?.toString?.() === m.value?.toString?.()
            )}
            onChange={(value) => formik.setFieldValue("children", value?.value || null)}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="residing_country" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Residing Country
          </label>
          <Select
            id="residing_country"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData.countryList}
            getOptionLabel={(option) => option.country_name}
            getOptionValue={(option) => option.id}
            value={optionsData.countryList?.filter(
              (m) =>
                formik.values.residing_country?.toString?.() ===
                m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("residing_country", value?.id?.toString?.() || null);
              formik.setFieldValue("residing_state", null);
            }}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="residing_state"
            isClearable={true}
            placeholder={"Select"}
            isDisabled={formik.values.residing_country ? false : true}
            options={optionsData.stateList.filter(
              (s) =>
                s.country_id.toString() ===
                formik.values.residing_country?.toString?.()
            )}
            getOptionLabel={(option) => option.state_name}
            getOptionValue={(option) => option.id}
            value={optionsData.stateList?.filter(
              (m) =>
                formik.values.residing_state?.toString?.() ===
                m.id?.toString?.()
            )}
            onChange={(value) =>
              formik.setFieldValue("residing_state", value?.id?.toString?.() || null)
            }
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            District
          </label>
          <Select
            id="residing_district"
            isMulti
            placeholder={"Select"}
            isDisabled={formik.values.residing_state ? false : true}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.districtsList.filter(
              (s) =>
                s.state_id.toString() ===
                formik.values.residing_state?.toString?.()
            )}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.district_name}
            value={optionsData.districtsList?.filter((m) =>
              formik.values.residing_district?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "residing_district",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Special Case
          </label>
          <Select
            id="special_case"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData.specialCaseList}
            getOptionLabel={(option) => option.special_case}
            getOptionValue={(option) => option.id}
            value={optionsData.specialCaseList?.filter(
              (m) =>
                formik.values.special_case?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("special_case", value?.id?.toString?.()|| null);
            }}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Star
          </label>
          <Select
            id="star_id"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData.starsList}
            getOptionLabel={(option) => option.star_name}
            getOptionValue={(option) => option.id}
            value={optionsData.starsList?.filter(
              (m) => formik.values.star_id?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("star_id", value?.id?.toString?.()|| "");
            }}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Jathakam
          </label>
          <Select
            id="jathakam_type"
            placeholder={"Select"}
            isMulti
            options={optionsData.jathakamLists}
            getOptionLabel={(option) => option.jathakam_type}
            getOptionValue={(option) => option.id}
            value={optionsData.jathakamLists?.filter((m) =>
              formik.values.jathakam_type?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) => {
              formik.setFieldValue(
                "jathakam_type",
                value.map((v) => v.id?.toString?.())
              );
            }}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
      </div>
    </>
  );
};

export default RowTwo;
