import {
  Actions,
  Button,
  Header,
  ModalLayout,
  MultiColumnTable,
  NavGroup,
  ProfileSideBar,
  // SimpleImageSlider,
  SimpleTextDetails,
  TabHeading,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import "swiper/css";
import Assets from "../../../assets/Assets";
import HeaderActions from "../HeaderActions";
import Style from "../CommonLayout/commonLayout.module.scss";
import ProtectRoute from "../../../utils/components/ProtectRoute";
import profileStyle from "./profileLayout.module.scss";
import useProfileLayout from "./useProfileLayout";
import RightActionDrawer from "../../../components/Global/RightActionDrawer";
import CallBox from "../../../components/Global/CallBox";
import ProfileLayoutSidebarShimmer from "../../../components/Global/Shimmers/ProfileLayoutSidebarShimmer";
import { ProfileCard } from "../../../components/Global/ProfileCard";
import ActionForms from "../../../components/Profile/ActionForms";
import ViewProfileDetails from "../../../components/Profile/ViewProfileDetails";
import PdfModalForm from "../../../components/Profile/ActionForms/PdfModalForm";
import { Helmet } from "react-helmet";
import { SimpleImageSlider } from "../../../components/Global/SimpleImageSlider";
import DeleteHistory from "../../../components/DeleteHistory";

const ProfileLayout = ({ navigations }) => {
  const {
    profiles,
    profileData,
    imageSlider,
    modalImage,
    actions,
    activeProfile,
    isFetching,
    profileState,
    show,
    isSuccess,
    printRef,
    showPdf,
    showLocked,
    isFetchLoading,
    showHistory,
    skip,
    pdfLoading,
    hasDeletePermission,
    hasActivatePermission,
    callIntiateStatus,
    setCallInitiateStatus,
    handleHistoryButtonClick,
    setIsFetchLoading,
    handleDownloadPdf,
    closePdfModal,
    handleClick,
    handleClose,
    handleProfileClick,
    closeModal,
    refetch,
  } = useProfileLayout();
  return (
    <ProtectRoute>
      <Helmet>
        <title>NEXUS Call Center | Profile</title>
      </Helmet>
      <Header
        Link={Link}
        logo={Assets.HeaderLogo}
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div className="pt-custom">
        <TabHeading
          data={profiles}
          uniqueId={"id"}
          activeId={activeProfile}
          handleClick={handleClick}
          handleClose={handleClose}
          closeIcon={<span class="material-symbols-outlined">close</span>}
        />
      </div>
      <div className={`pro-w-100 ${profileStyle.main_wrapper} profile_layout`}>
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching ? (
              <ProfileLayoutSidebarShimmer />
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5`}>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <SimpleImageSlider
                        data={imageSlider}
                        width={329.7}
                        height={329.55}
                        handleClick={handleProfileClick}
                        premiumStatus={profileData?.premium_status || null}
                        isLocked={profileData?.is_locked ?? false}
                      />
                      <SimpleTextDetails
                        isOnline={false}
                        title={profileData?.name}
                        uId={profileData?.id}
                        lastOnline={profileData?.last_online}
                        extraClassNames={`pro-mt-5 pro-mb-5`}
                      />

                      <Actions
                        actions={actions}
                        extraClassName={`pro-mt-1 pro-mb-5 ${profileStyle.profile_layout_action}`}
                      />
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <MultiColumnTable
                        data={profileData?.basic_data}
                        extraClassNames={`pro-mb-5`}
                      />
                      <CallBox
                        options={profileData?.mobile?.data}
                        isAgent={profileData?.is_agent}
                        callIntiateStatus={callIntiateStatus}
                        setCallInitiateStatus={setCallInitiateStatus}
                      />
                      <MultiColumnTable
                        title={profileData?.profile_info?.label}
                        data={profileData?.profile_info?.data}
                        extraClassNames={`pro-mt-5`}
                      />
                    </div>
                    {profileData?.profile_delete === 1 && (
                      <>
                        {" "}
                        <div className={profileStyle.btn_space}></div>
                        <div
                          className={`${profileStyle.delete_btn_wrap} pro-d-flex pro-justify-end `}
                        >
                          <Button
                            onClick={handleHistoryButtonClick}
                            className="pro-btn-primary lg pro-w-100"
                          >
                            Delete History
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          {/* main container */}
          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <NavGroup navigations={navigations} type={"type-2"} Link={Link} />
              <Outlet context={{ activeProfile }} />
            </div>
          </div>

          <div className={`${profileStyle.right_sidebar}`}>
            <RightActionDrawer
              isProfileDeleted={profileData?.profile_delete}
              userId={activeProfile}
              profileState={profileState}
              hasDeletePermission={hasDeletePermission}
              hasActivatePermission={hasActivatePermission}
            />
          </div>
        </div>
      </div>
      <ModalLayout show={show} handleClose={closeModal}>
        <ProfileCard
          data={imageSlider}
          handleClose={closeModal}
          userId={activeProfile}
          refetch={refetch}
        />
      </ModalLayout>

      <ModalLayout show={showLocked} handleClose={closeModal} backdrop="static">
        <ProfileCard
          data={modalImage}
          handleClose={closeModal}
          userId={activeProfile}
          refetch={refetch}
        />
      </ModalLayout>

      <ModalLayout show={showPdf} handleClose={closePdfModal}>
        <PdfModalForm
          handleCancel={closePdfModal}
          handleDownloadPdf={handleDownloadPdf}
          isFetchLoading={isFetchLoading}
          pdfLoading={pdfLoading}
        />
      </ModalLayout>

      <div style={{ position: "absolute", left: "-9999px" }}>
        <ViewProfileDetails
          ref={printRef}
          userId={activeProfile}
          skip={skip}
          setIsFetchLoading={setIsFetchLoading}
        />
      </div>

      <ModalLayout
        title={"Delete History"}
        propStyle={{
          root: profileStyle.delete_modal,
        }}
        centered={true}
        show={showHistory}
        handleClose={handleHistoryButtonClick}
        closeIcon={<span class="material-symbols-outlined x4">close</span>}
      >
        <DeleteHistory />
      </ModalLayout>

      <ActionForms
        userId={activeProfile}
        profileId={profileData?.id}
        ProfileName={profileData?.name}
        isSuccess={isSuccess}
      />
    </ProtectRoute>
  );
};

export default ProfileLayout;
