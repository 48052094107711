import React from "react";

export const WebIdRow = ({ data, activeProfile, type }) => {
  const ids = [activeProfile] ?? [];

  const newIds = ids.filter((id) => id !== data).join(",");
  if (!data) {
    return "";
  }
  return (
    <p
      className="pro-mb-0 pro-pnt"
      onClick={() =>
        window.open(
          `${window.location.origin}/${`profile?active=${
            type === "client"
              ? data?.client_users_id
              : data?.partner_client_users_id
          }&id=${newIds}`}`
        )
      }
    >
      {type === "client" ? data?.client_web_id : data?.partner_client_web_id}
    </p>
  );
};
