import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const StatusRow = ({ data, onClick }) => {
  if (!data?.status) {
    return "";
  }
  return <IconText title={`${data?.status}`} />;
};


