import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  useGetProfileDataQuery,
  useGetMultipleShareProfileMutation,
} from "../../../../store/queries/Profile";
import { useSelector } from "react-redux";

const useShareMultipleProfileForm = ({ userId, handleCancel, ProfileName }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetProfileDataQuery(userId);

  const { selectedProfiles, selectedProfileNames } = useSelector(
    (state) => state.profile
  );

  const [ShareProfileDetails] = useGetMultipleShareProfileMutation();

  const formik = useFormik({
    initialValues: {
      client_user_id: userId,
      user_id: selectedProfiles,
      user_name: selectedProfileNames,
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    ShareProfileDetails({ ...values, share_type: 1 })
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success("Profile Send Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Send Profile!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Send Profile!");
      });
  };

  const handleWhatsappShare = () => {
    ShareProfileDetails({ ...formik.values, share_type: 2 }).then(
      (response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Profile Sent Successfully!");
          handleCancel();

          let mob = response?.data?.data?.mobile;

          // const openInNewTab = (url) => {
          //   const a = document.createElement("a");
          //   a.href = url;
          //   a.setAttribute("target", "_blank");
          //   a.style.display = "none";
          //   document.body.appendChild(a);
          //   a.click();
          //   setTimeout(() => {
          //     document.body.removeChild(a);
          //     window.focus();
          //   }, 500);
          // };

          // for (const message of response?.data?.data?.message) {
          //   const url = `https://api.whatsapp.com/send?phone=${mob}&text=${message}&type=custom_url&app_absent=0`;
          //   openInNewTab(url);
          // }

          //eslint-disable-next-line
          response?.data?.data?.message?.map((item) => {
            window.open(
              `${`https://api.whatsapp.com/send?phone=${mob}&text=${item}&type=custom_url&app_absent=0`}`
            );
          });
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Share Profile!");
          handleCancel();
        }
      }
    );
  };

  return { formik, optionsData, isOptionDataSuccess, handleWhatsappShare };
};

export default useShareMultipleProfileForm;
