import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import useShareProfileForm from "./useShareProfileForm";
import style from "./shareProfileForm.scss";
import { FaWhatsapp } from "react-icons/fa";

const ShareProfileForm = ({ handleCancel, userId, isSuccess, ProfileName }) => {
  const { formik, isOptionDataSuccess, handleWhatsappShare } =
    useShareProfileForm({
      handleCancel,
      userId,
      ProfileName,
    });

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Send Profile Details</h4>
      </div>

      {isSuccess && isOptionDataSuccess ? (
        <>
          <Input
            label={"Mobile"}
            type="text"
            id="mobile_number"
            name="mobile_number"
            className={`pro-input lg ${
              formik.touched.mobile_number &&
              formik.errors.mobile_number &&
              " error"
            }`}
            {...formik.getFieldProps("mobile_number")}
            error={formik.touched.mobile_number && formik.errors.mobile_number}
            errorMessage={formik.errors.mobile_number}
          />

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              className={"pro-btn-primary whatsapp lg pro-px-5"}
              onClick={() => handleWhatsappShare()}
            >
              <span>Send</span>
              <FaWhatsapp fontSize={20} />
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Send SMS
            </Button>
          </div>
        </>
      ) : (
        [...Array(1)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default ShareProfileForm;
