import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  showAddService:false,

};

const myServiceSlice = createSlice({
  name: "myService",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = myServiceSlice.actions;

export default myServiceSlice.reducer;
