import {
  Button,
  HeadingGroup,
  ModalLayout,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import ErrorMessage from "../../Global/ErrorMessage";
import UserDetailTableShimmer from "../../Global/Shimmers/UserDetailTableShimmer";
import UserDetailTableListing from "../../Global/UserDetailTableListing";
import AdvancedSearchForm from "./AdvancedSearchForm";
import useAdvancedSearch from "./useAdvancedSearch";
import style from "./advancedSearch.module.scss";
import ViewProfileDetails from "../../Profile/ViewProfileDetails";
import PdfModalForm from "../../Profile/ActionForms/PdfModalForm";
import Select from "react-select";
import ViewTableDetails from "./ViewTableDetails";
const AdvancedSearch = () => {
  const {
    currentPage,
    formik,
    state,
    showScroll,
    selectedProfiles,
    activeProfile,
    printRef,
    tableRef,
    showPdf,
    skip,
    isFetchLoading,
    paginationOptions,
    advancedSearchResults,
    isFetching,
    isSuccess,
    isError,
    pdfLoading,
    pdf2Loading,
    isChecked,
    exportOption,
    optionsData,
    isSearchFilterSuccess,
    exportLoading,
    hasExportPermission,
    hasDownloadPdfPermission,
    setExportOption,
    handleExportClick,
    handleDownloadTable,
    setIsFetchLoading,
    handleDownloadClick,
    handleSelect,
    handleScrollToTop,
    closePdfModal,
    handleDownloadPdf,
    handleSearch,
    handleReset,
    handlePagination,
    handlePageSize,
    handleSelectAll,
  } = useAdvancedSearch();

  return (
    <div className={`pro-mb-4`}>
      {showScroll && (
        <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
          <span className="material-symbols-outlined">expand_less</span>
        </button>
      )}
      <HeadingGroup
        title={
          advancedSearchResults?.data?.data?.length !== undefined
            ? ` ${advancedSearchResults?.data?.total_count} Results`
            : "Advanced Search"
        }
        extraClassName={`${style.fixed_position}`}
      >
        <div className="pro-d-flex">
          <div className="pro-check-box pro-items-center pro-flex-nowrap">
            <input
              type="checkbox"
              id="admin_verified"
              name="admin_verified"
              className="pro-check"
              checked={formik.values.admin_verified}
              onChange={(e) => {
                formik.setFieldValue("admin_verified", e.target.checked);
              }}
            />
            <label
              htmlFor="admin_verified"
              className="pro-check-label single-line"
            >
              Admin Verified
            </label>
          </div>
          <div className="pro-check-box pro-items-center pro-ms-5">
            <input
              type="checkbox"
              id="entire_data"
              name="entire_data"
              className="pro-check"
              checked={formik?.values.entire_data}
              onChange={(e) => {
                formik.setFieldValue("entire_data", e.target.checked);
              }}
            />
            <label htmlFor="entire_data" className="pro-check-label">
              Entire Data
            </label>
          </div>
          <Button
            className={"pro-btn-link pro-px-5 pro-mx-2"}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            type="submit"
            className={`pro-btn pro-btn-primary ${isFetching ? "loading" : ""}`}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </HeadingGroup>

      <AdvancedSearchForm
        formik={formik}
        optionsData={optionsData}
        isSuccess={isSearchFilterSuccess}
      />
      {/* {state?.advancedSearchStatus === "pending" ? ( */}
      {isFetching ? (
        <div className={`col-12 pro-mt-4`}>
          <div className={`row g-4`}>
            {[...Array(2)].map((_, i) => (
              <div className={`col-6`} key={i}>
                <UserDetailTableShimmer />
              </div>
            ))}
          </div>
        </div>
      ) : isSuccess ? (
        <>
          <div className="pro-w-100 pro-d-flex pro-justify-between pro-items-center">
            <div>
              <div className="pro-check-box">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleSelectAll}
                  className="pro-check"
                  id="all"
                />
                <label htmlFor="all" className="pro-check-label">
                  Select All
                </label>
              </div>
            </div>

            <div className="pro-d-flex ">
              {hasExportPermission && (
                <div className="col-auto pro-ms-3 pro-me-3">
                  <Select
                    id="export_options"
                    isClearable={false}
                    placeholder={"Export Options"}
                    options={optionsData?.data?.export_count}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.value}
                    className={`pro-input lg `}
                    classNamePrefix="pro-input"
                    value={optionsData?.data?.export_count?.filter(
                      (p) =>
                        // registrationReportState?.currentPlatformFilter ===
                        exportOption === p.value
                    )}
                    onChange={(value) => setExportOption(value?.value)}
                  ></Select>
                </div>
              )}
              {hasExportPermission && (
                <button
                  className={`pro-btn pro-btn-primary pro-px-5 lg  ${
                    exportLoading ? "loading" : ""
                  }`}
                  onClick={handleExportClick}
                >
                  Export
                </button>
              )}
              {hasDownloadPdfPermission && (
                <button
                  className={`pro-btn pro-btn-primary pro-px-5 lg pro-ms-3 ${
                    pdf2Loading ? "loading" : ""
                  }`}
                  onClick={handleDownloadTable}
                >
                  Download PDF
                </button>
              )}
            </div>
          </div>

          <UserDetailTableListing
            data={advancedSearchResults?.data?.data || []}
            //  lastElement={lastElement}
            search
            handleSelect={handleSelect}
            checked={selectedProfiles?.filter?.((a) => a !== activeProfile)}
            showCheck
            handleDownload={handleDownloadClick}
          />

          {advancedSearchResults?.data?.data?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === state?.currentPageSize
              )}
              totalPageCount={Math.ceil(
                advancedSearchResults?.data?.total_count /
                  advancedSearchResults?.data?.per_page
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        // state?.advancedSearchStatus === "fulfilled" &&
        isSuccess &&
        advancedSearchResults?.data?.length === 0 && <ErrorMessage />
      )}
      {/* {state?.advancedSearchStatus === "rejected" && <ErrorMessage />} */}
      {isError && <ErrorMessage />}

      <ModalLayout show={showPdf} handleClose={closePdfModal}>
        <PdfModalForm
          handleCancel={closePdfModal}
          handleDownloadPdf={handleDownloadPdf}
          isFetchLoading={isFetchLoading}
          pdfLoading={pdfLoading}
        />
      </ModalLayout>

      <div style={{ position: "absolute", left: "-9999px" }}>
        <ViewProfileDetails
          setIsFetchLoading={setIsFetchLoading}
          ref={printRef}
          userId={activeProfile}
          skip={skip}
        />
      </div>

      <div style={{ position: "absolute", left: "-9999px" }}>
        <ViewTableDetails
          setIsFetchLoading={setIsFetchLoading}
          ref={tableRef}
          userId={activeProfile}
          skip={skip}
        />
      </div>
    </div>
  );
};

export default AdvancedSearch;
