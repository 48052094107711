import React from "react";

export const UserWebIdRow = ({ data, activeProfile, params }) => {


  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const newIds = ids.filter((id) => id !== data?.user_id).join(",");
  if (!data?.user_web_id) {
    return "";
  }

  return (
    <p
      className="pro-mb-0 pro-pnt"
      onClick={() =>
        window.open(
          `${
            window.location.origin
          }/${`profile?active=${data?.user_id}&id=${newIds}`}`
        )
      }
    >
      {data?.user_web_id}
    </p>
  );
};

export const ClientWebIdRow = ({ data, activeProfile, params }) => {


  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const newIds = ids.filter((id) => id !== data?.client_id).join(",");
  if (!data?.client_web_id) {
    return "";
  }

  return (
    <p
      className="pro-mb-0 pro-pnt"
      onClick={() =>
        window.open(
          `${
            window.location.origin
          }/${`profile?active=${data?.client_id}&id=${newIds}`}`
        )
      }
    >
      {data?.client_web_id}
    </p>
  );
};
