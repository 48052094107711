import {
  Button,
  Input,
  DatePickerWithInput,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useBasic from "./useBasic";

const Basic = ({ optionsData, isOptionDataSuccess }) => {
  const { formik, errors, handleCloseModal } = useBasic({
    optionsData,
    isOptionDataSuccess,
  });

  return (
    <div className={`pro-p-0 pro-w-100`}>
      <form action="" onSubmit={formik.handleSubmit}>
        <Input
          label={"Schedule Name*"}
          type="text"
          id="schedule_name
          "
          name="schedule_name
          "
          className={`pro-input lg ${
            formik.touched.schedule_name &&
            formik.errors.schedule_name &&
            " error"
          }`}
          {...formik.getFieldProps("schedule_name")}
          error={formik.touched.schedule_name && formik.errors.schedule_name}
          errorMessage={formik.errors.schedule_name}
        />

        <Input
          label={"Period (Days)*"}
          type="text"
          id="schedule_duration
          "
          name="schedule_duration
          "
          className={`pro-input lg ${
            formik.touched.schedule_duration &&
            formik.errors.schedule_duration &&
            " error"
          }`}
          {...formik.getFieldProps("schedule_duration")}
          error={
            formik.touched.schedule_duration && formik.errors.schedule_duration
          }
          errorMessage={
            formik.touched.schedule_duration && formik.errors.schedule_duration
          }
        />

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Schedule For*
          </label>
          <Select
            id="scheduled_for"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.user_types}
            getOptionLabel={(option) => `${option.user_type}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.scheduled_for &&
              formik.errors.scheduled_for &&
              " error"
            }`}
            value={optionsData?.data?.user_types?.filter?.((c) =>
              formik.values.scheduled_for?.includes?.(c.id)
            )}
            classNamePrefix="pro-input"
            onBlur={() => formik.handleBlur("scheduled_for")}
            onChange={(value) => {
              formik.setFieldValue(
                "scheduled_for",
                value.map((c) => c.id)
              );
            }}
          ></Select>
          {formik.touched.scheduled_for && formik.errors.scheduled_for && (
            <span className="error-text">{formik.errors.scheduled_for}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Type*
          </label>
          <Select
            id="schedule_type"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.schedule_types}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.schedule_type &&
              formik.errors.schedule_type &&
              " error"
            }`}
            value={optionsData?.data?.schedule_types?.filter(
              (p) =>
                p.id?.toString() === formik.values.schedule_type?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={() => formik.handleBlur("schedule_type")}
            onChange={(value) =>
              formik.setFieldValue("schedule_type", value?.id || null)
            }
          ></Select>
          {formik.touched.schedule_type && formik.errors.schedule_type && (
            <span className="error-text">{formik.errors.schedule_type}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4 ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Schedule Start Date*
          </label>
          <div className={`pro-w-100 pro-d-flex`}>
            <DatePickerWithInput
              CalendarOutlineIcon={
                <span className="material-symbols-outlined ">
                  calendar_month
                </span>
              }
              minDate={new Date()}
              id="start_date"
              placeholder={
                formik.values.start_date
                  ? formik.values.start_date
                  : "Select Date"
              }
              onBlur={() => formik.handleBlur("start_date")}
              className={`pro-input lg pro-min-full ${
                formik.touched.start_date &&
                formik.errors.start_date &&
                " error"
              }`}
              onDateChange={(value) => {
                formik.setFieldValue("start_date", value);
              }}
              isError={formik.touched.start_date && formik.errors.start_date}
              errorMessage={formik.errors.start_date}
            />
          </div>
        </div>

        <Input
          label={" No. of Selections / Call*"}
          type="text"
          id="count
          "
          name="count
          "
          className={`pro-input lg ${
            formik.touched.count && formik.errors.count && " error"
          }`}
          {...formik.getFieldProps("count")}
          error={formik.touched.count && formik.errors.count}
          errorMessage={formik.errors.count}
        />

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Exclude ‘Sunday’*
          </label>
          <Select
            id="exclude_sunday"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.exclude_sunday}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.exclude_sunday &&
              formik.errors.exclude_sunday &&
              " error"
            }`}
            value={optionsData?.data?.exclude_sunday?.filter(
              (p) =>
                p.id?.toString() === formik.values.exclude_sunday?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={() => formik.handleBlur("exclude_sunday")}
            onChange={(value) =>
              formik.setFieldValue("exclude_sunday", value?.id ?? null)
            }
          ></Select>
          {formik.touched.exclude_sunday && formik.errors.exclude_sunday && (
            <span className="error-text">{formik.errors.exclude_sunday}</span>
          )}
        </div>

        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={(e) => handleCloseModal(e)}
          >
            Cancel
          </Button>

          <Button className={"pro-btn-primary lg pro-mx-3"} type="submit">
            Next
          </Button>
        </div>
        {errors && <span className="error-message">{errors}</span>}
      </form>
    </div>
  );
};

export default Basic;
