import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetAddedServiceListQuery } from "../../../../store/queries/Profile";
import { updateConfig } from "../../../../store/slices/Profile/AddService";
import { WebIdRow } from "./Rows";

const useAddedServiceList = ({ refetchRef, formik }) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const addServiceState = useSelector((state) => state.addService);
  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: list = {},
    isFetching,
    refetch,
  } = useGetAddedServiceListQuery({
    page_size: addServiceState.currentPageSize,
    page: addServiceState.currentPage,
    sort_by: addServiceState.sortBy,
    sort: addServiceState.sortOrder,
    user_id: activeProfile,
    service_type: formik?.values?.service_type,
  });

  useEffect(() => {
    refetchRef.current = refetch;
    // eslint-disable-next-line
  }, []);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (addServiceState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            addServiceState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "desc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      partner_client_web_id: (_, data) => (
        <WebIdRow data={data} activeProfile={activeProfile} params={params} />
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    list,
    isFetching,
    currentPage: addServiceState?.currentPage,
    paginationOptions,
    handleSort,
    getRow,
    handlePagination,
    handlePageSize,
    refetch,
  };
};
export default useAddedServiceList;
