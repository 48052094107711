import {
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import ErrorMessage from "../../Global/ErrorMessage";
import UserDetailTableShimmer from "../../Global/Shimmers/UserDetailTableShimmer";
import UserDetailTableListing from "../../Global/UserDetailTableListing";
import useGlobalSearch from "./useGlobalSearch";
import style from "./globalSearch.module.scss";
import PdfModalForm from "../../Profile/ActionForms/PdfModalForm";
import ViewProfileDetails from "../../Profile/ViewProfileDetails";

const GlobalSearch = () => {
  const {
    state,
    showScroll,
    searchedKey,
    handleScrollToTop,
    lastElement,
    handleSelect,
    selectedProfiles,
    activeProfile,
    handleDownloadClick,
    // checked,
    // handleCheckBox,
    showPdf,
    closePdfModal,
    setIsFetchLoading,
    isFetchLoading,
    handleDownloadPdf,
    printRef,
    skip,
    pdfLoading,
  } = useGlobalSearch();

  return (
    <div className={`pro-mb-4`}>
      {showScroll && (
        <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
          <span class="material-symbols-outlined">expand_less</span>
        </button>
      )}
      <HeadingGroup
        title={
          state.globalSearchData?.data?.length !== undefined
            ? ` Your Search Results for '${searchedKey}' Returned ${state.globalSearchData?.total_count} Results`
            : "Global Search"
        }
        extraClassName={`${style.fixed_position}`}
      ></HeadingGroup>

      <div className={`pro-mt-5`}>
        <UserDetailTableListing
          data={state.globalSearchData?.data || []}
          lastElement={lastElement}
          search
          handleSelect={handleSelect}
          checked={selectedProfiles?.filter?.((a) => a !== activeProfile)}
          showCheck
          handleDownload={handleDownloadClick}
        />
      </div>

      {state?.globalSearchStatus === "pending" ? (
        <div className={`col-12 pro-mt-4`}>
          <div className={`row g-4`}>
            {[...Array(2)].map((_, i) => (
              <div className={`col-6`} key={i}>
                <UserDetailTableShimmer />
              </div>
            ))}
          </div>
        </div>
      ) : (
        state?.globalSearchStatus === "fulfilled" &&
        state?.globalSearchData?.data?.length === 0 && <ErrorMessage />
      )}
      {state?.globalSearchStatus === "rejected" && <ErrorMessage />}

      <ModalLayout show={showPdf} handleClose={closePdfModal}>
        <PdfModalForm
          handleCancel={closePdfModal}
          handleDownloadPdf={handleDownloadPdf}
          isFetchLoading={isFetchLoading}
          pdfLoading={pdfLoading}
        />
      </ModalLayout>

      <div style={{ position: "absolute", left: "-9999px" }}>
        <ViewProfileDetails
          setIsFetchLoading={setIsFetchLoading}
          ref={printRef}
          userId={activeProfile}
          skip={skip}
        />
      </div>
    </div>
  );
};

export default GlobalSearch;
