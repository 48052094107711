import React from "react";
import { Table, Pagination } from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import style from "./viewServiceList.module.scss";

const ViewServiceList = ({
  list,
  isFetching,
  currentPage,
  paginationOptions,
  getRow,
  handleSort,
  handlePagination,
  handlePageSize,
}) => {
  return (
    <div className={`col-auto pro-pb-6 ${style.table_wrapper}`}>
      <Table
        data={list?.data?.data || []}
        uniqueID={"web_id"}
        fields={list?.data?.fields}
        SortIcon={<FaSort />}
        handleSort={handleSort}
        getRow={getRow}
        loading={isFetching}
        perpage={10}
      />

      {list?.data?.data?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPageCount={Math.ceil(
            list?.data?.total_count / list?.data?.per_page
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
    </div>
  );
};

export default ViewServiceList;
