import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  allPermissions: [],
  allPermissionsStatus: "idle",
  rolePermission: {},
  rolePermissionStatus: "idle",
};

export const getAllPermissions = createAsyncThunk(
  "roles/getAllPermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`permissions/permission-list`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPermission = createAsyncThunk(
  "roles/getPermission",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `permissions/user-types-detail/${params.id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPermissions.pending, (state) => {
      state.allPermissionsStatus = "pending";
    });
    builder.addCase(getAllPermissions.fulfilled, (state, action) => {
      state.allPermissionsStatus = "fulfilled";
      state.allPermissions = action.payload?.data?.data;
    });
    builder.addCase(getAllPermissions.rejected, (state) => {
      state.allPermissionsStatus = "failed";
    });
    builder.addCase(getPermission.pending, (state) => {
      state.rolePermissionStatus = "pending";
    });
    builder.addCase(getPermission.fulfilled, (state, action) => {
      state.rolePermission[action.meta.arg.id] = action.payload?.data?.data;
      state.rolePermissionStatus = "fulfilled";
    });
    builder.addCase(getPermission.rejected, (state) => {
      state.rolePermissionStatus = "rejected";
    });
  },
});

export const { updateConfig } = roleSlice.actions;

export default roleSlice.reducer;
