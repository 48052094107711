import { getAxiosInstance } from "../../../../api";



export const getMatchedStars = async (obj) => {

    const api = await getAxiosInstance();
    try {
      const response = await api.post("user/integrated-stars",obj);
  
      return response;
    } catch (error) {
  
      return error.response.data
    }
  };
  