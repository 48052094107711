import { getAxiosInstance } from "../../../api";

export const getAddedServiceExistList = async (body) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`service/check-service-exist`,body);
  
      return response;
    } catch (error) {
  
      return error.response.data
    }
  };