import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  useDeleteProfileMutation,
  useGetProfileDataQuery,
  useGetDeleteReasonDataQuery,
} from "../../../../store/queries/Profile";
import { deleteHistory } from "../../../../store/queries/DeleteHistory";

const useDeleteProfileForm = ({ userId, handleCancel, ProfileName }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetProfileDataQuery(userId);

  const { data: deleteOptionsData = {} } = useGetDeleteReasonDataQuery();

  const [sendRequest] = useDeleteProfileMutation();

  const formik = useFormik({
    initialValues: {
      user_id: userId,
      reason: null,
      reason_id: "",
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    sendRequest(values)
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          deleteHistory.util.invalidateTags(["DeleteHistory"]);
          toast.success("Profile Deleted Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Delete Profile!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Delete Profile!");
      });
  };
  return { formik, optionsData, isOptionDataSuccess, deleteOptionsData };
};

export default useDeleteProfileForm;
