import {
  useGetUtmReportQuery,
  useUpdateUtmFieldsMutation,
} from "../../store/queries/UtmReport";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../store/slices/UtmReport/utmReportSlice";
import { updateConfig as updateOrderConfig } from "../../store/slices/OrderColumns/orderColumnSlice";
import { addDays } from "date-fns";
import { useState } from "react";
import { getFormatedDate } from "../../utils/functions/table";
import { NameIDrow } from "./Rows";

const useUtmReport = () => {
  const dispatch = useDispatch();
  const activeProfile = sessionStorage.getItem("active");
  const utmReportState = useSelector((state) => state.utmReports);
  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);
  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -30),
    endDate: new Date(),
    key: "selection",
  });

  const [checkboxValues, setCheckboxValues] = useState({
    others: false,
    existed: false,
    other_state: false,
  });

  const {
    data: utmReportData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetUtmReportQuery({
    sort_by: utmReportState.sortBy,
    sort_order: utmReportState.sortOrder,
    search: utmReportState.search,
    per_page: utmReportState.currentPageSize,
    page: utmReportState.currentPage,
    date: utmReportState.dateRange,
    others: checkboxValues["others"] ? 2 : 1,
    existed: checkboxValues["existed"] ? 2 : 1,
    other_state: checkboxValues["other_state"] ? 2 : 1,
  });

  const [updateUtmFields] = useUpdateUtmFieldsMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (_, data) => (
        <NameIDrow data={data} activeProfile={activeProfile} />
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (utmReportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = utmReportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const handleDateChange = (range) => {
    setInitialDateRange(range);

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;

        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.search = "";
      })
    );
  };

  const obj = utmReportData?.data?.field || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];
      return newObj;
    }, {});

  const handleEditAction = (id) => {
    const selectedId = id?.[0];
    const tempResponse = utmReportData?.data?.data?.filter(
      (item) => item?.id === selectedId
    )?.[0];

    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.selectedData = tempResponse;
      })
    );
  };

  //closing edit updation modal
  const closeUtmEditForm = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleChangeOthersCheckbox = (e) => {
    setCheckboxValues((state) => ({
      ...state,
      others: e.target.checked,
    }));
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.search = "";
      })
    );
  };

  const handleChangeExistedCheckbox = (e) => {
    setCheckboxValues((state) => ({
      ...state,
      existed: e.target.checked,
    }));
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.search = "";
      })
    );
  };

  const handleChangeOtherStateCheckbox = (e) => {
    setCheckboxValues((state) => ({
      ...state,
      other_state: e.target.checked,
    }));
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.search = "";
      })
    );
  };

  return {
    utmReportState,
    utmReportData,
    isLoading,
    isFetching,
    paginationOptions,
    showTableFieldsModal,
    initialDateRange,
    sortedTableFields,
    checkboxValues,
    handleChangeOtherStateCheckbox,
    handleChangeExistedCheckbox,
    handleChangeOthersCheckbox,
    setCheckboxValues,
    closeUtmEditForm,
    handleEditAction,
    handleDateChange,
    handleClearClick,
    handleEditFieldsClick,
    updateUtmFields,
    refetch,
    closeEditModal,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleSearch,
  };
};

export default useUtmReport;
