import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useListing from "./useListing";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import { ImagePdfCard } from "../../Global/ImagePdfCard";
import { AgreementMultipleImage } from "../../Global/AgreementMultipleImage";

const Listing = () => {
  const {
    activeTab,
    isLoading,
    isFetching,
    initialDateRange,
    paginationOptions,
    userAgreementData,
    sortedTableFields,
    userAgreementState,
    showTableFieldsModal,
    refetch,
    getRow,
    handleSort,
    handleSearch,
    closeEditModal,
    handlePageSize,
    closeImageModal,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handleEditFieldsClick,
    closeMultipleImageModal,
    updateAgreementFields,
  } = useListing();

  return (
    <>
      <div className="pro-mt-2">
        <HeadingGroup
          title={activeTab === "pending" ? "Pending" : "Approved"}
          extraClassName={`pro-mb-2`}
        />

        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                userAgreementState?.search === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: userAgreementState?.search }}
              showActions={true}
              handleActionClick={handleEditFieldsClick}
              //date picker props
              showDateRange={true}
              initialDateRange={initialDateRange}
              onDateChange={handleDateChange}
            />
          </div>
        </div>

        <div className={`col-auto pro-pb-6`}>
          <Table
            data={userAgreementData?.data?.data || []}
            uniqueID={"id"}
            fields={sortedTableFields}
            showCheckBox={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={userAgreementState?.currentPageSize}
            assignable={false}
            deletable={false}
            multiSelect={false}
          />
        </div>

        <div>
          {userAgreementData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={userAgreementState.currentPage}
              totalPageCount={Math.ceil(
                userAgreementData?.data?.total_count /
                  userAgreementState?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={userAgreementData?.data?.table_id}
              updateData={updateAgreementFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>

      <ModalLayout
        show={userAgreementState?.showImageModal}
        handleClose={closeImageModal}
        backdrop="static"
      >
        <ImagePdfCard
          data={userAgreementState?.imageData}
          documentType={userAgreementState?.document_type}
          handleClose={closeImageModal}
        />
      </ModalLayout>

      <ModalLayout
        show={userAgreementState?.showMultipleImageModal}
        handleClose={closeMultipleImageModal}
        title={"Preview"}
        closeIcon={<span className="material-symbols-outlined x5">close</span>}
      >
        <AgreementMultipleImage
          data={userAgreementState?.multipleImageData}
          handleClose={closeMultipleImageModal}
          agreement
        />
      </ModalLayout>
    </>
  );
};

export default Listing;
