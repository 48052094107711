import {
  MultiColumnTable,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useOverview from "./useOverview";
import Style from "./overview.module.scss";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import AboutMyselfForm from "./AboutMyselfForm";
import AstrologyTableNew from "../../Global/AstrologyTable/AstrologyTableNew";
import HoroscopeModal from "./HoroscopeModal";
import { MultiColumnTableCall } from "../../Global/MultiColoumnTableCall";

const Overview = ({ isBackground }) => {
  const navigate = useNavigate();
  const { activeProfile } = useOutletContext();
  const {
    overview,
    showModal,
    isFetching,
    horoscopeData,
    horoscopeImageData,
    showHoroscopeImage,
    profileData,
    hasEditPermission,
    outgoingCallStatus,
    loadingLabel,
    handleInitiateCall,
    refetch,
    handleModal,
    downloadImage,
    openHoroscopeModal,
    closeHoroscopeModal,
  } = useOverview();

  return (
    <>
      <div className={`${Style.overview_main} font-inc`}>
        <div className="row">
          {(profileData?.profile_delete === 1 ||
            profileData?.profile_hide === "1") && (
            <div className="col-12 pro-pt-5 pro-pb-2">
              {profileData?.profile_delete === 1 && (
                <div
                  className={`${Style.warning_box} ${Style.danger} pro-d-flex pro-fw-bold`}
                >
                  <span class="material-symbols-outlined x4"> warning</span>
                  <p className="pro-mb-0">{profileData?.deleted_reason}</p>
                </div>
              )}
              {profileData?.profile_hide === "1" && (
                <div
                  className={`${Style.warning_box} ${Style.warning} pro-d-flex pro-fw-bold`}
                >
                  <span class="material-symbols-outlined x4"> warning</span>
                  <p className="pro-mb-0">{profileData?.hidden_reason}</p>
                </div>
              )}
            </div>
          )}

          {isFetching ? (
            [...Array(5)].map((_, i) => (
              <div
                className={`col-12 ${Style.overview_wrap_list} ${Style.shimmer_wrapper} `}
                key={i}
              >
                <div className={`row gx-2 gx-xxl-6 `}>
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <OverviewShimmer />
                  </div>
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <OverviewShimmer />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div
                className={`col-12 ${Style.overview_wrap_list} ${
                  isBackground && "light_primary_bg"
                }`}
              >
                <div className="row gx-2 gx-xxl-6">
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTable
                        title={overview?.brief_details?.label}
                        data={overview?.brief_details?.data?.slice?.(
                          0,
                          Math.round(overview?.brief_details?.data?.length / 2)
                        )}
                        extraClassNames={Style.multi_column_table}
                      />
                    </div>
                  </div>
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTable
                        data={overview?.brief_details?.data?.slice?.(
                          Math.round(overview?.brief_details?.data?.length / 2),
                          overview?.brief_details?.data?.length
                        )}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() =>
                          navigate({
                            pathname: "/dashboard/profile-registration",
                            search: `edit=true&user=${activeProfile}&tab=Family`,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-12 ${Style.overview_wrap_list}`}>
                <div className="row gx-2 gx-xxl-6">
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTable
                        title={overview?.basic_details?.label}
                        data={overview?.basic_details?.data}
                        extraClassNames={Style.multi_column_table}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() =>
                          navigate({
                            pathname: "/dashboard/profile-registration",
                            search: `edit=true&user=${activeProfile}&tab=Basic`,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTable
                        title={overview?.educational_job_details?.label}
                        data={overview?.educational_job_details?.data}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() =>
                          navigate({
                            pathname: "/dashboard/profile-registration",
                            search: `edit=true&scroll_to=professional-info&user=${activeProfile}&tab=Basic`,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-12 ${Style.overview_wrap_list}`}>
                <div className="row gx-2 gx-xxl-6">
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTable
                        title={overview?.family_details?.label}
                        data={overview?.family_details?.data}
                        extraClassNames={Style.multi_column_table}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() =>
                          navigate({
                            pathname: "/dashboard/profile-registration",
                            search: `edit=true&user=${activeProfile}&tab=Family`,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTableCall
                        title={overview?.contact_info?.label}
                        data={overview?.contact_info?.data}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() =>
                          navigate({
                            pathname: "/dashboard/profile-registration",
                            search: `edit=true&scroll_to=contact-info&user=${activeProfile}&tab=Family`,
                          })
                        }
                        handleInitiateCall={handleInitiateCall}
                        outgoingCallStatus={outgoingCallStatus}
                        loadingLabel={loadingLabel}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-12 ${Style.overview_wrap_list}`}>
                <div className={`pro-m-4 pro-mx-5`}>
                  <div className={`row ${Style.p_rel}`}>
                    <div className="col-4">
                      <MultiColumnTable
                        title={overview?.horoscope_details?.label}
                        data={horoscopeData}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() =>
                          navigate({
                            pathname: "/dashboard/profile-registration",
                            search: `edit=true&user=${activeProfile}&tab=Religious`,
                          })
                        }
                      />

                      {horoscopeImageData?.value ? (
                        <p className="pro-d-flex pro-items-center pro-mb-0">
                          <span className="pro-me-4 pro-p-1">
                            {horoscopeImageData?.label}
                          </span>
                          <button
                            className="pro-btn pro-btn-primary pro-ms-5"
                            onClick={openHoroscopeModal}
                          >
                            Preview
                          </button>
                          <div
                            className={`col-auto pro-mx-4 ${Style.horoscope_btn_wrap}`}
                          >
                            <Link
                              to={`/dashboard/profile-registration?edit=true&user=${activeProfile}&tab=Gallery`}
                            >
                              Upload
                            </Link>
                          </div>
                        </p>
                      ) : (
                        <p className="pro-d-flex pro-items-center pro-mb-0">
                          <span className="pro-me-4 pro-p-1">
                            {horoscopeImageData?.label}
                          </span>
                          <div
                            className={`col-auto pro-mx-4 ${Style.horoscope_btn_wrap}`}
                          >
                            <Link
                              to={`/dashboard/profile-registration?edit=true&user=${activeProfile}&tab=Gallery`}
                            >
                              Upload
                            </Link>
                          </div>
                        </p>
                      )}

                      {/* <div className="row pro-justify-end pro-my-4 ">
                        <div className={`col-auto ${Style.horoscope_btn_wrap}`}>
                          <Link to={`/dashboard/profile-registration?edit=true&user=${activeProfile}&tab=Gallery`}>Upload</Link>
                        </div>
                      </div> */}

                      {hasEditPermission ? (
                        <button
                          className={Style.edit_button}
                          onClick={() =>
                            navigate({
                              pathname: "/dashboard/profile-registration",
                              search: `edit=true&user=${activeProfile}&tab=Horoscope`,
                            })
                          }
                        >
                          <span className="material-symbols-outlined">
                            edit_square
                          </span>
                        </button>
                      ) : null}
                    </div>

                    <div className="col-4">
                      <AstrologyTableNew
                        data={overview?.horoscope_graha?.data}
                        name={"Grahanila"}
                      />
                    </div>
                    <div className="col-4">
                      <AstrologyTableNew
                        data={overview?.horoscope_navamshakam?.data}
                        name={"Navamshakam"}
                      />
                    </div>

                    {/**#FIXME Aneesh add an edit button here */}
                  </div>
                </div>
              </div>
              <div className={`col-12 ${Style.overview_wrap_list}`}>
                <div className="row gx-2 gx-xxl-6">
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTable
                        title={overview?.partner_preference?.label}
                        showreadmore
                        data={overview?.partner_preference?.data}
                        extraClassNames={Style.multi_column_table}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() =>
                          navigate({
                            pathname: "/dashboard/profile-registration",
                            search: `edit=true&user=${activeProfile}&tab=Partner`,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`col-6 pro-pb-3 ${Style.overview_wrap_list_item}`}
                  >
                    <div className="pro-m-4 pro-mx-5">
                      <MultiColumnTable
                        title={overview?.about_myself?.label}
                        data={[overview?.about_myself?.data]}
                        editIcon={
                          hasEditPermission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={() => handleModal()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <ModalLayout show={showModal} handleClose={handleModal}>
        <AboutMyselfForm
          handleCancel={handleModal}
          userId={activeProfile}
          isFetching={isFetching}
          data={overview?.about_myself}
          refetch={refetch}
        />
      </ModalLayout>

      <ModalLayout
        show={showHoroscopeImage}
        handleClose={closeHoroscopeModal}
        backdrop="static"
      >
        <HoroscopeModal
          closeHoroscopeModal={closeHoroscopeModal}
          horoscopeImageData={horoscopeImageData}
          downloadImage={downloadImage}
        />
      </ModalLayout>
    </>
  );
};

export default Overview;
