import {
  Button,
  DatePickerWithInput,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import useFollowUpForm from "./useFollowUpForm";
import style from "./followUpForm.module.scss";
import moment from "moment";
import TimePicker from "react-time-picker";

const FollowUpForm = ({ handleCancel, userId, profileId, isSuccess }) => {
  const { formik, onChange, time, handleClearClick } = useFollowUpForm({
    handleCancel,
    userId,
  });

  let date = new Date();

  return (
    <div className={`pro-p-5 pro-w-100`}>
      <div className={style.heading}>
        <h4 className="pro-ttl h4 pro-mb-2">Follow up / Call later</h4>
        <h6 className="pro-ttl  pro-mb-5">{`Profile Id : ${profileId}`}</h6>
      </div>
      {isSuccess ? (
        <>
          <div className="input-wrap pro-mb-4 ">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Date
            </label>
            <div className={`pro-w-100 pro-d-flex`}>
              <DatePickerWithInput
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined ">
                    calendar_month
                  </span>
                }
                minDate={new Date(date.setDate(date.getDate() + 1))}
                id="call_date"
                placeholder={"Select Date"}
                className={`pro-input lg pro-min-full ${
                  formik.errors.call_date &&
                  formik.touched.call_date &&
                  " error"
                }`}
                onDateChange={(value) => {
                  formik.setFieldValue(
                    "call_date",
                    moment(value).format("YYYY-MM-DD")
                  );
                }}
                isError={formik.errors.call_date && formik.touched.call_date}
                errorMessage={formik.errors.call_date}
              />
            </div>
          </div>

          <div className={`col-12`}>
            {/* //FIXME change timePicker package */}
            {/* <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Time
              </label>
              <TimePicker
                format="h:mm a"
                id="call_time"
                hourPlaceholder="hh"
                minutePlaceholder="mm"
                disableClock
                className={`pro-input lg ${style.timePicker}  ${
                  formik.touched.call_time &&
                  formik.errors.call_time &&
                  " error"
                }`}
                onBlur={formik.handleBlur("call_time")}
                onChange={(value) => onChange(value)}
                value={time}
              />

              {formik.touched.call_time && formik.errors.call_time && (
                <span className="error-text">{formik.errors.call_time}</span>
              )}
            </div> */}

            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Time
              </label>
              <TimePicker
                format="hh:mm a"
                id="call_time"
                hourPlaceholder="00"
                minutePlaceholder="00"
                disableClock
                className={`pro-input lg ${style.timePicker}  ${
                  formik.touched.call_time &&
                  formik.errors.call_time &&
                  " error"
                }`}
                onBlur={formik.handleBlur("call_time")}
                clearIcon={
                  <span
                    onClick={() => handleClearClick()}
                    className="material-symbols-outlined x3"
                  >
                    close
                  </span>
                }
                onChange={(value) => onChange(value)}
                value={formik?.values?.call_time === "00:00 AM" ? null : time}
              />

              {formik.touched.call_time && formik.errors.call_time && (
                <span className="error-text">{formik.errors.call_time}</span>
              )}
            </div>
          </div>

          <Input
            label={"Reason"}
            type="text"
            id="description
          "
            name="description
          "
            className={`pro-input lg ${
              formik.touched.description &&
              formik.errors.description &&
              " error"
            }`}
            {...formik.getFieldProps("description")}
            error={formik.touched.description && formik.errors.description}
            errorMessage={
              formik.touched.description && formik.errors.description
            }
          />

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        [...Array(3)].map((_, i) => <InputShimmer key={i} />)
      )}
    </div>
  );
};

export default FollowUpForm;
