import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import BasicInfo from "./BasicInfo";
import PhysicalInfo from "./PhysicalInfo";
import ProffesionalInfo from "./ProffesionalInfo";
import useBasic from "./useBasic";

const Basic = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
  webId,
}) => {
  const {
    currentFormDataBasic,
    formik,
    errors,
    isChecked,
    isUpdating,
    stateRef,
    districtRef,
    casteRef,
    jobRef,
    educationRef,
    onChangeMob2Response,
    onChangeMobResponse,
    onChangeWhastappResponse,
    onChangeRegisterIdResponse,
    onChangeEmailResponse,
    countryData,
    handleClearTimeValues,
    setSaveAndNext,
    handleProfileRedirect,
    handleRegisterNumberBlur,
    handleMobileBlur,
    handleMobile2Blur,
    handleWhatsappNoBlur,
    handleEmailBlur,
    handleAgeChange,
    handleCheckbox,
    handleSelectReligion,
    handleSelectEducation,
    handleSelectJob,
    handleSelectJobCountry,
    handleSelectJobState,
    handleDobChange,
    handlePaymentDateChange,
    handleLastBoostedDate,
  } = useBasic({
    optionsData,
    isOptionDataSuccess,
    activeUser,
    edit,
    toTab,
    webId,
  });
  return (
    <div className={`pro-p-0 pro-w-100`}>
      <form onSubmit={formik.handleSubmit}>
        {/**
         * Registration Number
         * Package
         * Profile Visibility Score
         * Branch
         * Full Name
         * Email
         * Mobile
         * Mobile 2
         * Whatsapp No
         * Gender
         * Date of Birth
         * Age
         * Marital Status
         * No of childrens
         * Religion
         * Caste
         * Sub Caste
         * Profile Created For
         */}
        <BasicInfo
          formik={formik}
          handleRegisterNumberBlur={handleRegisterNumberBlur}
          handleMobileBlur={handleMobileBlur}
          handleMobile2Blur={handleMobile2Blur}
          handleWhatsappNoBlur={handleWhatsappNoBlur}
          handleProfileRedirect={handleProfileRedirect}
          handleEmailBlur={handleEmailBlur}
          handleAgeChange={handleAgeChange}
          onChangeMob2Response={onChangeMob2Response}
          onChangeMobResponse={onChangeMobResponse}
          onChangeWhastappResponse={onChangeWhastappResponse}
          onChangeRegisterIdResponse={onChangeRegisterIdResponse}
          onChangeEmailResponse={onChangeEmailResponse}
          optionsData={optionsData}
          currentFormDataBasic={currentFormDataBasic}
          handleSelectReligion={handleSelectReligion}
          casteRef={casteRef}
          handleDobChange={handleDobChange}
          handlePaymentDateChange={handlePaymentDateChange}
          countryData={countryData}
          handleClearTimeValues={handleClearTimeValues}
        />
        {/**
       * Height
       * Complexion
       * Body Type
       * Special Cases
       * Specify Detail
     
       */}
        <PhysicalInfo
          formik={formik}
          optionsData={optionsData}
          currentFormDataBasic={currentFormDataBasic}
        />

        {/**
         *  Education Main Category
         *  Education Sub Category
         *  Education In Detail
         *  Job Main Category
         *  Job Sub Category
         *  Job In Detail
         *  Annual Income
         *  Job Location - Country
         *  Job Location - State
         *  Job Location - District
         *  Job Location Address
         *  Profile Rate Score - checkbox
         *  Profile Rate Score - input
         */}

        <ProffesionalInfo
          formik={formik}
          edit={edit}
          optionsData={optionsData}
          currentFormDataBasic={currentFormDataBasic}
          handleSelectEducation={handleSelectEducation}
          educationRef={educationRef}
          handleSelectJob={handleSelectJob}
          jobRef={jobRef}
          handleSelectJobCountry={handleSelectJobCountry}
          stateRef={stateRef}
          handleSelectJobState={handleSelectJobState}
          districtRef={districtRef}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          handleLastBoostedDate={handleLastBoostedDate}
        />

        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
          {toTab && (
            <Button
              disabled={isUpdating}
              className={`pro-btn-primary lg pro-mx-3 ${
                isUpdating ? "loading" : ""
              }`}
              onClick={() => setSaveAndNext(true)}
              type="submit"
            >
              {isUpdating && (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              )}
              {toTab && "Save & Next"}
            </Button>
          )}

          <Button
            className={`pro-btn-primary lg pro-mx-3 ${
              isUpdating ? "loading" : ""
            }`}
            disabled={isUpdating}
            type="submit"
          >
            {isUpdating && (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            )}
            {toTab ? "Save" : "Save & Next"}
          </Button>
        </div>

        {errors && <span className="error-message">{errors}</span>}
      </form>
    </div>
  );
};

export default Basic;
