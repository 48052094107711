import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const UserImage = ({ image, onClick }) => {
  if (!image) {
    return "";
  }
  return (
    <span>
      <Image width={20} height={20} src={image} alt={""} />
    </span>
  );
};


export const PartnerImage = ({ image, onClick }) => {
  if (!image) {
    return "";
  }
  return (
    <span>
      <Image width={20} height={20} src={image} alt={""} />
    </span>
  );
};

