import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import RowOne from "./RowOne";
import RowThree from "./RowThree";
import RowTwo from "./RowTwo";
// import useAdvancedSearchForm from "./useAdvancedSearchForm";

const AdvancedSearchForm = ({ formik,optionsData,isSuccess }) => {
  // const { optionsData, isSuccess } = useAdvancedSearchForm();
  return isSuccess ? (
    <div className="pro-my-5">
      <div className="row">
        {/*
         *Looking For
         * Age From
         * Age To
         * Height From
         * Height To
         * Religion
         * Caste
         * Marital Status
         */}
        <RowOne formik={formik} optionsData={optionsData.data} />
        {/**
         * Children
         * Residing Country
         * State
         * District
         * Special Case
         * Star
         * Jathakam
         */}
        <RowTwo formik={formik} optionsData={optionsData.data} />
        {/**
         * Main Education
         * Select Education
         * Main Job
         * Select Job
         * Agreement Status
         * Agreement & Registered file
         * Photo Status
         * Registration From
         * Registration To
         */}
        <RowThree formik={formik} optionsData={optionsData.data} />
      </div>
    </div>
  ) : (
    <div className={`row pro-my-5`}>
      {[...Array(28)].map((_, i) => (
        <div className={`col-3`} key={i}>
          <InputShimmer />
        </div>
      ))}
    </div>
  );
};

export default AdvancedSearchForm;
