import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api[method](`${endpoint}`, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const userAgreement = createApi({
  reducerPath: "userAgreementApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["User-agreement"],
  endpoints: (builder) => ({
    getUserAgreement: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `user-agreement/user-agreement-list`,
      }),
      providesTags: ["User-agreement"],
    }),
    approveUserAgreement: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `user-agreement/approve-user-agreement`,
      }),
      invalidatesTags: ["User-agreement"],
    }),

    updateAgreementFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `permissions/table-permission-create`,
      }),
      invalidatesTags: ["User-agreement"],
    }),
  }),
});

export const {
  useGetUserAgreementQuery,
  useApproveUserAgreementMutation,
  useUpdateAgreementFieldsMutation,
} = userAgreement;
