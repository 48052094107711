import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
    addServiceStatus: "idle",
    data: {},
    currentPage: 1,
    currentPageSize: 10,
    sortBy: "",
    sortOrder: "",

};

export const submitAddService = createAsyncThunk(
    "addService/submitAddService",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.post(`service/create-user-service`, params);

            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const addServiceSlice = createSlice({
    name: "addService",
    initialState,
    reducers: {
        updateConfig: (state, action) => {
            action.payload(state);
        },
    },
    extraReducers: {
        [submitAddService.pending]: (state) => {
            state.addServiceStatus = "pending";
        },
        [submitAddService.fulfilled]: (state, action) => {
            state.addServiceStatus = "fulfilled";
        },
        [submitAddService.rejected]: (state) => {
            state.addServiceStatus = "failed";
        },
    },
});

export const { updateConfig } = addServiceSlice.actions;

export default addServiceSlice.reducer;
