import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const UserImage = ({ image, onClick }) => {
  if (!image) {
    return "";
  }
  return (
    <span>
      <Image width={20} height={20} src={image} alt={""} />
    </span>
  );
};

export const UserNameAndId = ({ data, activeProfile, params, navigate }) => {
  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const newIds = ids.filter((id) => id !== data?.user_id).join(",");

  if (data.name === "") {
    return "";
  }
  return (
    <span
      onClick={() =>
        navigate({
          pathname: "/profile",
          search: `active=${data?.user_id}&id=${newIds}`,
        })
      }
    >
      <p>{data.name}</p>
      <p>{data.web_id}</p>
    </span>
  );
};

export const ServiceDetail = ({ data, onClick }) => {
  if (data.service === "") {
    return "";
  }
  return (
    <span>
      <p>{data.service}</p>
      <p>{data.service_remark}</p>
    </span>
  );
};

export const FollowUpDate = ({ data, onClick }) => {
  if (data.dated_on === "") {
    return "";
  }
  return (
    <span>
      <p> Dated On : {data.dated_on}</p>
      <p> Follow Up : {data.follow_up_date}</p>
    </span>
  );
};

export const CreatedAt = ({ data, onClick }) => {
  if (data.created_at === "") {
    return "";
  }
  return (
    <span>
      <p>{data.created_at}</p>
      <p> Staff Name : {data.staff_name}</p>
      <p> Branch : {data.staff_branch}</p>
    </span>
  );
};
