import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { submitAddService } from "../../../store/slices/Profile/AddService";
import { useDispatch } from "react-redux";
import {
  // useGetAddedServiceExistListQuery,
  useGetProfileDataQuery,
} from "../../../store/queries/Profile";
import { updateProfileConfig } from "../../../store/slices/Profile";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { updateConfig } from "../../../store/slices/Profile/AddedServiceExist";
import { getAddedServiceExistList } from "./api";
import { useGetServiceTypeQuery } from "../../../store/queries/Profile";
import { format } from "date-fns";

const useAddService = ({ search, activity }) => {
  const dispatch = useDispatch();
  let refetchRef = useRef(null);
  const { data: optionsData = {}, isSuccess } = useGetServiceTypeQuery();
  const [params] = useSearchParams();
  const currentTime = new Date();
  const timeAfterTenMinutes = new Date(currentTime.getTime() + 10 * 60000);
  const currentDate = new Date();

  //function to get the formatted time
  function getCurrentTimeFormatted(now = timeAfterTenMinutes) {
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, "0");

    const isPM = hours >= 12;
    const ampm = isPM ? "PM" : "AM";

    // Adjusting hours for 12-hour format`
    hours = hours % 12;
    hours = hours || 12; // Convert 0 to 12 for midnight

    // Apply padding after adjusting for AM/PM to ensure correct 12-hour format
    const formattedHours = hours.toString().padStart(2, "0");

    return `${formattedHours}:${minutes} ${ampm}`;
  }

  const [time, setTime] = useState(timeAfterTenMinutes);

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const profile = useSelector((state) => state.profile);
  const addServiceState = useSelector((state) => state.addService);
  const addedServiceExistState = useSelector(
    (state) => state.addedServiceExist
  );

  // const { data: list = {} } = useGetAddedServiceExistListQuery(
  //   {
  //     page_size: addedServiceExistState.currentPageSize,
  //     page: addedServiceExistState.currentPage,
  //     client_web_id: addedServiceExistState.client_web_id,
  //     partner_client_web_id: addedServiceExistState.partner_client_web_id,
  //   },
  //   { skip }
  // );

  // while adding service from user activity search , fetching user data is happening , so skip added while in activity
  const { data: profileData } = useGetProfileDataQuery(activeProfile, {
    skip: activity ? true : false,
  });

  let addServiceValidation = Yup.object({
    service_date: Yup.mixed(),
    follow_up_date: Yup.mixed().required("Follow up date required"),
    follow_up_time: Yup.mixed()
      .required("*Required")
      .test("null check", "Time cannot be empty", function (value) {
        if (value === "00:00 AM") {
          return false;
        }
        return true;
      }),
    service_type: Yup.string().required("*Required"),
    service_id: Yup.string().required("*Required"),
    service_remark: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      service_date: format(currentDate, "yyyy-MM-dd"),
      follow_up_date: null,
      follow_up_time: time,
      service_type: "2",
      service_id: null,
      client_web_id: search
        ? profile.client_web_id
        : profileData?.data?.profile_data?.id,
      partner_client_web_id: profile.partner_client_web_id,
      service_remark: "",
    },

    validationSchema: addServiceValidation,

    enableReinitialize: true,
    onSubmit: (values) => {
      let data = {
        ...values,
        follow_up_time: getCurrentTimeFormatted(values?.follow_up_time),
      };
      handleSubmit(data);
    },
  });

  const handleSubmit = async (data) => {
    const resultAction = await dispatch(submitAddService(data));
    if (submitAddService.fulfilled.match(resultAction)) {
      toast.success("Service Created Successfully");
      if (refetchRef.current) {
        refetchRef?.current();
      }
      dispatch(
        updateConfig((state) => {
          state.storedFormikValues = {};
        })
      );

      setTimeout(() => {
        handleReset();
      }, 100);
    } else if (resultAction.payload.errors) {
      Object.keys(resultAction.payload.errors).forEach((key) => {
        formik.setFieldError([key], resultAction.payload.errors[key][0]);
      });
    }
  };
  useEffect(() => {
    const dateInput1 = document.getElementById("service_date");
    const dateInput2 = document.getElementById("follow_up_date");

    if (dateInput1) {
      dateInput1.value = "";
    }
    if (dateInput2) {
      dateInput2.value = "";
    }

    dispatch(
      updateConfig((state) => {
        state.storedFormikValues = {};
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleReset = () => {
    formik.resetForm();

    const dateInput1 = document.getElementById("service_date");
    const dateInput2 = document.getElementById("follow_up_date");

    if (dateInput1) {
      dateInput1.value = "";
    }
    if (dateInput2) {
      dateInput2.value = "";
    }
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = false;
        state.partner_client_web_id = "";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.storedFormikValues = {};
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddedServiceModal = false;
      })
    );
  };

  const handleWebIdChange = (value) => {
    formik.setFieldValue("partner_client_web_id", value);

    dispatch(
      updateConfig((state) => {
        state.client_web_id = profileData?.data?.profile_data?.id;
        state.partner_client_web_id = value;
      })
    );
  };

  useEffect(() => {
    if (Object.keys(addedServiceExistState.storedFormikValues)?.length > 0) {
      formik.setValues(addedServiceExistState.storedFormikValues);
    }
    // eslint-disable-next-line
  }, [addedServiceExistState.storedFormikValues]);

  const handleOnBlur = (value, serviceId) => {
    if (value !== "" && serviceId) {
      getAddedServiceExistList({
        page_size: addedServiceExistState.currentPageSize,
        page: addedServiceExistState.currentPage,
        client_web_id:
          profileData?.data?.profile_data?.id ?? profile?.client_web_id,
        partner_client_web_id: value,
      }).then((response) => {
        if (response?.data?.data?.data?.length > 0) {
          dispatch(
            updateProfileConfig((state) => {
              state.showCreateModal = false;
            })
          );
        }

        dispatch(
          updateConfig((state) => {
            state.showAddedServiceModal =
              response?.data?.data?.data?.length > 0 ? true : false;
            state.storedFormikValues = {
              ...formik.values,
              service_id: serviceId,
              client_web_id: search
                ? profile.client_web_id
                : profileData?.data?.profile_data?.id,
            };
          })
        );
      });
    }
  };

  const handleTimeChange = (newTime) => {
    if (newTime !== null) {
      setTime(newTime);
      let [hour, minute] = newTime.split(":");
      let now = new Date();
      now.setHours(hour, minute);

      let time12 = now.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        hourCycle: "h12",
      });

      let ConvertedTime12 = time12
        .replace(/^(\d{1}):/, "0$1:")
        .replace(/\s/, " ");

      formik.setFieldValue("follow_up_time", ConvertedTime12);
    }
  };

  const handleClearClick = () => {
    setTime("00:00 AM");
    formik.setFieldValue("follow_up_time", "00:00 AM");
  };

  return {
    formik,
    refetchRef,
    time,
    addedServiceExistState,
    addServiceState,
    optionsData,
    isSuccess,
    handleClearClick,
    handleReset,
    handleTimeChange,
    closeModal,
    handleWebIdChange,
    handleOnBlur,
  };
};

export default useAddService;
