import { useMemo } from "react";

const useMemoValues = (
  formik,
  optionsData,
  isOptionDataSuccess,
  isPreferenceDataSuccess
) => {
  const getFilteredData = (dataKey = "", parentData, filterBy = "") => {
    if (typeof parentData === "number" || typeof parentData === "string") {
      return optionsData[dataKey].filter(
        (p) => parentData.toString() === p[filterBy].toString()
      );
    } else {
      return (
        optionsData[dataKey]?.filter?.((p) =>
          parentData?.includes(p[filterBy]?.toString())
        ) || []
      );
    }
  };

  const casteValue = useMemo(() => {
    if (
      isOptionDataSuccess &&
      isPreferenceDataSuccess &&
      formik.values.religion
    ) {
      return getFilteredData(
        "casteList",
        formik.values.religion,
        "religion_id"
      );
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [
    formik.values.religion,
    formik.values.caste,
    isOptionDataSuccess,
    isPreferenceDataSuccess,
  ]);

  // const educationValue = useMemo(() => {
  //   if (
  //     isOptionDataSuccess &&
  //     isPreferenceDataSuccess &&
  //     formik.values.parent_education.length > 0
  //   ) {
  //     return getFilteredData(
  //       "educationCategory",
  //       formik.values.parent_education,
  //       "parent_id"
  //     );
  //   } else {
  //     return [];
  //   }
  //   // eslint-disable-next-line
  // }, [
  //   formik.values.parent_education,
  //   formik.values.education,
  //   isOptionDataSuccess,
  //   isPreferenceDataSuccess,
  // ]);

  // const occupationValue = useMemo(() => {
  //   if (
  //     isOptionDataSuccess &&
  //     isPreferenceDataSuccess &&
  //     formik.values.parent_occupation.length > 0
  //   ) {
  //     return getFilteredData(
  //       "jobCategory",
  //       formik.values.parent_occupation,
  //       "parent_id"
  //     );
  //   } else {
  //     return [];
  //   }
  //   // eslint-disable-next-line
  // }, [
  //   formik.values.parent_occupation,
  //   formik.values.job,
  //   isOptionDataSuccess,
  //   isPreferenceDataSuccess,
  // ]);

  const stateValue = useMemo(() => {
    if (
      isOptionDataSuccess &&
      isPreferenceDataSuccess &&
      formik.values.religion
    ) {
      return getFilteredData("stateList", formik.values.country, "country_id");
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [
    formik.values.country,
    formik.values.state,
    isOptionDataSuccess,
    isPreferenceDataSuccess,
  ]);

  const districtValue = useMemo(() => {
    if (
      isOptionDataSuccess &&
      isPreferenceDataSuccess &&
      formik.values.religion
    ) {
      return getFilteredData("districtsList", formik.values.state, "state_id");
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [
    formik.values.state,
    formik.values.district,
    isOptionDataSuccess,
    isPreferenceDataSuccess,
  ]);

  return {
    casteValue,
    // educationValue,
    // occupationValue,
    stateValue,
    districtValue,
    getFilteredData,
  };
};

export default useMemoValues;
