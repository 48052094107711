import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "asc",
};

const deleteHistorySlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = deleteHistorySlice.actions;

export default deleteHistorySlice.reducer;
