import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetReportFilterOptionsDataQuery } from "../../../../store/queries/Report";
import { updateConfig } from "../../../../store/slices/Dashboard/dashboardSlice";
import { updateConfig as updateOrderConfig } from "../../../../store/slices/OrderColumns/orderColumnSlice";
import { getFormatedDate } from "../../../../utils/functions/table";
import { RegisterIdRow } from "./Rows";
import {
  useGetDashboardPendingCallsListDataQuery,
  useUpdateDashboardTableFieldsMutation,
} from "../../../../store/queries/Dashboard";

const usePendingCalls = ({ activeProfile, params }) => {
  const dashboardState = useSelector((state) => state.dashboard);

  const [jobId, setJobId] = useState();

  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: queryData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetDashboardPendingCallsListDataQuery({
    per_page: dashboardState.currentPageSize,
    page: dashboardState.currentPage,
    branch: dashboardState.currentBranchFilter,
    staff: dashboardState.currentStaffFilter,
  });

  const obj = queryData?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const hasReAssignJobPermission = useMemo(() => {
    let permission = queryData?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_reassignjob")
    )[0].can_reassignjob;

    return permission;
    // eslint-disable-next-line
  }, [queryData]);

  const [updatePendingCallsFields] = useUpdateDashboardTableFieldsMutation();

  const { data: optionsData = {} } = useGetReportFilterOptionsDataQuery({});

  const dispatch = useDispatch();

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (dashboardState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = dashboardState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditFieldsModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      register_id: (_, data) => (
        <RegisterIdRow
          data={data}
          activeProfile={activeProfile}
          params={params}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleStaffFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentStaffFilter = value.map((v) => v.id?.toString?.());
      })
    );
  };

  const handleBranchFilter = (id) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = id;
        state.currentStaffFilter = [];
      })
    );
  };

  const handleTableEditClick = (item) => {
    setJobId(item[0]);
    dispatch(
      updateConfig((state) => {
        state.showJobAssign = true;
      })
    );
  };

  const handleClearFilters = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = null;
        state.currentStaffFilter = null;
        state.dateRange = [];
      })
    );
  };

  return {
    queryData,
    dashboardState,
    currentPage: dashboardState.currentPage,
    showTableFieldsModal,
    paginationOptions,
    isFetching,
    isLoading,
    optionsData,
    jobId,
    sortedTableFields,
    hasReAssignJobPermission,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleDateChange,
    handleEditFieldsClick,
    getRow,
    updatePendingCallsFields,
    handleStaffFilter,
    handleBranchFilter,
    closeEditFieldsModal,
    handleTableEditClick,
    handleClearFilters,
  };
};

export default usePendingCalls;
