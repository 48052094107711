import InputShimmer from "../../../Global/Shimmers/InputShimmer";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";
import useServiceSearchForm from "./useServiceSearchForm";

const ServiceSearchForm = ({
  formik,
  handleSeviceType,
  serviceRef,
  staffRef,
  handleBranchChange,
}) => {
  const { optionsData, isSuccess, loggedData } = useServiceSearchForm();
  return isSuccess ? (
    <div className="pro-my-5">
      <div className="row">
        {/*
         * Service Type
         * Service
         *  Branch
         * Staff Type
         * Staff
         * Religion
         * Caste
     
         */}
        <RowOne
          formik={formik}
          optionsData={optionsData.data}
          serviceRef={serviceRef}
          handleSeviceType={handleSeviceType}
          staffRef={staffRef}
          handleBranchChange={handleBranchChange}
          loggedData={loggedData}
        />
        {/**
         * Service Created From
         * Service Created To
         * Service Follow From
         * Service Follow To
         *  Service Dated On From
         *  Service Dated On To
         *  From Date
         *   To Date
         */}
        <RowTwo formik={formik} optionsData={optionsData.data} />
      </div>
    </div>
  ) : (
    <div className={`row pro-my-5`}>
      {[...Array(28)].map((_, i) => (
        <div className={`col-3`} key={i}>
          <InputShimmer />
        </div>
      ))}
    </div>
  );
};

export default ServiceSearchForm;
