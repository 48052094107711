import React, { useState } from "react";

import Style from "./multicoloumntablecall.module.scss";
import PreLoader from "../../PreLoader";

export const MultiColumnTableCall = ({
  extraClassNames,
  title,
  editIcon,
  showreadmore,
  sliceLength,
  handleEdit,
  data,
  minWidth,
  handleInitiateCall,
  outgoingCallStatus,
  loadingLabel,
  ActivityProfile,
}) => {
  const [readmore, setReadmore] = useState(() => (showreadmore ? false : true));

  const handleReadmore = () => {
    setReadmore((state) => !state);
  };
  return (
    <div className={`${Style.root} ${extraClassNames}`}>
      <div className={`${Style.root_header} pro-pb-4`}>
        {title && <h6 className="pro-ttl h6 pro-mb-0">{title}</h6>}
        {editIcon && (
          <button
            onClick={handleEdit}
            className={`${Style.root_Icon} pro-btn pro-p-2 border-rounded`}
          >
            {editIcon}
          </button>
        )}
      </div>

      <div className={`${Style.root_body} pro-pb-0`}>
        <table className="pro-table no-border">
          <tbody>
            {data?.map?.((item, index) => (
              <tr key={index}>
                <td width={minWidth || `auto`} colSpan={item?.value ? 1 : 2}>
                  <p className={`pro-mb-0 ${item?.bold ? "pro-fw-bold " : ""}`}>
                    {item?.label}
                  </p>
                </td>
                {item?.value && !item?.is_tel && !item?.is_agent ? (
                  <>
                    <td>
                      <p
                        className={`pro-font-sm pro-mb-0 ${
                          item?.bold ? "pro-fw-bolder" : ""
                        }`}
                      >
                        {showreadmore &&
                        (item.value?.length > sliceLength ||
                          item.value?.length > 100) ? (
                          <>
                            {readmore ? (
                              <>
                                {item?.value}
                                <span
                                  onClick={handleReadmore}
                                  className={`pro-btn-link pro-fw-medium pro-mt-2 ${Style.btn_read_more}`}
                                >
                                  read less
                                </span>
                              </>
                            ) : (
                              <>
                                {item?.value?.slice(0, sliceLength ?? 100)}
                                <span
                                  onClick={handleReadmore}
                                  className={`pro-btn-link pro-fw-medium pro-mt-2 ${Style.btn_read_more}`}
                                >
                                  read more
                                </span>
                              </>
                            )}
                          </>
                        ) : (
                          <>{item?.value}</>
                        )}
                      </p>
                    </td>
                  </>
                ) : item?.value && item?.is_tel && !item?.is_agent ? (
                  <td>
                    <a href={`tel:${item?.mobile}`} className="pro-btn-link">
                      {item?.value}
                    </a>
                  </td>
                ) : (
                  <td>
                    <>
                      <p
                        onClick={() =>
                          !outgoingCallStatus && handleInitiateCall(item)
                        }
                        href={`${item?.mobile}`}
                        className={`pro-btn-link ${Style.p_call_number} 
                        }`}
                      >
                        {loadingLabel === item?.label ? (
                          outgoingCallStatus ? (
                            <PreLoader />
                          ) : (
                            item?.value
                          )
                        ) : (
                          item?.value
                        )}
                      </p>
                    </>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
