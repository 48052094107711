import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";

import { saveUserTab, updateProfileConfig } from "../../store/slices/Profile";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useGetProfileDataQuery, useGetServiceTypeQuery } from "../../store/queries/Profile";
import { updateConfig as updateAddedServiceConfig } from "../../store/slices/Profile/AddedServiceExist";


const useProfile = () => {
  const [params] = useSearchParams();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { checkIfActiveRoute } = useRouteUtils();
  const profile = useSelector((state) => state.profile);
  const addedServiceExistState = useSelector(
    (state) => state.addedServiceExist
  );

  const { data: optionsData, isSuccess:isSuccessServiceType } = useGetServiceTypeQuery()

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const { data: profileData, isSuccess } =
    useGetProfileDataQuery(activeProfile);

  useEffect(() => {
    return () => {
      dispatch(saveUserTab({ id: activeProfile, path: location.pathname }));
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (profile?.prevTab?.[activeProfile]) {
      navigate(`${profile?.prevTab?.[activeProfile]}${window.location.search}`);
    }
    // eslint-disable-next-line
  }, [activeProfile]);

  const navigations = [
    {
      label: "Overview",
      link: `/profile${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile", true),
    },
    {
      label: "Auto Match",
      link: `/profile/auto-match${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/auto-match"),
    },
    {
      label: "Auto Star Match",
      link: `/profile/auto-star-match${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/auto-star-match"),
    },
    {
      label: "Partner Match",
      link: `/profile/partner-match${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/partner-match"),
    },
    {
      label: "Partner Star ",
      link: `/profile/partner-star-match${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/partner-star-match"),
    },
    {
      label: "Add service",
      link: `/profile/add-service${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/add-service"),
    },
    {
      label: "View service",
      link: `/profile/view-service${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/view-service"),
    },
    {
      label: "Schedule",
      link: `/profile/scheduled-history${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/scheduled-history"),
    },
    {
      label: "Activity",
      link: `/profile/activity${window.location.search}`,
      isButton: false,
      active: checkIfActiveRoute("/profile/activity"),
    },
  ];

  const closeAddServiceModal = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showCreateModal = false;
        state.partner_client_web_id = "";
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateAddedServiceConfig((state) => {
        state.showAddedServiceModal = false;
      })
    );
   
  };

  const finalNavigations = useMemo(() => {
    if (profileData?.data?.profile_data?.is_hindhu) {
      return navigations;
    } else {
      return navigations.filter((n) => !n.label.includes("Star"));
    }
    // eslint-disable-next-line
  }, [isSuccess, location.pathname, profileData]);

  return {
    navigations: finalNavigations,
    profile,
    addedServiceExistState,
    closeModal,
    closeAddServiceModal,
    optionsData,
    isSuccessServiceType
  };
};

export default useProfile;
