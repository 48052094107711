import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetReportFilterOptionsDataQuery,
  useGetReportListDataQuery,
  useUpdateReportListFieldsMutation,
} from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/reportSlice";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { RegisterIdRow } from "./Rows";

const useReport = () => {
  const reportState = useSelector((state) => state.report);
  const [jobId, setJobId] = useState();

  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: report = {},
    isFetching,
    isLoading,
    refetch
  } = useGetReportListDataQuery({
    per_page: reportState.currentPageSize,
    page: reportState.currentPage,
    branch: reportState.currentBranchFilter,
    staff: reportState.currentStaffFilter,
    date: reportState.dateRange,
    sort_by: reportState.sortBy,
    sort: reportState.sortOrder,
  });

  const obj = report?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});



  const hasReAssignJobPermission = useMemo(() => {
    let permission = report?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_reassignjob")
    )[0].can_reassignjob;

    return permission;
    // eslint-disable-next-line
  }, [report]);

  const [updateReportFields] = useUpdateReportListFieldsMutation();

  const { data: optionsData = {} } = useGetReportFilterOptionsDataQuery({});

  const dispatch = useDispatch();

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditFieldsModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      register_id: (_, data) => <RegisterIdRow data={data} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleStaffFilter = (id) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentStaffFilter = id;
      })
    );
  };

  const handleBranchFilter = (id) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = id;
        state.currentStaffFilter = ""
      })
    );
  };

  const handleTableEditClick = (item) => {
    setJobId(item);
    dispatch(
      updateConfig((state) => {
        state.showJobAssign = true;
      })
    );
  };

  const handleCloseOffCanvas = () => {
    dispatch(
      updateConfig((state) => {
        state.showJobAssign = false;
      })
    );
  };

  const handleClearFilters = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = null;
        state.currentStaffFilter = null;
        state.dateRange = [];
      })
    );
  };

  return {
    report,
    reportState,
    currentPage: reportState.currentPage,
    showTableFieldsModal,
    paginationOptions,
    isFetching,
    isLoading,
    optionsData,
    jobId,
    sortedTableFields,
    hasReAssignJobPermission,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleDateChange,
    handleEditFieldsClick,
    getRow,
    updateReportFields,
    handleStaffFilter,
    handleBranchFilter,
    closeEditFieldsModal,
    handleTableEditClick,
    handleCloseOffCanvas,
    handleClearFilters,
  };
};

export default useReport;
