import React from "react";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";

const BasicInfo = ({
  formik,
  optionsData,
  currentFormDataBasic,
  onChangeMob2Response,
  onChangeMobResponse,
  onChangeRegisterIdResponse,
  onChangeEmailResponse,
  onChangeWhastappResponse,
  handleSelectReligion,
  handleRegisterNumberBlur,
  handleMobileBlur,
  handleMobile2Blur,
  handleWhatsappNoBlur,
  handleProfileRedirect,
  handleEmailBlur,
  casteRef,
  handleDobChange,
  handlePaymentDateChange,
  handleAgeChange,
  countryData,
  handleClearTimeValues,
}) => {
  return (
    <div className={`row`} id="basic-info">
      {/**
       * Registration Number
       * Package
       * Profile Visibility Score
       * Branch
       * Full Name
       * Email
       * Mobile
       * Mobile 2
       */}
      <RowOne
        formik={formik}
        optionsData={optionsData}
        handleRegisterNumberBlur={handleRegisterNumberBlur}
        handleMobileBlur={handleMobileBlur}
        handleMobile2Blur={handleMobile2Blur}
        handleEmailBlur={handleEmailBlur}
        onChangeRegisterIdResponse={onChangeRegisterIdResponse}
        onChangeEmailResponse={onChangeEmailResponse}
        onChangeMob2Response={onChangeMob2Response}
        onChangeMobResponse={onChangeMobResponse}
        handleProfileRedirect={handleProfileRedirect}
        countryData={countryData}
      />

      {/**
       * Whatsapp No
       * Gender
       * Date of Birth
       * Age
       * Marital Status
       * No of childrens
       * Religion
       * Caste
       * Sub Caste
       * Profile Created For
       */}
      <RowTwo
        formik={formik}
        onChangeWhastappResponse={onChangeWhastappResponse}
        handleProfileRedirect={handleProfileRedirect}
        optionsData={optionsData}
        currentFormDataBasic={currentFormDataBasic}
        handleSelectReligion={handleSelectReligion}
        casteRef={casteRef}
        handleAgeChange={handleAgeChange}
        handleDobChange={handleDobChange}
        handlePaymentDateChange={handlePaymentDateChange}
        handleWhatsappNoBlur={handleWhatsappNoBlur}
        countryData={countryData}
        handleClearTimeValues={handleClearTimeValues}
      />
    </div>
  );
};

export default BasicInfo;
