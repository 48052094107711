import React from "react";
import Select from "react-select";

const RowOne = ({ formik, optionsData }) => {
  return (
    <>
      <div className="col-12">
        <div className="row row-cols-5">
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="looking_for"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Looking For
              </label>
              <Select
                id="looking_for"
                isClearable={true}
                placeholder={"Select"}
                options={optionsData.lookingForList}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                value={optionsData.lookingForList?.filter(
                  (m) =>
                    formik.values.looking_for?.toString?.() ===
                    m.id?.toString?.()
                )}
                onChange={(value) =>
                  formik.setFieldValue("looking_for", value?.id || null)
                }
                className={"pro-input lg"}
                classNamePrefix="pro-input"
              />
            </div>
          </div>

          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="age_from"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Age from
              </label>
              <Select
                id="age_from"
                isClearable={true}
                placeholder={"Select"}
                options={optionsData.ageList}
                getOptionValue={(option) => option.age}
                getOptionLabel={(option) => option.age}
                value={optionsData.ageList?.filter(
                  (m) =>
                    formik.values.age_from?.toString?.() === m.age?.toString?.()
                )}
                onChange={(value) =>
                  formik.setFieldValue("age_from", value?.age || null)
                }
                className={"pro-input lg"}
                classNamePrefix="pro-input"
              />
            </div>
          </div>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="age_to"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Age To
              </label>
              <Select
                id="age_to"
                placeholder={"Select"}
                isClearable={true}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                options={optionsData.ageList?.filter(
                  (p) => p?.age > formik.values.age_from
                )}
                getOptionValue={(option) => option.age}
                getOptionLabel={(option) => option.age}
                value={optionsData.ageList?.filter(
                  (m) =>
                    formik.values.age_to?.toString?.() === m.age?.toString?.()
                )}
                onChange={(value) =>
                  formik.setFieldValue("age_to", value?.age || null)
                }
              />
            </div>
          </div>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="height_from"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Height from
              </label>
              <Select
                id="height_from"
                placeholder={"Select"}
                isClearable={true}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                options={optionsData.heightList}
                getOptionValue={(option) => option.height_value}
                getOptionLabel={(option) => option.height}
                value={optionsData.heightList?.filter(
                  (m) =>
                    formik.values.height_from?.toString?.() ===
                    m.height_value?.toString?.()
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "height_from",
                    value?.height_value || null
                  )
                }
              />
            </div>
          </div>
          <div>
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="height_to"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Height To
              </label>
              <Select
                id="height_to"
                placeholder={"Select"}
                isClearable={true}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                options={optionsData.heightList?.filter(
                  (p) => p?.height_value > formik.values.height_from
                )}
                getOptionValue={(option) => option.height_value}
                getOptionLabel={(option) => option.height}
                value={optionsData.heightList?.filter(
                  (m) =>
                    formik.values.height_to?.toString?.() ===
                    m.height_value?.toString?.()
                )}
                onChange={(value) =>
                  formik.setFieldValue("height_to", value?.height_value || null)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-2 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="religion"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Religion
          </label>
          <Select
            id="religion"
            isClearable={true}
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.religionList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.religion_name}
            value={optionsData.religionList?.filter(
              (m) => formik.values.religion?.toString?.() === m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("religion", value?.id || null);
              formik.setFieldValue("caste", []);
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="caste" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Caste
          </label>
          <Select
            id="caste"
            isMulti
            placeholder={"Select"}
            isDisabled={formik.values.religion ? false : true}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.casteList?.filter?.(
              (c) =>
                c.religion_id?.toString?.() ===
                formik.values.religion?.toString?.()
            )}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.caste_name}
            value={optionsData.casteList?.filter((m) =>
              formik.values.caste.includes(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "caste",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="branch"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Branch
          </label>
          <Select
            id="branch"
            isMulti
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.branchList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.branch_name}
            value={optionsData.branchList?.filter((m) =>
              formik.values.branch?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "branch",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>

      <div className="col-lg-2 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="registered_platform"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Registered Platform
          </label>
          <Select
            id="registered_platform"
            isClearable={true}
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData?.branchCodeList}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.branch_code}
            value={optionsData.branchCodeList?.filter(
              (m) =>
                formik.values.registered_platform?.toString?.() ===
                m.id?.toString?.()
            )}
            onChange={(value) => {
              formik.setFieldValue("registered_platform", value?.id || null);
            }}
          />
        </div>
      </div>

      <div className="col-lg-4 col-xl-3">
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="marital_status"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Marital Status
          </label>
          <Select
            id="marital_status"
            isMulti
            placeholder={"Select"}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
            options={optionsData.maritalStatusList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.marital_status}
            value={optionsData.maritalStatusList?.filter((m) =>
              formik.values.marital_status?.includes?.(m.id?.toString?.())
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "marital_status",
                value.map((v) => v.id?.toString?.())
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default RowOne;
