import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentFormDataBasic: {},
  currentFormDataFamily: {},
  currentFormDataImage: {},
  currentFormDataHoroscope: {},
  currentFormDataPartner: {},
  currentFormDataReligious: {},
  activeTab: "Basic",
  webId: "",
  completedTabs: {
    Basic: false,
    Family: false,
    Gallery: false,
    Horoscope: false,
    Partner: false,
    Religious: false,
  },
  CompletedPercentage: {
    Basic: 0,
    Family: 0,
    Gallery: 0,
    Horoscope: 0,
    Partner: 0,
    Religious: 0,
  },
};

export const getUserDetails = createAsyncThunk(
  "createProfile/getUserDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`profile/${params.endpoint}/${params.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createProfileSlice = createSlice({
  name: "createProfile",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      let formName = action.meta.arg.endpoint.split("-")[1];
      let capitalizedFormName =
        formName.charAt(0).toUpperCase() + formName.slice(1);

      state[`currentFormData${capitalizedFormName}`] = action.payload.data.data;
      if (!!action.payload?.data?.data?.web_id) {
        state.webId = action.payload.data.data.web_id;
      }
    });
  },
});

export const { updateFormData } = createProfileSlice.actions;

export default createProfileSlice.reducer;
