import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import BasicPreference from "./BasicPreference";
import EducationalPreference from "./EducationalPreference";
import JobLocationPreference from "./JobLocationPreference";
import LocationPreference from "./LocationPreference";
import OtherPreference from "./OtherPreference";
import usePartner from "./usePartner";

const Partner = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const {
    formik,
    errors,
    isUpdating,
    casteRef,
    handleSelectReligion,
    handleSelectCountry,
    handleSelectState,
    handleSelectDistrict,
    stateRef,
    districtRef,
    locationRef,
    setSaveAndNext,
    handleSelectJobCountry,
    handleSelectJobDistrict,
    handleSelectJobState,
    stateJobRef,
    districtJobRef,
    locationJobRef,
  } = usePartner({
    optionsData,
    isOptionDataSuccess,
    activeUser,
    edit,
    toTab,
  });

  return (
    <div className={`pro-p-0 pro-w-100`} id="partner-preference">
      <form action="" onSubmit={formik.handleSubmit}>
        {/**
         * Religion
         * Caste
         * Sub Caste
         * Marital Status
         * Age From
         * Age To
         * Height From
         * Height To
         * Complexion
         * Body Type
         * Special Case
         */}
        <BasicPreference
          formik={formik}
          optionsData={optionsData}
          casteRef={casteRef}
          handleSelectReligion={handleSelectReligion}
        />

        {/**
         * Country
         * State
         * District
         * Panchayat / Municipality
         */}
        <LocationPreference
          formik={formik}
          optionsData={optionsData}
          handleSelectCountry={handleSelectCountry}
          handleSelectDistrict={handleSelectDistrict}
          handleSelectState={handleSelectState}
          stateRef={stateRef}
          districtRef={districtRef}
          locationRef={locationRef}
        />

        {/**
         * Education Main Category
         * Education Sub Category
         * Education In Detail
         * Job Main Category
         * Job Sub Category
         * Job In Detail
         * Job Location
         */}
        <EducationalPreference formik={formik} optionsData={optionsData} />

        {/**
         * Country
         * State
         * District
         * Panchayat / Municipality
         */}
        <JobLocationPreference
          formik={formik}
          optionsData={optionsData}
          handleSelectJobCountry={handleSelectJobCountry}
          handleSelectJobDistrict={handleSelectJobDistrict}
          handleSelectJobState={handleSelectJobState}
          stateJobRef={stateJobRef}
          districtJobRef={districtJobRef}
          locationJobRef={locationJobRef}
        />
        {/**
         * Social / Religious Expectations
         * Financial Expectation
         * Jathakam Types
         * House Square Feet Between
         * Matching Stars
         */}
        <OtherPreference formik={formik} optionsData={optionsData} />

        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
          {toTab && (
            <Button
              disabled={isUpdating}
              className={`pro-btn-primary lg pro-mx-3 ${isUpdating ? "loading" : ""}`}
              onClick={() => setSaveAndNext(true)}
              type="submit"
            >
              {isUpdating ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {toTab && "Save & Next"}
            </Button>
          )}

          <Button
            disabled={isUpdating}
            className={`pro-btn-primary lg pro-mx-3 ${isUpdating ? "loading" : ""}`}
            type="submit"
          >
            {isUpdating ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            {toTab ? "Save" : "Save & Next"}
          </Button>
        </div>

        {errors && <span className="error-message">{errors}</span>}
      </form>
    </div>
  );
};

export default Partner;
