import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";

const EducationalPreference = ({ formik, optionsData }) => {
  return (
    <div className="row" id="educational-Preference">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">
          Educational Professional Prefernce
        </h6>
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Education Main Category
          </label>
          <Select
            id="prefer_educational_main_category"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.parentEducationCategory}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.parent_category}
            value={optionsData?.data?.parentEducationCategory?.filter?.((c) =>
              formik.values.prefer_educational_main_category?.includes?.(
                c.id?.toString()
              )
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "prefer_educational_main_category",
                value.map((edu) => edu.id?.toString())
              )
            }
            onBlur={formik.handleBlur("prefer_educational_main_category")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_educational_main_category &&
              formik.errors.prefer_educational_main_category &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_educational_main_category &&
            formik.errors.prefer_educational_main_category && (
              <span className="error-text">
                {formik.errors.prefer_educational_main_category}
              </span>
            )}
        </div>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Education Sub Category
          </label>
          <Select
            id="prefer_educational_category"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.educationCategory?.filter((p) =>
              formik.values.prefer_educational_main_category?.includes?.(
                p.parent_id?.toString()
              )
            )}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.category_title}
            value={optionsData?.data?.educationCategory?.filter?.((c) =>
              formik.values.prefer_educational_category?.includes?.(c.id?.toString())
            )}
            onChange={(value) => {
              formik.setFieldValue(
                "prefer_educational_category",
                value.map((c) => c.id?.toString())
              );
            }}
            onBlur={formik.handleBlur("prefer_educational_category")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_educational_category &&
              formik.errors.prefer_educational_category &&
              " error"
            } ${!formik.values.prefer_educational_main_category && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_educational_category &&
            formik.errors.prefer_educational_category && (
              <span className="error-text">
                {formik.errors.prefer_educational_category}
              </span>
            )}
        </div>
      </div>

      <div className={`col-4`}>
        <Input
          label={"Education In Detail"}
          type="text"
          id="prefer_education_in_detail"
          name="prefer_education_in_detail"
          className={`pro-input lg ${
            formik.touched.prefer_education_in_detail &&
            formik.errors.prefer_education_in_detail &&
            " error"
          }`}
          {...formik.getFieldProps("prefer_education_in_detail")}
          error={
            formik.touched.prefer_education_in_detail &&
            formik.errors.prefer_education_in_detail
          }
          errorMessage={formik.errors.prefer_education_in_detail}
        />
      </div>

      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Job Main Category
          </label>
          <Select
            id="prefer_job_main_category"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.parentJobCategory}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.parent_job_category}
            value={optionsData?.data?.parentJobCategory?.filter?.((c) =>
              formik.values.prefer_job_main_category?.includes?.(
                c.id?.toString()
              )
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "prefer_job_main_category",
                value.map((job) => job.id?.toString())
              )
            }
            onBlur={formik.handleBlur("prefer_job_main_category")}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_job_main_category &&
              formik.errors.prefer_job_main_category &&
              " error"
            }`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_job_main_category &&
            formik.errors.prefer_job_main_category && (
              <span className="error-text">
                {formik.errors.prefer_job_main_category}
              </span>
            )}
        </div>
      </div>
      <div className="col-4">
        <div className={"input-wrap pro-mb-5"}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Job Sub Category
          </label>
          <Select
            id="prefer_job_category"
            isMulti
            placeholder={"Select"}
            options={optionsData?.data?.jobCategory?.filter((p) =>
              formik.values.prefer_job_main_category?.includes?.(
                p.parent_id?.toString()
              )
            )}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.job_title}
            value={optionsData?.data?.jobCategory?.filter?.((c) =>
              formik.values.prefer_job_category?.includes?.(c.id?.toString())
            )}
            onBlur={formik.handleBlur("prefer_job_category")}
            onChange={(value) => {
              formik.setFieldValue(
                "prefer_job_category",
                value.map((c) => c?.id?.toString())
              );
            }}
            className={`pro-input lg multi-select ${
              formik.touched.prefer_job_category &&
              formik.errors.prefer_job_category &&
              " error"
            } ${!formik.values.prefer_job_main_category && "disabled"}`}
            classNamePrefix="pro-input"
          />
          {formik.touched.prefer_job_category &&
            formik.errors.prefer_job_category && (
              <span className="error-text">
                {formik.errors.prefer_job_category}
              </span>
            )}
        </div>
      </div>

      <div className={`col-4`}>
        <Input
          label={"Job In Detail"}
          type="text"
          id="prefer_job_in_detail"
          name="prefer_job_in_detail"
          className={`pro-input lg ${
            formik.touched.prefer_job_in_detail &&
            formik.errors.prefer_job_in_detail &&
            " error"
          }`}
          {...formik.getFieldProps("prefer_job_in_detail")}
          error={
            formik.touched.prefer_job_in_detail &&
            formik.errors.prefer_job_in_detail
          }
          errorMessage={formik.errors.prefer_job_in_detail}
        />
      </div>

      <div className={`col-4`}>
        <Input
          label={"Job Location"}
          type="text"
          id="prefer_job_location_details"
          name="prefer_job_location_details"
          className={`pro-input lg ${
            formik.touched.prefer_job_location_details &&
            formik.errors.prefer_job_location_details &&
            " error"
          }`}
          {...formik.getFieldProps("prefer_job_location_details")}
          error={
            formik.touched.prefer_job_location_details &&
            formik.errors.prefer_job_location_details
          }
          errorMessage={formik.errors.prefer_job_location_details}
        />
      </div>
    </div>
  );
};

export default EducationalPreference;
