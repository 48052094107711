import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as updateOrderConfig } from "../../store/slices/OrderColumns/orderColumnSlice";
import { updateConfig } from "../../store/slices/Service/ServiceNotification";
import {
  useUpdateServiceNotificationListFieldsMutation,
  useGetServiceNotificationListDataQuery,
} from "../../store/queries/Service";
import { UserImage } from "./Row";

const useServiceNotification = () => {
  const dispatch = useDispatch();

  const serviceNotificationState = useSelector(
    (state) => state.serviceNotification
  );

  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const [updateServiceNotificationFields] =
    useUpdateServiceNotificationListFieldsMutation();

  const {
    data: serviceNotification = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetServiceNotificationListDataQuery({
    page_size: serviceNotificationState.currentPageSize,
    page: serviceNotificationState.currentPage,
    sort_by: serviceNotificationState.sortBy,
    sort_order: serviceNotificationState.sortOrder,
  });

  const obj = serviceNotification?.data?.fields || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const hasCreatePermission = useMemo(() => {
    let permission = serviceNotification?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_create")
    )[0].can_create;

    return permission;
    // eslint-disable-next-line
  }, [serviceNotification]);

  const hasEditPermission = useMemo(() => {
    let permission = serviceNotification?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_edit")
    )[0].can_edit;

    return permission;
    // eslint-disable-next-line
  }, [serviceNotification]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (serviceNotificationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            serviceNotificationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      image: (_, data) => <UserImage image={data?.Image?.user_image_thumbnail} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    serviceNotification,
    serviceNotificationState,
    currentPage: serviceNotificationState.currentPage,
    hasCreatePermission,
    hasEditPermission,
    paginationOptions,
    isFetching,
    isLoading,
    showTableFieldsModal,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    updateServiceNotificationFields,
  };
};

export default useServiceNotification;
