import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const ProffesionalInfo = ({
  formik,
  optionsData,
  edit,
  handleSelectEducation,
  educationRef,
  handleSelectJob,
  jobRef,
  handleSelectJobCountry,
  stateRef,
  handleSelectJobState,
  districtRef,
  handleCheckbox,
  handleLastBoostedDate
}) => {
  let newDate = new Date();
  newDate.setFullYear(newDate.getFullYear());

  return (
    <div className={`row`} id="professional-info">
      <div className="input-wrap pro-mb-5">
        <h6 className="pro-font-sm pro-mb-1 pro-fw-medium">
          Professional Info
        </h6>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Education Main Category*
          </label>
          <Select
            id="education_main_category"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.parentEducationCategory}
            getOptionLabel={(option) => `${option.parent_category}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input  lg multi-select ${formik.touched.education_main_category &&
              formik.errors.education_main_category &&
              " error"
              }`}
            value={optionsData?.data?.parentEducationCategory?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.education_main_category?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("education_main_category")}
            onChange={(value) => handleSelectEducation(value)}
          ></Select>
          {formik.touched.education_main_category &&
            formik.errors.education_main_category && (
              <span className="error-text">
                {formik.errors.education_main_category}
              </span>
            )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Education Sub Category*
          </label>
          <Select
            id="educational_category_id"
            isClearable={true}
            ref={educationRef}
            placeholder={"Select"}
            options={optionsData?.data?.educationCategory?.filter(
              (p) =>
                p.parent_id?.toString() ===
                formik.values.education_main_category?.toString()
            )}
            getOptionLabel={(option) => `${option.category_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${formik.values.education_main_category &&
              formik.touched.educational_category_id &&
              formik.errors.educational_category_id &&
              " error"
              } ${!formik.values.education_main_category && "disabled"}`}
            value={optionsData?.data?.educationCategory?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.educational_category_id?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("educational_category_id")}
            onChange={(value) =>
              formik.setFieldValue("educational_category_id", value?.id || null)
            }
          ></Select>
          {formik.values.education_main_category &&
            formik.touched.educational_category_id &&
            formik.errors.educational_category_id && (
              <span className="error-text">
                {formik.errors.educational_category_id}
              </span>
            )}
        </div>
      </div>

      <div className={`col-6`}>
        <Input
          label={"Education In Detail"}
          type="text"
          id="education_info"
          name="education_info"
          className={`pro-input lg ${formik.touched.education_info &&
            formik.errors.education_info &&
            " error"
            }`}
          {...formik.getFieldProps("education_info")}
          error={formik.touched.education_info && formik.errors.education_info}
          errorMessage={formik.errors.education_info}
        />
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Job Main Category*
          </label>
          <Select
            id="job_main_category"
            placeholder={"Select"}
            options={optionsData?.data?.parentJobCategory}
            getOptionLabel={(option) => `${option.parent_job_category}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${formik.touched.job_main_category &&
              formik.errors.job_main_category &&
              " error"
              }`}
            value={optionsData?.data?.parentJobCategory?.filter(
              (p) =>
                p.id?.toString() === formik.values.job_main_category?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("job_main_category")}
            onChange={(value) => handleSelectJob(value)}
          ></Select>
          {formik.touched.job_main_category &&
            formik.errors.job_main_category && (
              <span className="error-text">
                {formik.errors.job_main_category}
              </span>
            )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Job Sub Category*
          </label>
          <Select
            id="job_category_id"
            isClearable={true}
            placeholder={"Select"}
            ref={jobRef}
            options={optionsData?.data?.jobCategory?.filter(
              (p) =>
                p.parent_id?.toString() ===
                formik.values.job_main_category?.toString()
            )}
            getOptionLabel={(option) => `${option.job_title}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${formik.values.job_main_category &&
              formik.touched.job_category_id &&
              formik.errors.job_category_id &&
              " error"
              } ${!formik.values.job_main_category && "disabled"}`}
            value={optionsData?.data?.jobCategory?.filter(
              (p) =>
                p.id?.toString() === formik.values.job_category_id?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("job_category_id")}
            onChange={(value) =>
              formik.setFieldValue("job_category_id", value?.id || null)
            }
          ></Select>
          {formik.values.job_main_category &&
            formik.touched.job_category_id &&
            formik.errors.job_category_id && (
              <span className="error-text">
                {formik.errors.job_category_id}
              </span>
            )}
        </div>
      </div>

      <div className={`col-6`}>
        <Input
          label={"Job In Detail"}
          type="text"
          id="job_info"
          name="job_info"
          className={`pro-input lg ${formik.touched.job_info && formik.errors.job_info && " error"
            }`}
          {...formik.getFieldProps("job_info")}
          error={formik.touched.job_info && formik.errors.job_info}
          errorMessage={formik.errors.job_info}
        />
      </div>



      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Job Location - Country
          </label>
          <Select
            id="job_country"
            isClearable={true}
            placeholder={"Select"}
            options={optionsData?.data?.countryList}
            getOptionLabel={(option) => `${option.country_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${formik.touched.job_country &&
              formik.errors.job_country &&
              " error"
              }`}
            value={optionsData?.data?.countryList?.filter(
              (p) => p.id?.toString() === formik.values.job_country?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("job_country")}
            onChange={(value) => handleSelectJobCountry(value)}
          ></Select>
          {formik.touched.job_country && formik.errors.job_country && (
            <span className="error-text">{formik.errors.job_country}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Job Location - State
          </label>
          <Select
            id="job_state"
            isClearable={true}
            placeholder={"Select"}
            ref={stateRef}
            options={optionsData?.data?.stateList?.filter(
              (p) =>
                p.country_id?.toString() ===
                formik.values.job_country?.toString()
            )}
            getOptionLabel={(option) => `${option.state_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${formik.touched.job_state && formik.errors.job_state && " error"
              } ${!formik.values.job_country && "disabled"}`}
            value={optionsData?.data?.stateList?.filter(
              (p) => p.id?.toString() === formik.values.job_state?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("job_state")}
            onChange={(value) => handleSelectJobState(value)}
          ></Select>
          {formik.touched.job_state && formik.errors.job_state && (
            <span className="error-text">{formik.errors.job_state}</span>
          )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Job Location - District
          </label>
          <Select
            id="job_district"
            isClearable={true}
            placeholder={"Select"}
            ref={districtRef}
            options={optionsData?.data?.districtsList?.filter(
              (p) =>
                p.state_id?.toString() === formik.values.job_state?.toString()
            )}
            getOptionLabel={(option) => `${option.district_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${formik.touched.job_district &&
              formik.errors.job_district &&
              " error"
              } ${!formik.values.job_state && "disabled"}`}
            value={optionsData?.data?.districtsList?.filter(
              (p) => p.id?.toString() === formik.values.job_district?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("job_district")}
            onChange={(value) =>
              formik.setFieldValue("job_district", value?.id || null)
            }
          ></Select>
          {formik.touched.job_district && formik.errors.job_district && (
            <span className="error-text">{formik.errors.job_district}</span>
          )}
        </div>
      </div>

      {edit === "true" && (
        <div className={`col-3`}>
          <div className="input-wrap pro-mb-5">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Annual Income
            </label>
            <Select
              id="annual_income"
              isClearable={true}
              placeholder={"Select"}
              options={optionsData?.data?.familyIncomeList}
              getOptionLabel={(option) => `${option.income_title}`}
              getOptionValue={(option) => `${option["id"]}`}
              className={`pro-input lg multi-select ${formik.touched.annual_income &&
                formik.errors.annual_income &&
                " error"
                }`}
              value={optionsData?.data?.familyIncomeList?.filter(
                (p) =>
                  p.id?.toString() === formik.values.annual_income?.toString()
              )}
              classNamePrefix="pro-input"
              onBlur={formik.handleBlur("annual_income")}
              onChange={(value) =>
                formik.setFieldValue("annual_income", value?.id || null)
              }
            ></Select>
            {formik.touched.annual_income &&
              formik.errors.annual_income && (
                <span className="error-text">
                  {formik.errors.annual_income}
                </span>
              )}
          </div>
        </div>
      )}

      <div className={`col-6`}>
        <Input
          label={"Job Location Address"}
          type="text"
          id="location_address"
          name="location_address"
          className={`pro-input lg ${formik.touched.location_address &&
            formik.errors.location_address &&
            " error"
            }`}
          {...formik.getFieldProps("location_address")}
          error={
            formik.touched.location_address && formik.errors.location_address
          }
          errorMessage={formik.errors.location_address}
        />
      </div>



      <div className={`col-lg-12 pro-mt-3 pro-mb-5`}>
        <div className="pro-check-box">
          <input
            type="checkbox"
            className="pro-check "
            id="profile_rate_score_id"
            name="profile_rate_score_id"
            checked={formik.values?.profile_rate_score_id ?? false}
            onChange={(e) =>
              handleCheckbox(formik.values?.profile_rate_score_id ?? false)
            }
          />
          <label htmlFor="profile_rate_score_id" className="pro-check-label">
            {" "}
            Profile Rate Score{" "}
          </label>
        </div>
      </div>


      {formik.values.profile_rate_score_id && (
        <div className={`col-3`}>
          <Input
            label={"Profile Rate Score"}
            type="text"
            id="profile_rate_score"
            name="profile_rate_score"
            className={`pro-input lg ${formik.touched.profile_rate_score &&
              formik.errors.profile_rate_score &&
              " error"
              }`}
            {...formik.getFieldProps("profile_rate_score")}
            error={
              formik.touched.profile_rate_score &&
              formik.errors.profile_rate_score
            }
            errorMessage={formik.errors.profile_rate_score}
          />
        </div>
      )}


      {edit && <div className={`row`}>
        <div className="col-3">
          <Input
            value={formik.values.last_boosted}
            label={"Last Boosted Date"}
            type={"date"}
            max={newDate.toLocaleDateString("fr-ca")}
            className={`pro-input lg ${formik.touched.last_boosted &&
              formik.errors.last_boosted &&
              "error"
              }`}
            onChange={(e) => {
              handleLastBoostedDate(e);
            }}
            onBlur={formik.handleBlur("last_boosted")}
            error={
              formik.touched.last_boosted && formik.errors.last_boosted
            }
            errorMessage={formik.errors.last_boosted}
          />
        </div>  
      </div>}

    </div>
  );
};

export default ProffesionalInfo;
