import {
  BreadCrumbs,
  HeadingGroup,
  Permissions,
} from "@wac-ui-dashboard/wac_component_library";
import useManageRoles from "./useManageRoles";
import ManageRoleShimmer from "../../../Global/Shimmers/ManageRoleShimmer";

const ManageRoles = () => {
  const {
    state,
    role,
    name,
    error,
    statusFulfilled,
    permissionModules,
    handleNameChange,
    handlePermissionChange,
    handleSave,
    handleGoBack,
  } = useManageRoles();

  return (
    <>
      <BreadCrumbs
        data={["Roles", "Manage Roles"]}
        clickable
        handleClick={handleGoBack}
      />
      <HeadingGroup title={"Role Management"} extraClassName={`pro-py-3`}>
        <button onClick={handleSave} className="pro-btn pro-btn-primary min">
          Save
        </button>
      </HeadingGroup>
      <div className="input-wrap pro-mb-5">
        <label
          htmlFor="role_name_input"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Name
        </label>
        <input
          id="role_name_input"
          type="text"
          value={name}
          onChange={handleNameChange}
          className={`pro-input lg ${error && "error"}`}
        />
        {error && <span className="error-text">{error}</span>}
      </div>
      {/* {state?.allPermissionsStatus === "fulfilled" ? ( */}
      {statusFulfilled
        ? state?.allPermissions?.map?.((permission, index) => {
            let submenu =
              permission?.sub_menu_list?.length !== 0
                ? [
                    {
                      id: `parentmenu_${permission.id}`,
                      name: "Allow",
                      submenu: false,
                    },
                    ...permission?.sub_menu_list?.map?.((item) => {
                      return {
                        id: `submenu_${item.id}`,
                        name: item.sub_menu_name,
                        submenu: true,
                      };
                    }),
                  ]
                : [
                    {
                      id: `parentmenu_${permission.id}`,
                      name: "Allow",
                      submenu: false,
                    },
                  ];
            return (
              <>
                <Permissions
                  title={permission?.menu_name}
                  permissions={submenu}
                  defaultCheckedList={permissionModules?.map?.((p) => {
                    return { id: p };
                  })}
                  key={index}
                  uniqueID={"id"}
                  handleChange={(item) =>
                    handlePermissionChange(item, permission.id)
                  }
                />
                <div className="col-12 pro-pb-5">
                  <span
                    style={{
                      borderBottom: ` 1px solid #F0F0F0`,
                      display: "block",
                    }}
                  ></span>
                </div>
              </>
            );
          })
        : role && <ManageRoleShimmer />}
    </>
  );
};

export default ManageRoles;
