import { Outlet } from "react-router-dom";
import Style from "./Auth.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { Helmet } from "react-helmet";
import Assets from "../../assets/Assets";

const AuthModule = () => {
  return (
    <>
      <Helmet>
        <title>NEXUS Call Center | Login</title>
      </Helmet>
      <div className={Style.root}>
        <div className={Style.root_inner}>
          <div className={`${Style.logoWrapper} pro-pb-6`}>
            <Image
              src={Assets.HeaderLogo}
              width={117.89}
              height={19.29}
              alt={`Matrimony Logo`}
            />
          </div>
          <div className={`${Style.auth} pro-pt-3`}>
            <Outlet context={{ emailRef: "ref" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthModule;
