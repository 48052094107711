import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useSendLoginCredentialsMutation,
  useGetProfileDataQuery,
  useGetWhatsappShareCredentialsMutation,
} from "../../../../store/queries/Profile";
import { phoneRegExp } from "../../../../utils/functions/table";

const useLoginCredentialsForm = ({ userId, handleCancel }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetProfileDataQuery(userId);

  const [getCredentialDetails] = useGetWhatsappShareCredentialsMutation();

  const [sendRequest] = useSendLoginCredentialsMutation();

  let loginCredentialsFormSchema = Yup.object({
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(10, "phone number must be 10 digits")
      .max(10, "phone number must be 10 digits"),
  });

  const formik = useFormik({
    initialValues: {
      user_id: userId,
      contact_number: null,
      mobile_number: "",
    },
    validationSchema: loginCredentialsFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleWhatsappShare = () => {
    if(formik.values.contact_number||formik.values.mobile_number){
      getCredentialDetails({ user_id: userId }).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Login Credentials Sent Successfully!");
          handleCancel();
          let mob = formik.values.mobile_number
            ? formik.values.mobile_number
            : formik.values.contact_number[0];
          window.open(
            `${`https://api.whatsapp.com/send?phone=${mob}&text=${response?.data?.data?.message}&type=custom_url&app_absent=0`}`
          );
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Sent Login Credentials!");
          handleCancel();
        }
      });
    }
    else{
      toast.error("Failed to Sent Login Credentials!");
    }
    
  };

  const handleSubmit = (values) => {
    sendRequest(values)
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success("Login Credentials Sent Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Sent Login Credentials!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Sent Login Credentials!");
      });
  };
  return { formik, optionsData, isOptionDataSuccess, handleWhatsappShare };
};

export default useLoginCredentialsForm;
