import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Assets from "../../../assets/Assets";
import {
  useActivateProfileMutation,
  useGetProfileDataQuery,
} from "../../../store/queries/Profile";
import html2pdf from "html2pdf.js";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileConfig } from "../../../store/slices/Profile";
import moment from "moment/moment";
import { deleteHistory } from "../../../store/queries/DeleteHistory";

const useProfileLayout = () => {
  const [params] = useSearchParams();
  const [showLocked, setShowLocked] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const { showPdf, skip } = useSelector((state) => state.profile);
  const [callIntiateStatus, setCallInitiateStatus] = useState(false);
  const profileState = useSelector((state) => state.profile);

  const printRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sendRequest] = useActivateProfileMutation();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const allProfiles = JSON.parse(
    sessionStorage.getItem("all_profiles") || "[]"
  );

  const [profiles, setProfiles] = useState(
    Array(ids.length).fill({
      name: "",
      image: "",
    })
  );

  const [show, setShow] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    if (showLocked) {
      setTimeout(() => setShowLocked(false), 600);
    }
  }, [showLocked]);

  const {
    data: profileData,
    isSuccess,
    isError,
    isFetching,
    refetch,
  } = useGetProfileDataQuery(activeProfile);

  const imageSlider = useMemo(() => {
    if (isSuccess) {
      if (
        profileData?.data?.profile_data?.images &&
        profileData?.data?.profile_data?.images?.length !== 0
      ) {
        return profileData?.data?.profile_data?.is_locked
          ? [
              {
                image: Assets.locked,
                id: 0,
                image_name: "locked_image.jpg",
                isPrimary: 0,
              },
            ]
          : profileData?.data?.profile_data?.images?.map((img, i) => {
              return {
                image: showThumbnail
                  ? img?.user_image_thumbnail
                  : img?.user_image,
                thumbnail_image: img?.user_image_thumbnail,
                id: img?.id,
                image_name: img?.image_file,
                isPrimary: img?.primary ?? 0,
              };
            });
      } else {
        return [
          {
            image:
              profileData?.data?.profile_data?.gender === "Female"
                ? Assets.FemaleProfile
                : Assets.MaleProfile,
            id: 1,
            isPrimary: 0,
            image_name: "gender_image.jpg",
            isDefault: true,
          },
        ];
      }
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [profileData, isFetching, showThumbnail]);

  const modalImage = useMemo(() => {
    if (isSuccess) {
      if (
        profileData?.data?.profile_data?.images &&
        profileData?.data?.profile_data?.images?.length !== 0
      ) {
        return profileData?.data?.profile_data?.images?.map((img, i) => {
          return {
            image: img?.user_image,
            id: img?.id,
            isPrimary: img?.primary ?? 0,
          };
        });
      } else {
        return [
          {
            image:
              profileData?.data?.profile_data?.gender === "Female"
                ? Assets.FemaleProfile
                : Assets.MaleProfile,
            id: 1,
            isPrimary: 0,
            isDefault: true,
          },
        ];
      }
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [profileData, isFetching]);

  useEffect(() => {
    //FIXME find a better way to handle this

    if (isSuccess) {
      if (
        allProfiles.findIndex((profile) => profile.id === activeProfile) === -1
      ) {
        const getImage = () => {
          if (
            profileData.data?.profile_data?.images &&
            profileData.data?.profile_data?.images?.length === 0
          ) {
            return profileData.data.profile_data?.gender === "Female"
              ? Assets.FemaleProfile
              : Assets.MaleProfile;
          } else if (profileData?.data?.profile_data?.is_locked) {
            return Assets.locked;
          } else {
            return (
              profileData.data.profile_data.images.filter(
                (image) => image.primary === 1
              )[0]?.user_image_thumbnail ||
              profileData.data.profile_data.images[0]?.user_image_thumbnail
            );
          }
        };

        let profile = {
          id: activeProfile,
          name: profileData.data.profile_data.name,
          image: getImage(),
        };

        sessionStorage.setItem("active", activeProfile);
        sessionStorage.setItem(
          "all_profiles",
          JSON.stringify([...allProfiles, profile])
        );

        setProfiles(
          [...allProfiles, profile].filter((p) => ids.includes(p.id.toString()))
        );
      } else {
        setProfiles(allProfiles.filter((p) => ids.includes(p.id.toString())));
      }
    }
    // eslint-disable-next-line
  }, [profileData]);

  useEffect(() => {
    if (isError) {
      if (allProfiles.length === 0) {
        toast.error(`Oops, Something went wrong!`);
        navigate("/");
      } else {
        toast.error(`Oops, Something went wrong!`);
        navigate({
          pathname: "/profile",
          search: `active=${allProfiles[0].id}`,
        });
      }
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleClick = (item) => {
    const newIds = ids.filter((id) => id !== item?.id).join(",");
    navigate({
      pathname: "/profile",
      search: `?active=${item?.id}&id=${newIds}`,
    });
    sessionStorage.setItem("active", `${item?.id}`);
  };

  const handleProfileClick = () => {
    setShowThumbnail(false);
    if (profileData?.data?.profile_data?.is_locked) {
      setShowLocked((state) => (state = true));
    } else {
      setShow((state) => (state = true));
    }
  };

  const closeModal = () => {
    setShowThumbnail(true);
    setShow((state) => (state = false));
    if (profileData?.data?.profile_data?.is_locked) {
      setShowLocked((state) => (state = false));
    }
  };

  const closePdfModal = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showPdf = false;
      })
    );
  };

  const handleHideProfile = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "hide_profile";
      })
    );
  };
  const handleActivateProfile = () => {
    sendRequest({ profile_type: 1, user_id: activeProfile })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Profile Activated Successfully!");
          deleteHistory.util.invalidateTags(["DeleteHistory"]);
        } else if (response?.error?.data?.status_code === 422) {
          toast.error(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Activate Profile!");
        }
      })
      .catch(() => {
        toast.error("Failed to Activate Profile!");
      });
  };

  const handleShareProfile = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = true;
        state.actionFormType = "share_profile";
      })
    );
  };

  //FIXME pass index from TabHeading to handleClose and use it to move to prev tab
  const handleClose = (item) => {
    const filteredAllProfiles = allProfiles.filter(
      (profile) =>
        ids.includes(profile?.id.toString()) && profile.id !== item.id
    );
    setProfiles(filteredAllProfiles);
    sessionStorage.setItem("all_profiles", JSON.stringify(filteredAllProfiles));
    if (filteredAllProfiles.length === 0) {
      navigate("/");
    } else {
      navigate({
        pathname: "/profile",
        search: `?active=${
          filteredAllProfiles[filteredAllProfiles.length - 1].id
        }&id=${filteredAllProfiles.map((p) => p.id).join(",")}`,
      });
    }
  };

  // const handleDownloadPdf = async () => {
  //   const element = printRef.current;

  //   html2pdf(element, {
  //     margin: [0, 0, 10, 0],
  //     filename: `${profileData?.data?.profile_data?.id +
  //       moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
  //       }.pdf`,
  //     image: { type: "jpeg", quality: 1 },
  //     html2canvas: {
  //       dpi: 100,
  //       letterRendering: true,
  //       useCORS: true,
  //       logging: true,
  //       scale: 4,
  //       scrollY: 0,
  //     },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
  //     DisablePdfCompression: true,
  //   });
  //   dispatch(
  //     updateProfileConfig((state) => {
  //       state.skip = true;
  //     })
  //   );
  //   // dispatch(
  //   //   updateProfileConfig((state) => {
  //   //     state.selectedProfiles = [];
  //   //   })
  //   // );
  // };

  const handleDownloadPdf = async () => {
    setPdfLoading(true);
    const element = printRef.current;
    const pdfPromise = new Promise((resolve) => {
      html2pdf(element, {
        margin: [0, 0, 0, 0],
        filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          width: window?.width,
          dpi: 100,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 3,
          scrollY: 0,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
        DisablePdfCompression: true,
      }).then(() => {
        // Resolve the Promise when the pdf is generated
        resolve();
      });
    });

    // Wait for the Promise to resolve before showing "Download finished" toast
    await pdfPromise;

    setPdfLoading(false);
    dispatch(
      updateProfileConfig((state) => {
        state.skip = true;
      })
    );
  };

  const handleDownloadClick = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.selectedProfiles = [activeProfile];
        state.showPdf = true;
        state.skip = false;
      })
    );
  };

  // const handlePrintClick = useReactToPrint({
  //   content: () => printRef.current,
  // });

  const actions = [
    {
      label: "Share",
      handleClick: handleShareProfile,
      icon: <span className="material-symbols-outlined"> share </span>,
    },
    {
      label: "Download",
      handleClick: handleDownloadClick,
      icon: <span className="material-symbols-outlined"> cloud_download </span>,
    },
    {
      label: "Hide Profile",
      icon: <span className="material-symbols-outlined">block</span>,
      handleClick: handleHideProfile,
    },
    {
      label: "Show Profile",
      icon: <span className="material-symbols-outlined">visibility</span>,
      handleClick: handleActivateProfile,
    },
  ];

  const finalActions = useMemo(() => {
    if (isSuccess && profileData?.data?.profile_data?.profile_hide === "0") {
      return actions.filter((n) => !n.label.includes("Show Profile"));
    } else {
      return actions.filter((n) => !n.label.includes("Hide Profile"));
    }
    // eslint-disable-next-line
  }, [isSuccess, actions]);

  //checking delete permission
  const hasDeletePermission = useMemo(() => {
    let permission = profileData?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_delete")
    )[0].can_delete;

    return permission;
    // eslint-disable-next-line
  }, [profileData?.data?.permissions]);

  //checking activate permission
  const hasActivatePermission = useMemo(() => {
    let permission = profileData?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_activate")
    )[0].can_activate;

    return permission;
    // eslint-disable-next-line
  }, [profileData?.data?.permissions]);

  const closeCallModal = () => {
    localStorage.removeItem("CALL_ACTIVE");
  };
  const handleHistoryButtonClick = () => {
    setShowHistory((prevShowHistory) => !prevShowHistory);
  };

  return {
    profiles,
    profileData: profileData?.data?.profile_data,
    hasDeletePermission,
    hasActivatePermission,
    imageSlider,
    modalImage,
    actions: finalActions,
    activeProfile,
    profileState,
    isFetching,
    isSuccess,
    skip,
    show,
    printRef,
    showPdf,
    showLocked,
    isFetchLoading,
    pdfLoading,
    callIntiateStatus,
    showHistory,
    setCallInitiateStatus,
    handleHistoryButtonClick,
    closeCallModal,
    setIsFetchLoading,
    handleClick,
    closePdfModal,
    handleClose,
    handleProfileClick,
    handleDownloadPdf,
    closeModal,
    refetch,
  };
};

export default useProfileLayout;
