import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
// import TimePicker from "react-time-picker";
import useReligious from "./useReligious";
// import style from "./religious.module.scss";
import { DatePicker } from "rsuite";
import { FaClock } from "react-icons/fa";

const Religious = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  edit,
  toTab,
}) => {
  const {
    formik,
    errors,
    isUpdating,
    time,
    initialTimeSet,
    format,
    handleClearTimeValues,
    setSaveAndNext,
    onChange,
    handleSelectStar,
  } = useReligious({
    optionsData,
    isOptionDataSuccess,
    activeUser,
    edit,
    toTab,
  });

  return (
    <div className={`pro-p-0 pro-w-100`} id="religious-info">
      <form action="" onSubmit={formik.handleSubmit}>
        <div className={`row`}>
          <div className={`col-3`}>
            <Input
              label={"Malayalam Date Of Birth"}
              type="text"
              id="malayalam_dob"
              name="malayalam_dob"
              placeholder={"dd/mm/yyyy"}
              className={`pro-input lg ${
                formik.touched.malayalam_dob &&
                formik.errors.malayalam_dob &&
                " error"
              }`}
              {...formik.getFieldProps("malayalam_dob")}
              error={
                formik.touched.malayalam_dob && formik.errors.malayalam_dob
              }
              errorMessage={formik.errors.malayalam_dob}
            />
          </div>

          <div className={`col-auto`}>
            {/* //FIXME change timePicker package */}
            <div className="input-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Time Of Birth
              </label>
              <DatePicker
                id="time-picker"
                showMeridian
                showHeader={false}
                format={format}
                character={" - "}
                caretAs={FaClock}
                placeholder={"Select Time of Birth"}
                menuClassName="time-picker"
                onChange={(value) => onChange(value)}
                defaultValue={initialTimeSet}
                size="md"
                value={time}
                onClean={() => handleClearTimeValues()}
              />
              {formik.touched.time_of_birth && formik.errors.time_of_birth && (
                <span className="error-text">
                  {formik.errors.time_of_birth}
                </span>
              )}
            </div>
            {/* <div className="input-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Time Of Birth
              </label>
              <TimePicker
                format="hh:mm:ss a"
                id="time_of_birth"
                hourPlaceholder="00"
                minutePlaceholder="00"
                secondPlaceholder="00"
                disableClock
                className={`pro-input lg ${style.timePicker}  ${
                  formik.touched.time_of_birth &&
                  formik.errors.time_of_birth &&
                  " error"
                }`}
                onBlur={formik.handleBlur("time_of_birth")}
                onChange={(value) => onChange(value)}
                clearIcon={
                  <span
                    onClick={() => handleClearClick()}
                    className="material-symbols-outlined x3"
                  >
                    close
                  </span>
                }
                value={
                  formik?.values?.time_of_birth === "00:00:00 AM" ? null : time
                }
              />

              {formik.touched.time_of_birth && formik.errors.time_of_birth && (
                <span className="error-text">
                  ` `{formik.errors.time_of_birth}
                </span>
              )}
            </div> */}
          </div>

          <div className={`col-3`}>
            <div className="input-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Star
              </label>
              <Select
                id="stars_id"
                placeholder={"Select"}
                isClearable={true}
                options={optionsData?.data?.starsList}
                getOptionLabel={(option) => `${option.star_name}`}
                getOptionValue={(option) => `${option["id"]}`}
                className={`pro-input lg multi-select ${
                  formik.touched.stars_id && formik.errors.stars_id && " error"
                }`}
                value={optionsData?.data?.starsList?.filter(
                  (p) => p.id?.toString() === formik.values.stars_id?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("stars_id")}
                onChange={(value) => handleSelectStar(value)}
              ></Select>
              {formik.touched.stars_id && formik.errors.stars_id && (
                <span className="error-text">{formik.errors.stars_id}</span>
              )}
            </div>
          </div>

          <div className={`col-3`}>
            <div className="input-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Type Of Jathakam
              </label>
              <Select
                id="jathakam_type"
                placeholder={"Select"}
                isClearable={true}
                options={optionsData?.data?.jathakamLists}
                getOptionLabel={(option) => `${option.jathakam_type}`}
                getOptionValue={(option) => `${option["id"]}`}
                className={`pro-input lg multi-select ${
                  formik.touched.jathakam_type &&
                  formik.errors.jathakam_type &&
                  " error"
                }`}
                value={optionsData?.data?.jathakamLists?.filter(
                  (p) =>
                    p.id?.toString() === formik.values.jathakam_type?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("jathakam_type")}
                onChange={(value) =>
                  formik.setFieldValue("jathakam_type", value?.id || null)
                }
              ></Select>
              {formik.touched.jathakam_type && formik.errors.jathakam_type && (
                <span className="error-text">
                  {formik.errors.jathakam_type}
                </span>
              )}
            </div>
          </div>

          <div className="input-wrap pro-mb-5">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Match Stars
            </label>
            <Select
              id="match_star"
              placeholder={"Select"}
              isMulti
              options={optionsData?.data?.starsList}
              getOptionLabel={(option) => `${option.star_name}`}
              getOptionValue={(option) => `${option["id"]}`}
              className={`pro-input lg multi-select ${
                formik.touched.match_star &&
                formik.errors.match_star &&
                " error"
              } ${!formik.values.stars_id && "disabled"}`}
              value={optionsData?.data?.starsList?.filter?.((c) =>
                formik.values.match_star?.includes?.(c.id?.toString())
              )}
              classNamePrefix="pro-input"
              onBlur={formik.handleBlur("match_star")}
              onChange={(value) => {
                formik.setFieldValue(
                  "match_star",
                  value.map((c) => c.id?.toString())
                );
              }}
            ></Select>
            {formik.touched.match_star && formik.errors.match_star && (
              <span className="error-text">{formik.errors.match_star}</span>
            )}
          </div>

          <div className={`col-3`}>
            <Input
              label={"Dhasa"}
              type="text"
              id="dasa_name"
              name="dasa_name"
              className={`pro-input lg ${
                formik.touched.dasa_name && formik.errors.dasa_name && " error"
              }`}
              {...formik.getFieldProps("dasa_name")}
              error={formik.touched.dasa_name && formik.errors.dasa_name}
              errorMessage={formik.errors.dasa_name}
            />
          </div>

          <div className={`col-3`}>
            <Input
              label={"Sista Dhasa - End Year"}
              type="text"
              id="sista_dasa_year"
              name="sista_dasa_year"
              className={`pro-input lg ${
                formik.touched.sista_dasa_year &&
                formik.errors.sista_dasa_year &&
                " error"
              }`}
              {...formik.getFieldProps("sista_dasa_year")}
              error={
                formik.touched.sista_dasa_year && formik.errors.sista_dasa_year
              }
              errorMessage={formik.errors.sista_dasa_year}
            />
          </div>

          <div className={`col-3`}>
            <Input
              label={"Sista Dhasa - End Month"}
              type="text"
              id="sista_dasa_month"
              name="sista_dasa_month"
              className={`pro-input lg ${
                formik.touched.sista_dasa_month &&
                formik.errors.sista_dasa_month &&
                " error"
              }`}
              {...formik.getFieldProps("sista_dasa_month")}
              error={
                formik.touched.sista_dasa_month &&
                formik.errors.sista_dasa_month
              }
              errorMessage={formik.errors.sista_dasa_month}
            />
          </div>

          <div className={`col-3`}>
            <Input
              label={"Sista Dhasa - End Day"}
              type="text"
              id="sista_dasa_day"
              name="sista_dasa_day"
              className={`pro-input lg ${
                formik.touched.sista_dasa_day &&
                formik.errors.sista_dasa_day &&
                " error"
              }`}
              {...formik.getFieldProps("sista_dasa_day")}
              error={
                formik.touched.sista_dasa_day && formik.errors.sista_dasa_day
              }
              errorMessage={formik.errors.sista_dasa_day}
            />
          </div>

          <div className={`col-12`}>
            <div className="input-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Other Religious Info
              </label>
              <textarea
                id="other_religious_info"
                name="other_religious_info"
                className={`pro-input lg ${
                  formik.touched.other_religious_info &&
                  formik.errors.other_religious_info &&
                  " error"
                }`}
                {...formik.getFieldProps("other_religious_info")}
              ></textarea>

              {formik.touched.other_religious_info &&
                formik.errors.other_religious_info && (
                  <span className="error-text">
                    {formik.errors.other_religious_info}
                  </span>
                )}
            </div>
          </div>

          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end btn-fixed-btm">
            {toTab && (
              <Button
                disabled={isUpdating}
                className={`pro-btn-primary lg pro-mx-3 ${
                  isUpdating ? "loading" : ""
                }`}
                onClick={() => setSaveAndNext(true)}
                type="submit"
              >
                {isUpdating ? (
                  <i
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  ></i>
                ) : (
                  ""
                )}
                {toTab && "Save & Next"}
              </Button>
            )}

            <Button
              disabled={isUpdating}
              className={`pro-btn-primary lg pro-mx-3 ${
                isUpdating ? "loading" : ""
              }`}
              type="submit"
            >
              {isUpdating ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              {toTab ? "Save" : "Save & Next"}
            </Button>
          </div>
          {errors && <span className="error-message">{errors}</span>}
        </div>
      </form>
    </div>
  );
};

export default Religious;
