import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentFormData: [],
  backendErrors:"",
  activeTab: "Basic",
  completedTabs: {
    Basic: false,
    Interval: false,
  },
  CompletedPercentage:{
    Basic: 0,
    Interval: 0,
  },
};

const createScheduleSlice = createSlice({
  name: "createSchedule",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateFormData } = createScheduleSlice.actions;

export default createScheduleSlice.reducer;
