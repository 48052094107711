import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import style from './serviceNotification.module.scss'
import useServiceNotification from "./useServiceNotification";
import OrderColumn from "../Global/OrderColumn";

const ServiceNotification = () => {
  const {
    serviceNotification,
    serviceNotificationState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showTableFieldsModal,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    updateServiceNotificationFields,
  } = useServiceNotification();

  return (
    <>
      <HeadingGroup
        title={"Service Notification"}
        extraClassName={`pro-mb-2`}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6 ${style.table_wrapper}`}>
        <SearchFilters
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          searchable={false}
        />

        <Table
          data={serviceNotification?.data?.data || []}
          uniqueID={"Image"}
          fields={sortedTableFields}
          showCheckBox={false}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          deletable={false}
          multiSelect={false}
          perpage={serviceNotificationState.currentPageSize}
        />

        {serviceNotification?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              serviceNotification?.data?.total_count /
                serviceNotification?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={serviceNotification?.data?.table_id}
              updateData={updateServiceNotificationFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default ServiceNotification;
