import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/CallHistory/incomingCallSlice";

const useFilterForm = () => {
  const dispatch = useDispatch();
  const [time, setTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");

  const formik = useFormik({
    initialValues: {
      start_time: "00:00",
      end_time: "00:00",
    },

    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.timeFilter = [values?.start_time, values?.end_time];
        })
      );
    },
  });

  const onChange = (newTime) => {
    if (newTime !== null) {
      setTime(newTime);
      let [hour, minute] = newTime.split(":");
      let now = new Date();
      now.setHours(hour, minute);

      let time24 = now.toLocaleString("en-US", {
        hour: "2-digit", // Display hours in 2-digit format (e.g., "00", "01", ..., "23")
        minute: "2-digit", // Display minutes in 2-digit format (e.g., "00", "01", ..., "59")
        hour12: false, // Use 24-hour format
        hourCycle: "h24", // Set to 24-hour format
      });

      let ConvertedTime24 = time24
        .replace(/^(\d{1}):/, "0$1:")
        .replace(/^(\d{1}) /, "0$1 ");

      formik.setFieldValue("start_time", ConvertedTime24);
    }
  };
  const handleClearStartClick = () => {
    setTime("00:00");
    formik.setFieldValue("start_time", "00:00");
  };

  const onEndTimeChange = (newTime) => {
    if (newTime !== null) {
      setEndTime(newTime);
      let [hour, minute] = newTime.split(":");
      let now = new Date();
      now.setHours(hour, minute);

      let time24 = now.toLocaleString("en-US", {
        hour: "2-digit", // Display hours in 2-digit format (e.g., "00", "01", ..., "23")
        minute: "2-digit", // Display minutes in 2-digit format (e.g., "00", "01", ..., "59")
        hour12: false, // Use 24-hour format
        hourCycle: "h24", // Set to 24-hour format
      });

      let ConvertedTime24 = time24
        .replace(/^(\d{1}):/, "0$1:")
        .replace(/^(\d{1}) /, "0$1 ");

      formik.setFieldValue("end_time", ConvertedTime24);
    }
  };

  const handleClearEndClick = () => {
    setEndTime("00:00");
    formik.setFieldValue("end_time", "00:00");
  };

  return {
    onChange,
    onEndTimeChange,
    handleClearStartClick,
    handleClearEndClick,
    time,
    endTime,
    formik,
  };
};

export default useFilterForm;
