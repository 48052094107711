import {
  FormTabs,
  HeadingGroup,
} from "@wac-ui-dashboard/wac_component_library";
import Basic from "./Basic";
import Family from "./Family";
import Gallery from "./Gallery";
import Horoscope from "./Horoscope";
import Partner from "./Partner";
import Religious from "./Religious";
import useProfileCreationForm from "./useProfileCreationForm";
import style from "./createProfileForm.module.scss";

const ProfileCreationForm = () => {
  const {
    tabs,
    activeTab,
    webId,
    optionsData,
    isOptionDataSuccess,
    activeUser,
    edit,
    toTab,
    handleTabClick,
  } = useProfileCreationForm();

  return (
    <div className={`${style.profile_create} pro-mb-6`}>
      <HeadingGroup title={"Create Profile"} extraClassName={`pro-mb-2`}>
        <span>
          {activeUser ? (
            <span className={`${style.web_id} web-id`}>
              <span>Register ID</span> : {webId}
            </span>
          ) : (
            ""
          )}
        </span>
      </HeadingGroup>

      <FormTabs
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
        propStyle={{ root_items: style.root_items }}
      />
      {activeTab && activeTab === "Basic" ? (
        <Basic
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
          activeUser={activeUser}
          edit={edit}
          toTab={toTab}
          webId={webId}
        />
      ) : activeTab === "Family" ? (
        <Family
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
          activeUser={activeUser}
          toTab={toTab}
          webId={webId}
        />
      ) : activeTab === "Religious" ? (
        <Religious
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
          activeUser={activeUser}
          toTab={toTab}
          webId={webId}
        />
      ) : activeTab === "Horoscope" ? (
        <Horoscope
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
          activeUser={activeUser}
          toTab={toTab}
          webId={webId}
        />
      ) : activeTab === "Partner" ? (
        <Partner
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
          activeUser={activeUser}
          toTab={toTab}
          webId={webId}
        />
      ) : activeTab === "Gallery" ? (
        <Gallery
          optionsData={optionsData}
          isOptionDataSuccess={isOptionDataSuccess}
          activeUser={activeUser}
          edit={edit}
          toTab={toTab}
          webId={webId}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProfileCreationForm;
