import React from "react";
import { useNavigate } from "react-router-dom";

export const RegisterIdRow = ({ data, onClick }) => {
  const navigate = useNavigate();

  if (!data?.register_id) {
    return "";
  }
  return (
    <p
      className="pro-mb-0 pro-pnt"
      onClick={() => navigate(`/dashboard/report/${data?.id}`)}
    >{`${data?.register_id}`}</p>
  );
};
