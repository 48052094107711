import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import AddServiceForm from "../../../components/Profile/AddService/AddServiceForm";
import useAddService from "../../../components/Profile/AddService/useAddService";

const AddService = ({ search, activity ,optionsData ,isSuccess }) => {
  const { formik, handleReset, time, handleTimeChange, handleWebIdChange, handleOnBlur } =
    useAddService({
      search,
      activity,
    });
    
  return (
    <div className={`pro-p-0 pro-w-100`}>
      <AddServiceForm
        formik={formik}
        parentClass={"pro-p-0 pro-w-100"}
        childClass={"col-12"}
        optionsData={optionsData}
        isSuccess={isSuccess}
        time={time}
        search={search}
        handleWebIdChange={handleWebIdChange}
        handleOnBlur={handleOnBlur}
        handleTimeChange={handleTimeChange}
      />
      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleReset()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik.handleSubmit}
          type="submit"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddService;
