import moment from "moment";

export const getFormatedDate = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};

export const getMaxDate = (years)=>{
  return moment().subtract(years, 'years')._d
}

export const getAge = (dob)=>{
  return moment().diff(dob, 'years',false)
}

export const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
  
export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

