import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useUtmReport from "./useUtmReport";
import EmptyData from "../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../Global/OrderColumn";
import UtmReportForm from "./UtmReportForm";

const UtmReport = () => {
  const {
    utmReportState,
    utmReportData,
    isLoading,
    isFetching,
    paginationOptions,
    showTableFieldsModal,
    initialDateRange,
    sortedTableFields,
    checkboxValues,
    handleChangeOtherStateCheckbox,
    handleChangeExistedCheckbox,
    handleChangeOthersCheckbox,
    closeUtmEditForm,
    handleEditAction,
    handleDateChange,
    handleClearClick,
    handleEditFieldsClick,
    updateUtmFields,
    refetch,
    closeEditModal,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleSearch,
  } = useUtmReport();
  return (
    <>
      <HeadingGroup title={"UTM Reports"} className={`pro-mb-0`} />
      <div className={`col-row pro-pt-3`}>
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditFieldsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          handleClear={handleClearClick}
          searchInputProps={{ value: utmReportState?.search }}
          //date picker props
          showDateRange={true}
          initialDateRange={initialDateRange}
          onDateChange={handleDateChange}
          filterChildren={
            <div className=" col">
              <div className="row pro-items-center pro-justify-end pro-h-100">
                {/* others checkbox */}
                <div className="col-auto">
                  <div className="pro-check-box pro-items-center pro-flex-nowrap">
                    <input
                      type="checkbox"
                      id="others"
                      name="others"
                      className="pro-check"
                      checked={checkboxValues["others"]}
                      onChange={(e) => {
                        handleChangeOthersCheckbox(e);
                      }}
                    />
                    <label
                      htmlFor="others"
                      className="pro-check-label single-line"
                    >
                      Others
                    </label>
                  </div>
                </div>
                {/* existed checkbox */}
                <div className="col-auto">
                  <div className="pro-check-box pro-items-center pro-flex-nowrap">
                    <input
                      type="checkbox"
                      id="existed"
                      name="existed"
                      className="pro-check"
                      checked={checkboxValues["existed"]}
                      onChange={(e) => {
                        handleChangeExistedCheckbox(e);
                      }}
                    />
                    <label
                      htmlFor="existed"
                      className="pro-check-label single-line"
                    >
                      Existed
                    </label>
                  </div>
                </div>
                {/* other_state checkbox */}
                <div className="col-auto">
                  <div className="pro-check-box pro-items-center pro-flex-nowrap">
                    <input
                      type="checkbox"
                      id="other_state"
                      name="other_state"
                      className="pro-check"
                      checked={checkboxValues["other_state"]}
                      onChange={(e) => {
                        handleChangeOtherStateCheckbox(e);
                      }}
                    />
                    <label
                      htmlFor="other_state"
                      className="pro-check-label single-line"
                    >
                      Other State
                    </label>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>

      <div className="pro-pb-6 pro-scroll-lg">
        {utmReportData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={utmReportData?.data?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={true}
            deletable={false}
            editable={true}
            handleEdit={handleEditAction}
            clear={utmReportState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={sortedTableFields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={utmReportState?.currentPageSize}
          />
        )}
      </div>

      {utmReportData?.data?.data?.length > 0 && (
        <Pagination
          currentPage={utmReportState?.currentPage}
          totalPageCount={Math.ceil(
            utmReportData?.data?.total_count / utmReportData?.data?.per_page
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}

      <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            tableFields={sortedTableFields}
            moduleId={utmReportData?.data?.table_id}
            updateData={updateUtmFields}
            handleCancel={closeEditModal}
            refetch={refetch}
          />
        </div>
      </ModalLayout>

      <OffCanvasLayout
        title={"Edit Report"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        show={utmReportState?.showEditModal}
        handleClose={closeUtmEditForm}
      >
        <UtmReportForm handleClose={closeUtmEditForm} refetch={refetch} />
      </OffCanvasLayout>
    </>
  );
};

export default UtmReport;
