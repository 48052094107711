import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("user_type_sort")}&${getParams(
          "sort_by"
        )}&${getParams("sort_order")}&per_page=${
          params?.page_size || "10"
        }&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const service = createApi({
  reducerPath: "serviceApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "myService",
    "serviceNotification",
    "serviceStatusAlert",
    "serviceChat",
  ],
  endpoints: (builder) => ({
    getMyServiceListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/service/user-service`,
      }),
      invalidatesTags: ["myService"],
    }),

    updateServiceListFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["myService"],
    }),

    getServiceNotificationListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/notification/service-notification`,
      }),
      providesTags: ["serviceNotification"],
    }),

    UpdateServiceNotificationListFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["serviceNotification"],
    }),

    getServiceStatusAlertListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/notification/service-status-alert`,
      }),
      providesTags: ["serviceStatusAlert"],
    }),

    UpdateServiceStatusAlertListFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["serviceStatusAlert"],
    }),
    getServiceChatListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/notification/service-chat`,
      }),
      providesTags: ["serviceChat"],
    }),
  }),
});

export const {
  useGetMyServiceListDataQuery,
  useUpdateServiceListFieldsMutation,
  useUpdateServiceNotificationListFieldsMutation,
  useGetServiceNotificationListDataQuery,
  useGetServiceStatusAlertListDataQuery,
  useUpdateServiceStatusAlertListFieldsMutation,
  useGetServiceChatListDataQuery,
} = service;
