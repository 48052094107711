import { toast } from "react-toastify";
import { InboundOutboundCalls } from "../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const useOutboundCalls = () => {
  const [params] = useSearchParams();
  const [error, setError] = useState(false);
  const phone = params.get("to");
  const navigate = useNavigate();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  useEffect(() => {
    InboundOutboundCalls({
      mobile_number: phone,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        navigate({
          pathname: "/profile",
          search: `?active=${response?.data?.data?.user_id}&id=${activeProfile}`,
        });
      } else {
        setError(() => true);
        toast.error("User Not Found");
      }
    });
    // eslint-disable-next-line
  }, []);

  return { error };
};

export default useOutboundCalls;
