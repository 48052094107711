import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateProfileConfig } from "../../../store/slices/Profile";
import DoNotCallLaterForm from "./DoNotCallLaterForm";
import FollowUpForm from "./FollowUpForm";
import HideProfileForm from "./HideProfileForm";
import LoginCredentialsForm from "./LoginCredentialsForm";
import PosibilityForm from "./PosibilityForm";
import ScheduleTerminationForm from "./ScheduleTerminationForm";
import SelectionRequestForm from "./SelectionRequestForm";
import ShareProfileForm from "./ShareProfileForm";
import ShareProfileLinkForm from "./ShareProfileLinkForm";
import ShCompleteForm from "./ShCompleteForm";
import TLSupportForm from "./TLSupportForm";
import ShareMultipleProfileForm from "./ShareMultipleProfileForm";
import DeleteProfileForm from "./DeleteProfileForm";
import CompleteFollowUpForm from "./CompleteFollowUpForm";

const useActionForms = ({
  userId,
  isSuccess,
  profileId,
  ProfileName,
  permissions,
}) => {
  const dispatch = useDispatch();
  const { showActionsModal, actionFormType } = useSelector(
    (state) => state.profile
  );

  const handleCloseActionsModal = () => {
    dispatch(
      updateProfileConfig((state) => {
        state.showActionsModal = false;
      })
    );
  };

  const forms = {
    login: (
      <LoginCredentialsForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
      />
    ),
    hide_profile: (
      <HideProfileForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
      />
    ),
    share_profile: (
      <ShareProfileForm
        userId={userId}
        ProfileName={ProfileName}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
      />
    ),
    delete_profile: (
      <DeleteProfileForm
        userId={userId}
        ProfileName={ProfileName}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
      />
    ),
    share_multiple_profile: (
      <ShareMultipleProfileForm
        userId={userId}
        ProfileName={ProfileName}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
      />
    ),
    profile_link: (
      <ShareProfileLinkForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
      />
    ),

    follow_up: (
      <FollowUpForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),

    follow_up_complete: (
      <CompleteFollowUpForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),
    tl_support: (
      <TLSupportForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),
    posibility: (
      <PosibilityForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),
    sh_complete: (
      <ShCompleteForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),
    do_not_call_later: (
      <DoNotCallLaterForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),
    selection_request: (
      <SelectionRequestForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),
    schedule_termination: (
      <ScheduleTerminationForm
        userId={userId}
        handleCancel={handleCloseActionsModal}
        isSuccess={isSuccess}
        profileId={profileId}
      />
    ),
  };

  return { handleCloseActionsModal, showActionsModal, actionFormType, forms };
};

export default useActionForms;
