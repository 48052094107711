import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useRegistration from "./useRegistration";

const Registration = () => {
  const {
    registration,
    registrationState,
    currentPage,
    hasCreatePermission,
    hasEditPermission,
    paginationOptions,
    isFetching,
    isLoading,
    showTableFieldsModal,
    sortedTableFields,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleRegisterClick,
    getRow,
    handleEditClick,
    closeEditModal,
    updateRegistrationFields,
    handleTableEditClick,
  } = useRegistration();


  return (
    <>
      <HeadingGroup
        title={"Registered Profiles"}
        buttonTitle={hasCreatePermission ? "Register" : ""}
        extraClassName={`pro-mb-2`}
        handleClick={handleRegisterClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          showActions={true}
          handleActionClick={handleEditClick}
          loading={isLoading}
          searchable={false}
        />

        <Table
          data={registration?.data?.data || []}
          uniqueID={"user_id"}
          fields={sortedTableFields}
          showCheckBox={hasEditPermission}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          deletable={false}
          multiSelect={false}
          handleEdit={handleTableEditClick}
          perpage={registrationState.currentPageSize}
        />

        {registration?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              registration?.data?.total_count / registration?.data?.per_page
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showTableFieldsModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={registration?.data?.table_id}
              updateData={updateRegistrationFields}
              handleCancel={closeEditModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Registration;
