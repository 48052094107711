import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  BreadCrumbs,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import EditModal from "./EditModal";
import HeadingGroup from "./HeadingGroup";
import useReportStatus from "./useReportStatus";

const ReportStatusListing = () => {
  const {
    reportStatus,
    errors,
    reportStatusState,
    filters,
    currentPage,
    activeFilter,
    showTableFieldsModal,
    showDeleteModal,
    showUpdateModal,
    paginationOptions,
    selectedUpdateRows,
    isFetching,
    isLoading,
    sortedTableFields,
    hasUpdatePermission,
    hasDeletePermission,
    handleRedirectProfile,
    refetch,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleSort,
    handleEditFieldsClick,
    closeEditFieldsModal,
    getRow,
    updateReportStatusFields,
    handleLinkClick,
    handleTableEditClick,
    handleTableDeleteClick,
    closeDeleteModal,
    closeUpdateModal,
    handleDeleteData,
  } = useReportStatus();

  // FIXME aneesh Add loading shimmer for text

  return (
    <>
      {reportStatus?.data?.register_id ? (
        <BreadCrumbs
          data={["Allotted list", `${reportStatus?.data?.register_id}`]}
          clickable
          handleClick={handleLinkClick}
        />
      ) : (
        <div className="pro-d-flex pro-gap-1">
          <div className="shimmer pro-py-1 pro-px-6 pro-mb-1"></div>
          <div className="shimmer pro-py-1 pro-px-6 pro-mb-1"></div>
        </div>
      )}

      {reportStatus?.data?.schedule_name ? (
        <HeadingGroup
          title={`${reportStatus?.data?.register_id} Status`}
          sub={`Schedule Name : ${
            reportStatus?.data?.schedule_name
              ? reportStatus?.data?.schedule_name
              : "loading..."
          }`}
          handleRedirectProfile={handleRedirectProfile}
        />
      ) : (
        <div className="pro-pb-4">
          <div className="pro-d-flex pro-my-3">
            <span className="shimmer pro-d-block pro-py-4 pro-px-6"></span>
            <span className="shimmer pro-d-block pro-py-4 pro-px-6"></span>
            <span className="shimmer pro-d-block pro-py-4 pro-px-6"></span>
          </div>
          <div className="shimmer pro-py-1 pro-px-6 pro-mt-1"></div>
        </div>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={filters}
          activeFilter={activeFilter}
          handleButtonGroupChange={handleFilter}
          loading={isLoading}
          showActions={true}
          handleActionClick={handleEditFieldsClick}
          searchable={false}
        />

        <Table
          data={reportStatus?.data?.data || []}
          uniqueID={"call_id"}
          fields={sortedTableFields}
          showCheckBox
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={reportStatusState.currentPageSize}
          assignable={false}
          editable={hasUpdatePermission}
          deletable={hasDeletePermission}
          handleEdit={handleTableEditClick}
          handleDelete={handleTableDeleteClick}
          
        />

        {reportStatus?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              reportStatus?.data?.total_count /
                reportStatusState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showUpdateModal} handleClose={closeUpdateModal}>
          <div className="pro-m-5">
            <EditModal
              title={"Add Comments"}
              selectedUpdateRows={selectedUpdateRows}
              closeUpdateModal={closeUpdateModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered
          show={showDeleteModal}
          handleClose={closeDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              subTitle={`You won’t be able to reverse this action.`}
              isRight={true}
              cancelText={`Cancel`}
              submitText={`Delete`}
              cancelAction={closeDeleteModal}
              submitAction={handleDeleteData}
            >
              ConfirmationBox
            </ConfirmationBox>

            {errors && <span className="error-message">{errors}</span>}
          </div>
        </ModalLayout>

        <ModalLayout
          show={showTableFieldsModal}
          handleClose={closeEditFieldsModal}
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={sortedTableFields}
              moduleId={reportStatus?.data?.table_id}
              updateData={updateReportStatusFields}
              handleCancel={closeEditFieldsModal}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default ReportStatusListing;
