import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("task_type")}&${getParams(
          "user_type_sort"
        )}&${getParams("sort_by")}&sort=${
          params?.sort_order || "desc"
        }&per_page=${params?.page_size || "10"}&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const dashboard = createApi({
  reducerPath: "dashboardApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Dashboard",
    "FollowUp",
    "DoNotCallLater",
    "SelectionRequest",
    "JobAssign",
    "DashboardList",
    "PendingCalls",
    "ServiceFollowUp",
  ],
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/dashboard/task-count`,
      }),
      providesTags: ["Dashboard"],
    }),

    getDashboardFollowUpListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `dashboard/follow-up-profiles`,
      }),
      providesTags: ["FollowUp"],
    }),

    updateDashboardTableFields: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/permissions/table-permission-create`,
      }),

      invalidatesTags: ["FollowUp"],

      // async onQueryStarted({},{ dispatch, queryFulfilled }) {

      //   await queryFulfilled;

      //   setTimeout(() => {
      //     dispatch(schedules.util.invalidateTags(["Schedules"]));
      //   }, 1000);

      // },
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/dashboard/task-status-update`,
      }),

      invalidatesTags: ["FollowUp"],
    }),

    getDashboardDoNotCallLaterListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `dashboard/do-not-call-later-profiles`,
      }),
      providesTags: ["DoNotCallLater"],
    }),

    updateDoNotCallLater: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/dashboard/do-not-call-later`,
      }),

      invalidatesTags: ["DoNotCallLater"],
    }),

    getDashboardSelectionDataListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `dashboard/selection-request`,
      }),
      providesTags: ["SelectionRequest"],
    }),

    getDashboardListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `dashboard/schedule-termination-profiles`,
      }),
      providesTags: ["DashboardList"],
    }),

    updateScheduleStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/dashboard/schedule-status-change`,
      }),

      invalidatesTags: ["DashboardList"],
    }),

    getDashboardJobAssignListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `dashboard/assign-job-list`,
      }),
      providesTags: ["JobAssign"],
    }),

    getDashboardReportsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `dashboard/selection-request`,
      }),
      providesTags: ["Reports"],
    }),

    getDashboardPendingCallsListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        // params: values,
        endpoint: `dashboard/staff-pending-calls`,
      }),
      providesTags: ["PendingCalls"],
    }),
    //service follow up starts here
    getDashboardServiceFollowUpListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `dashboard/service-follow-up`,
      }),
      providesTags: ["ServiceFollowUp"],
    }),
    //service follow up ends here
  }),
});

export const {
  useGetDashboardDataQuery,
  useGetDashboardFollowUpListDataQuery,
  useUpdateDashboardTableFieldsMutation,
  useUpdateStatusMutation,
  useUpdateScheduleStatusMutation,
  useGetDashboardDoNotCallLaterListDataQuery,
  useUpdateDoNotCallLaterMutation,
  useGetDashboardSelectionDataListDataQuery,
  useGetDashboardListDataQuery,
  useGetDashboardJobAssignListDataQuery,
  useGetDashboardReportsListDataQuery,
  useGetDashboardPendingCallsListDataQuery,
  useGetDashboardServiceFollowUpListDataQuery,
} = dashboard;
