import { useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import {
  useDeactivateStaffMutation,
  useGetStaffListDataQuery,
  useUpdateStaffsListFieldsMutation,
  useFilterDataQuery,
} from "../../../store/queries/Staff";
import { updateConfig } from "../../../store/slices/Staff/staffSlice";
import { updateConfig as updateOrderConfig } from "../../../store/slices/OrderColumns/orderColumnSlice";

const useStaff = () => {
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const navigate = useNavigate();
  const [isMultiSelected, setIsMultiSelected] = useState([]);
  const [errors, setErrors] = useState("");
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [selectedUpdateRows, setSelectedUpdatedRows] = useState([]);

  const { showTableFieldsModal } = useSelector((state) => state.orderColumn);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const [updateStaffFields] = useUpdateStaffsListFieldsMutation();
  const [deactivateStaff] = useDeactivateStaffMutation();

  const {
    data: staffs = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetStaffListDataQuery({
    branch: [...staffState.currentBranchFilter],
    role: [...staffState.currentRoleFilter],
    status: [...staffState?.currentStatusFilter],
    search: staffState?.search,
    per_page: staffState.currentPageSize,
    page: staffState.currentPage,
    sort_by: staffState.sortBy,
    sort_order: staffState.sortOrder,
  });

  const { data: optionsData = {} } = useFilterDataQuery();

  const obj = staffs?.data?.field || {};

  const sortedTableFields = Object.keys(obj)
    .sort((a, b) => obj[a].position - obj[b].position)
    .reduce((newObj, key) => {
      newObj[key] = obj[key];

      return newObj;
    }, {});

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    let permission = staffs?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_create")
    )[0].can_create;

    return permission;

    // eslint-disable-next-line
  }, [staffs]);

  const hasEditPermission = useMemo(() => {
    let permission = staffs?.data?.permissions?.filter((Obj) =>
      Object.keys(Obj).includes("can_edit")
    )[0].can_edit;

    return permission;

    // eslint-disable-next-line
  }, [staffs]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (staffState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = staffState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = false;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleTableDeactivateClick = (item) => {
    setShowDeactivateModal(true);
    setSelectedUpdatedRows(item);
  };

  const handleDeactivateData = (value) => {
    deactivateStaff({ staff_id: selectedUpdateRows }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        setShowDeactivateModal(false);

        toast.success("Successfully Deactivated");
      } else if (response?.error?.status === 422) {
        setErrors(response?.error?.data?.errors);
      }
    });
  };

  const closeDeactivateModal = () => {
    setShowDeactivateModal(false);
  };

  const handleCreateClick = () => {
    navigate("/dashboard/staff-create");
  };

  const handleTableEditClick = (item) => {
    if (item.length > 1) {
      toast.error("Cannot edit multiple staff");
    } else navigate(`/dashboard/staff-create?id=${item?.[0]}`);
  };

  const handleSelectedIds = (ids) => {
    setIsMultiSelected(ids);
  };

  const handleBranchFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = ids;
      })
    );
  };

  const handleRoleFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentRoleFilter = ids;
      })
    );
  };

  const handleStatusFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentStatusFilter = ids;
      })
    );
  };

  const handleEditFieldsClick = () => {
    dispatch(
      updateOrderConfig((state) => {
        state.showTableFieldsModal = true;
      })
    );
  };

  const handleClearFilters = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = [];
        state.currentRoleFilter = [];
        state.search = "";
        state.dateRange = [];
      })
    );
  };

  return {
    staffs,
    staffState,
    currentPage: staffState.currentPage,
    hasCreatePermission,
    hasEditPermission,
    paginationOptions,
    isFetching,
    isLoading,
    sortedTableFields,
    errors,
    showDeactivateModal,
    showTableFieldsModal,
    isMultiSelected,
    optionsData,
    handleSearch,
    handleRoleFilter,
    handleBranchFilter,
    handleStatusFilter,
    handleEditFieldsClick,
    handleClearFilters,
    handleSelectedIds,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    updateStaffFields,
    handleTableDeactivateClick,
    closeDeactivateModal,
    handleDeactivateData,
    handleCreateClick,
    handleTableEditClick,
  };
};

export default useStaff;
