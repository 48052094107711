import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useSendProfileLinkMutation,
  useGetProfileDataQuery,
} from "../../../../store/queries/Profile";
import { phoneRegExp } from "../../../../utils/functions/table";

const useShareProfileLinkForm = ({ userId, handleCancel, ProfileName }) => {
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetProfileDataQuery(userId);

  const [sendRequest] = useSendProfileLinkMutation();

  let shareProfileLinkFormSchema = Yup.object({
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(10, "phone number must be 10 digits")
      .max(10, "phone number must be 10 digits")
      .required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      user_id: userId,
      mobile_number: null,
    },
    validationSchema: shareProfileLinkFormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    sendRequest({ ...values, share_type: 1 })
      .then((response) => {
        formik.setErrors("");
        if (response?.data?.status_code === 200) {
          toast.success("Profile Link Send Successfully!");
          handleCancel();
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Send Profile Link!");
          handleCancel();
        }
      })
      .catch(() => {
        toast.error("Failed to Send Profile Link!");
      });
  };

  const handleWhatsappShare = () => {
    if (formik.values.mobile_number) {
      sendRequest({ ...formik.values, share_type: 2 }).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Profile Link Send Successfully!");
          handleCancel();
          let mob = formik.values.mobile_number;

          window.open(
            `${`https://api.whatsapp.com/send?phone=${mob}&text=${response?.data?.data?.message}&type=custom_url&app_absent=0`}`
          );
        } else if (response?.error?.data?.status_code === 422) {
          formik.setErrors(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Send Profile Link!");
          handleCancel();
        }
      });
    } else {
      toast.error("Failed to Send Profile Link!");
    }
  };
  return { formik, optionsData, isOptionDataSuccess, handleWhatsappShare };
};

export default useShareProfileLinkForm;
