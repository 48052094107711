import { Button, ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import Style from "./PdfCard.module.scss";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export function PdfCard({
  imageData,
  docId,
  verification,
  handleClose,
  handleNonProfileDocumentUpload,
  note,
  showPdfModal,
}) {
  return (
    <ModalLayout
      show={showPdfModal}
      handleClose={handleClose}
      backdrop="static"
    >
      <div className={`${Style.root} pro-p-2`}>
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <>
              <div className={Style.action_icon}>
                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={handleClose}
                >
                  <span className="material-symbols-outlined">close</span>
                </button>
                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={() => zoomIn()}
                >
                  <span className="material-symbols-outlined">zoom_in</span>
                </button>
                <button
                  className={`${Style.icon} pro-btn pro-p-0`}
                  onClick={() => zoomOut()}
                >
                  <span className="material-symbols-outlined">zoom_out</span>
                </button>
              </div>
              <TransformComponent>
                <iframe
                  title="PDF Viewer"
                  // src={`${imageData}#toolbar=0&navpanes=0`}
                  src={imageData || ""}
                  height="700px"
                  width="100%"
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>

        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end pro-items-center`}
        >
          <>
            <Button
              className="pro-btn lg pro-btn-outline"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              className="pro-btn lg pro-btn-primary"
              onClick={() => handleNonProfileDocumentUpload()}
            >
              Upload
            </Button>
          </>
        </div>
      </div>
    </ModalLayout>
  );
}
