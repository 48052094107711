import React from "react";
import Style from "./actionButtonList.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function ActionButtonList({ title, data, children, type, expanded }) {
  // console.log(data, "data");
  const isType2 = type === "type-2";
  const location = useLocation();

  let path = location.pathname.slice(9);
  const { selectedProfiles } = useSelector((state) => state.profile);
  const { partnerMatchFilters } = useSelector((state) => state.partnerMatch);
  return (
    <div className={Style.root}>
      {/* heading */}
      {title && (
        <div className={`${Style.heading} pro-mb-5`}>
          <h6 className="pro-ttl h6 pro-mb-0">{title}</h6>
        </div>
      )}
      {/* loop of buttons */}

      {/* FIXME Aneesh adjust text size for right action drawer action buttons */}

      <div className={Style.items}>
        <ul>
          {data?.map?.((btn) => {
            return (
              <li
                className={`pro-mb-3`}
                key={btn.label}
                title={`${expanded ? btn.title : ""}`}
              >
                <button
                  className={`pro-btn ${
                    isType2 ? `pro-btn-secondary` : `pro-btn-outline`
                  } full`}
                  onClick={() =>
                    btn.handleClick(
                      btn.label,
                      selectedProfiles,
                      partnerMatchFilters,
                      path
                    )
                  }
                >
                  <span className="material-symbols-outlined">{btn.icon}</span>
                  <span>{btn.title}</span>
                </button>
              </li>
            );
          })}
          {children}
        </ul>
      </div>
    </div>
  );
}

export default ActionButtonList;
