import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getActivityDetails,
  updateConfig,
} from "../../../store/slices/Profile/Activity";

import useObserver from "../../../utils/hooks/useObserver";

import { useGetActivityViewHistoryQuery } from "../../../store/queries/Profile";

const useActivity = () => {
  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const state = useSelector((state) => state.activity);
  const dispatch = useDispatch();
  const activityState = state?.activityData?.[activeProfile];

  const [activeFilter, setActiveFilter] = useState(1);
  const [page, setPage] = useState(1);
  const [viewHistoryModal, setViewHistoryModal] = useState(false);
  const [viewHistorySort, setViewHistorySort] = useState("desc");
  const [skip, setSkip] = useState(true);

  const { data: viewHistoryData, isFetching } = useGetActivityViewHistoryQuery(
    {
      users_id: activeProfile,
      contact_profile_id: state.contact_profile_id,
      sort: viewHistorySort,
    },
    {
      skip: skip,
    }
  );

  useEffect(() => {
    if (state?.activityStatus !== "pending") {
      dispatch(
        getActivityDetails({
          page: page,
          id: activeProfile,
          filter: activeFilter,
        })
      );
    }

    //eslint-disable-next-line
  }, [activeProfile, activeFilter, page]);

  useEffect(() => {
    setPage(state.activityData?.[activeProfile]?.page || 1);

    return () => {
      dispatch(
        updateConfig((state) => {
          if (state.activityData?.[activeProfile]?.data) {
            state.activityData[activeProfile].data = [];
            state.activityData[activeProfile].page = 1;
          }
        })
      );
    };
    //eslint-disable-next-line
  }, [activeProfile]);

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    dispatch(
      updateConfig((state) => {
        state.activityData[activeProfile].page += 1;
      })
    );
  };

  const handleChange = (item) => {
    if (item.value !== activeFilter) {
      setPage(1);
      dispatch(
        updateConfig((state) => {
          if (state.activityData?.[activeProfile]?.data) {
            state.activityData[activeProfile].data = [];
            state.activityData[activeProfile].page = 1;
          }
        })
      );
      setActiveFilter(item.value);
    }
  };

  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Contact Viewed By Me", value: 1 },
        { name: "Contact Viewed By Others ", value: 2 },
        { name: "Short Listed By Me", value: 3 },
        { name: "Short Listed By Others", value: 4 },
        { name: "Interest Send By Me ", value: 5 },
        { name: "Interest Send By Others", value: 6 },
        { name: "Interest Accepted By Me ", value: 7 },
        { name: "Interest Accepted By Others", value: 8 },
        { name: "Interest Rejected By Me ", value: 9 },
        { name: "Interest Rejected By Others", value: 10 },
        { name: "Profile Viewed By Me", value: 11 },
        { name: "Profile Viewed By Others", value: 12 },
      ],
    },
  ];

  const { lastElement } = useObserver({
    loading: state?.activityStatus === "pending",
    hasMore:
      activityState?.per_page * activityState?.current_page <
      activityState?.total_count,
    callback: handleLoadMore,
  });

  //function to open the view history modal
  const handleViewHistoryModal = (e, data, index) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      updateConfig((state) => {
        state.contact_profile_id = data?.profile_data?.id;
      })
    );

    setSkip(false);
    setViewHistoryModal(true);
  };

  const closeViewHistoryModal = () => {
    setViewHistoryModal(false);
    setSkip(true);
    dispatch(
      updateConfig((state) => {
        state.viewHistoryData = {};
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSort = () => {
    setViewHistorySort((prevState) => {
      return prevState === "asc" ? "desc" : "asc";
    });
  };

  return {
    activityState,
    state,
    filters,
    activeFilter,
    viewHistoryModal,
    viewHistoryData,
    isFetching,
    handleSort,
    getRow,
    closeViewHistoryModal,
    handleViewHistoryModal,
    lastElement,
    handleChange,
  };
};

export default useActivity;
