import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  search: "",
  sortBy: "",
  sortOrder: "",
  dateRange: [
    moment(Date.now()).add(-30, "days").format("YYYY-MM-DD"),
    moment(Date.now()).format("YYYY-MM-DD"),
  ],
  showEditModal: false,
};

const utmReportSlice = createSlice({
  name: "utmReports",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = utmReportSlice.actions;

export default utmReportSlice.reducer;
